.breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: unset;

    &-item {
        position: relative;
        display: flex;
        align-items: center;
        font-size: $font-size-12;
        line-height: $line-height-16;
        font-weight: $font-weight-medium;

        &:hover {
            a {
                opacity: 0.5;
            }
        }
        &::before {
            display: none;
        }

        &.active {
            pointer-events: none;
            color: $spanish-gray;
        } 

        a {
            display: flex;
            color: $black;
            align-items: center;
            padding-right: 0.5rem;
            font-weight: $font-weight-inter-medium;
        }

        svg {
            height: auto;
            cursor: default;
            margin-bottom: auto;
            pointer-events: none;
            padding-right: sizer(0.5);

            path {
                fill: $spanish-gray;
            }
        }
    }
}