.settings-plan {
    // padding-top: sizer(2);
    @media #{$tablet} {
        .settings-plan-details {
            padding: sizer(1.5);
            &-plan {
                display: grid;
                gap: 0.5rem;
                grid-template-columns: unset !important;
                > div {
                    display: flex;
                    flex-direction: row !important;
                    width: 100%;
                    gap: 0.5rem;
                    span{
                        font-size: $font-size-16;
                        &:last-child{
                            text-align: end;
                            font-size: $mobile-font-size-18;
                            line-height: $line-height-26;
                        }
                    }
                    &:nth-child(4),
                    &:nth-child(7) {
                        display: grid;
                        grid-template-columns: auto auto;
                    }
                }
                span.react-loading-skeleton{
                    width: 100% !important;
                }
            }
            &-cancel-subscription {
                .form-group{
                    width: 100%;
                }
                .button-group {
                    display: flex;
                    flex-direction: column-reverse;
                    gap: 1rem;
                    button{
                        padding: sizer(0.75) sizer(3) !important;
                    }
                }
            }
            .settings-plan-storage-bar {
                width: 100%;
                margin-top: sizer(1.5);
                > div{
                    border-radius: 0;
                }
                &-size{
                    width: 100%;
                    margin: 0;
                }
            }
            .settings-plan-details-title{
                margin-bottom: sizer(1.5);
                gap: sizer(1);
                h2{
                    margin: 0;
                    margin-bottom: sizer(0.5) !important;
                }
            }
            .mobile-modal {
                .mobile-modal-content {
                    gap: 1.5rem;
                }
            }
            &-payment-method{
                width: 100%;
                margin-bottom: sizer(2.5);
               > div {
                > span{
                    justify-content: space-between;
                    img{
                        margin-right: 0;
                    }
                    button{
                        padding: 0;
                    }
                }
                    button:hover {
                        background-color: transparent;
                    }
               }
               .chips-components {
                    margin-left: 0;
               }

            }
            &-pricing {
                padding: sizer(1.5);
                width: 100%;
                .product-features{
                    margin-top: sizer(2.5);
                    ul{
                        padding-top: sizer(2.5);
                        li{
                            display: flex;
                        }
                    }
                }
                .product-name{
                    margin-bottom: sizer(0.5);
                }
                .product-price-label {
                    margin-bottom: sizer(2.5);
                    line-height: $line-height-24;
                }
                .product-price{
                    font-size: $mobile-font-size-32;
                    font-weight: 500;
                    line-height: sizer(2.5);
                }
            }

        }
        .settings-plan-pricing-details {
            margin-top: sizer(3);
            h2 {
                margin-bottom: sizer(2.5) !important;
            }
        }

        .settings-plan-payment-method{
            margin-top: sizer(3);
            .stripe-payment-form {
                width: 100%;
                .StripeElement{
                    width: 100%;
                    padding: sizer(1) sizer(1.5);
                    height: 56px;
                }
                .form-group:nth-child(1) {
                    margin-bottom: sizer(2.5);
                }
                &-btn-group{
                    margin-top: sizer(2.5);

                }
                .stripe-payment-skeleton-group {
                    width: 100%;
                }

            }
            .settings-plan-details-title{
                margin-bottom: sizer(2.5);
                h2{
                    margin: 0;
                }
            }
        }
        button.btn-primary,
        button.btn-secondary{
            padding: sizer(0.75) sizer(3) !important;
            font-size: $font-size-18 !important;
            line-height: 26px !important;
        }
        &-container {
            gap: sizer(2.5) !important;
        }
        .pricing-skeleton {
            width: 100%;
        }
    }//end mobile

    &-alert-notification {
        margin-bottom: sizer(2);
        position: absolute;
        top: -92px;
        margin: unset;
        //padding: 0.5rem 1.5rem;
        max-width: sizer(35);
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: sizer(3);
    }
    &-pricing-details {
        margin-top: sizer(6);

        h2 {
            margin-bottom: sizer(3) !important;
        }
    }

    &-payment-method {
        margin-top: sizer(6);

        .stripe-payment-form {
            width: 550px;
            .form-group >label{
                top: -0.688rem;
                left: 1.25rem;
                line-height: 1rem;
                padding: 0.25rem;
            }
            &.loading {
                .form-group, .stripe-payment-form-btn-group {
                    display: none !important;
                }
            }
            .form-error .error-msg {
                font-weight: 700;
            }
            .stripe-payment-skeleton {
                &-group {
                    width: 500px;
                    display: flex;
                    flex-direction: row;
                    gap: sizer(1);
                    margin-top: sizer(1);

                    span {
                        width: 50%;
                    }
                }
            }

            .form-group {
                &:nth-child(1) {
                    margin-bottom: sizer(3);
                }

                &:nth-child(2) {
                    margin-right: sizer(1);
                }

                &:nth-child(2),
                &:nth-child(3) {
                    display: inline-block;
                    width: calc(50% - sizer(.5));
                }
            }

            .StripeElement {
                display: block;
                font-size: $font-size-16;
                font-weight: $font-weight-normal;
                line-height: $line-height-24;
                color: $black;
                padding: sizer(1.5);
                border: 1px $chinese-white solid;
                background-color: transparent !important;
                border-radius: $border-radius-16;
                &.StripeElement--invalid{
                    border-color: $candy-red;
                }
                &--focus {
                    border-color: $black;
                }
            }

            &-btn-group {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: sizer(1);
                margin-top: sizer(3);
            }
        }
    }

    &-storage {
        &-bar {
            position: relative;
            display: block;
            margin: auto;
            width: 60%;
            height: 24px;
            overflow: hidden;
            background-color: $medium-gray;
            border-radius: $border-radius-40;
            margin-top: sizer(3);

            &-size{
                width: 60%;
                margin: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                span {
                    font-size: $font-size-16;
                    list-style: $line-height-24;

                    &:nth-child(1) {
                        text-align: left;
                    }
                    &:nth-child(2) {
                        text-align: right;
                    }

                    b {
                        font-size: $font-size-20;
                        list-style: $line-height-28;
                    }
                }
            }

            > div {
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                border-radius: 40px;
                z-index: 2;
                background-color: $black;
            }
        }
    }

    &-details {
        padding: sizer(3);
        border: 1px solid $chinese-white;
        border-radius: $border-radius-20;

        &-cancel-subscription {
            padding-top: 1.5rem;
            border-top: 1px solid $chinese-white;
            h3 {
                font-weight: $font-weight-bold;
                font-family: $inter;
            }

            p {
                border-top: 0 !important;
                padding-top: 0 !important;
                margin-bottom: 0 !important;
            }

            .form-group {
                margin: sizer(1.5) 0;
                width: 50%;
                >label{
                    top: -0.688rem;
                    left: 1.25rem;
                    line-height: 1rem;
                    padding: 0.25rem;
                }
            }

            .button-group {
                display: flex;
                flex-direction: row;
                gap: 1rem;
            }
        }

        &-title {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: sizer(1.5);

            h2 {
                margin-right: sizer(1);
            }
        }

        &-plan {
            display: grid;
            gap: sizer(1.5);
            grid-template-columns: repeat(4, 1fr);

            &-cont {
                &.active {
                    //border-bottom: 1px solid $chinese-white;
                    margin-bottom: sizer(1.5);
                }
            }

            &-desc {
                padding-top: sizer(1.5);
                margin-top: sizer(1.5);
                margin-bottom: sizer(1.5);
                border-top: 1px solid $chinese-white;

                &.expiring {
                    margin-bottom: 0;
                }

                span {
                    font-weight: $font-weight-bold;
                }
            }

            p {
                margin-top: sizer(1.5);
            }

            > div {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: sizer(.5);

                span {
                    display: block;
                    width: 100%;
                    height: 100%;

                    + span {
                        font-weight: $font-weight-bold;
                        font-size: $font-size-20;
                        line-height: $line-height-28;
                    }
                }
            }
        }

        &-pricing {
            padding: sizer(3);
            border: 1px solid $chinese-white;
            border-radius: $border-radius-20;
            width: 447px;
            display: flex;
            flex-direction: column;

            .product {
                &-name {
                    margin-bottom: 0;
                    font-family: $inter;
                    margin-bottom: sizer(.25);
                }
                &-price {
                    font-weight: $font-weight-medium;
                    font-size: $font-size-48;
                    line-height: $line-height-56;
                    font-family: $inter;
                    margin-bottom: sizer(.5);

                    &-label {
                        margin-bottom: sizer(3);
                        font-weight: $font-weight-normal;
                        font-size: $font-size-20;
                        line-height: $line-height-16;
                        font-family: $inter;
                    }
                }
                &-features {
                    margin-top: sizer(3);
                    border-top: 1px solid $chinese-white;

                    ul {
                        list-style-type: none;
                        padding-top: sizer(1.5);
                        padding-left: 0;
                        margin-bottom: 0;

                        li {
                            svg {
                                margin-right: sizer(1);
                            }

                            &:not(:last-child) {
                                margin-bottom: sizer(1);
                            }
                        }
                    }
                }
            }

            .btn {
                width: 100%;
                justify-content: center;
            }
        }

        &-payment-method {
            width: 560px;
            padding: sizer(1.5);
            border: 1px solid $chinese-white;
            border-radius: $border-radius-20;
            margin-bottom: sizer(3);

            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: $font-size-16;
                line-height: $line-height-24;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: sizer(1.5);
                    padding-bottom: sizer(1.5);
                    border-bottom: 1px solid $chinese-white;
                }

                span {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: sizer(1.5);
                }

                img {
                    width: 50px;
                    margin-right: sizer(1.5);
                }

                button {
                    // padding: 0;
                    color: $black;
                    &:hover {
                        background-color: unset;
                        opacity: 0.5;
                    }
                }

                .chips-components {
                    margin-left: sizer(0.5);
                }

                .set-default-payment {
                    position: absolute;
                    left: 50%;
                    top: 30px;
                    padding: sizer(1);
                    border: 1px solid $black;
                    border-radius: $border-radius-20;
                    /*box-shadow: 0px 1px 1px 0px #00000014;
                    box-shadow: 1px 2px 2px 0px #9895951F;*/
                    text-wrap: nowrap;
                    transform: translateX(-50%);
                    background-color: $white;
                    z-index: 5;

                    button {
                        padding: sizer(.25) sizer(.5);
                        border-radius: $border-radius-8;
                    }
                }

                &:last-child {

                    span:last-child {
                        div {
                            button:hover {
                                background-color: unset;
                                opacity: 0.5;
                            }

                            &:first-child {
                                .set-default-payment {
                                    button:hover {
                                        background-color: $light-gray;
                                        opacity: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .settings-plan-details-title {
            border-bottom: 1px solid $chinese-white;

            h2 {
                font-weight: $font-weight-semi-bold !important;
                margin-bottom: sizer(1.5);
            }
        }

        h2 {
            font-weight: $font-weight-bold;
        }

        p {
            margin: sizer(1.5) 0;
            padding-top: sizer(1.5);
            border-top: 1px solid $chinese-white;
        }
        .text-button {
            font-weight: $font-weight-bold;
            color: $sunset-orange;
            font-family: $inter;
            
            &.cancel-subscription {
                padding: 0;
            }
        }
    }

    .pricing-skeleton {
        padding: sizer(3);
        border: 1px solid $chinese-white;
        border-radius: $border-radius-20;
        width: 447px;
    }
}