.tooltip {
    &-component {
        display: inline-block;
        text-decoration: none;
        margin-left: 4px;

        svg {
            border-radius: 50%;
            vertical-align: top;
        }

        &:hover {
            opacity: .5;
        }
    }
}

.react-tooltip {
    &.styles-module_tooltip__mnnfp {
        font-family: "Inter";
        font-weight: normal;
        text-align: center;
        font-size: $font-size-12;
        line-height: $line-height-16;
        padding: sizer(.25);
        color: $granite-gray;
        background-color: $bright-gray;
        text-align: center;
        width: 200px;
        opacity: 1;
    }
}

.custom-tooltip .tooltip-inner {
    background-color: $bright-gray;
    color: $black;
}
  
.custom-tooltip .tooltip-arrow::before {
    border-bottom-color: $bright-gray;;
}