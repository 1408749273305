.upload-collection {
    &-wrapper {
        width: 100%;
        //height: 100%;
        height: auto;
        margin-bottom: 3rem;
        @media #{$tablet} {
            margin-bottom: 10rem;
        }
        .upload-offcanvas-form {
            .Mui-expanded {
                svg {
                    transform: rotate(180deg);
                    transition: .25 all ease-in-out;
                }
            }
        }
    }
    &-none {
        margin: sizer(1) 0;

        p {
            color: $spanish-gray;
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }
    &-popper {
        display: flex;
        flex-direction: column;
        z-index: 1;
        margin: sizer(.5) 0;
        border: 1px solid $black;
        border-radius: $border-radius-12;
        padding: sizer(1);
        background-color: $white;
        width: 100% !important;
        transform: translate3d(0px, 66px, 0px) !important;
        position: relative !important;
        top: -60px !important;
        hr {
            margin: sizer(1) 0;
        }

        .MuiPaper-root {
            box-shadow: none;
            color: $black;

            .MuiAutocomplete-listbox {
                max-height: 200px;
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 8px;
                    border-radius: $border-radius-8;
                    height: 8px;
                }
                &::-webkit-scrollbar-track {
                    background: $bright-gray;
                    border-radius: $border-radius-8;
                }
                &::-webkit-scrollbar-thumb {
                    background: $spanish-gray;
                    border-radius: $border-radius-8;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: $spanish-gray;
                }

                li {
                    align-items: center;
                    padding: 0;
                    padding: sizer(.5);
                    background-color: transparent;
                    width: unset !important;

                    &:hover {
                        background-color: $chinese-white
                    }

                    &.MuiAutocomplete-option[aria-selected="true"] {
                        background-color: transparent;
                    }

                    > div {
                        padding-left: sizer(.25);
                        cursor: pointer;
                        align-items: center;
                        .form-check-input-mixed::after {
                            left: 7px;
                            top: 11px;
                        }
                    }

                    span, input {
                        pointer-events: none;
                        word-break: break-all;
                    }

                    hr {
                        display: none;
                    }
                }
            }
        }

        .MuiAutocomplete-noOptions,
        .MuiAutocomplete-listbox {
            padding: 0;
        }
        @media #{$tablet} {
            position: relative !important;
            //top: -60px !important;
            //margin-bottom: 200px;
        }
    }
    &-new {
        &-input-group {
            position: relative;
            margin-bottom: sizer(1);
        }
        &-input {
            padding: sizer(.5) sizer(1.5);
            border: 0;
            background-color: $light-gray;
            border-radius: $border-radius-8;
            color: $black;

            &.red-border { border: 1px solid $candy-red; }

            &:focus {
                background-color: $light-gray;
            }
        }
        &-btn-group {
            margin-top: sizer(2);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: sizer(1);
            @media #{$tablet} {
                &.upload-collection-btn{
                    width: 67.61%;
                    margin: 2rem auto 0;
                }
            }
            .spinner-border {
                margin-left: sizer(.5);
                width: sizer(1);
                height: sizer(1);
            }

            .btn {
                padding: sizer(.5) sizer(1.5);
                font-size: $font-size-16;
                font-weight: $font-weight-inter-medium;

                .spinner-border { z-index: 4; margin-left: 8px; }

                &-primary:has(div){
                    width: 155px;
                    color: $black;

                    &::after {
                        content: 'Creating';
                        z-index: 3;
                        width: fit-content;
                        top: 50%;
                        transform: translate(24px, -50%);
                        height: fit-content;
                        box-shadow: unset !important;
                    }
                }
            }
        }
    }
    &-create {
        border: 0;
        font-size: $font-size-16;
        line-height: $line-height-24;
        background-color: transparent;
        color: $sunset-orange;
        font-weight: $font-weight-bold;
    }
    &-added {
        &-header {
            p {
                margin-bottom: sizer(.5);
                color: $spanish-gray;

                // @media #{$xlarge_and_down} {
                //     font-size: $font-size-12;
                // }
                @media #{$tablet} {
                    font-size: $font-size-16;
                    font-weight: normal;
                    line-height: $line-height-24;
                }
            }
        }
        &-desc {
            display: flex;
            gap: sizer(.5);
            flex-wrap: wrap;
            button {
                border-radius: $border-radius-12;
                max-width: 100%;
                width: auto;
                word-break: break-word;
                height: auto;
            }
            @media #{$tablet}{
                row-gap: 1rem;
            }
        }
    }
    /* &-same {
        margin-top: sizer(1.5);
    } */
    &-add-all {
        margin-top: sizer(1.5);
    }
    &-clear-all, &-add-all {
        display: flex;
        align-items: center;
        justify-content: end;

        button {
            font-weight: $font-weight-medium;
        }
    }
}

.upload-collection-added-header .collection-header{
    color: $spanish-gray;
}

.upload-collection-create:hover{
    opacity: 0.5;
}

.form-poppper-item{
    margin-right: 8px;
    padding-left: 0px !important;

    &:hover{
        background-color: $light-gray !important;
    }
}