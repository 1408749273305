.asset-view {
    &-no-asset {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: sizer(3);
        padding-left: sizer(1.5) !important;
        padding-right: sizer(1.5) !important;
        padding-bottom: sizer(6);
        flex-direction: column;
        margin: 0 !important;

        img {
            height: 306px;
            width: 300px;
        }

        h2 {
            margin-bottom: sizer(1);
        }
        img, p {
            margin-bottom: sizer(3);
        }
    }
    &-container {
        .form-label {
            color: $spanish-gray;
            font-size: $font-size-16;
            font-weight: $font-weight-medium;
            line-height: $line-height-36;
        }
        .form-select {
            font-size: $font-size-16;
            font-weight: $font-weight-medium;
        }
        .icon-button {
            cursor: pointer;
            color: $black;

            &:hover {
                svg {
                    path {
                        fill:  rgb(151, 149, 149, 0.5);
                    }
                }
            }
            &.selected {
                pointer-events: all;
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
            svg {
                path {
                    fill: $spanish-gray;
                }
            }
        }
    }
}

.assets-container {
    &.row {
        margin-top: 0 !important;
        margin-right: calc(-.75 * var(--bs-gutter-x)) !important;
        margin-left: calc(-.75 * var(--bs-gutter-x)) !important;

        > * {
            padding-left: sizer(.75);
            padding-right: sizer(.75);
            margin-top: sizer(3);

            @media (min-width: #{$small_screen_up + 1px}) and (max-width: #{$medium_screen_up}) {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: sizer(2.5);
                margin-top: 0 !important;
            }
            @media #{$tablet_to_mobile} {
                padding-left: sizer(.75);
                padding-right: sizer(.75);
            }
        }

        .asset-card-skeleton:nth-child(1), .asset-card-skeleton:nth-child(2),
        .asset-card-skeleton:nth-child(3), .asset-card-skeleton:nth-child(4) {
            @media #{$medium_and_up} {
                margin-top: 0;
            }
        }

        .asset-card-skeleton:nth-child(1) {
            @media #{$medium_and_down} {
                margin-top: 0;
            }
        }

        .asset-card-skeleton:nth-child(4) {
            @media #{$large_and_down} {
                margin-top: sizer(3);
            }
        }

        .nousersfound {
            display: flex;
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            flex-direction: column;
            margin-top: unset !important;
        }
    }
    .no-cards-found {
        margin-top: unset;
        padding-right: unset;

        p {
            padding: unset;
        }

        ul {
            padding: unset;
            padding-left: sizer(1.6);

            li {
                line-height: $line-height-24;
            }
        }
    }
    .no-results{
        margin-top: 0;
        p{
            font-weight: $font-weight-bold;
            padding-bottom: 0;
        }
        ul{
            padding-left: 2rem !important;
            li{
                line-height: $line-height-28;
            }
        }
    }
    .table {
        line-height: $line-height-56;

        img {
            width: 5.71rem;
            height: 3.75rem;
            overflow: hidden;
            object-fit: cover;

            &:hover {
                cursor: pointer;
            }
        }
        svg {
            font-size: $font-size-48;
        }
        .modal-container {
            position: absolute;
        }

        thead {
            @media #{$tablet} {
                display: none;
            }
        }
        tr {
            border-bottom: 1px solid $chinese-white;

            @media #{$tablet} {
                border: unset;
                position: relative;

                &:first-child {
                    td {
                        margin-top: unset;
                    }
                }

                hr {
                    position: relative;
                    width: calc(100% + 96px);
                    left: -48px;
                    margin: 0;
                    padding: 0;
                }
            }

            &.asset-card-skeleton-list-view {
                border-bottom: 0;

                td {
                    span {
                        position: relative;
                        top: -2px;
                        width: 100%;
                        height: 100%;
                    }
                }

                @media #{$large_and_up} {
                    hr {
                        margin-top: sizer(1.5);
                        margin-bottom: sizer(.5);
                        padding: 0;
                    }
                }
            }

            td {
                padding: 0 0.5rem;
                display: inline-flex;
                height: 4.75rem;
                border: unset;

                &.thumbnail {
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                &>div.td-assets{
                    height: 4.5rem;
                }
                &:first-child {
                    width: 8rem;
                    div{
                        display: flex !important;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                    }

                    .mobile-modal-header {
                        flex-direction: row;
                        .list-view-title-container {
                            flex-direction: row;
                        }
                        .mobile-modal-close{
                            width: auto;
                        }
                    }
                    
                }
                &:nth-child(2) {
                    div.td-assets {
                        display: flex;
                        align-items: center;
                        width: calc(100% - 50px);

                        .list-view-title-container {
                            width:100%;
                            display:inline-flex;
                            flex-wrap:nowrap;

                            .first-text {
                                flex: 0 1 content;
                                text-overflow: ellipsis;
                                overflow:hidden;
                                white-space:nowrap;
                                max-width: fit-content;
                                width: auto;

                                @media #{$tablet} {
                                    width: fit-content;
                                    max-width: 300px;
                                }

                                @media #{$mobile} {
                                    width: fit-content;
                                    max-width: 200px;
                                }
                            }

                            .end-text {
                                flex: 1 0 content;
                                white-space:nowrap;
                            }
                        }

                        .mobile-type-and-size-container {
                            display: none;
                        }
                    }
                }
                &:nth-child(3)>div.td-assets,
                &:nth-child(4)>div.td-assets,
                &:nth-child(5)>div.td-assets{
                    display: flex;
                    align-items: center;
                    gap: sizer(1);

                    .mobile-more-vertical { display: none; }
                }

                &:first-child { width: 10%; padding: 0 0 !important; }
                &:nth-child(2) { width: 50%; }
                &:nth-child(3) { width: 10%; }
                &:nth-child(4) { width: 10%; }
                &:nth-child(5) { width: 20%; }

                @media #{$tablet} {
                    height: 60px;
                    margin-bottom: sizer(1);
                    margin-top: sizer(1);

                    &:nth-child(1) {
                        width: 80px;
                        padding: unset;

                        div {
                            img { width: 80px; }
                        }
                    }
                    &:nth-child(2) {
                        width: calc(100% - (80px + 24px));
                        padding: 0 sizer(0.5);
                        align-items: center;

                        div.td-assets {
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            gap: sizer(.5);
                            width: 100%;

                            .list-view-title-container {
                                div {
                                    font-size: $font-size-16;
                                    line-height: $line-height-24;
                                }
                            }

                            .mobile-type-and-size-container {
                                display: flex;
                                gap: sizer(1);

                                span {
                                    font-family: $inter;
                                    font-size: $font-size-12;
                                    line-height: $line-height-16;
                                    color: $spanish-gray;
                                }
                            }
                        }
                    }
                    &:nth-child(3) { display: none; }
                    &:nth-child(4) { display: none; }
                    &:nth-child(5) {
                        width: sizer(1.5);
                        padding: unset;
                        align-items: center;

                        div.td-assets {
                            .mobile-more-vertical {
                                display: block;
                                cursor: pointer;

                                svg {
                                    font-size: sizer(1.5);
                                }
                            }

                            button { display: none; }
                        }
                    }
                }
            }
            th {
                font-weight: $font-weight-inter-medium;
                line-height: unset;
                vertical-align: middle;
                display: inline-flex;
                align-items: center;
                gap: 4px;
                height: 4rem;
                border: unset;
                margin-bottom: unset;

                &:first-child { width: 10%; }
                &:nth-child(2) { width: 50%; }
                &:nth-child(3) { width: 10%; }
                &:nth-child(4) { width: 10%; }
                &:nth-child(5) { width: 20%; }

                &.small-column {
                    // width: 14rem !important;
                }
                svg {
                    margin-left: 0.25rem;
                    font-size: $font-size-14;
                    path { fill: $white; }
                }
                div{
                    display: flex;
                    align-items: center;
                    height: 50px;
                }
            }
        }
        .svg-container {
          svg {
            width: 100%;
            height: 4.75rem;
          }
        }
        .button-group {
            gap: sizer(1);
            .spinner-border {
                width: $icon-size;
                height: $icon-size;
            }
            button {

                &.btn-row-action {
                    height: initial;
                    padding: unset !important;
                }
                align-items: center;
                width: $icon-size;
                height: $icon-size;
                border: 0;
                flex: initial;

                svg {
                    color: $black;
                    width: $icon-size;
                    height: $icon-size;
                }
            }
        }
    }
}

.table-assets>thead>tr>th{
    padding: 12px 0.5rem;
}