.view-mobile-modal {
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: unset !important;
    justify-content: flex-end !important;
    z-index: 999;

    &-content {
        height: 100% !important;
        background-color: $white;
        display: flex;
        align-items: unset !important;
        justify-content: unset !important;
        flex-direction: column;
        gap: sizer(.75);
        padding: sizer(0) sizer(1.5) sizer(.75) sizer(1.5);
        position: relative;

        &#with-btn-bottom {
            padding-bottom: unset;
        }

    }
    .sub-title{
        margin-top: sizer(0.75);
    }
    &-body{
        display: flex;
        flex-direction: column;
        gap: 48px;
        h1{
            font-family: $urbane-rounded;
            margin-bottom: 0;
            font-weight: 600;
            line-height: 40px;
        }
        .name-role, 
        .email-username, 
        .approve-details{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        .email-username > p {
            display: flex;
            gap: sizer(1);
        }
        .paragraph-1{
            margin-bottom: 0;
        }
        .approved-name{
            font-weight: 700 !important;
        }
        .view-mobile{
            &-modal-check{
                .check-data{
                    height: 68px;
                    padding: 12px 0;
                }
                .form-check{
                    position: absolute;
                    left: 0;
                    height: sizer(1.5);
                    width: sizer(1.5);
                    top: 35%;
                    display: unset;
                    input {
                        float: unset;
                        margin-left: 2px;
                        height: sizer(1.25);
                        width: sizer(1.25);
                        border: 2px solid $black;
                    }
                }
                .role-desc-container {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-left: sizer(2);
                }
            }
           
        }
        .view-mobile-modal-btn{
            position: fixed;
            bottom: 0;
            height: 74px;
            background-color: $light-gray;
            display: flex;
            align-items: center;
            width: 88%;
            &::before{
                content: "";
                height: 74px;
                width: 140%;
                position: absolute;
                bottom: -1px;
                left: -50px;
                //clip-path: inset(0 -100vmax);
                //box-shadow: 0 0 0 100vmax $light-gray;
                border-bottom: 1px solid $light-gray;
                background-color: $light-gray;
            }
            &.mobile-offcanvas-btn{
                width: 88%;
                height: 102px;
                @media screen and (max-width: sizer(25)){
                    height: 120px;
                }
                &.success-error{
                    height: 74px;
                }
                &::before{
                    height: 102px; 
                    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5), 0 0 4px -4px rgba(0, 0, 0, 0.5); 
                    @media screen and (max-width: sizer(25)){
                        height: 120px;
                    }
                }
                &.success-error::before{
                    height: 74px; 
                    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5), 0 0 4px -4px rgba(0, 0, 0, 0.5); 
                }
                .button-groups-offcanvas{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    gap: 12px;
                    padding: 12px 0;
                    z-index: 1;
                    .paragraph-2{
                        margin-bottom: 0;
                        color: $spanish-gray;
                        font-size: 11px;
                    }
                }
            }
            .button-groups{
                display: flex;
                flex-direction: row !important;
                align-items: center;
                justify-content: center;
                gap: 20px;
                position: relative;
                z-index: 1;
                width: 100%;
                .btn.btn-secondary{
                    background-color: transparent;
                }
            }
        }
     
    }
    &-header {
        display: flex;
        justify-content: unset !important;
        align-items: center;
        gap: sizer(0.5);
        padding: 12px 0;
        height: 74px;
        position: relative;

        &::before {
            content: "";
            height: 0.5px;
            width: 115%;
            position: absolute;
            bottom: -1px;
            left: 0;
            transform: translateX(-25px);
            //clip-path: inset(0 -100vmax);
            //box-shadow: 0 0 0 100vmax #E1E1E1;
            border-bottom: 1px solid #E1E1E1;
        }

        h3 {
            font-weight: $font-weight-medium;
            color: $black;
            margin-bottom: unset;
        }
      
    }
}