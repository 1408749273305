.upload-offcanvas {
    &-sticky-collapse-bar {
        display: flex;
        justify-content: center;
        width: 30px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background-color: $light-gray;
        cursor: pointer;

        &.sticky {
            position: fixed;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 30px;
            position: absolute;
            top: sizer(3.5);
            left: 0;
            background-color: $primary;
            border-radius: $border-radius-12;
            opacity: 0;
            transition: all .25s ease-in-out;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
            svg {
                path {
                    fill: $white;
                }
            }
        }

        svg {
            position: relative;
            top: sizer(4);
            left: sizer(.25);
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            path {
                fill: $spanish-gray;
                transition: all .25s ease-in-out;
            }
        }
    }
    &-show {
        .upload-view-sticky-bottom {
            width: calc(100% - 30% + sizer(.5));
            @media #{$tablet} {
                width: 100%;
                padding: sizer(0.75) sizer(1.5);
                span{
                    font-size: $font-size-18;
                    font-weight: $font-weight-medium;
                    line-height: $line-height-26;
                }
            }
        }
        // .upload-view-wrapper {
        //     h2 {
        //         margin-bottom: 0;
        //     }
        // }
        .upload-process-grid-view .upload-card-wrapper {
            flex: 0 0 calc(100% / 4 - sizer(1.25));
            max-width: calc(100% / 4 - sizer(1.25));

            @media #{$small_and_down} {
                flex: 0 0 calc(100% / 2 - sizer(1.25));
                max-width: calc(100% / 2 - sizer(1.25));
            }
        }

        .upload-view-wrapper {
            width: 70%;
            padding-bottom: sizer(8);
            min-height: 100vh;
           
            @media #{$xlarge_and_down} {
                width: 65%;
            }
            @media #{$large_and_down} {
                width: 50%;
            }
            @media #{$medium_and_down} {
                width: 20%;
            }
            @media #{$small_and_down} {
                width: 10%;
            }
        }
        &.error-canvas{
            .upload-offcanvas-wrapper {
                //top: 0;
                transform: translate(0, 0);
            }
        }
        .upload-offcanvas-wrapper {
            //transform: translateX(0);
            top: unset;
            transform: translate(0, 0);
            
            @media #{$tablet} {
                transform: translate(0, 0);
            }
        }
        .upload-dropzone-wrapper {
            height: 240px;
        }
    }
    &-collapse {
        color: $spanish-gray;
        font-size: $font-size-16;
        line-height: $line-height-24;
        padding: sizer(.5);
        margin-bottom: sizer(1);
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: $border-radius-16;
        transition: all .25s ease-in-out;
        width: fit-content;

        &:hover {
            background-color: $primary;
            color: $white;

            svg {
                path {
                    fill: $white;
                }
            }
        }
        svg {
            width: 15px;
            height: 15px;
            margin-right: sizer(.75);

            path {
                fill: $spanish-gray;
            }
        }
    }
    &-wrapper {
        position: absolute;
        width: 30%;
        height: 100%;
        z-index: 6;
        display: inline-flex;
        top: 0;
        right: 0;
        padding: sizer(6) sizer(3);
        transform: translateX(100%);
        // transition: transform .25s ease-in-out;
        border-left: 1px solid $chinese-white;
        color: $black;
        background-color: $white;
        justify-content: center;
        overflow-y: scroll;
        margin-right: -8px;

        &::-webkit-scrollbar {
            width: sizer(.5);
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-track {
            background: $bright-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb {
            background: $spanish-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $spanish-gray;
        }

        &.sticky {
            z-index: 9;
            height: 100%;
            position: absolute;
            top: auto !important;

            .upload-offcanvas-cont {
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                padding: sizer(3);
                &.progress-state{
                    padding: sizer(3) sizer(3);
                }
                .upload-collection-wrapper {
                    padding-bottom: 3rem;
                }
                @media #{$tablet} {
                    padding: 0 sizer(1.5) sizer(1.5) !important;
                    .upload-error-wrapper{
                        margin-top: sizer(6);
                    }
                }
            }
            @media #{$tablet} {
                &.progress-state-wrapper{
                    z-index: 15;
                }
            }
        }

        .upload-tag-tagging {
            margin-bottom: 24px;
        }

        ol {
            list-style-type: decimal;

            > li {
                margin-bottom: sizer(2);

                &:first-child {
                    p {
                        span {
                            color: $spanish-gray;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }

                button {
                    margin-left: sizer(1 * -1);
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-left: sizer(1 * -1);

                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-top: 1px solid $chinese-white;
                    padding-top: sizer(.75);
                    padding-bottom: sizer(.75);

                    &:first-child {
                        border-top: 0;
                        padding-top: 0;
                    }

                    p {
                        display: flex;
                        align-items: center;
                        color: $black;
                        margin-bottom: 0;

                        &:first-child {
                            color: $spanish-gray;
                            min-width: 120px;
                            margin-right: sizer(.5);

                            svg {
                                path {
                                    fill: $spanish-gray;
                                }
                            }
                        }

                        svg {
                            margin-right: sizer(.5);
                        }
                    }
                }
            }
        }

        @media #{$xlarge_and_down} {
            width: 35%;
        }
        @media #{$large_and_down} {
            width: 50%;
        }
        @media #{$tablet} {
            width: 100%;
            margin-right: unset;
            z-index: 5;
            position: fixed;
        }
        @media #{$small_and_down} {
            // width: 90%;
            padding: sizer(1.5) sizer(1.5);
        }
    }
    &-cont {
        width: 100%;
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        // &:not(.progress-state) {
        //     height: calc(100% + 82px);
        // }

        h3 {
            font-weight: $font-weight-bold;
            font-family: $inter;
            margin-bottom: sizer(1.5);
        }

        p {
            margin-bottom: sizer(1.5);
            @media #{$tablet} {
                margin-bottom: sizer(0);
            }
        }
    }
    &-form {
        * {
            // color: $spanish-gray;
        }
        > div {
            position: relative;

            &:not(:last-child) {
                margin-bottom: sizer(3);
            }

            .form-check-input {
                margin-right: sizer(.5);
                @media #{$tablet} {
                    margin-right: sizer(1);
                    width: 24px;
                    height: 24px;
                }
            }

            .error {
                position: absolute;
                left: 0;
                bottom: 0;
                font-weight: $font-weight-bold;
            }
            .upload-char-container {
                display: flex;
                justify-content: flex-end;
            }
            .upload-char-limit-right {
                margin-left: auto;
                position: absolute;
                bottom: -1.25rem;
                background-color: #ffffff;
                font-size: 12px;
                line-height: 16px;
                color: #989595;
                padding: 0 0.5rem;
                margin-top: 0.5rem;
            }
            .upload-char-limit {
                width: 100%;
                position: absolute;
                width: 100%;
                right: 0;
                bottom: sizer(1.25 * -1);
                display: flex;
                gap: 8px;
                background-color: $white;
                font-size: $font-size-12;
                line-height: $line-height-16;
                color: $spanish-gray;
                padding: 0 sizer(.5);
                margin-top: sizer(.5);

                span {
                    &:nth-child(1) {
                        width: 80%;
                        word-wrap: wrap;
                        font-weight: $font-weight-normal;
                    }
                    &:nth-child(1) {
                        width: unset;
                    }
                }

                &:has(span:nth-child(2)) {
                    span:nth-child(2) { margin-left: auto; }
                }
            }
            label {
                color: $black !important;
                padding: 0 sizer(.25);

                &.collection-label {
                    position: absolute;
                    inset: unset;
                    top: -7px;
                    left: 14px;
                    font-size: $font-size-12;
                    line-height: $line-height-16;
                    font-family: $inter;
                    background-color: $white;
                    padding: 0 sizer(0.25);
                }
            }

            & > .Mui-expanded > div > div {
                border: 1px solid $black !important;
            }
        }

        .collection-spinner {
            position: absolute;
            right: sizer(1);
            top: sizer(1);
            width: 20px;
            height: 20px;
        }
        .MuiAutocomplete-root {
            &.Mui-focused {
                .MuiInputBase-root {
                    border-color: $black;
                }
                .MuiFormLabel-root {
                    color: $black;
                    background-color: $white;
                }
            }

            .Mui-disabled {
                pointer-events: none;
                cursor: default;

                input,
                fieldset {
                    pointer-events: none;
                }
            }
            .MuiFormLabel-root {
                font-size: $font-size-16;
                line-height: $line-height-24;
                padding: 0 sizer(.5);
                color: $spanish-gray;
            }
            .MuiInputBase-root {
                border: 1px solid $chinese-white;
                border-radius: $border-radius-12;
                padding: sizer(1) !important;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                input {
                    padding: 0;
                }
            }
            .MuiOutlinedInput-notchedOutline {
                border: 0 !important;
            }
            .MuiFormControl-root {
                margin: 0;
                display: flex;
                align-items: center;
                @media #{$tablet} {
                    >div{
                        border-radius: sizer(1.25);
                        padding: sizer(1) sizer(1.5) !important;
                        input{
                            line-height: $line-height-24;
                            color: $black;
                            opacity: 1;
                            &::placeholder{
                                color: $spanish-gray;
                                opacity: 1;
                            }
                        }
                        svg{
                            path{
                                fill: $black;
                            }
                        }
                    }
                }

            }
        }
        @media #{$tablet} {
            &-collection{
                padding-bottom: 1px;
                position: relative;
            }
            label{
                left: sizer(1.25) !important;
            }
        }
    }
    &-header {
        width: 100%;
        margin-bottom: sizer(3);

        // &::after {
        //     content: '';
        //     position: absolute;
        //     bottom: -12px;
        //     left: -7.5vw;
        //     max-width: 105vw;
        //     width: 105vw;
        //     height: 1px;
        //     background: $chinese-white;
        // }

        @media #{$medium_and_up} {
            .mobile-back-button, .upload-offcanvas-header-hr {
                display: none;
            }
            .upload-offcanvas-close {
                display: block;
            }
        }

        .mobile-back-button, .upload-offcanvas-header-hr {
            display: none;
        }
        .upload-offcanvas-close {
            display: block;
        }

        @media #{$tablet} {
            position: relative;
            padding: sizer(0.75) 0;
            height: 74px;
            display: flex;
            align-items: center;
            margin-bottom: sizer(1.5);
            h3{
                display: flex;
                gap: sizer(0.5);
                font-weight: $font-weight-medium;
                button{
                    padding: 0;
                }
            }
            .mobile-back-button, .upload-offcanvas-header-hr {
                display: table-row;
            }
            .upload-offcanvas-close {
                display: none;
            }
            .paragraph-1 {
                display: none;
            }
            &::after{
                content: "";
                position: absolute;
                width: 140%; //63%;
                height: 1px;
                bottom: 0;
                background-color: $chinese-white;
                transform: translateX(-48px);
                @media (max-width: 500px) {
                    //width: 427px;
                }
            }
        }

        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            + p {
                margin-top: sizer(.5);
                margin-bottom: sizer(3);
            }

            .upload-offcanvas-close {
                .icon-button svg {
                    path {
                        fill: $black !important;
                    }
                }
            }
        }
    }
    &-no-files {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        height: auto;
        color: $spanish-gray;
        font-size: $font-size-20;
        line-height: $line-height-28;
    }
    &-close {
        svg {
            path {

                &:hover{
                    fill: #00000080;
                }
                fill: $black !important;
            }
        }
    }
}

.upload-offcanvas-no-files{
    margin-top: 240px;
    font-weight: 500;
}