.chips-components {
    &-color {
        padding: sizer(0.25) sizer(1);
        border-radius: $border-radius-40;

        &.green {
            background-color: $light-green;
            color: $leafy-green;
        }
        &.red {
            background-color: $dawn-pink;
            color: $candy-red;
        }
        &.orange {
            background-color: $light-orange;
            color: $orange;
        }
    }
}