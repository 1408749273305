.user-group {
    font-size: $font-size-16;
    line-height: $line-height-24;

    button.icon-button.selected {
        pointer-events: all;
    }

    & > .form-group {
        h2 { margin-bottom: 48px; padding: 0; }
        .form-search > input { width: 414px; }
    }

    .tablePagination {
      padding-top: 48px;
    }

    .selectall_checkbox {
        position: unset;
        top: unset;
        left: unset;
        background-color: unset;
        padding: unset;
        font-size: unset;
        color: unset;
        z-index: unset;

        .ant-checkbox-inner {
            height: 24px; width: 24px;
            top: -5px;
            &::after { inset-inline-start: 26.5%; }
        }
        .ant-checkbox-checked:after { top: -5px; }
    }

    &-settings-bar {
        width: 100%;
        border-top: 1px solid $chinese-white;
        border-bottom: 1px solid $chinese-white;
        padding: sizer(.25) sizer(1);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
            display: flex;
            align-items: center;
        }
        &-right {
            display: flex;
            flex-direction: row;
            gap: sizer(2.5);
            align-items: center;
        }

        .btn:disabled {
            background-color: transparent;
        }
    }

    &-table {
        // margin-top: sizer(3);

        .ant-table-cell {
            width: calc(100% / 4);
        }
        .custom-ant-table  {
            margin-top: sizer(1.5);

            .ant-table {
                colgroup,
                .ant-table-tbody .ant-table-row-expand-icon-cell,
                .ant-table-thead .ant-table-row-expand-icon-cell {
                    display: none;
                }

                .ant-table-tbody {
                    .ant-table-expanded-row {
                        .ant-table-custom-expanded-cell {
                            > div {
                                //width: calc(100% / 4);
                                width: 21.5%;
                            }
                        }
                    }
                }
            }

            .ant-table-tbody {
                .ant-table-row.ant-table-row-level-0 {
                    .ant-table-cell {
                        .ant-table-custom-cell {
                            display: flex;
                            flex-direction: column;
                            gap: 0;

                            & > span {
                                display: flex;
                                align-items: center;

                                label {
                                    top: 14px;
                                    padding: 0;
                                    left: 0;

                                    .ant-checkbox-inner {
                                        height: 24px; width: 24px;
                                        &::after { inset-inline-start: 26.5%; }
                                    }
                                    .ant-checkbox-checked:after { top: 0px; }

                                }


                                input[type="text"] {
                                    width: 328px;
                                    height: 24px;
                                    border: unset;
                                    border-bottom: 1px solid #000;
                                    border-radius: unset;
                                    padding: 0;

                                    &:focus { box-shadow: unset; }
                                }

                                &:last-child {
                                    gap: 10px;
                                    justify-content: space-between;
                                    & > div > div {
                                        display: flex;
                                        align-items: center;
                                        font-weight: 700;

                                        & > svg { margin-right: 8px; }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .actions_groups_btns {
            display: flex;
            flex-direction: row;
            gap: sizer(1);

            /* button {
                padding: 0;
            } */
        }
    }

    &-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
            margin-bottom: sizer(3);
        }

        h2 {
            margin-bottom: sizer(1);
        }

        p {
            color: $spanish-gray;
            font-weight: $font-weight-medium;
            margin-bottom: 0;
        }
    }

    .user-group-input {
        display: flex;
        align-items: center;
    }

    .user-group-input-message {
        & > div:last-child {
            margin-left: auto;
        }
    }
}

.user-group-settings-bar { padding: 0.25rem 1rem 0.25rem 0; }

.nogroupsfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    & h2 { margin: 48px 0 16px 0; }
}

.no-user-found-search {
    margin-top: 48px;

    & > h2 {
        font-size: $font-size-16;
        line-height: 24px;
        font-weight: 700;
    }
}

.ant-modal-body {
     & > ul {
        height: 136px;
        overflow-y: scroll;
        /* Hide scrollbar arrows in WebKit browsers (Chrome, Safari) */
        ::-webkit-scrollbar {
            width: 12px; /* Adjust the width as needed */
            border-radius: $border-radius-8;
        }

        ::-webkit-scrollbar-button {
            display: none;
        }

        /* Optional: Customize scrollbar track and handle */
        ::-webkit-scrollbar-track {
            background-color: #f1f1f1;
            border-radius: $border-radius-8;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: $border-radius-8;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
     }
}

.disabled-icon {
    opacity: 0.5; /* Make the icon half transparent */
    pointer-events: none; /* Disallow any mouse interaction with the icon */
    cursor: not-allowed; /* Optional: change cursor to indicate the item is not interactable */
}

.alert-notification {
    margin: 48px 0 48px 0;
}

.col-sm-9:has(#user-tabs-tabpane-user-group.active.show .alert-notification){
    padding-top: 0 !important;
 }

 .ant-table-cell > span > button {
    border-radius: 12px;
    padding: 8px 24px;
    height: unset;

    &:nth-child(1) { border: 1px solid #000; }
    &:nth-child(2) {
        border: none;

        &[disabled] {
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07),
                    2px 8px 4px 0px rgba(0, 0, 0, 0.04);
        }

        &:not([disabled]) {
            background-color: #000;
            color: #fff;
        }
    }

    & > span { font-weight: 500; }
}