.user-list {
  @media #{$tablet} {
    thead{
      display: none;
    }
    table{
      //transform: translateX(-24px);
      //width: 113%;
      table-layout: fixed;
      .ant-table-expanded-row{
        display: none;
      }
      span{
        color: $black;
        &.ant-checkbox-inner{
          border-color: $black;
        }
      }

    }
    .ant-table-cell{
      width: 100% !important;

      &.mobile-col-name{
          padding-right: 0 !important;
          padding-left: 41px !important;
          border-bottom: 0;
          position: relative;
          &::after{
            content: "";
            position: absolute;
            width: 1400px; 
            height: 0.5px;
            bottom: 0;
            background-color: $chinese-white;
            transform: translateX(-105px);
            @media screen and (max-width: 500px) {
                width: 442px;
            }
        }
      }
      &.mobile-col-actions{
        border-bottom: 0;
        padding-left: 1rem !important;
        padding-right: 0 !important;
        width: auto !important;
        button.mobile-more-vertical{
          padding: 0;
          margin-right: -4px;
          svg{
             transform: rotate(90deg);
          }

        }
      }
    }
    .tablePagination {
      padding-top: sizer(2.5) !important;
      padding-bottom: sizer(0.5) !important;
      span.table-pagination{
        font-size: $mobile-font-size-18;
        line-height: $line-height-26;
      }
    }

    .mobile-setting-bar {
      display: flex;
      flex-direction: column;
      gap: sizer(1);
      .mobile-setting-filter{
        transform: translateX(-24px);
        width: 113%;
    
        button.icon-button-text {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 24px;
          padding-bottom: sizer(1);
          width: 40%;
        }

        &::after{
          content: "";
          position: absolute;
          width: 1400px; 
          height: 1px;
          bottom: 0;
          background-color: $chinese-white;
          transform: translateX(-105px);
          @media screen and (max-width: 500px) {
              width: 442px;
          }
        }
      }
      .mobile-modal-body .mobile-modal-sort .sort-data {
        position: relative;
        width: 100%;
        height: 48px;
        label {
          font-size: 16px;
          margin-left: 24px;
          left: 0 !important;
          padding: 0 8px;
          &::before{
            left: -22px;
          }
          &::after{
            left: -17px;
            height: 10px;
            width: 10px;
          }
        }
      }
      .mobile-role{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        p{
          margin-bottom: 4px;
          font-weight: 500;
        }
        &-container{
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-bottom: sizer(0.75);
          .form-check {
            height: sizer(3);
            padding: 12px 0;
            margin: 0;
            label {
              left: 0 !important;
            }
            &-input + label {
              margin-left: 0.5rem;
              padding: 0;
              font-weight: 400;
            }
            &-input {
              margin-left: 0;
              width: sizer(1.5);
              height: sizer(1.5);
              padding: 0;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
            }
          }
        }
      }
      .filters-h3{
        font-weight: 400;
        margin-bottom: sizer(0);
      }
      .filter-title {
        margin-bottom: sizer(0.5) !important;
      }
      .mobile-rangepicker-container{
        height: sizer(5.25);
        width: sizer(22.875);
        display: flex;
        align-items: center;
        label {
          top: sizer(2.8) !important;
          left: sizer(1.5) !important;
        }
      }
      .asset-filter-top{
        button{
          font-size: sizer(1);
          line-height: 26px;
        }
      }
      .mobile-check-delete{
        background-color: $light-gray;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding: sizer(0.75) sizer(1.5);
        position: fixed;
        width: 100%;
        height: 74px;
        z-index: 99;
        left: 0;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5), 0 0 4px -4px rgba(0, 0, 0, 0.5);
        .mobile-user-check{
          width: sizer(20.375);
          span{
            font-size: $mobile-font-size-18;
            font-weight: 500;
            line-height: 26px;
            &.ant-checkbox-checked span{
              background: $black !important;
            }
            span{
              background: transparent;
            }
          }
          .ant-checkbox-inner:before {
            background-color: $black;
            content: "";
            display: inline-block;
            position: absolute;
            top: 45%;
            left: 5px;
            height: 1.67px;
            width: 11.67px;
          }
          .selectall_checkbox.some-checkbox-filled:hover .ant-checkbox .ant-checkbox-inner::before {
              background-color: transparent !important;
          }
        }
        .mobile-user-bulk-delete{
          button{
            padding: 0;
          }
        }
      }
    }
    .form-search{
      #search-full-name{
        padding: sizer(1) sizer(4) !important;
        padding-left: 4rem !important;
        border-radius: sizer(2.5);
      }
    }

    .form-group label {
      top: unset !important;
      left: 0 !important;
    }
    .ant-picker-range .ant-picker-input input {
      padding: 0 !important;
    }
    .ant-checkbox .ant-checkbox-input,
    .ant-checkbox .ant-checkbox-inner,
    .ant-checkbox-checked::after
     {
      width: sizer(1.5);
      height: sizer(1.5);
    }

    .ant-checkbox-inner:hover,
    .ant-checkbox-wrapper:hover {
      opacity: unset;
    }
    .tablecheckbox .ant-checkbox {
      top: -5px !important;
      left: 0;
    }
    .custom-ant-table.ant-table-wrapper .ant-table-tbody > tr > td {
      padding-left: sizer(4);
    }
    .ant-pagination{
      &.ant-table-pagination{
      margin-top: sizer(2.5) !important;
      }
      &-item-active {
        height: 40px !important;
      }
      li{
        height: 40px;
        width: 24px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.ant-pagination-options{
          display: none !important;
        }
        .ant-pagination-item-link{
          display: flex;
          align-items: center;
        }
      }
      a:hover {
        color: unset !important;
        opacity: unset !important;
      }
    }
   .role-desc-container{
      display: flex;
      flex-direction: column;
      gap: 4px;
      span{
        &.paragraph-1{
          margin-bottom: 0;
        }
        &.role-desc{
          font-size: $font-size-12;
          font-weight: 400;
          line-height: $line-height-16;
          color: $spanish-gray
        }
      }
   }
   button.mobile-more-vertical{
    border: none;
    background: transparent;
  }
  .nousersfound{
    padding-top: sizer(2.5) !important;
  }
  }

/* User List Table Skeleton */
  &-table-skeleton {
    width: 100%;
    margin-top: sizer(3);

    > span {
      display: block;
      margin-bottom: sizer(1.5);

      + div {
          background-color: $black;
          color: $white;

          > div {
              border-bottom: 0;
              // @media screen and (max-width: $large_screen){
              //   &:nth-child(1),
              //   &:nth-child(2),
              //   &:nth-child(3),
              //   &:nth-child(5){
              //     flex-grow: unset;
              //   }
              // }
          }
      }

    }
    > div {
        display: flex;
        flex-direction: row;

        > div {
            padding: sizer(1.25) sizer(3);
            flex-grow: 1;
            border-bottom: 1px solid $chinese-white;

            &:nth-child(0) {
                width: 10px;
            }

            &:nth-child(2) {
              @media (min-width: $large_screen_up) {
                padding: sizer(1.25) sizer(3) sizer(1.25) 0 !important;
              }
            }

            @media screen and (min-width: $large_screen_up) and (max-width: 1500px){
              padding: sizer(1.25) sizer(2) sizer(1) sizer(1.25);
            }

            @media #{$tablet} {
              padding: sizer(1) !important;
              //padding-left: 0;

              &:nth-child(1) {
                //flex-grow: inherit;
                > span > span {
                  // width: 24px !important;
                  height: 24px !important;
                }
              }
              &:nth-child(2) {
                padding-left: 0;
                > span > span {
                  // width: 240px !important;
                  height: 24px !important;
                }
              }
              &:nth-child(3) {
                @media (max-width: $medium_screen_up) {
                  display: none !important;
                }
              }
            }
        }
    }
  }

/*Mobile End*/
  .filter-options-rangepicker-container p {
    font-weight: $font-weight-semi-bold;
  }

  button.icon-button.selected {
      pointer-events: all;
  }
  .tablePagination {
    padding-top: 48px;
    padding-bottom: 24px;
  }
  .ant-table-pagination{
    font-family: $inter;
    margin-top: sizer(3) !important;
    a{
      font-family: $inter;
      &:hover{
        color: $spanish-gray;
        opacity: 0.5;
      }
    }
    .ant-pagination-options-quick-jumper input{
      border-color: $spanish-gray;
      &:focus {
        border-color: $black !important;
        box-shadow: none;
      }
      &:not(:focus):hover {
        opacity: 0.5;
      }
    }
  }
  .alert-notification-success {
    width: sizer(28);
  }
  .role-checkbox-filter {
    padding-bottom: sizer(1.5);
    min-width: 125px;
    width: 100%;
    margin-bottom: unset;

    &:last-child { padding-bottom: unset; }

    .form-check-input + label {
      margin-left: 1rem;
      width: 100%;
      font-size: $font-size-16;
    }
  }
  .mb-225 {
    margin-bottom: sizer(1.5);
    font-weight: $font-weight-semi-bold;
  }

  .user-list-table-list .ant-select-selector {
    border: unset;
  }

  .nousersfound {
    display: flex;
    /*justify-content: center;
    align-items: center;*/
    font-size: $font-size-16;
    font-weight: $font-weight-normal;
    line-height: $line-height-24;
    flex-direction: column;

    img {
      margin-bottom: sizer(3);
    }

    padding-top: 48px;
  }
  .search-input svg {
    color: #989595;
    padding-right: unset;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .btn {
    font-size: sizer(1) !important;
    font-weight: $font-weight-medium;
    line-height: $line-height-24;
    padding: 0.5rem 1.5rem;
  }
  .ant-select,
  .ant-select-selection-item {
    font-size: $font-size-16;
    font-weight: $font-weight-normal;
    line-height: $line-height-24
  }
  .ant-select-dropdown {
    border-radius: sizer(1.25);
    border: 1px solid $chinese-white;
    background: $white;
  }
  .actions_groups_btns {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .buttons-container {
      display: flex;
      gap: 10px;
      &.cancel-save {
        position: absolute;
      }
  }
  .text-container {
    display: inline-block;
    max-width: 328px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

 /*  .creator,
  .viewer,
  .admin,
  .new_users_request {
    display: none;
  } */

  .defaultGroupNameStyle,
  .assetapprover,
  .enduser,
  .useradmin,
  .customeradmin,
  .assetowner {
    font-size: $font-size-16;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: $line-height-24;
    padding: sizer(.25) sizer(.5);
    border-radius: sizer(.5);
    border-style: none;

    width: fit-content;
  }

  .creator,
  .viewer,
  .admin,
  .defaultGroupNameStyle {
    color: black;
  }

  .creator {
    color: var(--secondary-orange, #FB8B07);
    background: var(--secondary-light-pink, #FFEBF2);
  }

  .viewer {
    color: var(--secondary-leafy-green, #30BA34);
    background: var(--secondary-light-green, #ECF7F3);
  }

  .assetapprover {
    color: var(--primary-purple-blue, #FF4E89);
    background: var(--secondary-light-purple, #FFEBF2);
  }

  .enduser {
    color: var(--secondary-leafy-green, #30BA34);
    background: var(--secondary-light-green, #ECF7F3);
  }

  .useradmin,
  .new_users_request {
    color: var(--secondary-blue, #0276E1);
    background: var(--secondary-sky-blue, #D9F6FF);
  }

  .customeradmin,
  .admin {
    color: var(--primary-purple-blue, #683BE2);
    background: var(--secondary-light-purple, #F4F0FF);
  }

  .assetowner {
    color: var(--secondary-orange, #FB8B07);
    background: var(--secondary-light-pink, #FFEBF2);
  }

  .expanded-content-flex {
    display: flex;
    flex-direction: row;
  }

  .expanded-content-column {
      flex: 1;
      padding: 0 10px;
  }

    .selectall-label {
      display: flex;
      align-items: center;
      padding: 16px 0px;
    }

    .selectall-checkbox {
        margin-right: 8px;
        border-color: $spanish-gray !important;
    }

    .table-users {
      .action-buttons {
        .btn {
          display: unset;
        }
      }
    }

    .selectall_checkbox {
      position: unset;
      top: unset;
      left: unset;
      background-color: unset;
      padding: unset;
      font-size: unset;
      color: unset;
      z-index: unset;

      span input { border-color: $black; }
      span span.ant-checkbox-inner { border-color: $black; }

      & > span:last-child {
        padding-left: sizer(1);
        font-size: $font-size-20;
        line-height: $line-height-28;
        font-weight: $font-weight-medium;
      }
    }
    .ant-table-cell-row-hover{
      .tablecheckbox-disable{
        .ant-checkbox-inner{
          border-color: $chinese-white;
        }
      }
    }
    .tablecheckbox {
      top: unset;
      padding: 0;
      display: block;
      left: 0;
      &-disable{
        input{
          cursor: default;
        }
        .ant-checkbox-inner{
          border-color: $chinese-white;
        }
      }
      .ant-checkbox {
        top: 0;
      }

      &::after {
        display: none;
      }
      @media #{$tablet} {
        top: unset !important;
      }
    }

    .userlist-navigation {
      .selectall-label {
        display: flex;
        align-items: center;
        padding: 16px 0px;
      }

      .selectall-checkbox {
          margin-right: 8px;
          border-color: $spanish-gray !important;
      }

      .form-group label {
        position: unset;
        top: unset;
        left: unset;
        background-color: unset;
        padding: unset;
        font-size: unset;
        color: unset;
        z-index: unset;
      }
      .form-group input {
        font-size: unset;
        font-weight: unset;
        line-height: unset;
        color: unset;
        padding: unset;
        border: unset;
        background-color: unset;

        padding: 8px 0px !important;
      }
    }

  font-size: $font-size-16;
  line-height: $line-height-24;
  //padding-top: 144px;

  &-settings-bar {
    width: 100%;
    border-top: 1px solid $chinese-white;
    border-bottom: 1px solid $chinese-white;
    padding: sizer(.25) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
        display: flex;
        align-items: center;
    }
    &-right {
        display: flex;
        flex-direction: row;
        gap: sizer(2.5);
        align-items: center;
    }



    .btn:disabled {
        background-color: transparent;
    }
  }

  .user-request {
    &-filter {
      .row {
        align-items: unset;
        gap: 0;
      }
    }

    &-settings-bar {
      &-right {
        button.icon-button.selected {
          background-color: unset !important;
          color: $black !important;

          svg path {
            fill: $black !important;
          }
        }
      }
    }
  }

  &-table {
      margin-top: 0;

      .ant-table-cell {
          width: calc(100% / 4);
          &.col-role{
            padding-left: 0 !important;

              .ant-tag-container {
                  span {
                      margin: auto 0;
                  }
              }

              svg {
                  margin: auto 0;
              }
          }
      }
      .custom-ant-table  {
          margin-top: 0;

          .ant-table {
              colgroup,
              .ant-table-tbody .ant-table-row-expand-icon-cell,
              .ant-table-thead .ant-table-row-expand-icon-cell {
                  display: none;
              }

              .ant-table-tbody {
                  .ant-table-expanded-row {
                      .ant-table-custom-expanded-cell {
                          > div {
                              //width: calc(100% / 4);
                              width: 100%;
                          }
                      }
                  }
              }
          }
      }

      .actions_groups_btns {
          display: flex;
          flex-direction: row;
          gap: sizer(1);

          /* button {
              padding: 0;
          } */
      }
  }
  &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
          margin-bottom: sizer(3);
      }

      h2 {
          margin-bottom: sizer(1);
      }

      p {
          color: $spanish-gray;
          font-weight: $font-weight-medium;
          margin-bottom: 0;
      }
  }

  .nav-tabs {
      border: 0;
  }
  .alert-notification  {
      margin-bottom: sizer(2);
      position: absolute;
      top: -140px;
  }
}