.bookmark {
   
    &-success-wrapper {
        width: 100%;
        height: 100%;
        margin: 0;
        margin-bottom: 0;
        justify-content: center;
        text-align: center;

        .content-wrapper {
            
            .icon-container {
                height: $icon-size-collection-web;
                margin-bottom: 10.665px; // since bottom of svg have 13.335px
    
                
                i svg{
                    color: $leafy-green;
                    height: 100%;
                    width: 100%;
                }
            }

            h3 {
                font-size: $font-size-20 !important;
                line-height: $tablet-line-height-28;
                margin-bottom: 0.5rem !important;

                @media #{$large_and_up} {
                    font-size: $font-size-20 !important;
                    line-height: $line-height-28;
                }
            } 
            
            p {
                font-size: $font-size-16;
                margin-bottom: 24px !important;
            }
            
            
        }
        .done-btn {
            display: inline-flex; 
            justify-content: center;

            .btn {
                font-size: $font-size-20;
                line-height: $tablet-line-height-28;

                @media #{$large_and_up} {
                    font-size: $font-size-20 !important;
                    line-height: $line-height-28;
                }
            }
        }
        
    }   
}

@media #{$mobile} {
    .bookmark {
        &-success-wrapper {
            .content-wrapper {
                padding: 0 16px;
                .icon-container{
                    height: auto;
                    padding-top: 6rem;
                    margin-bottom: 10.665px; // since bottom of svg have 13.335px
                }
    
                
                .icon-container {
                    i svg{
                        color: $leafy-green;
                        height: 160px;
                        width: 160px;
                    }
                }

                h3 {
                    font-size: $font-size-18 !important;
                    line-height: 26px;
                    margin-bottom: 0.5rem !important;
                } 
                
                p {
                    font-size: $font-size-16;
                    margin-bottom: 24px !important;
                }
            }

            .done-btn {
                bottom: 0;
                width: 100%;
                display: flex;
                position: fixed;
                padding: 0.75rem 1.5rem; 
                background-color: $light-gray;

                .btn {
                    margin-top: 0;
                }
            }
        }
    }
}