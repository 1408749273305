.upload-error {
    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        hr {
            width: 100%;
        }
        h3{
            margin-bottom: sizer(0.5);
        }
    }
    &-button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-icon {
        width: 160px;
        // height: 160px;
        height: 115px;
        display: flex;
        justify-content: center;
        margin: auto;
        margin-bottom: sizer(1.5);

        svg {
            width: 127px;
            height: 113px;

            path {
                fill: $candy-red;
            }
        }
    }
    &-status {
        font-size: $font-size-16;
        line-height: $line-height-24;
        width: 100%;

        &-filename {
            max-height: 140px;
            overflow: auto;
            text-align: left;
            @media #{$tablet} {
                width: 100%;
                word-break: break-all;
            }
            &-cont {
                padding: sizer(1);
                border: 1px solid $chinese-white;
                border-radius: $border-radius-16;
            }

            &::-webkit-scrollbar {
                width: sizer(.5);
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-thumb {
                background: $spanish-gray;
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $spanish-gray;
            }

            span {
                width: 100%;
                margin-bottom: 4px;
                line-height: 24px;
                display: block;
            }
        }
        &-error {
            p {
                margin-bottom: 0;
            }
            ul {
                list-style-type: disc;
                padding-left: sizer(1);
                margin-bottom: sizer(1.5);
                @media #{$tablet} {
                    padding-left: sizer(2);
                    padding-top: 0;
                }
            }
        }
    }
}   