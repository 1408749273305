@import "./upload-information";
@import "./upload-process";
@import "./upload-dropzone";
@import "./upload-progress";
@import "./upload-card";
@import "./upload-offcanvas";
@import "./upload-settings";
@import "./upload-error";
@import "./upload-edit";
@import "./upload-tag";
@import "./upload-collection";

.notification-banner-show {
    .upload-view-wrapper {
        padding-bottom: 138 + 96px;
    }
    .upload-view-sticky-bottom {
        bottom: 138px;
    }
}
.upload-offcanvas-show {
    .upload-view-sticky-bottom{
        /* padding-right: 3.5rem;
        @media #{$medium_and_down} {
            padding-right: 5.5rem;
        } */
    }
}
.upload-view {
    &-container {
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;

        /* @media #{$medium_and_down} {
            .headroom-wrapper {
                z-index: 0;
            }
        } */

        &::-webkit-scrollbar {
			width: sizer(.5);
			border-radius: $border-radius-8;
		}
		&::-webkit-scrollbar-track {
			background: $bright-gray;
            border-radius: $border-radius-8;
		}
		&::-webkit-scrollbar-thumb {
			background: $spanish-gray;
            border-radius: $border-radius-8;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: $spanish-gray;
		}
        .menu-tabs-wrapper {
            /* @media #{$small_and_down} {
                padding: unset;
            } */
        }
    }

    &-sticky-bottom {
        position: fixed;
        bottom: sizer(5.225);
        left: 0;
        width: 100%;
        z-index: 5;
        padding: sizer(1) sizer(6);
        background-color: $light-gray;
        display: flex;
        justify-content: end;
        transform: translateY(100%);
        transition: all .25s ease-in-out;

        @media #{$tablet} {
            bottom: 0;
            flex-direction: column;
            padding: sizer(1.5) sizer(5);
            z-index: 10;
            transform: unset;
            .return-btn, .upload-btn {
                width: 100%;
                justify-content: center;
            }
            .upload-settings {
                &-wrapper {
                    position: relative;
                    padding: sizer(.25) 0;
                    margin-bottom: unset;
                    max-width: unset !important;

                    &::after {
                        display: none;
                    }
                }
            }
            .upload-settings-cont{
                >div{
                    gap: unset;
                    justify-content: space-between;
                    button{
                        display: flex;
                        &.icon-button.selected {
                            padding: 4px !important;
                        }
                        &.icon-button:hover{
                            opacity: 1;
                        }
                    }
                }
                .upload-settings-select-all{
                    width: 70%;
                    .form-check {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
        @media #{$mobile} {
            bottom: 0;
            padding: sizer(0.75) sizer(1.5);
            transform: unset;
        }

        &-show {
            transform: translateY(0);
        }
    }
    &-sticky-top {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        padding: sizer(1) 0;
        background-color: $light-gray;
        //transition: all .25s ease-in-out;
        //box-shadow: 0px 1px 1px 0px #00000014;
        &.sticky{
            position: fixed;
            padding: sizer(1) sizer(6);
            @media screen and (max-width: 1480px) {
                padding: sizer(1) sizer(3);
            }
        }
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: $light-gray;
            width: 125%;
            height: 100%;
            transform: translateX(-100px);     
            box-shadow: 0px 1px 1px 0px #00000014;   
        }
        &-container{
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 10;
            .upload-settings-wrapper{
                margin-bottom: 0;
                padding: 0;
                width: unset !important;
                &::after{
                    display: none;
                }
            }
        }
    }
    &-wrapper {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        padding: sizer(3) 0 sizer(9.625) 0;
        // transition: all .25s ease-in-out;
        h2 {
            margin-bottom: sizer(3);
        }
        &.upload-view-height{
            height: auto;
            min-height: 100vh;
        }
        .upload-progress-process{
            margin-top: 0;
            .upload-card-header-list{
                order: 2;
                @media #{$tablet}{
                    display: flex;
                    justify-content: flex-end;
                    svg{
                        margin-right: 0;
                    }
                    .spinner-border{
                        margin-right: 0;
                    }
                }
            }
            &.progress-desktop{
                .upload-process-list-view .upload-process-list-sorting > div:nth-child(1) {
                    width: 103px;
                }
                .upload-card-header-list{
                    width: 15%;
                    display: flex;
                    justify-content: flex-start;
                    align-self: center;
                }
                .upload-card-delete {
                    display: none;
                }
            }
        }
    }
    &-cont {
        display: flex;
        flex-direction: column;

        &.upload-view-cont-uploaded {
            .upload-dropzone-wrapper {
                height: 240px;
            }
        }
        .upload-guide-btn {
            display: flex;
            justify-content: end;

            a {
                color: $sunset-orange;
                font-weight: $font-weight-bold;
                transition: opacity .25s ease-in-out;
                opacity: 1;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
        &.upload-progress-view {
            > * {
                width: 50%;
            }
        }
    }
    @media #{$tablet} {
        &-wrapper {
            width: 100%;
            padding: sizer(3) 0;
            min-height: 100vh;
            height: auto;
            h2{
                margin-bottom: sizer(2.5);
            }
        }
    }
}
.upload-settings {
    &-mobile {
        .upload-settings {
            &-select-all, &-edit, &-tag, &-collection, &-delete {
                @media #{$tablet} {
                    display: unset;
                    .icon-button-text {
                        font-size: 0;
                        padding: 0;
                    }
                }
            }
            &-view {
                @media #{$tablet} {
                    display: none;
                }
            }
        }
    }
    &-bottom {
        display: none;
        @media #{$tablet} {
            display: unset;
            padding-bottom: sizer(0.75);
        }
    }
}
