.view-asset {
    @media #{$tablet}{
        .modal-content {
            width: 100% !important;
            padding-top: 0 !important;
            border-radius: 0 !important;
            .container.modal-body{
                display: flex;
                flex-direction: column-reverse;
                padding: 0 sizer(1.5) !important;
                gap: sizer(1.5);
                flex: 0;
                .row{
                    margin: 0;
                    >*{
                        padding: 0;
                    }
                }
                li {
                    display: block;
                    height: auto;
                    label{
                        margin-left: 0.5rem;
                        top: unset !important;
                        left: unset !important;
                    }
                    .form-check-input{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .modal-header{
                padding: 0 !important;
                height: sizer(4.625);
                .container{
                    button{
                        gap: sizer(0.5);
                        svg{
                            margin-right: 0 !important;
                            margin-left: 0 !important;
                        }
                    }
                }
            }
        }
        .view-asset-bar {
            position: sticky;
            width: 100%;
            height: 64px;
            right: 0;
            left: 0;
            top: 0;
            background: #ffffff;
            border: 0;
            padding: 0;
            display: flex;
            align-items: center;
            gap: sizer(3);
            padding: sizer(1) 0;
            flex-direction: row;
            justify-content: end;
            > div {
                button{
                    padding: 0 !important;
                    svg {
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                }
            }
            &::before{
                content: "";
                position: absolute;
                width: 114%; //63%;
                height: 64px;
                bottom: 0;
                background-color: $white;
                transform: translateX(23px);
            }

            &::after{
                content: "";
                position: absolute;
                width: 113%; //63%;
                height: 1px;
                bottom: 0;
                background-color: $chinese-white;
                transform: translateX(24px);
            }
        }
        &-form{
            margin-top: sizer(1.5);
            .view-asset-name {
                margin-bottom: sizer(2.5) !important;
                &.mobile-view-asset-name{
                    margin-bottom: sizer(1) !important;
                }
            }
            .view-asset-desc {
                margin-bottom: sizer(2.5) !important;
            }
            .view-asset-accordion {
                &.view-asset-collection{
                    button{
                        max-width: 100% !important;
                        word-break: break-all;
                        height: auto !important;
                        text-align: center;
                    }
                }
                .accordion-body {
                    button:not(.text-button) {
                        width: auto !important;
                        border-color: $black;
                        max-width: 100% !important;
                        word-break: break-all;
                        height: auto !important;
                        text-align: center;
                        &:hover{
                            border-color: $black ;
                        }
                    }
                }
                &:not(:last-child) {
                    margin-bottom: sizer(2.5);
                }
                button{
                    &.text-button:hover {
                        opacity: unset;
                    }
                    &:not(.text-button):hover {
                        color: $black !important;
                        //border-color: transparent !important;
                        svg path {
                            fill: unset;
                        }
                    }
                }
            }
            div.edit-asset-container {
                width: 100% !important;
                height: auto !important;
                z-index: 2;
                padding: 1.5rem !important;
                padding-top: 3rem !important;
                gap: sizer(2.5) !important;
                padding-bottom: calc(82.13px + sizer(1.5)) !important;

                div.form-group .ant-dropdown .ant-dropdown-menu {
                    padding: sizer(0.75) !important;
                    height: auto;
                }
                h3{
                    font-size: sizer(1.25);
                    font-weight: 700;
                    line-height: $line-height-28;
                }
                .collection-dropdown-select{
                    min-height: sizer(3.5) !important;
                    padding: 1rem 1.5rem;
                }
            }
        }
        .view-asset-edit-btn {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            gap: sizer(1.25);
            padding: sizer(1) sizer(1.5);
            width: 100%;
            z-index: 2;
            background-color: $light-gray;
            position: fixed;
            > div{
                width: 100%;
                button.save-edit-asset,
                button.cancel-edit-asset {
                    width: 100%;
                    border-color: $black;
                    font-size: $font-size-18;
                }
            }
        }
        .form-group input.create-collection:not([type=checkbox]) {
            padding: 0.5rem 0.75rem !important;
        }
        .view-asset-document-page{
            //padding: 0;
            button{
                padding: 0;
                z-index: 1;
                &.btn-primary.disabled,
                &.btn-primary:disabled{
                    background-color: transparent;
                }
            }
            span{
                width: 30%;
                text-align: center;
            }
        }

    }
    .row  {
        margin-right: sizer(0.025);

        > * {
            padding-left: sizer(1.5);
            padding-right: sizer(1.5);

            @media #{$xlarge_and_down} {
                padding-left: 0;
            }
        }

        .col-lg-8 {
            @media ((min-width:$large_screen_up) and (max-width:$xlarge_screen)) {
                width: 60.66666667% !important;
            }
        }
    }
    .modal-content {
        padding: 0;
        overflow-x: hidden;
        border-radius: 0;
    }
    .modal-header {
        border: 0;
        margin: 0;
        height: sizer(6);
        background-color: $black;
        border-radius: 0;
        color: $white;
        padding: 0;

        .container {
            display: flex;
            justify-content: space-between;

            > div {
                display: flex;
                flex-direction: row;

                &:nth-child(1) {
                    svg {
                        margin-right: sizer(.75);
                    }
                }

                &:nth-child(2) {
                    button {
                        &:nth-child(1) {
                            svg {
                                width: sizer(1.5);
                                height: sizer(1.5);
                                margin-right: sizer(.75);
                            }
                        }
                        &:nth-child(2) {
                            svg {
                                margin-left: sizer(.75);
                            }
                        }
                    }
                }
            }

            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: sizer(.75) sizer(1.25);
                h3{
                    font-family: $inter;
                }
                &.hidden {
                    display: none;
                }
            }
        }

        .btn {
            &:disabled {
                background: transparent !important;
            }
            &-primary {
                transition: none;

                &:hover {
                    // background-color: $sunset-orange;
                    color: $white;
                    opacity: 0.5;

                    &::before {
                        display: none;
                    }

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
            &:first-child:active {
                color: var(--bs-btn-active-color);
                background-color: transparent;
                border-color: transparent;
            }
        }
    }

    .modal-body {
        padding: sizer(3) 0 sizer(6) !important;
        &.custom-modal{
            padding-top: sizer(6)!important;
            position: static !important;
        }
    }

    .modal-body,
    .modal-footer {
        background-color: transparent;
        border: 0;
    }

    &-skeleton {
        position: relative;
        padding: sizer(3) 0;

        > .row {
            padding: sizer(0) sizer(1.75);
        }
    }

    &-bar {
        position: absolute;
        width: sizer(7);
        height: 100%;
        right: sizer(6 * -1);
        top: 0;
        background: $white;
        border-left: 1px solid $chinese-white;
        padding: sizer(3) sizer(1);
        display: flex;
        flex-direction: column;
        gap: sizer(3);

        > div {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 40px;
                height: 40px;
                cursor: pointer;

                path {
                    fill: $black;
                }

                &:hover {
                    path {
                        fill: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        @media #{$xlarge_and_up} {
            width: sizer(6);
            padding: sizer(3) sizer(1.75);
            right: sizer(-5.5);
        }

        @media #{$xlarge_and_down} {
            right: sizer(-3);
        }
    }

    &-status {
        display: flex;
        margin-top: sizer(1.25);
        margin-bottom: sizer(3);
        gap: sizer(2.5);

        svg {
            margin-right: sizer(.5);
        }
    }

    &-enlarge {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: sizer(1);
        width: sizer(1.5);
        height: sizer(1.5);
        background-color: $chinese-white;
        border-radius: $border-radius-8;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 6;
        transition: transform .5s ease-in-out, opacity .25s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: translateY(-50%) scale(.9);
        }

        &-visible {
            opacity: 1;
            pointer-events: all;
        }

        svg {
            width: $responsive-size-20;
            height: $responsive-size-20;

            path {
                fill: $black;
            }
        }
    }
    &-document {
        // border: 1px solid $chinese-white;
        &-page-container{
            padding-bottom: sizer(6);
        }
        &-page {
            /*position: absolute;
            bottom: -56px;*/
            left: 0;
            background: $black;
            padding: sizer(1);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            z-index: 10;
            opacity: 1;
            transition: opacity .25s ease-in-out;
            border-top: none !important;
            border: 2px solid $chinese-white;

            .view-asset-enlarge {
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
            svg {
                path {
                    fill: $white;
                }
            }
            span {
                color: $white;
            }
            button {
                padding: 0 sizer(2);
                cursor: pointer;
                &:hover{
                    opacity: 1;
                    svg path {
                        fill: $white;
                    }
                }
                &:disabled {
                    pointer-events: none;
                    opacity: 0;
                    @media #{$mobile} {
                        background-color: transparent !important;
                    }
                }
                @media #{$mobile} {
                    width: auto;
                }
            }
        }
        .react-pdf__Page__canvas {
            margin: auto;
        }
        .react-pdf__Page__textContent,
        .react-pdf__Page__annotations {
            display: none;
        }
    }
    &-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: 1px solid $chinese-white;
        svg {
            width: sizer(8);
            height: sizer(8);
            margin-bottom: sizer(2);
            cursor: pointer;

            path {
                fill: $black;
            }
        }
        .svg-container {
            path {
                fill: unset;
            }
        }
    }
    &-information {
        overflow: hidden;

        .row {
            gap: unset !important;

            > * {
                padding-left: calc(var(--bs-gutter-x) * .5);
                padding-right: calc(var(--bs-gutter-x) * .5);
            }

            .col-12 {
                span {
                    &:nth-child(2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
        span {
            display: block;
            width: 100%;

            &:nth-child(1) {
                color: $spanish-gray;
                margin-bottom: sizer(.25);
                font-size: $font-size-12;
                line-height: $line-height-16;
            }
            &:nth-child(2) {
                font-size: $font-size-16;
                line-height: $line-height-24;
                margin-bottom: sizer(1);
                color: $black;
                &:first-letter {
                    text-transform: uppercase;
                }

            }
        }
    }
    &-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid $chinese-white;

        &-visible {
            &:before {
                opacity: 1 !important;
            }

            .view-asset-image-enlarge {
                opacity: 1;
            }
        }

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100px;
            bottom: -50px;
            left: 0;
            z-index: 2;
            background-color: #000000;
            opacity: 0;
            transition: opacity .25s ease-in-out;
            pointer-events: none;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%, rgb(255, 255, 255) 100%);
        }

        &-enlarge {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: sizer(1);
            width: 100%;
            display: flex;
            justify-content: flex-end;
            opacity: 0;

            .view-asset-enlarge {
                position: relative;
                right: 0;
                top: 0;
                transform: none;

                &:hover {
                    transform: scale(.9);
                }
            }
        }
        img {
            width: 100%;
            height: 100%;
            background-image: contain;
        }
    }
    &-video {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $chinese-white;
        overflow: hidden;
        .vjs-fluid:not(.vjs-audio-only-mode) {
            padding-top: 56.25%;
        }
        &-visible {
            &:before {
                opacity: 1 !important;
            }

            .view-asset-video-enlarge {
                opacity: 1;
            }
        }

        &-enlarge {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: sizer(1);
            width: 100%;
            display: flex;
            justify-content: flex-end;
            opacity: 0;
        }

        > div {
            width: 100%;
        }

        video {
            width: 100%;
        }
    }
    &-form {
        display: flex;
        flex-direction: column;
        .view-asset-name {
            margin-bottom: sizer(3);

            h2 {
                white-space: break-spaces;
            }
            + .view-asset-desc {
                margin-bottom: sizer(3)
            }
        }

        p {
            margin-bottom: 0;
        }
        > div {
            position: relative;

            + hr {
                margin: sizer(2) 0;
            }
        }
        div.edit-asset-container{
            position: absolute;
            width: 34.31%; //113.655%;
            background: $white;
            height: auto;
            min-height: 126.625%; //121.652%;
            z-index: 2;
            top: 0;
            right: 0;
            padding: sizer(3);
            padding-top: sizer(6);
            //transform: translateY(-47px);
            display: flex;
            flex-direction: column;
            gap: sizer(3);
            border-left: 1px solid $chinese-white;

            >h3{
                font-family: $inter;
                font-weight: $font-weight-bold;
                font-size: $font-size-20;
                line-height: $line-height-28;
            }
            div.form-group{
                >label{
                    top: sizer(-0.688);
                    left: sizer(1.25);
                    line-height: sizer(1);
                    padding: sizer(0.25);
                }
                textarea{
                    font-family: $inter;
                    font-weight: $font-weight-normal;
                    font-size: $font-size-16;
                    line-height: $line-height-24;

                    &#Filename{
                        height: sizer(6);
                        padding: sizer(0.875) sizer(1.5);
                        border: 0;
                    }
                    &#Description{
                        height: sizer(10.5);
                        padding: sizer(0.875) sizer(1.5);
                        border: 0;
                    }
                }
                input {
                    font-family: $inter;
                    font-weight: $font-weight-normal;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                }

                .collection-dropdown-select{
                    font-family: $inter;
                    font-weight: $font-weight-normal;
                    font-size: $font-size-16;
                    line-height: clamp(1rem, 0.8766rem + 0.5195vw, 1.5rem);
                    color: $black;
                    align-items: center;
                    //border-radius: 20px;
                    //border: 1px solid #E1E1E1;
                    //background-color: transparent !important;
                    //max-height: sizer(5);
                    min-height: sizer(4.5);
                    height: auto;
                    overflow: auto;
                    .create-collection-tag{
                        width: 100%;
                        padding: 1px;
                        border: 0;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        gap: 8px;
                        .chips-primary {
                            height: auto;
                            text-align: center;
                            button{
                                border: 0;
                                background: transparent;
                                padding: 0;
                                svg{
                                    color: $white !important;
                                }
                                &:hover{
                                    svg{
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                        input{
                            height: inherit;
                            padding: 0 !important;
                            box-sizing: border-box;
                            width: 0;
                            min-width: 30px;
                            flex-grow: 1;
                            border: 0;
                            margin: 0;
                            outline: 0;
                        }
                       
                    }
                    .rotate-arrow{
                        transform: rotate(180deg);
                    }
                }
                .ant-dropdown{
                    margin-top: sizer(1);
                    max-width: 100% !important;
                    .ant-dropdown-menu{
                        padding: 16px 24px 16px 16px;
                        border-color: $black;
                        box-shadow: none;
                        height: auto;
                        ul{
                            padding-left: 0 !important;
                            height: auto;
                            max-height: sizer(12.688);
                            overflow-y: auto;
                            &.no-collections{
                                overflow-y: hidden;
                                overflow-x: hidden;
                                li:hover{
                                    background-color: transparent;
                                }
                            }
                            li{
                                list-style: none;
                                padding: 8px 0px 8px 8px;
                                border-radius: 8px;
                                margin-right: 8px;
                                margin-bottom: 0;
                                &:hover{
                                    background-color: #F5F5F5;
                                }
                                label{
                                    background-color: transparent;
                                    width: 100%;
                                }
                            }
                            &::-webkit-scrollbar {
                                width: 8px;
                                border-radius: 8px;
                                height: 8px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: #989595;
                                border-radius: 40px;
                            }
                            &::-webkit-scrollbar-track {
                                background: #EDEDED;
                                border-radius: 40px;
                            }
                        }

                        &-item{
                            input{
                                padding: 0 !important;
                            }
                        }
                    }
                }
                .edit-collection-new-input-group{
                    .create-collection{
                        padding: 0.5rem 1.5rem;
                        background-color: #F5F5F5 !important;
                        border-radius: 8px;
                        color: #000000;
                        &:focus{
                            border-color: #F5F5F5;
                        }
                        &.red-border{
                            border-color: #D80029 !important;
                        }
                    }
                }
                div.edit-char-limit {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    background-color: #ffffff;
                    font-size: $font-size-12;
                    line-height: 16px;
                    color: #989595;
                    padding: 0 0.5rem;
                    margin-top: 0.5rem;
                    span:first-child{
                        font-weight: $font-weight-normal;
                    }
                    .error{
                        margin-top: 0;
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }
    &-edit-btn{
        display: flex;
        flex-direction: row-reverse;
        gap: sizer(1.5);
        bottom: 0;
        padding: 16px 96px;
        width: 66%;
        z-index: 1;
        background-color: #F5F5F5;
        position: fixed;
        button.save-edit-asset,
        button.cancel-edit-asset{
            font-family: $inter;
            font-weight: $font-weight-medium;
            font-size: $font-size-20;
            line-height: $line-height-28;
            text-align: center;
        }
        button.save-edit-asset{
            width: sizer(8.938);
            &:first-child:active {
                background-color: transparent;
                border-color: transparent;
                color: var(--black);
            }
        }
        button.cancel-edit-asset{
            width: sizer(10.188);
            //background-color: rgb(0, 0, 0, 0.5);
            border: 1px solid rgb(0, 0, 0, 0.5);
        }

    }
    &-accordion {
        &:not(:last-child) {
            margin-bottom: sizer(3);
        }

        .accordion-header {
            button {
                font-size: $font-size-16;
                line-height: $line-height-24;
                font-weight: $font-weight-inter-semi-bold;
                color: $black;
                font-family: $inter;
            }
        }

        .accordion-body {
            padding: 0;
            margin-top: sizer(1);

            > div {
                position: relative;

                &:nth-child(1) {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: sizer(1);
                }
                &:nth-child(2) {
                    .text-button {
                        font-weight: $font-weight-bold;
                        color: $sunset-orange
                    }
                }
            }
            button {
                &:not(.text-button) {
                    padding: sizer(.5) sizer(1);
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    border-radius: $border-radius-12;
                    min-height: 40px;
                    border-color: $black;
                    font-weight: $font-weight-normal;
                    width: auto;
                    max-width: 100% !important;
                    height: auto;
                    word-break: break-word;

                    &:hover {
                        background-color: transparent;
                        color: $translucent-black;
                        opacity: 1;
                        border-color: $translucent-black;
                    }
                    &::before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
    &-edit-tag {
        &-cont {
            margin-bottom: sizer(1.5);
        }

        &-cont,
        &-ai-smart-tag-cont {
            display: flex;
            gap: sizer(1);
            flex-wrap: wrap;
        }
        &-desc {
            margin-bottom: sizer(.5);
            color: $spanish-gray;
        }

        &-limit {
            width: 100%;
            position: absolute;
            width: 100%;
            right: 0;
            bottom: -1.25rem;
            display: flex;
            gap: 8px;
            background-color: #ffffff;
            font-size: 12px;
            line-height: 16px;
            color: #989595;
            padding: 0 0.5rem;
            margin-top: 0.5rem;

            &-cont {
                position: relative;
                display: flex;
                justify-content: flex-end;
            }
            &-right {
                margin-left: auto;
                position: absolute;
                bottom: -1rem;
                background-color: #ffffff;
                font-size: 12px;
                line-height: 16px;
                color: #989595;
                padding: 0 0.5rem;
            }
        }

        &-clear-all {
            margin-top: sizer(1.5);
        }

        &-clear-all,
        &-add-all {
            padding: sizer(0.5) sizer(1);
            display: flex;
            justify-content: flex-end;
            margin-bottom: sizer(0.5);
        }

        &-add-all {
            margin-top: sizer(1.5);
        }

        hr {
            margin: sizer(1.5) 0;
        }
    }
}
