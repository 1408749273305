.asset-filter-container {
    @media #{$tablet} {
        .file-size-input-fields {
            height: 56px;
            gap: 0.5rem;
            //margin-bottom: sizer(1.5);
            padding: 0;
        }
        .size-component {
            padding: sizer(1) sizer(1.5) !important;
            width: 100% !important;
        }
        p.filter-title {
            font-weight: 500 !important;
            margin-bottom: sizer(1.5) !important;
            padding: 0;
        }
        .range-container{
            margin-top: sizer(0.75);
            margin-bottom: sizer(1);
            .published-date{
                margin-bottom: sizer(1) !important;
            }
        }

    }
    @media #{$mobile}{
        .file-size-input-fields {
            //margin-bottom: sizer(2.5);
        }
    }
    margin-top: sizer(0.75);

    .ant-select {
        &-selection-item {
            font-weight: $font-weight-normal;
            font-size: $font-size-16;
            line-height: $line-height-24;
        }
    }

    .col-3 {
        width: 33.3%;
        .rangepicker-container {
            .ant-picker-range {
                //width: 100%;
                .ant-picker-input {
                    width: 100%;

                    input {
                        text-align: left;
                        width: 98px;

                        @media #{$tablet} { width: 94px; }
                    }

                    &:first-child {
                        width: fit-content;
                    }
                }
            }
            .rangepicker-close-icon:hover{
                opacity: 0.5;
            }
        }

        &.orientation {
            padding-right: 0;
            .filter-title {
                margin-bottom: 1.5rem;
            }
            .select-orientation {
                gap: sizer(1);
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                .chips-secondary {
                    border: 1px solid $translucent-black;
                    background-color: $secondary;
                    transition: none;
                    color: $black;
                    &:hover {
                        opacity: 0.5;
                    }
                    .orientation-title {
                        color: $black;
                    }
                    .orientation-icon {
                        display: none;
                        color: $black;
                    }
                    
                    //Transition when orientation is selected
                    &.clicked {
                        background-color: $black;
                        .orientation-title {
                            color: $white;
                        }
                        .orientation-icon {
                            background-color:$white;
                            text-align: center;
                            border-radius: 50%;
                            display: flex;
                            height: 20px;
                            width: 20px;
                            align-items: center;
                            padding: 4px;
                            svg {
                                vertical-align: baseline;
                                path {
                                    fill: $black !important;
                                    height: 16.7px;
                                    width: 16.7px;
                                    color: $white;             
                                }
                            }
                        }
                    }
                }
            } 
        }
    }

    .filter-title {
        font-size: $font-size-16;
        font-weight: $font-weight-inter-semi-bold !important;
        margin-bottom: 24px;

        @media #{$tablet} {margin-bottom: 0;}
    }

    .file-size-input-fields {
        display: flex;
        align-items: center;
        height: 76px;
        position: relative;
        gap: sizer(.5);

        &::before {
            content: '';
            background-color: $chinese-white;
            height: 1px;
            width: 32px;
            order: 2;
        }
        input{
            font-family: $inter;
            padding: unset;
        }
        .grey-font{
            input{
                color: $spanish-gray;
                font-weight: $font-weight-medium;
            }
        }
        .black-font{
            input{
                color: $primary
            }
        }
    }


    .size-component {
        position: relative;
        height: 76px;
        border-radius: $border-radius-20;
        border: 1px solid $chinese-white;
        padding: sizer(1.5);
        width: 256px;

        label {
            font-size: $font-size-12;
            background-color: $white;
            padding-left: sizer(0.25);
            padding-right: sizer(0.25);
            position: absolute;
            top: -12px;
            left: 20px;
            background-color: $white;
            color: $spanish-gray;
        }

        &.min-size-component {
            order: 1;

            @media #{$large_and_down} {
                height: 60px;
            }
        }
        &.max-size-component {
            order: 3;
            
            @media #{$large_and_down} {
                height: 60px;
            }
        }

        &::after {
            content: "MB";
            font-family: inherit;
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;
            position: absolute;
            bottom: 50%;
            right: sizer(1.5);
            transform: translateY(50%);
        }
    }

}

.asset-filter-top {
    .btn {
        height: 2.5rem;
        font-size: sizer(1) !important;
        line-height: $line-height-24;
        padding: sizer(0.5) sizer(1.5);
        font-weight: $font-weight-medium !important;

        @media #{$tablet} {
            padding: sizer(.75) sizer(2);
            height: 50px;
        }
    }

    .apply-filter-button {
        margin-left: 24px;

        &:has(span:nth-child(2)) {
            gap: unset;
        }
    }

}

.type-container{
    .form-check{
        margin-bottom: 32px;

        .form-check-label{
            margin-left: 16px;
        }
    }
}

.ant-picker-focused{

    .ant-picker-input:before{
        color: black !important;
    }

    .ant-picker-input{
        border-color: black !important;
    }
}

.ant-select-open{
    .ant-select-selection-item{
        color: black !important;
    }
}

.ant-select-item-option-active{
    background-color: black !important;
    color: white !important;
}

.ant-picker-range-arrow{
    left: 0px !important;
}