.notification-banner {
    width: 100%;
    position: fixed;
    z-index: 6;
    bottom: 0;
    left: 0;
    padding: sizer(.5) sizer(1.5);
    font-weight: $font-weight-normal;
    font-size: $font-size-16;
    line-height: $line-height-24;
    background-color: $sky-blue;
    color: $black;
    height: 56px;

    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 100%;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
        }

        svg {
            margin-right: sizer(.5);
        }


        .btn {
            margin-left: sizer(1.5);
        }

        span {
            &.mobile-notification-content-text { display: none; }
            &.desktop-notification-content-text { display: inline-block; }
        }

        @media #{$mobile} {
            div {

                span {
                    &.mobile-notification-content-text { display: inline-block; }
                    &.desktop-notification-content-text { display: none; }
                }

                .btn {
                    width: fit-content;
                    margin-left: sizer(0.5);
                }
            }
        }
    }

    &.error-banner {
        background-color: $dawn-pink;
    }
}