button {
    &:hover {
        svg {
            path {
                fill: $translucent-black;
            }
        }
    }
    &.icon-button {
        position: relative;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        margin: 0;
        color: $black;
        cursor: pointer;
        transform: rotate(0);

        &:active {
            background-color: transparent !important;

            svg {
                path {
                    fill: $black !important;
                }
            }
        }
        &.expanded {
            svg {
                transform: rotate(180deg);
            }
        }
        &:disabled {
            pointer-events: none;
            border-color: transparent !important;

            svg {
                path {
                    fill: $chinese-white;
                }
            }
        }
        &-text {
            display: flex;
            align-items: center;
            gap: sizer(0.5);
            border-radius: $border-radius-40;
            padding: sizer(0.75) sizer(1.25);

            &:hover {
                opacity: 1;
                color: $translucent-black !important;

                svg {
                    path {
                        fill: $translucent-black !important;
                    }
                }
            }
        }

        &.icon-right {
            svg {
                order: 2;
            }
        }

        &.selected {
            background-color: $black !important;
            color: $white !important;
            pointer-events: none;

            svg {
                path {
                    fill: $white !important;
                }
            }
        }

        &:hover {
            opacity: .5;

            svg {
                path {
                    fill: $black;
                }
            }
        }
    }
    &.text-button {
        background: transparent;
        border: 0;
        display: flex;
        flex-direction: row;
        font-size: $font-size-16;
        line-height: $line-height-24;

        &:hover {
            opacity: 0.5;
        }
    }
}
.btn {
    display: flex;
    align-items: center;
    gap: sizer(.5);
    width: sizer(6);
    width: auto;
    height: fit-content;
    padding: sizer(0.75) sizer(3);
    border-radius: $border-radius-40;
    transition: all .25 ease-in-out;
    position: relative;
    font-size: $font-size-18;
    line-height: $line-height-26;
    font-weight: $font-weight-medium;
    border-color: transparent;

    @media #{$tablet} {
        width: 100%;
        justify-content: center;
    }

    &:first-child:active {
        color: $white;

        svg {
            path {
                fill: $white;
            }
        }
    }
    &:hover {
        opacity: 0.70;
        border-color: transparent;
    }
    &:disabled,
    &:disabled {
        pointer-events: none;
        color: $medium-gray;
        background-color: $bright-gray;
        border-color: transparent !important;
    }
    &:not(.btn-check)+.btn:active {
        color: $white;
        background-color: $black;
        border-color: transparent;
        @media #{$mobile}{
            color: $black;
            background-color: transparent;
        }
    }
    &.btn {
        &-sm {
            padding: sizer(0.5) sizer(1.5) !important;
            border-radius: $border-radius-40;
            font-size: $font-size-16 !important;
            line-height: $line-height-24 !important;
        }
        &-md {
            padding: sizer(0.75) sizer(3) !important;
            border-radius: $border-radius-40;
        }
        &-lg {
            padding: sizer(1) sizer(4) !important;
            border-radius: $border-radius-40;
        }
        &-primary {
            position: relative;
            color: $white;
            background-color: $primary;
            border-color: $primary;
            font-size: $font-size-18;
            line-height: $line-height-26;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 2px 8px 4px 0 rgba(0, 0, 0, 0.04);
                width: 100%;
                height: 100%;
                z-index: 1;
                border-radius: $border-radius-40;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.07);
                width: 100%;
                height: 100%;
                z-index: 1;
                border-radius: $border-radius-40;
            }

            &:hover {
                opacity: 0.5;
                @media #{$mobile}{
                    opacity: unset;
                }
            }
            &.disabled,
            &:disabled {
                pointer-events: none;
                color: $medium-gray;
                background-color: $bright-gray;
                border-color: transparent !important;
            }
        }
        &-secondary {
            position: relative;
            color: $black;
            background-color: $secondary;
            border-color: $translucent-black;
            font-size: $font-size-18;
            line-height: $line-height-26;

            // &::before {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     box-shadow: 2px 8px 4px 0 rgba(0, 0, 0, 0.04);
            //     width: 100%;
            //     height: 100%;
            //     z-index: 1;
            //     border-radius: $border-radius-40;
            // }

            // &::after {
            //     content: "";
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.07);
            //     width: 100%;
            //     height: 100%;
            //     z-index: 1;
            //     border-radius: $border-radius-40;
            // }

            &:hover {
                opacity: 0.5;
            }
            &.disabled,
            &:disabled {
                pointer-events: none;
                color: $medium-gray;
                background-color: $bright-gray;
                border-color: transparent !important;
            }
        }
    }
}
.chips {
    &-primary {
        background-color: $black;
        padding: sizer(.5) sizer(1);
        color: $white;
        border-radius: $border-radius-12;
        display: flex;
        gap: sizer(.5);
        align-items: center;
        cursor: default !important;
        border: none;
        word-break: break-word;
        min-height: sizer(2.601875);
        height: auto;
        &:hover {
            svg {
                path {
                    fill: $white;
                }
            }
        }

        span {
            font-size: $font-size-16;
            line-height: $line-height-24;

            &:nth-child(2) {
                cursor: pointer;
                position: relative;

                &:hover {
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $black;
                        opacity: .25;
                        z-index: 1;
                        border-radius: 50%;
                    }

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }

            svg {
                path {
                    fill: $white;
                }
            }
        }
    }

    &-secondary {
        position: relative;
        background-color: $white;
        color: $black;
        border: 2px solid $black;
        transition: all 0.25s ease-in-out;
        cursor: pointer !important;
        padding: sizer(.5) sizer(1);
        border-radius: $border-radius-12;
        gap: sizer(.5);
        display: flex;
        align-items: center;
        height: sizer(2.601875);

        svg {
            path {
                fill: $black;
            }
        }
        &.hover {
            &::before {
                content: attr(data-tooltip);
                position: absolute;
                bottom: 125%;
                left: 50%;
                transform: translateX(-50%);
                background-color: $chinese-white;
                padding: sizer(.25);
                border-radius: $border-radius-4;
                color: $spanish-gray;
                width: max-content;
                font-size: $font-size-16;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: 101%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color:  $chinese-white transparent transparent transparent;
            }
        }
        &:hover {
            border-color: $sunset-orange;
            background-color: $sunset-orange;
            color: $white;

            span {
                color: $white;
            }

            svg {
                path {
                    fill: $white !important;
                }
            }
        }
    }
}
:not(.btn-check)+.btn:active {
    color: $white;
    background-color: $black;
    border-color: transparent;
}

.spinner {
    &-border {
        width: 24px;
        height: 24px;

        span {
            color: $spanish-gray;
        }
    }
}