.collection-offcanvas {
    
    &-wrapper {
        width: 100%;
        height: 100%;
        z-index: 6;
        display: inline-flex;
        top: 0;
        right: 0;
        padding: sizer(4) sizer(1);
        //border-left: 1px solid $chinese-white;
        color: $black;
        background-color: $white;
        justify-content: center;
        //overflow-y: scroll;
        margin-right: -8px;


        &::-webkit-scrollbar {
            width: sizer(.5);
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-track {
            background: $bright-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb {
            background: $spanish-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $spanish-gray;
        }

        &.sticky {
            position: fixed;
            
        }
    }
    
    &-cont {
        width: 100%;

        &:not(.progress-state) {
            height: calc(100% + 82px);
        }

        h3 {
            font-weight: $font-weight-bold;
            font-family: $inter;
            margin-bottom: sizer(0.5);
        }

        p {
            margin-bottom: sizer(1.5);
        }
    }

    &-header {
        width: 100%;
        padding-bottom: 3rem;
        
        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            + p {
                margin-top: sizer(.5);
                margin-bottom: sizer(3);
            }

            .collection-offcanvas-close {
                .icon-button svg {
                    path {
                        fill: $black !important;
                    }
                }
            }
        }
    }

    &-header.web {
        display: block;
    }

    &-header.mobile {
        display: none;
    }
}

@media #{$mobile} {
    .collection-offcanvas {
        &-wrapper {
            padding: sizer(0) sizer(0);
        }

        &-header {
            > div {
                justify-content: flex-start;
                padding-left: 1rem;
            }
        }
        
        &-header.web {
            display: none;
        }
      
        &-header.mobile {
            height: 74px;
            display: flex;
            padding: 1rem 0;
            align-items: center;
            border-bottom: 1px solid $bright-gray;

            & h3 {
                margin-left: 0;
                font-size: $font-size-18;
                font-weight: $font-weight-medium;
            }
      
            button.icon-button{
                padding: 0 !important;
                padding-right: 8px !important;
            }
            
        }
    }
}