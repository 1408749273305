.setting {
    &-navbar {
        position: relative;

        .container-fluid {
            display: flex;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;

            .navbar-nav {
                flex-direction: row;
                gap: sizer(3);

                .filter-button-wrapper{
                    margin-right: sizer(3);
                }
                .new-old{
                    margin-right: sizer(3);
                }

                .nav-item {
                    display: flex;
                    align-items: center;
                    font-weight: $font-weight-medium;

                    & > .row.g-0 {
                        height: 100%;
                        padding: 6px 0 12px 0;
                    }

                    &.selected {
                        position: relative;
                        padding: 0 10px;
                        height: 100%;

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            height: 4px;
                            border-radius: 5px;
                            width: 100%;
                            background-color: $black;
                        }

                        img {
                            opacity: 1 !important;
                        }
                        a {
                            pointer-events: none;
                            color: $black;
                        }
                        span {
                            margin-left: sizer(.5);
                            color: $black !important;
                        }
                    }

                    .ant-select {
                        &-selection {
                            &-search,
                            &-item {
                                border: 0 !important;
                                margin: 0 !important;
                                color: $black !important;
                                font-weight: $font-weight-normal !important;
                                font-size: $font-size-20;
                                line-height: $line-height-28;
                                align-items: center;
                                display: flex;
                                font-family: $inter;

                                @media #{$xlarge_and_down} {
                                    font-size: $font-size-16;
                                    line-height: $line-height-24;
                                }
                            }
                        }
                        &-arrow {
                            border: 0 !important;
                            margin: 0 !important;
                            padding-left: 0;
                            right: 0;

                            svg {
                                width: sizer(1.75);
                                height: sizer(1.75);
                                path {
                                    fill: $primary;
                                }
                            }
                        }
                        &:hover span,
                        &:hover path{
                            color: rgb(0, 0, 0, 0.5) !important;
                            fill: rgb(0, 0, 0, 0.5) !important;
                        }
                    }

                    .nav-link {
                        font-size: $font-size-20;
                        line-height: $line-height-28;
                        padding-left: unset;
                        padding-right: unset;
                        padding-bottom: sizer(.75);

                        &:hover {
                            color: rgb(152, 149, 149, 0.5);

                            img {
                                opacity: 0.2;
                            }

                            span {
                                border-color: rgb(152, 149, 149, 0.5) !important;
                                color: rgb(152, 149, 149, 0.5) !important;
                            }
                        }

                        span {
                            font-size: $font-size-16;
                            line-height: $line-height-24;
                            font-weight: $font-weight-normal;
                            margin-left: sizer(.5);
                            padding: unset;
                            background-color: white !important;
                            color: $spanish-gray;
                        }
                        img {
                            margin-right: sizer(.5);
                            opacity: 0.5;
                        }
                    }

                    .filter-button-wrapper {
                        a {
                            cursor: pointer;
                            margin-top: unset;
                            margin-bottom: unset;
                            font-size: $font-size-20;
                            line-height: $line-height-28;
                            font-weight: $font-weight-normal;

                            &:hover {
                                opacity: .5;
                            }

                            span{
                                display: inline-block;
                                margin-left:  sizer(.25);

                                svg {
                                    width: sizer(1.75);
                                    height: sizer(1.75);
                                }
                            }
                        }
                    }
                }

                @media #{$xlarge_and_down} {
                    gap: sizer(2);

                    .filter-button-wrapper{
                        margin-right: sizer(2);
                    }
                    .new-old{
                        margin-right: sizer(2);
                    }
                }
            }
        }
    }
}