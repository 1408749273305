body {
	width: 100%;
	height: 100%;
	margin: 0;
	font-size: $font-size-16;
	font-weight: $font-weight-normal;
	line-height: $line-height-24;
	font-family: $inter;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&::-webkit-scrollbar {
		width: sizer(.5);
		border-radius: $border-radius-8;
	}
	&::-webkit-scrollbar-track {
		background: $bright-gray;
		border-radius: $border-radius-8;
	}
	&::-webkit-scrollbar-thumb {
		background: $spanish-gray;
		border-radius: $border-radius-8;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: $spanish-gray;
	}

	&:has(.my-assets-delete-modal) {
		overflow: hidden;
	}

	@media #{$tablet} {
		&:has(.mobile-modal) {
			overflow: hidden !important;

			.menu-tabs-wrapper { z-index: 10 !important; }
		}
	}
}
main {
	width: 100%;
    height: 100%;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 8px;
		height: 8px;
	}
	&::-webkit-scrollbar-thumb {
		background: $spanish-gray;
		border-radius: 40px;
	}
	&::-webkit-scrollbar-track {
		background: $bright-gray;
		border-radius: 40px;
	}

	@media #{$mobile} {
		&:has(.mobile-modal) {
			overflow: hidden !important;

			.menu-tabs-wrapper { z-index: 10 !important; }
		}
	}
}
.ratio {
	&.ratio-4x3 {
		&::before {
			padding-top: $ratio-4x3;
		}
	}
	&.ratio-3x2 {
		&::before {
			padding-top: $ratio-3x2;
		}
	}
	&.ratio-8x5 {
		&::before {
			padding-top: $ratio-8x5;
		}
	}
	&.ratio-16x9 {
		&::before {
			padding-top: $ratio-16x9;
		}
	}

	&::before {
		content: "";
		display: block;
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: contain;
	}
}
.nav-link {
	&.active {
		cursor: default;
		pointer-events: none;
	}
}
.session-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
    justify-content: center;
    align-items: center;
	z-index: 9999;
	opacity: .5;

	.spinner-grow {
		width: 200px;
		height: 200px;
	}
}
hr {
	opacity: 1;
	border-color: $chinese-white;
	margin: sizer(1.5) 0;
}
