.my-assets {
    @media #{$tablet}{
        &-cards-container &-cards-grid{
            gap:sizer(2.5) sizer(1.25) !important;
            padding-bottom: sizer(2.5) !important;
        }
        .mobile-setting-bar{
            .mobile-setting-filter{
                position: relative;
                display: flex;
                justify-content: space-between;
                height: 42px;
                button{
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    h3{
                        margin-bottom: 0;
                    }
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 120%; //63%;
                    height: 1px;
                    top: 41px;
                    background-color: $chinese-white;
                    transform: translateX(-50px);
                }
            }
            .my-assets-filter-search-box{
                margin-top: sizer(1);
                input{
                    width: 100%;
                    padding: sizer(1) sizer(1);
                    padding-left: 64px;
                    border-radius: 40px;
                    height: 56px;
                    min-width: unset;
                    background: $white;
                }
            }
            .mobile-check-delete{
                background-color: $light-gray;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                padding: sizer(0.75) sizer(1.5);
                position: fixed;
                width: 100%;
                height: 74px;
                z-index: 1;
                left: 0;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5), 0 0 4px -4px rgba(0, 0, 0, 0.5);
                .mobile-user-check{
                  width: sizer(20.375);
                  span{
                    font-size: $mobile-font-size-18;
                    font-weight: 500;
                    line-height: 26px;
                  }
                  .ant-checkbox-inner:before {
                    background-color: $black;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 45%;
                    left: 5px;
                    height: 1.67px;
                    width: 11.67px;
                  }
                  .selectall_checkbox.some-checkbox-filled:hover .ant-checkbox .ant-checkbox-inner::before {
                      background-color: transparent;
                  }
                }
                label{
                    font-size: $font-size-18;
                  }
                .mobile-user-bulk-delete{
                  button{
                    padding: 0;
                  }
                }
            }
        }
        .my-assets-cards-container{
            padding-top:sizer(2.5) !important;
            .my-assets-cards-grid  {
                .asset-card-body{
                    height: 100%;
                    left: -0.1px;
                    .asset-card-type-icon {
                        top: sizer(0.5);
                        right: sizer(1);
                    }
                }
                .asset-card:hover{
                    .asset-card-body-title,
                    .volume-icon-section{
                        display: none !important;
                    }
                }
                .asset-card:hover .asset-card-body::before{
                    background: transparent;
                }
                .asset-card-body{
                    &:hover{
                        .asset-card-body-title,
                        .volume-icon-section{
                            display: flex !important;
                        }
                        &::before{
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(217, 217, 217, 0) 50.17%) !important;
                        }
                    }
                    .asset-card-body-title,
                    .volume-icon-section{
                        padding: sizer(1.2) !important;
                    }
                    .volume-icon-section{
                        gap: 8px;
                        .volume-svg{
                            padding: 0 !important;
                            margin: 0 !important;
                        }
                        .grid-view-title-container{
                            width: 100%!important;
                        }
                    }
                }
                .asset-card-footer{
                    height: 49px;
                    padding: sizer(0.75) sizer(1);
                    label:hover,
                    span:hover{
                        opacity: unset;
                    }
                }
            }
            .no-assets-yet {
                padding-top: 0;
            }
            .no-asset-button-container{
                button{
                    font-size: $font-size-18 !important;
                }
            }
            .my-assets-cards-row{
                margin-top: sizer(0.5);
                margin-bottom: sizer(2.5);
            }
        }
        .my-assets-filter-select-all:hover {
            opacity: unset;
        }
        button.mobile-more-vertical {
            border: none;
            background: transparent;
            padding: 0;
            position: relative;
            right: -10px;
            svg {
                transform: rotate(90deg);
            }
        }

        .my-assets-cards-row{
            .mobile-row-filter{
                display: flex;
                position: relative;
                //gap: sizer(0.5);
                .my-assets-card-row-data {
                    border-bottom: 0;
                    width: 90%;
                    gap: sizer(0.5);
                    margin-right: auto;
                    > div:nth-child(1) {
                        @media #{$tablet}{
                            width: 35%;
                        }
                        gap: sizer(0.5);
                    }
                    > div:nth-child(2) {
                        width: 65% !important;
                        display: flex;
                        flex-direction: column;
                        gap: sizer(0.5);
                        .mobile-type-size{
                            display: flex;
                            gap: sizer(1);
                            span{
                                text-transform: capitalize;
                                color: $spanish-gray;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .asset-card-actions{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    //width: 50px;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 120%; //63%;
                    height: 1px;
                    bottom: 0;
                    background-color: $chinese-white;
                    transform: translateX(-50px);
                }
            }
        }
        .filter-inputs-container {
            .filter-file-size-input-container {
                .filter-file-min-size input,
                .filter-file-max-size input {
                    width: 100%;
                }
            }
        }
        .my-assets-cards-container{
            .no-assets-yet .svg-field img {
                width: 366px;
                height: 260px;

            }
            .no-asset-text-container{
                margin: sizer(2.5) 0 !important;
                h3{
                    margin-bottom: unset;
                    font-size: $mobile-font-size-h2 !important;
                    font-weight: 500 !important;
                    line-height: 32px !important;
                }
                p{
                    margin-bottom: unset;
                }
            }
            .no-asset-button-container button {
                width: 100%;
            }
        }
        .btn:first-child:active, :not(.btn-check)+.btn:active {
            background-color: transparent;
            border-color: transparent;
            color: $black;
            svg path {
                fill: $black;
            }
        }
        .ant-pagination{
            &-item-active {
              height: 40px !important;
            }
            li{
              height: 40px;
              width: 24px;
              padding: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              &.ant-pagination-options{
                display: none !important;
              }
              .ant-pagination-item-link{
                display: flex;
                align-items: center;
              }
              & > a:hover{
                opacity: unset;
              }
            }
          }
          span.table-pagination{
            font-size: $mobile-font-size-18;
            line-height: $line-height-26;
          }

    }
    display: flex;
    flex-direction: column;

    &-card-skeleton-cont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: sizer(3) sizer(1.5) !important;

        .asset-card-skeleton {
            width: calc(20% - 19.2px);
            height: 100%;

            @media #{$tablet} {
                width: calc(25% - 15px);
            }

            @media #{$mobile} {
                width: calc(50% - 12px);
            }
        }
    }

    &-delete-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);

        .modal-body {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 852px;
            overflow: hidden;
            background-color: $white;
            border-radius: $border-radius-20;
            padding: unset !important;
            box-shadow: 0px 2px 2px 0px #00000012,
                        2px 8px 4px 0px #0000000A;

            .modal-title {
                padding: sizer(3) sizer(3) 0 sizer(3);

                h3 {
                    font-family: $inter;
                    font-size: $font-size-20;
                    line-height: $line-height-28;
                    font-weight: $font-weight-medium;
                    margin-bottom: unset;
                    padding-bottom: sizer(.5);
                    border-bottom: 1px solid $chinese-white;
                }
            }
            .modal-content {
                padding: sizer(3);
                padding-top:  sizer(1.5);
                border: none;

                &::before,
                &::after {
                    content: unset;
                }

                h4 {
                    font-family: $inter;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-normal;
                    margin: unset;
                    width: auto;
                }
                &-list-container {
                    overflow: hidden;
                    padding: sizer(1);
                    // padding-right: sizer(0.5);
                    border-radius: $border-radius-20;
                    border: 1px solid $chinese-white;
                    max-width: 560px;
                    margin-top: sizer(1.5);

                    ul {
                        list-style: none;
                        padding: unset;
                        margin: unset;
                        display: flex;
                        flex-direction: column;
                        gap: sizer(.25);
                        overflow: auto;
                        overflow-x: hidden;
                        max-height: clamp(6.25rem, 5.6rem + 2.275vw, 20.75rem);

                        &::-webkit-scrollbar {
                            width: sizer(.5);
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: $bright-gray;
                            border-radius: 20px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $spanish-gray;
                            border-radius: 20px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: $granite-gray;
                        }

                        li > p {
                            font-family: $inter;
                            font-size: $font-size-16;
                            line-height: $line-height-24;
                            font-weight: $font-weight-normal;
                            word-break: break-all;
                            margin-bottom: unset;
                        }
                    }
                }
            }
            .buttons-container {
                width: 100%;
                background-color: $light-gray;
                height: 76px;
                padding-right: sizer(3);
                align-items: center;

                button {
                    height: 52px;
                    padding: sizer(.75) sizer(3);
                    border-radius: $border-radius-40;
                }
            }
        }
    }

    h2 {
        font-size: $font-size-28;
        line-height: $line-height-36;
        margin-bottom: sizer(3);
    }

    &-types {
        display: flex;
        gap: 40px;
        padding: unset;
        margin-bottom: unset;

        li {
            list-style: none;
            display: flex;
            align-items: center;
            gap: sizer(.5);
            padding-bottom: sizer(.75);
            position: relative;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 4px;
                width: 100%;
                background-color: transparent;
                border-radius: 5px;
            }

            img {
                opacity: .5;
            }

            span {
                color: $spanish-gray;
                font-family: $inter;
                font-size: $font-size-20;
                line-height: $line-height-28;
            }

            span + span {
                font-size: $font-size-16;
                line-height: $line-height-24;
                padding-right: sizer(.5);
            }

            &.active {
                &::after { background-color: $black; }
                img { opacity: 1; }
                span { color: $black; }
            }

            &:not(.active):hover {
                img {
                    opacity: 0.2;
                }
                span {
                    color: rgba(152, 149, 149, 0.5);
                }
                span + span {
                    color: rgba(152, 149, 149, 0.5) !important;
                }
            }


        }
    }

    &-filter {
        display: flex;
        align-items: center;
        gap: Min(sizer(3), 100vw/40); // need to replace
        padding: 10px;
        border-bottom: 1px solid $chinese-white;
        border-top: 1px solid $chinese-white;

        &-select-all {
            margin-right: auto;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }

            input {
                // display: none;
                position: absolute;
                left: 36px;
                opacity: 0;
            }

            label:nth-child(2) {
                height: $icon-size;
                width: $icon-size;
                border-radius: sizer(.25);
                border: 1px solid $black;
                margin-right: sizer(1);
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &.dashed {
                    &::before {
                        content: '';
                        height: 2px;
                        width: 12px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: black;
                        border-radius: 2px;
                    }
                }

                &.checked {
                    background-color: $black;

                    svg path { fill: $white; }
                }
            }

            label:nth-child(3) {
                font-family: $inter;
                font-weight: $font-weight-medium;
                color: $black;
                font-size: $font-size-20;
                line-height: $line-height-28;
                text-wrap: nowrap;
            }

            input, label { cursor: pointer; }
        }

        &-search-box {
            position: relative;

            span {
                height: sizer(1.5);
                width: sizer(1.5);
                position: absolute;
                top: 50%;
                left: sizer(1.5);
                transform: translateY(-50%);
            }

            input {
                border: 1px solid $chinese-white;
                padding: sizer(.5) sizer(1.5);
                padding-left: 64px;
                border-radius: $border-radius-20;
                max-width: 414px;
                width: 100%;
                height: 40px;
                background: $white;

                &:focus {
                    outline: 1px solid $black;
                }
            }
        }

        &-dropdown {
            display: flex;
            align-items: center;
            gap: sizer(.5);
            cursor: pointer;

            div {
                color: $black;
                font-family: $inter;
                font-size: $font-size-20;
                line-height: $line-height-28;
                font-weight: $font-weight-medium;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        &-delete {
            display: flex;
            align-items: center;
            gap: sizer(.5);
            cursor: pointer;

            div {
                color: $black;
                font-family: $inter;
                font-size: $font-size-20;
                line-height: $line-height-28;
                font-weight: $font-weight-medium;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        &-grid-row {
            display: flex;
            gap: sizer(.5);

            .grid-icon,
            .row-icon {
                cursor: pointer;
                &.active {
                    svg path { fill: $black; }
                }

                &:not(.active):hover {
                    svg path { fill: rgba(151, 149, 149, 0.5); }
                }
            }
        }
    }

    .filter {
        &-dropdown-shown {
            padding-top: sizer(3);
            display: flex;
            flex-direction: column;
            gap: sizer(1.5);
            width: 100%;

            &.hidden { display: none; }

            .row {
                padding-bottom: sizer(3);
                gap: 0;
            }
        }

        &-inputs-container {
            display: flex;
            gap: sizer(1.5);

            .filter-date {
                display: flex;
                flex-direction: column;
                gap: 28px;
                width: 33.3%;
                position: relative;

                &-header {
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-semi-bold;
                    font-family: $inter;
                }

                &-input-container {
                    position: relative;
                    width: 100%;

                    .date-component {
                        position: relative;
                        // width: 100%;
                        width: fit-content;

                        .ant-picker-input {
                            // width: 100%;

                            &:first-child {
                                width: fit-content;
                            }

                            input {
                                text-align: unset;
                                width: 98px;
                            }
                        }

                        &:has(.ant-picker-clear) {
                            .ant-picker-input:not(:first-child) {
                                &::after {
                                    opacity: 0;
                                }
                            }

                            .ant-picker-clear {
                                span {
                                    font-size: $font-size-24;
                                    svg {
                                        fill: $black;
                                    }
                                }

                                &:hover { opacity: 0.5; }
                            }
                        }
                    }

                    label {
                        position: absolute;
                        top: -8px;
                        left: 21px;
                        background-color: $white;
                        padding: 0 sizer(0.25);
                        font-size: $font-size-12;
                        line-height: $line-height-16;
                    }
                }
            }

            .filter-file-size {
                display: flex;
                flex-direction: column;
                gap: 28px;

                &-header {
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-semi-bold;
                    font-family: $inter;
                }

                &-input-container {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .filter-file {
                        &-min-size,
                        &-max-size {
                            position: relative;
                            label {
                                position: absolute;
                                top: -8px;
                                left: 21px;
                                background-color: $white;
                                padding: 0 sizer(0.25);
                                font-size: $font-size-12;
                                line-height: $line-height-16;
                            }
                            input {
                                width: 223px;
                                border: 1px solid $chinese-white;
                                border-radius: $border-radius-20;
                                padding: calc(sizer(1.5) + 2px) 65px calc(sizer(1.5) + 2px) sizer(1.5);
                                font-family: $inter;
                                font-size: $font-size-16;
                                line-height: $line-height-24;
                                outline: none;

                                &:focus-within {
                                    border: 1px solid $black;
                                }
                            }
                            span {
                                position: absolute;
                                top: 50%;
                                right: sizer(1.5);
                                transform: translateY(-50%);
                                font-family: $inter;
                                font-size: $font-size-16;
                                line-height: $line-height-24;
                            }
                        }

                        &-separator {
                            width: 32px;
                            height: 1px;
                            background-color: $chinese-white;
                        }
                    }
                }
            }
        }

    }

    &-cards-container {
        display: flex;
        flex-direction: column;
        //padding-top: sizer(3);

        .my-assets-cards {
            &-showing {
                p {
                    color: $black;
                    margin-bottom: unset;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                }
            }

            &-grid {
                display: flex;
                gap: sizer(3) sizer(1.5);
                padding-top: sizer(1.5);
                padding-bottom: sizer(3);
                position: relative;
                flex-direction: row;
                flex-wrap: wrap;

                .asset-card {
                    padding: unset;
                    border-radius: $border-radius-20;
                    position: relative;
                    overflow: hidden;
                    flex: 0 0 calc(20% - 19.2px);
                    width: 100%;
                    height: 100%;
                    isolation: isolate;

                    &:hover {
                        .asset-card-body {
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 100%;
                                width: 100%;
                                background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(217, 217, 217, 0) 50.17%);
                                z-index: 2;
                            }


                            &.document {
                                .asset-card-body-title {
                                    display: flex;
                                    z-index: 3;
                                }
                            }

                            &.image {
                                .asset-card-body-title {
                                    display: flex;
                                    z-index: 3;
                                }
                            }

                            &.audio {
                                .asset-card-body-title {
                                    display: flex !important;
                                }
                            }

                            &.video {
                                .volume-icon-section {
                                    display: flex;
                                }
                            }
                        }
                    }

                    &-body {
                        position: relative;
                        z-index: 1;
                        isolation: isolate;
                        width: 100%;
                        height: 100%;

                        &.audio {
                            .svg-icon {
                                position: absolute;
                                width: 40px;
                                height: 53px;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: $black !important;

                                svg {
                                    width: 40px;
                                    height: 53px;
                                }
                            }
                            .volume-icon-section {
                                align-items: flex-end !important;
                            }
                        }

                        &.audio,
                        &.video {
                            .volume-icon-section {
                                position: absolute;
                                bottom: 0px;
                                left: 0;
                                z-index: 2;
                                padding: sizer(1) sizer(1.5);
                                width: 100%;
                                display: flex;
                                align-items: center;

                                .grid-view-title-container {
                                    width:100%;
                                    display:inline-flex;
                                    flex-wrap:nowrap;
                                    line-height: 24px;

                                    .first-text {
                                        flex: 0 1 content;
                                        text-overflow: ellipsis;
                                        overflow:hidden;
                                        white-space:nowrap;
                                        font-size: $font-size-16;
                                        line-height: $line-height-24;
                                        font-weight: $font-weight-normal;
                                        color: $white;
                                    }

                                    .end-text {
                                        flex: 1 0 content;
                                        white-space:nowrap;
                                        font-size: $font-size-16;
                                        line-height: $line-height-24;
                                        font-weight: $font-weight-normal;
                                        color: $white;
                                    }
                                }

                                .volume-svg {
                                    margin: 0 sizer(0.25) 0 auto;
                                    padding-left: sizer(1);

                                    svg {
                                        color: $white;
                                        height: sizer(1.5);
                                        width: sizer(1.5);
                                    }
                                }

                                p {
                                    display: inline;
                                    margin: unset;
                                    font-size: $font-size-16;
                                    line-height: $line-height-24;
                                    font-weight: $font-weight-normal;
                                    color: $white;

                                    @media screen and (max-width: 1750px) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &.image,
                        &.document {
                            .svg-icon {
                                position: absolute;
                                width: 40px;
                                height: 53px;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: $black !important;

                                svg {
                                    width: 40px;
                                    height: 53px;
                                }
                            }
                            .asset-card-body-title {
                                position: absolute;
                                bottom: 0px;
                                left: 0;
                                z-index: 2;
                                padding: sizer(1) sizer(1.5);
                                width: 100%;
                                align-items: center;
                                display: none;

                                .grid-view-title-container {
                                    width:100%;
                                    display:inline-flex;
                                    flex-wrap:nowrap;
                                    line-height: 24px;

                                    .first-text {
                                        flex: 0 1 content;
                                        text-overflow: ellipsis;
                                        overflow:hidden;
                                        white-space:nowrap;
                                        font-size: $font-size-16;
                                        line-height: $line-height-24;
                                        font-weight: $font-weight-normal;
                                        color: $white;
                                    }

                                    .end-text {
                                        flex: 1 0 content;
                                        white-space:nowrap;
                                        font-size: $font-size-16;
                                        line-height: $line-height-24;
                                        font-weight: $font-weight-normal;
                                        color: $white;
                                    }
                                }
                            }
                        }

                        img {
                            background-size: cover;
                            object-fit: cover;
                            background-repeat: no-repeat;
                            background-position: center;
                            height: 100%;
                            width: 100%;
                        }

                        video,
                        audio {
                            height: 100%;
                            width: 100%;
                        }

                        .asset-card-type-icon {
                            position: absolute;
                            top: sizer(1);
                            right: sizer(1.5);
                            background-color: #00000033;
                            border-radius: $border-radius-20;
                            padding: sizer(0.25) sizer(0.75);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                height: 12px;
                                width: 12px;
                                color: $white;
                            }
                        }
                    }

                    &-footer {
                        position: relative;
                        bottom: 0;
                        left: 0;
                        height: 60px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: sizer(1.5);
                        padding: sizer(1.25) sizer(1.5);
                        background-color: $white;
                        border-top: 1px solid $chinese-white;
                        z-index: 1;

                        label {
                            height: $icon-size;
                            width: $icon-size;
                            border-radius: $border-radius-4;
                            border: 1px solid black;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                transform: scale(0.8);
                                path { fill: $white; }
                            }
                        }

                        input { display: none; }

                        input:checked + label {
                            background-color: $black;

                            svg path { fill: $white; }
                        }

                        label, span {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }

                    @media #{$tablet} {
                        flex: 0 0 calc(25% - 15px);
                    }

                    @media #{$mobile} {
                        flex: 0 0 calc(50% - 12px);
                    }
                }

                .asset-card-skeleton {
                    height: 100%;
                    width: calc(20% - 19.2px);
                    position: relative;

                    span {
                        height: 100%;
                    }

                    @media #{$tablet} {
                        width: calc(25% - 15px);
                    }
                    @media #{$mobile} {
                        width: calc(50% - 12px);
                    }
                }
            }

            &-row {
                margin-top: sizer(1.5);
                margin-bottom: sizer(3);
                .my-assets-card {
                    &-header {
                        display: flex;
                        gap: 24px;
                        align-items: center;
                        max-height: 64px;
                        background-color: $black;
                        padding: 20px 0;

                        div:nth-child(1) {
                            width: 122px;
                            @media screen and #{$mlarge_and_down} { width: 15%; }
                            @media screen and (max-width: 1200px) { width: 18%; }
                        }
                        div:nth-child(2) {
                            width: 50%;
                            cursor: pointer;
                            @media screen and #{$mlarge_and_down} { width: 46%; }
                        }
                        div:nth-child(3) { width: 13%; cursor: pointer; }
                        div:nth-child(4) { width: 13%; cursor: pointer; }
                        div:nth-child(5) { width: 13%; }

                        div {
                            padding: 12px unset;
                            color: $white;
                            font-weight: $font-weight-medium;

                            svg {
                                margin-left: 14px;
                                cursor: pointer;
                            }
                        }
                    }

                    &-row-data {
                        display: flex;
                        align-items: center;
                        gap: 24px;
                        padding: 10px 0;
                        border-bottom: 1px solid $chinese-white;

                        div {
                            // padding: 0 0.5rem;
                            padding: unset;
                            position: relative;
                            overflow: hidden;
                        }

                        & > div:nth-child(1) {
                            //width: 11%;
                            @media screen and #{$mlarge_and_down} { width: 15%; }
                            @media screen and (max-width: 1200px) { width: 18%; }
                            display: flex;
                            align-items: center;
                            gap: 18px;
                            padding-left: unset;

                            input { display: none; }

                            label {
                                height: $icon-size;
                                width: $icon-size;
                                line-height: 24px;
                                border-radius: $border-radius-4;
                                border: 1px solid black;
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;

                                svg {
                                    transform: scale(0.8);
                                    path { fill: $white; }
                                }
                            }

                            input:checked + label {
                                background-color: $black;

                                svg path { fill: $white; }
                            }

                            .row-data-image-container {
                                position: relative;
                                height: 60px;
                                width: 80px;
                                overflow: hidden;
                                padding: unset;
                                cursor: pointer;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    background-size: cover;
                                    object-fit: cover;
                                }

                                .svg-icon {
                                    width: 100%;
                                    height: 100%;

                                    svg {
                                        height: 24px;
                                        width: 24px;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);

                                        path{ fill: $black; }
                                    }
                                }
                            }
                        }
                        & > div:nth-child(2) {
                            width: 50%;
                            @media screen and #{$mlarge_and_down} { width: 46%; }
                        }
                        & > div:nth-child(3) {
                            width: 13%;
                            text-transform: capitalize;
                        }
                        & > div:nth-child(4) { width: 13%; }

                        .list-view-title-container {
                            width:100% !important;
                            display:inline-flex;
                            flex-wrap:nowrap;
                            padding: unset;

                            .first-text {
                                flex: 0 1 content;
                                text-overflow: ellipsis;
                                overflow:hidden;
                                white-space:nowrap;
                                padding: unset;
                                max-width: fit-content;
                                width: auto;

                                @media #{$tablet} {
                                    width: fit-content;
                                    max-width: 300px;
                                }

                                @media #{$mobile} {
                                    width: fit-content;
                                    max-width: 200px;
                                }
                            }

                            .end-text {
                                flex: 1 0 content;
                                white-space:nowrap;
                                width: unset;
                                padding: unset;
                            }
                        }

                        .asset-card-actions {
                            display: flex;
                            align-items: center;
                            gap: sizer(1);
                            width: 13%;
                            padding-right: unset;

                            span:hover {
                                opacity: 0.5;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .no-result-search {
            display: flex;
            flex-direction: column;

            p, ul, li {
                font-family: $inter;
                font-size: $font-size-16;
                line-height: $line-height-24;
            }

            p {
                font-weight: $font-weight-bold;
            }
        }

        .no-assets-yet {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            padding-top: 40px;

            .svg-field {
                display: flex;
                justify-content: center;

                img {
                    height: 300px;
                    width: 294.32px;
                }
            }
            .no-filter-result {
                display: flex;
                flex-direction: column;
            }
            .no-asset-text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: sizer(1);
                margin: 50px 0 sizer(3) 0;

                h3 {
                    text-align: center;
                    font-family: $urbane-rounded;
                    font-size: $font-size-28;
                    font-weight: $font-weight-medium;
                    line-height: $line-height-36;
                    color: $black;
                    margin-bottom: 0;
                }

                p {
                    text-align: center;
                    font-family: $inter;
                    font-size: $font-size-16;
                    font-weight: $font-weight-normal;
                    line-height: $line-height-24;
                    color: $black;
                    margin-bottom: 0;
                }
            }

            .no-asset-button-container {
                display: flex;
                justify-content: center;

                button {
                    font-family: $inter;
                    font-size: $font-size-20;
                    font-weight: $font-weight-medium;
                    line-height: $line-height-28;
                    color: $white;
                    background-color: $black;
                    border-radius: $border-radius-40;
                    padding: sizer(.75) sizer(3);
                    border: none;
                    cursor: pointer;
                }
            }
        }

        .pagination-container {
            display: flex;
            justify-content: center;

            ul {
                li {
                    font-family: $inter;
                    height: 40px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    &.ant-pagination-options {
                        display: none;
                    }
                    &.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
                    &.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
                        a div span {
                            transform: translateY(6px);
                            svg path { fill: $black; }
                            &.ant-pagination-item-ellipsis {
                                color: $black;
                                font-size: $font-size-8;
                            }
                        }
                    }

                    &.ant-pagination-item-active {
                        a {
                            font-weight: $font-weight-normal;
                            width: unset !important;
                            padding: sizer(.5) !important;
                            font-size: $font-size-16;
                            line-height: $line-height-24;
                        }
                    }

                    button {
                        height: 40px !important;
                        display: inline-flex !important;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            &-input {
                font-family: $inter;
                font-size: $font-size-16;
                line-height: $line-height-24;
                font-weight: $font-weight-normal;
                color: $black;
                padding-left: sizer(1);
                margin-left: sizer(1);
                border-left: 1px solid $chinese-white;
                display: flex;
                align-items: center;

                input {
                    margin: 0 sizer(1) !important;
                    //border-radius: $border-radius-12;
                    border: 1px solid $spanish-gray;
                    width: 50px !important;
                    font-family: $inter;
                    &:focus {
                      border-color: $black !important;
                      box-shadow: none;
                    }
                    &:not(:focus):hover {
                      opacity: 0.5;
                    }
                }
            }
        }
    }

    &-notification {
        position: absolute;
        top: -96px;

        .notification-holder {
            position: relative;
            width: 560px;
            height: 64px;
            display: flex;
            align-items: center;
            gap: sizer(.5);
            padding-left: sizer(1.5);

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: sizer(.5);
            }
        }

        span:nth-child(1){
            height: sizer(1.5);
            width: sizer(1.5);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: $white;
                transform: scale(0.8);
            }
        }

        span:nth-child(2) {
            font-family: $inter;
            font-size: $font-size-16;
            line-height: $line-height-24;
        }

        &.danger .notification-holder {
            background-color: $light-pink;
            &::before { background-color: $candy-red; }
            span:nth-child(1) svg { fill: $candy-red; }
        }

        &.success .notification-holder {
            background-color: $light-green;

            span:nth-child(1) { background-color:$leafy-green; }
            span:nth-child(1) svg { fill: $white; }
        }
    }

    .buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 21px;

        button {
            padding: sizer(0.5) sizer(1.5);
            border: 1px solid $black;
            border-radius: $border-radius-20;
            font-size: $font-size-16;
            line-height: $line-height-24;
            font-weight: $font-weight-inter-medium;
            font-family: $inter;

            &.clear-btn {
                color: $black;
                background-color: transparent;
            }
            &.apply-btn {
                color: $white;
                background-color: $black;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }
}