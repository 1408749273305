@import "./bookmark-card";

.bookmark {
    &-view {
        &-sort-by {
            .ant-select {
                min-width: 185px;
                
                &-arrow {
                    svg {
                        margin-left: sizer(.5);
                        path {
                            fill: $black;
                        }
                    }
                }
            }
            &:hover {
                opacity: 0.5;
                transition: all .25s ease-in-out;
            }
        }

        &-wrapper {
            padding-top: sizer(6);
            padding-bottom: sizer(6);

            .row .col-12{
                @media #{$large_and_up} {
                    padding-right: calc(var(--bs-gutter-x)* 0);
                    padding-left: calc(var(--bs-gutter-x)* 0);
                }

                nav {
                    &::before {
                        content: "";
                        height: 0.5px;
                        width: 100%;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        clip-path: inset(0 -100vmax);
                        box-shadow: 0 0 0 100vmax #E1E1E1;
                        border-bottom: 1px solid #E1E1E1;
                    }
                    
                    .navbar-nav .nav-item:nth-child(2){
                        margin-left: auto;
                    }
                }
            }
            
            ol {
                flex-wrap: nowrap;
                
                .breadcrumb-item {
                    margin-bottom: auto;
                }

                .breadcrumb-item.active {
                    padding-left: 0;

                    @media #{$mobile} {
                        word-break: break-word;
                        
                    }

                    > div {
                        width: 100%;
                        text-align: justify;
                    }
                }
            }

            .asset-view-container {
                .navbar  {
                    &-nav {
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }

            .no-bookmark-view {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                img {
                    width: 300px;
                    height: 306px;
                    margin-bottom: sizer(3);
                }
                h2 {
                    margin-bottom: sizer(1);
                    text-align: center;

                    + p {
                        text-align: center;
                        color: $black;
                        margin-bottom: sizer(3);
                    }
                }
            }

            .bookmark-view-search {
                section.pb-5:first-child {
                    .bookmark-title-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-bottom: 48px !important ;
                        width: 100%;

                        h2 {
                            display: flex;
                            flex-direction: row;
                            span {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                &:hover {
                                    opacity: 0.5;
                                }

                                svg {
                                    vertical-align: text-bottom;
                                    margin-left: 8px;
                                }
                            }
                        }

                        .title {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row; 
                            
                            h2 {
                                margin-bottom: 0;
                                text-align: justify;
                            }
                            
                            .icon {
                                padding: 0 5px;
                                position: relative;
                                top: 2px;
                                cursor: pointer;

                                svg {
                                    margin-left: 0.3em;
                                    vertical-align: baseline;
                                }
                            }
                        }
                    }

                    .asset-view-container {
                        .mobile-type-active-container { display: none; }
                        .mobile-filter-container { display: none; }
                        .mobile-view-container { display: none; }
                    }
                }
                section:last-child {
                    .assets-container .nousersfound {
                        display: none;
                    }

                    .no-cards-found {
                        margin-top: unset;
                        padding-right: unset;

                        p {
                            padding: unset;
                        }

                        ul {
                            padding: unset;
                            padding-left: sizer(1.6);

                            li {
                                line-height: $line-height-24;
                            }
                        }
                    }

                    .assets-container:has(.no-cards-found){
                        thead { display: none; }
                    }
                }
            }

            .mobile-bookmark-view-container { display: none; }

            @media #{$large_and_down} {
                .row {
                    .col-12 {
                        &:not(:last-child) {
                            margin-bottom: sizer(1);
                        }
                    }
                }
            }
        }
        &-show-number {
            color: $black;
            font-size: $font-size-20;
            line-height: $line-height-28;
            font-weight: $font-weight-medium;
            height: 100%;
            align-content: center;
        }
        &-card-container {
            margin-top: sizer(3);

            .row {
                margin-right: calc(-0.5* var(--bs-gutter-x));
                margin-left: calc(-0.5* var(--bs-gutter-x));
            }
        }
    }

    &-view {
        @media #{$large_and_down} {
            &-wrapper {
                padding-top: sizer(3);
                padding-bottom: unset;

                h2 {
                    margin-bottom: sizer(2.5);
                }

                .asset-view-container {
                    display: block;
                    margin-right: 0;
                    margin-left: 0;

                    .col-12 {
                        .navbar {
                            display: none;
                        }

                        .mobile-bookmark-view-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: sizer(1);
                            position: relative;

                            &::before {
                                content: '';
                                height: 0.5px;
                                width: 100%;
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                clip-path: inset(0 -100vmax);
                                box-shadow: 0 0 0 100vmax $chinese-white;
                                border-bottom: 1px solid $chinese-white;
                            }

                            h3 {
                                margin-bottom: unset;
                            }

                            .mobile-bookmark-new-to-old {
                                cursor: pointer;

                                h3 {
                                    margin-bottom: unset;

                                    svg {
                                        margin-left: sizer(.5);
                                    }
                                }
                            }
                        }
                    }
                }

                .bookmark-view-card-container {
                    margin-top: sizer(2.5);
                    .row {
                        margin: unset;
                        .col-4 {
                            width: 100%;
                            flex: unset;
                            padding: unset;
                            margin: unset;

                            .bookmark-card {
                                .bookmark-card-wrapper {
                                    .bookmark-card-group {
                                        .two-image {
                                            .bookmark-card-icon {
                                                svg.fa-file-audio,
                                                svg.fa-file-word,
                                                svg.fa-file-pdf {
                                                    height: sizer(4);
                                                    width: sizer(4);
                                                }
                                            }
                                        }

                                        .three-image {
                                            & > div {
                                                .bookmark-card-icon {
                                                    svg.fa-file-audio,
                                                    svg.fa-file-word,
                                                    svg.fa-file-pdf {
                                                        height: sizer(3);
                                                        width: sizer(3);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    h3 {
                                        margin: 15.43px 0 sizer(2.5) 0;
                                    }
                                }
                            }
                        }
                    }
                }

                // bookmark page after user click one bookmark
                .bookmark-view-search {
                    section.pb-5:first-child {
                        padding-top: sizer(2.5) !important;
                        padding-bottom: sizer(2.5) !important;

                        .bookmark-title-wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            margin-bottom: 48px !important;

                            h2 {
                                margin-bottom: 0;
                                word-break: break-word;
                            }

                            .icon {
                                padding: 0 5px;
                                cursor: pointer;
                            }
                        }

                        .asset-view-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            // gap: 51.5px;
                            width: unset;
                            flex-wrap: nowrap;
                            margin: unset;
                            padding-bottom: sizer(1);
                            position: relative;

                            &::before {
                                content: "";
                                height: 0.5px;
                                width: 100%;
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                clip-path: inset(0 -100vmax);
                                box-shadow: 0 0 0 100vmax $chinese-white;
                                border-bottom: 1px solid $chinese-white;
                            }

                            .mobile-type-active-container {
                                display: flex;
                                align-items: center;
                                width: 127px;
                                padding: unset;
                                cursor: pointer;

                                h3 {
                                    margin-bottom: unset;
                                    margin-right: sizer(.25);
                                    display: inline-block;
                                    font-weight: $font-weight-normal;
                                }
                            }

                            .mobile-filter-container {
                                display: flex;
                                align-items: center;
                                width: 112px;
                                padding: unset;
                                cursor: pointer;

                                h3 {
                                    margin-bottom: unset;
                                    margin-right: sizer(.25);
                                    display: inline-block;
                                    font-weight: $font-weight-normal;
                                }
                            }

                            .mobile-view-container {
                                display: block;
                                width: 24px;
                                padding: unset;
                                cursor: pointer;

                                .mobile-view-icon {
                                    &.show {
                                        display: block;
                                    }

                                    &.hide {
                                        display: none;
                                    }
                                }
                            }

                            .navbar {
                                display: none;
                            }
                        }
                    }

                    section.pb-5:last-child {
                        padding-bottom: sizer(3) !important;

                        .assets-container {
                            margin: unset;

                            .asset-card-wrapper {
                                &:last-child {
                                    margin-bottom: unset !important;
                                }
                            }

                            .asset-view-no-asset {
                                padding: unset !important;

                                img {
                                    width: 221.89px;
                                    height: 225px;
                                    padding: unset;
                                }

                                h2 {
                                    padding: unset;
                                    margin-bottom: sizer(1);
                                }

                                p {
                                    text-align: center !important;
                                    padding: unset !important;
                                    margin-bottom: sizer(2.5);
                                }
                            }

                            &:has(.asset-view-no-asset) {
                                .asset-view-no-asset {
                                    padding-bottom: sizer(3) !important;
                                }
                            }

                            .no-cards-found {
                                padding: unset;

                                ul {
                                    li {
                                        font-size: $font-size-16;
                                        line-height: $line-height-24;
                                    }
                                }
                            }
                        }
                    }
                }

                // no bookmark
                .no-bookmark-view {
                    p {
                        color: $black !important;
                        margin-bottom: sizer(2.5) !important;
                    }
                }

                &:has(.no-bookmark-view) {
                    padding-bottom: 3rem;
                }
            }
        }
    }
}