.asset-view-container .navbar {
  position: relative;

  &::before {
    content: '';
    height: 0.5px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    clip-path: inset(0 -100vmax);
    box-shadow: 0 0 0 100vmax $chinese-white;
    border-bottom: 1px solid $chinese-white;
  }

  &-nav:last-child {
    .nav-item {
      div > div:nth-child(2) {
        .ant-select-borderless {
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.search-result-list {

  .search-title {
    color: $spanish-gray;
    margin-bottom: 8px;
  }

  .search-image-container {
    display: flex;
    height: 178.77px;
    margin-bottom: 8px;

    @media #{$large_and_down} {
      height: 115px;
    }
  }

  .search-image-action-container {
    display: flex;
    gap: 24px;
    margin-bottom: 48px;

    @media #{$large_and_down} {
      margin-bottom: 40px;
					
    }
  }

  .search-keyword {
    font-size: $font-size-28;
    font-weight: $font-weight-medium;
    line-height: $line-height-36;
    color: $black;

      @media #{$large_and_down} {
        font-size: sizer(1.5);
      }
  }

    .assets-container {
      .row {
        gap: sizer(3) 0;

        @media (min-width:$large_screen_up) {
          margin-right: calc(-.5 * var(--bs-gutter-x));
          margin-left: calc(-.5 * var(--bs-gutter-x));
        }
      }

      .no-results {
        font-size: $font-size-16;
        font-weight: $font-weight-bold;
        line-height: $line-height-24;
        color: $black;
      }

      ul {
        font-weight: $font-weight-normal;

        li {
          list-style-type: disc;
        }
      }
    }

    .asset-view-container {
      .mobile-type-active-container { display: none; }
      .mobile-filter-container { display: none; }
      .mobile-view-container { display: none; }
    }

  @media #{$large_and_down} {
    section.pb-5:first-child {
      padding-bottom: sizer(2.5) !important;

      h2.h2-mb-48 {
        margin-bottom: sizer(2.5) !important;
      }

      .asset-view-container {
        flex-direction: row;
        justify-content: space-between;
        // gap: 51.5px;
        width: unset;
        flex-wrap: nowrap;
        margin: unset;
        padding-bottom: sizer(1);
        position: relative;

        &::before {
          content: "";
          height: 0.5px;
          width: 100%;
          position: absolute;
          bottom: -1px;
          left: 0;
          clip-path: inset(0 -100vmax);
          box-shadow: 0 0 0 100vmax $chinese-white;
          border-bottom: 1px solid $chinese-white;
        }

        .mobile-type-active-container {
          display: flex;
          align-items: center;
          width: 127px;
          padding: unset;
          cursor: pointer;

          h3 {
            margin-bottom: unset;
            margin-right: sizer(.25);
            display: inline-block;
          }
        }

        .mobile-filter-container {
          display: flex;
          align-items: center;
          width: 112px;
          padding: unset;
          cursor: pointer;

          h3 {
            margin-bottom: unset;
            margin-right: sizer(.25);
            display: inline-block;
          }
        }

        .mobile-view-container {
          display: block;
          width: 24px;
          padding: unset;
          cursor: pointer;

          .mobile-view-icon {
            &.show {
              display: block;
            }

            &.hide {
              display: none;
            }
          }
        }

        .navbar {
          display: none;
        }
      }
    }

    section.pb-5:last-child {
      .assets-container {
        margin: unset;

        .asset-view-no-asset {
          padding: unset !important;
          img {
            width: 221.89px;
            height: 225px;
          }

          h2 {
            font-size: $mobile-font-size-24;
            line-height: $mobile-line-height-32;
          }

          p {
            text-align: center !important;
            font-size: $font-size-16;
            line-height: $line-height-24;
          }
        }
      }
    }
  }

  .image-cropper-modal {
    position: fixed; /* Position fixed to keep it in the viewport */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset by half of its own width and height to truly center */
    z-index: 1050; /* Make sure it appears above other content */
    background-color: #fff; /* Background color for the modal */
    padding: 20px; /* Padding for content */
    border-radius: 8px; /* Rounded corners for the modal */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
    width: 80%; /* Width of the modal */
    max-width: 600px; /* Maximum width of the modal */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  .image-cropper-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 256px;
    position: relative;

    .image-cropper-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .reactEasyCrop_Container {
        width: 256px;
        height: 256px;
        top: -120px;
        left: -123px;
      }
    }
  }
}