.upload-guide {
    .col-8 {
        padding: sizer(6) 0;
        padding-left: sizer(1);
        padding-right: sizer(1.5);
    }
    .col-4 {
        border-left: 1px solid $chinese-white;
        padding: sizer(6) sizer(2.5);
        padding-right: 0;
    }
    &-header {
        padding-bottom: sizer(.25);
        border-bottom: 1px solid $chinese-white;
        color: $spanish-gray;
        margin-bottom: sizer(1.5);
        font-size: $font-size-20;
        line-height: $line-height-28;
        font-weight: $font-weight-inter-medium;
        font-family: $inter;
    }
    &-table{
        &-content  {
            width: 100%;
            height: 100%;

            &.sticky {
                position: fixed;
                width: calc(33.33333333% - sizer(6));
                top: sizer(1.5);
            }

            p {
                margin-bottom: sizer(3);
                font-size: $font-size-20;
                line-height: $line-height-28;
            }
        }
        &-button {
            button {
                width: 100%;
                border-radius: $border-radius-8;
                padding: sizer(.25) sizer(.5);

                &:not(:last-child) {
                    margin-bottom: sizer(.5);
                }

                &:hover {
                    background-color: $light-gray;
                    color: $black;
                    opacity: 1;
                }

                &.active {
                    background-color: $black;
                    color: $white;
                    pointer-events: none;
                    cursor: default;
                }
            }

            #select-file,
            #review-file,
            #upload-file {
                font-weight: $font-weight-urbane-medium;
            }
        }
    }
    &-img-viewer {
        display: flex;
        flex-direction: row;
        gap: sizer(1.5);
        justify-content: center;
        align-items: center;
        padding: sizer(1) 0;

        .text-button {
            position: relative;
            padding: 0;
            color: $spanish-gray;
            font-weight: $font-weight-medium;

            &.active {
                color: $sunset-orange;

                &::before {
                    opacity: 1;
                }
            }
            &::before {
                content: "";
                bottom: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $sunset-orange;
                opacity: 0;
                display: block;
            }
        }
    }

    ul ul {
        list-style-type: disc;
    }

    .alert-notification {
        width: 60%;

        &-content {
            font-size: $font-size-12;
            line-height: $line-height-16;
        }
    }

    h2 {
        margin-bottom: 8px;

        + p {
            color: $spanish-gray;
            margin-bottom: 48px;
            font-size: $font-size-20;
            line-height: $line-height-28;
            font-weight: $font-weight-inter-regular;
        }
    }

    .paragraph {
        &-1 {
            margin-bottom: sizer(1.5);

            &.sub-paragraph {
                text-decoration: underline;
                margin-bottom: sizer(1.5);
            }
            .text-button {
                padding: 0;
                display: inline;
                color: $black;
                font-weight: $font-weight-bold;
                &.bolded-orange{
                    color: $sunset-orange;
                    &:hover{
                        opacity: 0.5;
                    }
                }
            }
        }
        &-2 {
            color: $spanish-gray;
        }
    }

    section {
        &[data-id="file-errors"],
        &[data-id="delete-file"] {
            margin-bottom: sizer(6) !important;
        }
        &[data-id="file-requirements"] {
            table tbody tr td {
                padding-bottom: 0;
            }
        }
        &:not(:last-child) {
            margin-bottom: sizer(3);
        }

        img {
            width: 100%;
            border: 1px solid $chinese-white;
        }

        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-top: 1px solid $chinese-white;
                        padding-top: sizer(.25);
                        padding-bottom: sizer(.25);

                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }

                        p {
                            color: $spanish-gray;
                        }
                    }
                }
            }
            tbody {
                tr {
                    &:not(:last-child) {
                        border-bottom: 1px solid $chinese-white;
                    }

                    td {
                        border-bottom: 0;
                        padding-top: sizer(1);
                        padding-bottom: sizer(1);
                        border-bottom: 0;
                        width: 25%;

                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }

                        ul {
                            padding-left: sizer(1);
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}