.upload-progress {
    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: sizer(1.5);
        h3{
            font-size: $font-size-20;
            line-height: $line-height-28;
            font-weight: $font-weight-bold;
            margin-bottom: sizer(1.5);
        }
        .upload-offcanvas-close {
            button {
                padding: 0;
            }
        }
    }
    &-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: sizer(2);
        width: 100%;
        margin-bottom: sizer(3);
        @media #{$mlarge_and_down} {
            flex-direction: column !important;
        }
        @media (min-width: 1441px) {
            gap: sizer(4);
        }
        @media #{$tablet} {
            margin-bottom: sizer(2.5);
        }
    }
    &-circular {
        width: 100px;
        height: 100px;
        @media #{$tablet} {
            width: 133px;
            height: 133px;
            .upload-progress-circular {
                position: relative;
                width: 133px !important;
                height: 133px !important;
            }
            .CircularProgressbar {
                width: 133px !important;
                height: 133px !important;
            }
        }

        .upload-progress-circular {
            position: relative;
            width: 100px;
            height: 100px;
        }
        .CircularProgressbar {
            width: 100px;
            height: 100px;

            + div {
                position: relative;
                flex-wrap: wrap;

                .CircularProgressbar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
    
                    + .CircularProgressbar {
                        z-index: 1;
                    }
                }
            }

            .CircularProgressbar-text {
                font-size: $font-size-20;
                line-height: $line-height-28;
                font-weight: $font-weight-medium;
                fill: $spanish-gray;
                @media #{$tablet} {
                    font-size: sizer(1.25);
                    line-height: $mobile-line-height-28;
                    transform: translate(2px, 1px);
                }
            }
        }
    }
    &-wrapper {
        text-align: center;

        .paragraph-1 {
            font-weight: $font-weight-medium;
        }
    }
    &-content {
        display: flex;
        flex-direction: row;
        font-size: $font-size-20;
        line-height: $line-height-32;
        font-weight: $font-weight-normal;
        gap: sizer(2);
        color: $spanish-gray;
        @media #{$mlarge_and_down} {
            justify-content: space-between;
            width: 100%;
        }
        @media #{$tablet} {
            span{
                font-size: $font-size-18;
                line-height: $line-height-26;
            }
        }
        > div {
            &:nth-child(1) {
                //margin-right: sizer(7);

                span {
                    position: relative;
                    display: flex;
                    align-items: center;

                    &:nth-child(2),
                    &:nth-child(3) {
                        padding-left: sizer(1.5);

                        &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            width: 16px;
                            height: 16px;
                            background-color: transparent;
                        }
                        @media #{$tablet} {
                            padding-left: sizer(2);
                        }
                    }
                    &:nth-child(2) {
                        &::before {
                            background-color: $leafy-green;
                        }
                    }
                    &:nth-child(3) {
                        &::before {
                            background-color: $candy-red;
                        }
                    }
                }
            }
            &:nth-child(2) {
                font-size: $font-size-24;
                line-height: $line-height-32;
                font-weight: $font-weight-bold;
            }
            span {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: sizer(1);
                }
            }
        }
    }
    &-status {
        padding-bottom: sizer(2);
        display: flex;
        flex-direction: column;
        align-items: center;
        @media #{$tablet} {
            h3{
                font-size: sizer(1.25);
                line-height: $mobile-line-height-28;
            }
        }
        button {
            width: fit-content;
        }

        &-filename-container {
            padding: sizer(1);
            border-radius: $border-radius-16;
            border: 1px solid $chinese-white;
            margin-bottom: sizer(3);
            @media #{$tablet} {
                width: 100%;
                word-break: break-all;
                margin-top: 24px;
                height: 168px;
                padding-right: 4px;
            }
        }

        &-filename {
            max-height: 140px;
            overflow: auto;
            text-align: left;
            @media #{$tablet} {
                padding-right: sizer(1.25);
            }
            &::-webkit-scrollbar {
                width: sizer(.5);
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-track {
                background: $bright-gray;
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-thumb {
                background: $spanish-gray;
                border-radius: $border-radius-8;
                background-clip: padding-box;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $spanish-gray;
            }

            span {
                width: 100%;
                display: block;
                font-size: $font-size-16;
                line-height: $line-height-24;
            }
        }

        h3 {
            margin-bottom: sizer(1);

            + p {
                margin-bottom: sizer(3);
            }

            ~ p {
                font-weight: $font-weight-normal !important;
            }
        }
    }

}

.upload-progress-detail span{
    color: black;
    font-size: $font-size-20;
    @media #{$tablet} {
        font-size: $font-size-18;
        line-height: $line-height-26;
        font-weight: $font-weight-bold;
    }
}

.btn-done{
    padding-left: 50px;
    padding-right: 50px;
}

.store-info{
    font-weight: 400;
}