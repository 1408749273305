.bookmark-card {
    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        &.small {
            svg {
                width: 80px;
                height: 80px;
            }
        }
        svg {
            width: 80px;
            height: 80px;

            path {
                fill: $black;
            }
        }
    }
    &-skeleton {
        display: flex;
        flex-direction: column;
        border-radius: $border-radius-20;

        div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: sizer(.5);

            &:nth-child(2) {
                display: flex;
                flex-direction: row;
                gap: sizer(.5);

                > div {
                    width: 50%;

                    &:nth-child(1) {
                        .react-loading-skeleton {
                            border-bottom-left-radius: $border-radius-20;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                    &:nth-child(2) {
                        .react-loading-skeleton {
                            border-bottom-right-radius: $border-radius-20;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                }
            }

            .react-loading-skeleton {
                border-top-left-radius: $border-radius-20;
                border-top-right-radius: $border-radius-20;
            }
        }
        .react-loading-skeleton {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
    &-wrapper {
        width: 100%;
        height: 100%;

        h3 {
            margin-top: sizer(1);
            margin-bottom: sizer(3);
            font-weight: $font-weight-bold;
            font-family: $inter;
            word-break: break-word;
        }
    }
    &-ratio {
        position: relative;
        width: 100%;
        background-color: $white;

        &:before {
            content: "";
            display: block;
            padding-top: 85.36%
        }
        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &.one-image {
            .bookmark-card-img,
            .bookmark-card-icon {
                border-radius: $border-radius-20;
            }
        }
        &.two-image {
            display: flex;
            flex-direction: column;
            gap: sizer(.5);

            > div {
                width: 100%;
                height: auto;

                &::before {
                    content: "";
                    display: block;
                    padding-top: 41.96%
                }
                &:nth-child(1) {
                    border-top-left-radius: $border-radius-20;
                    border-top-right-radius: $border-radius-20;
                }
                &:nth-child(2) {
                    top: inherit;
                    bottom: 0;
                    border-bottom-left-radius: $border-radius-20;
                    border-bottom-right-radius: $border-radius-20;
                }
                > * {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        &.three-image {
            display: flex;
            flex-direction: column;
            gap: sizer(.5);

            > div {
                width: 100%;
                height: auto;
                overflow: hidden;

                &:nth-child(1) {
                    border: 1px solid $chinese-white;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    .bookmark-card-img,
                    .bookmark-card-icon {
                        border-top-left-radius: $border-radius-20;
                        border-top-right-radius: $border-radius-20;
                        overflow: hidden;
                    }
                    &::before {
                        content: "";
                        display: block;
                        padding-top: 56.25%
                    }
                }

                &:nth-child(2) {
                    top: initial;
                    bottom: 0;
                    display: flex;
                    flex-direction: row;
                    gap: sizer(.5);

                    &::before {
                        content: "";
                        display: block;
                        padding-top: 27.68%;
                    }

                    .bookmark-card-img {
                        border: 1px solid $chinese-white;
                    }
                    .bookmark-card-img,
                    .bookmark-card-icon {
                        width: calc(50% - 4px);
                        top: initial;
                        bottom: 0;

                        &:nth-child(1) {
                            border-bottom-left-radius: $border-radius-20;
                        }
                        &:nth-child(2) {
                            border-bottom-right-radius: $border-radius-20;
                            left: initial;
                            right: 0;
                        }
                    }
                }
                > * {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.no-asset {
            .bookmark-card-icon {
                display: flex;
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
                
                border-radius: $border-radius-20;
                background-color: $light-gray;
    
                h3 {
                    color: $spanish-gray;
                    font-weight: 400;
                }
            }
        }
    }
 
    &-icon {
        border: 1px solid $chinese-white;
        overflow: hidden;

        svg.fa-file-audio {
            height: 80px;
            width: 80px;
        }
    }
    &-img {
        width: 100%;
        height: 100%;
        border: 1px solid $chinese-white;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &-group {
        pointer-events: all;
        cursor: pointer;
    }
}