.menu-tabs {

    &::before {
        content: "";
        position: absolute;
        width: 110%;
        height: 1px;
        left: 0px;
        bottom: 0;
        z-index: -1;
    }

    &:not([data-tab="search"]),
    &:not([data-tab="profile"]) {
        &::before {
            content: "";
            position: absolute;
            width: 110%;
            height: 1.5rem;
            left: -10px;
            top: 2.5rem;
            background-color: $white;
            z-index: 2;
            box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.08);
        }
    }

    &-nav {
        border: 0;
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: nowrap;

        .nav-item {
            height: 100%;

            button {
                position: relative;
                border: 0;
                padding: 0 46px;
                font-size: 20px;
                line-height: 28px;
                font-weight: $font-weight-medium;
                color: $black;
                height: 100%;

                &:disabled {
                    color: $spanish-gray;
                }
                &:hover{
                    opacity: 0.5;
                }
                &.active {
                    color: $black;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: sizer(-1.5);
                        left: 0;
                        background: $black;
                        width: 100%;
                        height: 4px;
                        border-radius: $border-radius-40;
                    }
                }

                @media #{$large_and_down} {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }

        @media #{$large_and_down} {
            .nav-item {
                button {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    padding: 0;

                    &::after {
                        display: none
                    }

                    button {
                        padding: 0
                    }
                }
            }
        };
    }

    &-wrapper {
        position: relative;
        z-index: 1;
        height: 100%;

        .row {
            @media (min-width:$large_screen_up) {
                margin-right: 0;
                margin-left: 0;
            }
        }
        .tab-content {
            width: 100%;
            height: 100%;
            min-height: 100vh;

            section { position: relative; }

            .container {
                section {
                    &:last-child {
                        padding-bottom: sizer(6) !important;
                    }
                }
            }
            [aria-labelledby="menu-tabs-id-tab-upload"] {
                .container {
                    display: flex;
                    align-items: stretch;
                }
            }

            .settings-view-wrapper {
                .container {
                    .setting-row {
                        height: 100%;
                    }
                }
            }

            .tab-pane {
                width: 100%;
                height: 100%;

                .settings-plan {
                    > h2 {
                        margin-bottom: sizer(3);
                        font-family: $urbane-rounded;
                    }

                    h2 {
                        font-weight: $font-weight-medium;
                    }
                }
            }

            > .active {
                position: relative;
            }
        }

        @media #{$tablet} {
            padding: 0 sizer(1.5);
        }
    }
}

.no-boxshadow {
    &::before {
        box-shadow: unset !important;
    }
}