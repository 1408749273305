/* text colors */
/* background colors */
.primary--color {
	color: $primary;
}
.secondary--color {
	color: $secondary;
}
.purple-blue--color {
	color: $purple-blue;
}
.light-purple--color {
	color: $light-purple;
}
.pale-purple--color {
	color: $pale-purple;
}
.candy-apple--color {
	color: $candy-red;
}
.dawn-pink--color {
	color: $dawn-pink;
}
.leafy-green--color {
	color: $leafy-green;
}
.light-green--color {
	color: $light-green;
}
.hot-pink--color {
	color: $hot-pink;
}
.light-pink-color {
	color: $light-pink;
}
.orange--color {
	color: $orange;
}
.light-orange--color {
	color: $light-orange;
}
.blue--color {
	color: $blue;
}
.sky-blue--color {
	color: $sky-blue;
}
.granite-gray--color {
	color: $granite-gray;
}
.spanish-gray--color {
	color: $spanish-gray;
}
.chinese-white--color {
	color: $chinese-white;
}
.bright-gray--color {
	color: $bright-gray;
}
.light-gray--color {
	color: $light-gray;
}
.white--color {
	color: $white;
}
.black--color {
	color: $black;
}
.primary--bg {
	background-color: $primary;
}
.secondary--bg {
	background-color: $secondary;
}
.black--bg {
	background-color: $black;
}
.purple-blue--bg {
	background-color: $purple-blue;
}
.light-purple--bg {
	background-color: $light-purple;
}
.pale-purple--bg {
	background-color: $pale-purple;
}
.candy-apple--bg {
	background-color: $candy-red;
}
.dawn-pink--bg {
	background-color: $dawn-pink;
}
.leafy-green--bg {
	background-color: $leafy-green;
}
.light-green--bg {
	background-color: $light-green;
}
.hot-pink--bg {
	background-color: $hot-pink;
}
.light-pink--bg {
	background-color: $light-pink;
}
.orange--bg {
	background-color: $orange;
}
.light-orange--bg {
	background-color: $light-orange;
}
.blue--bg {
	background-color: $blue;
}
.sky-blue--bg {
	background-color: $sky-blue;
}
.granite-gray--bg {
	background-color: $granite-gray;
}
.spanish-gray--bg {
	background-color: $spanish-gray;
}
.chinese-white--bg {
	background-color: $chinese-white;
}
.bright-gray--bg {
	background-color: $bright-gray;
}
.light-gray--bg {
	background-color: $light-gray;
}
.white--bg {
	background-color: $white;
}
.svg-red {
	filter: $filter-red;
}
.svg-green {
	filter: $filter-green;
}
.svg-white {
	filter: $filter-white;
}
.svg-gray {
	filter: $filter-gray;
}
