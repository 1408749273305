.fslightbox {
   &-container {
    .audio-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Pushes audio element to the bottom */
        height: 100%;
        background-color: white;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(217, 217, 217, 0) 50.17%);
        padding: 10px; /* Optional: add padding if desired */

        .audio-container {
            margin: 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            align-items: center;

            svg {
                width: 240px;
                height: 240px;
            }
        }

        audio {
            align-self: flex-end; /* Ensures the audio player aligns at the bottom */
        }
    }
    @media #{$tablet} {
        background: $black !important;
        .fslightbox-nav{
            .fslightbox-toolbar {
                background: transparent;
                div.fslightbox-toolbar-button{
                    height: auto;
                    width: 40px;
                    margin-top: 24px;
                    margin-right: 15px;
                    &:first-child{
                        display: none;
                    }
                    &:nth-child(2){
                        svg{
                            width:21.97px;
                            height:21.97px;
                            path{
                                fill: $white;
                            }
                        }
                    }
                }
               
            }
        }
    }
    .fslightboxs {

        width: 1024px !important;
        height: 576px !important;
        @media #{$mobile} {
            width: 100% !important;
            height: 100% !important;
        }
    }
    img, video {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    audio {
        width: 100%;
        object-fit: contain;
        // transform: translate(-50%, -50%);
        // position: absolute;
        // top: 50%;
        // left: 50%;
        @media #{$mobile} {
            width: 300px;
            height: 54px;
            position: relative;
        }
    }

    .fslightbox-slide-btn-container,
    .fslightbox-slide-number-container {
        display: none;
    }

    .react-pdf__Document {
        canvas {
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100% !important;
            height: 100% !important;
        }
    }
   }
}