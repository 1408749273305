.settings-view {
  &-wrapper {
    .tab-content {
      padding-bottom: sizer(6);
    }
    .setting-row {
      .col-sm-2 {
        border-right: 1px solid $chinese-white;
        padding-top: 96px;
        padding-right: sizer(1.5);
        min-height: calc(100vh - 95px);
        @media #{$tablet} {
          padding: 48px 0 0 0;
          border-right: none;
          min-height: auto;
          width: 100% !important;
          .mobile-title-container {
            margin-bottom: sizer(2.5) !important;
            margin-left: sizer(0.625);
            margin-right: sizer(0.625);
            .mobile-title-sub-text {
              color: $spanish-gray;
              margin-bottom: sizer(0.5);
            }
          }
          .asset-management,
          .plan-management {
            display: none;
          }
          .settings-buttons .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 0 !important;
            min-width: 40%;
            width: auto;
            font-size: sizer(1.5);
            font-family: $urbane-rounded;
            line-height: 32px;
            &:hover {
              color: unset;
              opacity: unset;
              background-color: unset;
            }
          }
          .nav-pills{
            margin-bottom: sizer(0.75);
            transform: translate(0) !important;
            .nav-item{
              height: 50px;
              .nav-link {
                padding: 0.75rem 0.5rem !important;
                font-size: $mobile-font-size-18;
                line-height: $line-height-26;
                display: flex;
                align-items: center;
                gap: 8px;
                height: 50px;
              }
            }
          }
          
        }
      }
      .col-sm-10 {
        padding-top: 96px;
        padding-left: sizer(1.5);
        min-height: calc(100vh - 95px);
        @media #{$tablet} {
          padding-top: 0;
          padding-left: calc(var(--bs-gutter-x)* .5);
          width: 100%;
        }
      }
    }

    .arrow-icon {
        cursor: pointer;
    }

    .invoices-list {
      h2 {
        margin-bottom: sizer(3);
      }
    }

    .arrow-icon.disabled {
        display: none;
    }

    .table {
      table-layout: auto;
      width: 100%;
    }

    .table td {
      width: auto;
    }

    .table .td-width1 {
      width: 328px;
    }

    .table .td-width2 {
      width: 148px;
    }

    .title-text {
      font-size: $font-size-28;
      font-weight: $font-weight-medium;
      line-height: $line-height-36;
      color: $black;
      padding-bottom: sizer(3);
      margin-bottom: 0 !important;
    }

    .title-sub-text {
      color: $spanish-gray;
      margin-bottom: sizer(0);
      text-transform: capitalize;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $chinese-white;
      }
    }
    .nav-pills{
      margin-top: sizer(.5);
    }

    .title-icon-spacing svg {
      /* Adjust margin or padding as needed */
      margin-left: 4px; /* Add left margin to create spacing between text and icon */
    }

    .center-vertical {
      vertical-align: middle;
      line-height: 1; /* Adjust line-height if necessary */
    }

    .userlist-navigation {
      .selectall-label {
        display: flex;
        align-items: center;
        padding: sizer(1) 0px;
      }

      .selectall-checkbox {
          margin-right: sizer(.5);
          border-color: $spanish-gray !important;
      }

      .form-group label {
        position: unset;
        top: unset;
        left: unset;
        background-color: unset;
        padding: unset;
        font-size: unset;
        color: unset;
        z-index: unset;
      }
      .form-group input {
        font-size: unset;
        font-weight: unset;
        line-height: unset;
        color: unset;
        padding: unset;
        border: unset;
        background-color: unset;

        padding: 8px 0px !important;
      }
    }

    .settings-group {
      height: 100%;

      .settings-buttons {
        > div {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: sizer(3);
          }
        }
        .badge {
          font-size: $font-size-12;
          font-weight: $font-weight-normal;
          line-height: $line-height-16;

          border-radius: $border-radius-8;
          border: 1px solid var(--component-black, #000);
          color: $black;
          background-color: $white !important;
        }
        .btn {
          width: 110%;
          padding: 4px 8px;
          margin-bottom: 8px;
          border-style: none;
          border-radius: 8px !important;
        }
        .btn:hover {
          color: $white;
          opacity: unset;

          background-color: $sunset-orange;
        }
        .btn.btn-secondary::before,
        .btn.btn-secondary::after {
          box-shadow: unset;
        }

        .nav-item {
          .nav-link {
            padding: sizer(.25) sizer(.5);
            margin-bottom: sizer(.5);
            color: $black;
            //margin-left: sizer(.5);

            &:hover {
              background-color: $light-gray;

              .badge {
                background-color: $light-gray !important;
              }
            }
            &.active {
              background-color: $black;
              color: $white;

              .badge {
                border-color: $white;
                background-color: $black !important;
                color: $white !important;
              }
            }
          }
        }
      }

    }

    .list-group {

      tbody tr td {
        padding-top: sizer(1);
        padding-bottom: sizer(1);
        font-family:  $inter;
        .ant-table-custom-cell{
          gap: 0 !important;
        }
        div.paragraph-1 > span{
            display: flex !important;
            gap: sizer(1) !important;
        }
        span>div{
          position: absolute;
          left: 0;
        }
      }

      .row {
        display: flex;
        align-items: flex-start;
        gap: sizer(.5);

        .row {
          gap: sizer(3);

          .col-5:first-child {
            padding-right: unset;
            width: unset;
          }
          .col-5:last-child {
            padding-left: unset;
          }
        }
      }
    }

    .search-input {
      * {
          border: none !important;
        }
      box-sizing: border-box;

      button {
          background-color: unset;
      }

      .MuiInputBase-input {
          font-size: $font-size-20;
          line-height: $line-height-28;
          font-weight: $font-weight-normal;

          padding-left: 19px;
      }
      .MuiInputBase-input::placeholder {
          color: $spanish-gray;
        }
      svg {
          color: $spanish-gray;
          padding-right: 16px;
      }

      /* border: 1px solid $chinese-white !important;
      border-radius: 20px; */

      border-radius: $border-radius-12;
      border: 1px solid $chinese-white;

      &:active, &:focus-within {
          border: 1px solid $black !important;
      }
    }

  }
}
.ant-picker-dropdown {
    font-family: $inter !important;
}