.upload {
    &-dropzone {
        &-storage-space {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: sizer(1.5);
            flex-direction: column;

            .upload-dropzone {
                &-storage-bar {
                    position: relative;
                    width: 268px;
                    background-color: $medium-gray;
                    height: 8px;
                    border-radius: $border-radius-40;
                    z-index: 1;
                    overflow: hidden;
                    
                    > div {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 8px;
                        border-radius: $border-radius-40;
                        background-color: $black;
                        z-index: 2;
                    }
                }

                &-storage-space-content {
                    margin-top: sizer(.25);
                    font-size: $font-size-12;
                    line-height: $line-height-16;
                    font-weight: $font-weight-normal;
                }
            }
        }
        &-wrapper {
            width: 100%;
            height: 384px;
            background-color: $light-gray;
            border-radius: $border-radius-20;
            transition: all .25s ease-in-out;

            cursor: pointer;

            &.upload-dropzone-active, &:hover {
                background-color: rgba(245, 245, 245, 0.20);
            }

            @media #{$xxlarge_and_up} {
                max-width: 1920px;
                margin: 0 auto;
            }
        }
        &-cont {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: $border-radius-20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &::before {
                content: "";
                position: absolute;
                top: sizer(1);
                left: sizer(1);
                width: calc(100% - sizer(2));
                height: calc(100% - sizer(2));
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23989595FF' stroke-width='1' stroke-dasharray='5%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                @media #{$tablet}{
                    top: sizer(1.1);
                }
            }
        }
        &-content {
            text-align: center;

            svg {
                width: sizer(3);
                height: sizer(3);

                path {
                    fill: $spanish-gray;
                }
            }
            p {
                margin-bottom: 0;
                font-size: $font-size-20;
                line-height: $line-height-28;

                span {
                    color: $sunset-orange;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}