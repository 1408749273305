.ant-pagination {
    .ant-pagination {
        &-item {
            border: 0;

            &:not(.ant-pagination-item-active) {
                &:hover {
                    color: $black;
                    opacity: 1;

                    a {
                        opacity: 1;
                        color: $black;
                    }
                }
            }

            &-active {
                pointer-events: none;
                background-color: black;
                color: white;
            }
        }
        &-options {
            border-left: 1px solid $chinese-white;
            margin-left: sizer(1);
            padding-left: sizer(1);
        }
    }
}