.modal {
    @media #{$tablet} {
        @media screen and (max-width: 390px) {
            &.mobile-my-asset-modal{
                .modal-dialog{
                    margin-top: sizer(8.5);
                }
            }
        }
        &.mobile-upload-delete{
            .modal-dialog .modal-content .modal-body  {
                padding-bottom: sizer(2.5) !important;
            }
        }
        .modal-dialog {
            padding: 0 1rem;
            //margin-top: 11.938rem; //14.5rem;

            .modal-content {
                //border-radius: 20px;
                padding-top: 24px;
                .modal-header {
                    margin: 0rem 0;
                    padding: 0 0 0.5rem 1.5rem;
                }
                .modal-image-search-header {
                    margin-right: 24px;
                    border-bottom: unset;

                    .btn-close {
                        opacity:unset;
                    }
                }
                .modal-body {
                    padding: sizer(1.5) sizer(1.5) !important;
                    padding-bottom: sizer(2.5) !important;
                    word-break: break-word;

                    .modal-user-list-name{
                        display: none;
                    }

                    li{
                        list-style: none;
                        display: inline-block;
                    }                    
    
                    .image-search-upload-loader-container {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    
                        .loader {
                            border: 10px solid $chinese-white;
                            border-radius: 50%;
                            border-top: 10px solid $leafy-green;
                            width: 80px;
                            height: 80px;
                            animation: spin 2s linear infinite;
                            margin-bottom: 10px;
                        }
                        
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    }
                }

                .modal-footer {
                    flex-direction: column-reverse;
                    gap: sizer(0.75);
                    padding: sizer(0.75) sizer(1.5);
                    button{
                        margin: 0;
                    }
                }
            }

        }

    }
    font-size: $font-size-16;
    line-height: $line-height-24;
    &-backdrop{
        z-index: 1055;
    }
    &-user-request-user, &-user-list-name {
        overflow: hidden;
        border: 1px solid $chinese-white;
        border-radius: $border-radius-20;
        padding: sizer(1);
        padding-right: sizer(0.5);
        max-width: 560px;
        margin-top: 1.5rem;
        ul{
            padding: unset;
            margin: unset;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            overflow: auto;
            overflow-x: hidden;
            max-height: clamp(6.25rem, 5.6rem + 2.275vw, 20.75rem);
            &::-webkit-scrollbar {
                width: 0.5rem;
            }
            &::-webkit-scrollbar-thumb {
                background: #989595;
                border-radius: 20px;
            }
            &::-webkit-scrollbar-track {
                background: #EDEDED;
                border-radius: 20px;
            }
            li{
                list-style: none;
                margin-right: 10px;
            }
        }
        p {
            margin-bottom: sizer(.25);
        }
        @media #{$mobile} {
            display: none;
        }
    }
    &-full-page {
        overflow: hidden;
        padding-left: 0 !important;

        .modal-dialog,
        .modal-content {
            overflow: auto;
            width: 100vw;
            height: 100%;
            max-width: initial;
            border-radius: 0;
            margin: 0;
            padding: 0;
            border: 0;
        }
        .modal-content {
            //padding: sizer(2);
            border-radius: 20px;
        }
    }
    &-header {
        margin: sizer(0) 48px;
        padding: 0 0 sizer(.5) 0;
    }
    &-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0 sizer(2);
        // margin-top: sizer(11.5);
        max-width: $modal-width;
    }
    &-content {
        padding-top: 48px;
    }
    &-body {
        padding: sizer(1.5) 48px 48px !important;
        //padding-bottom: 48px !important;

        .no-margin{
            margin: 0 !important;
        }

        .modal-filename{
            width: sizer(35);
            //height: sizer(3.5);
            height: auto;
            word-wrap: break-word;
            border-radius: sizer(1.25);
            border: 1px solid $chinese-white;
            padding: sizer(1);
            margin-top: sizer(1.5);
        }
        .modal-filename-bulk{
            border: 1px solid #e1e1e1;
            border-radius: 20px;
            margin-top: sizer(1.5);
            max-width: 560px;
            overflow: hidden;
            padding: sizer(1);
            padding-right: sizer(0.5);
            ul{
                display: flex;
                flex-direction: column;
                gap: .25rem;
                list-style: none;
                margin: initial;
                max-height: clamp(6.25rem, 5.6rem + 2.275vw, 20.75rem);
                overflow: auto;
                overflow-x: hidden;
                padding: initial;
                li {
                    list-style: none;
                    margin-right: 10px;
                    word-break: break-all;
                }
                &::-webkit-scrollbar {
                    width: sizer(0.5);
                }
                &::-webkit-scrollbar-thumb {
                    background: #989595;
                    border-radius: 20px;
                }
                &::-webkit-scrollbar-track {
                    background: #EDEDED;
                    border-radius: 20px;
                }
            }
        }

    }
    &-footer {
        padding: sizer(.5) sizer(2);
        background-color: $light-gray;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .btn-secondary {
        background-color: transparent;
    }

    .modal-form-group {

        &.main {
            margin-top: 36px;
        }

        // margin-bottom: sizer(1);
        width: min(560px, 100%);
        display: flex;
        flex-direction: column;
        position: relative;

        .form-control.warning-input {
            border-color: $candy-red !important;
        }

        p {
            margin-bottom: sizer(.5);
            display: flex;
            align-items: center;
            gap: sizer(.5);
            line-height: $line-height-24;
            &:last-child { margin: unset; }
        }

        label {
            position: absolute;
            top: -9px;
            left: 20px;
            background-color: $white;
            padding: 0 5px;
            font-size: 12px;
            line-height: 16px;
            color: $black;
        }

        input {
            background-color: white !important;
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;
            padding: 24px;
            border: sizer(0.0625) $chinese-white solid !important;

            @media #{$mobile} {
                padding: 16px 24px;
            }
        }

        input:focus {
            border-color: $black !important;
        }

        .show-password-icon {
            position: absolute;
            top: 33%;
            right: sizer(1.5);
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;

            @media #{$mobile} {
                top: 29%
            }

            & > svg { color: black; }
        }

        .eightchar, .lowercase, .uppercase {
            color:$spanish-gray;
        }

        .newpassvalid {
            color:$black;
            svg {
                color:$leafy-green
            }
        }

        .warning-label {
            color: $candy-red !important;
        }

        .reenterpassword-warning {
            color:$candy-red;
            font-weight: $font-weight-bold;
            svg {
                color:$candy-red
            }
        }
    }

    .reenterpassword-group {
        position: absolute;
    }
}