.tab {
    &-pane {
        .nav-tabs {
            border-color: $chinese-white;

            .nav-item {

                &:first-child {
                    button {
                        padding-left: 0;
                    }
                }

                button {
                    border: 0;
                    padding-top: sizer(1);
                    padding-bottom: sizer(1);
                    padding-right: sizer(1.25);
                    padding-left: sizer(1.25);
                    font-size: $font-size-20;
                    line-height: $line-height-28;
                    font-weight: $font-weight-normal;
                    margin-bottom: 1px;

                    &.active {
                        color: $black;
                    }

                    i {
                        font-style: normal;
                        font-size: $font-size-16;
                        line-height: $line-height-24;
                    }
                }
            }
        }
    }
}