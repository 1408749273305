.login {
    &-wrapper {
        display: flex;
        width: 100vw;
        
        @media #{$large_and_up} {
            height: 100vh;
        }
       
        .register-now a, .nav-link {
            font-weight: $font-weight-bold;
            color: $sunset-orange;
        }
    }
    
    &-left-cont {
        background-color: $white;
        width: 50%;
        position: relative;

        @media #{$large_and_down} {
            width: 100%;
            height: 100vh;
        }

        @media #{$small_and_down} {
            min-height: 100vh;
            height: auto;
        }
    }

    &-right-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        background-color: #F9F9F9;

        .content-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .content-wrapper {
                display: contents;

                img {
                    max-width: 960px;
                    width: 100%;
                    padding-bottom: 24px;
                }
                h2 {
                    @media ((min-width: $medium_screen_up) and (max-width:$large_screen_up)) {
                        font-size: $tablet-font-size-h3;
                    }
                    @media #{$medium_and_down} {
                        font-size: $mobile-font-size-h2;
                    }
                }
                h3 {
                    @media ((min-width: $medium_screen_up) and (max-width:$large_screen_up)) {
                        font-size: $font-size-20;
                    }
                }
            }
        }

        .visual-wrapper {
            width: 771px;
            height: auto;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .image-container {
                width: 100%;
                
                img {
                    width: 100%;
                    margin-bottom: sizer(4);

                    @media ((min-width: 768px) and (max-width:900px)) {
                        margin-bottom: sizer(1.5);
                    }
                            
                    @media ((min-width: 900px) and (max-width:1226px)) {
                        margin-bottom: sizer(2);
                    }
        
                    @media ((min-width: 1226px) and (max-width:1280px)) {
                        margin-bottom: sizer(2.5);
                    }
        
                    @media ((min-width: 1280px) and (max-width:1530px)) {
                        margin-bottom: sizer(3);
                    }
        
                    @media ((min-width: 1530px) and (max-width:1920px)) {
                        margin-bottom: sizer(4);
                    }
                }
            
                .content-desc1 {
                        padding: 0;
                        width: 100%;
                        text-align: center;
                        
                        h2 {
                            margin: 0 0 sizer(1.5) 10% !important;
                            font-size: $font-size-28;
                            line-height: $line-height-36;

                            @media ((min-width: 768px) and (max-width:900px)) {
                                font-size: $tablet-font-size-18;
                            }
                                    
                            @media ((min-width: 900px) and (max-width:1066px)) {
                                font-size: $font-size-24;
                            }

                            @media ((min-width: 1066px) and (max-width:1280px)) {
                                font-size: $font-size-28;
                            }
                        }
                        h3 {
                            margin: 0 0 0 10% !important;
                            font-weight: $font-weight-inter-regular;
                        }

                }
            }

            @media ((min-width: 768px) and (max-width:900px)) {
                width: 381px;
            }
                    
            @media ((min-width: 900px) and (max-width:1226px)) {
                width: 444px;
            }

            @media ((min-width: 1226px) and (max-width:1280px)) {
                width: 555px;
            }

            @media ((min-width: 1280px) and (max-width:1530px)) {
                width: 581px;
            }

            @media ((min-width: 1530px) and (max-width:1920px)) {
                width: 700px;
            }

        }

        .login-image {
            position: absolute;
            top: 30vh;
            right: 10vw;
            //transform: translateY(-50%);
            z-index: 1;
            width: 20vw;

            /* display: flex;
            flex-direction: row;
            align-items: center; */

            .card-header {
                height: 24px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.20);
                border-radius: $border-radius-20;
                position: absolute;
                right: sizer(1.5);
                top: sizer(1);

                svg {
                    height: sizer(1);
                    width: sizer(1);
                }
            }
            .card-footer {
                border-bottom-left-radius: $border-radius-20;
                border-bottom-right-radius: $border-radius-20;
                padding: sizer(1.25) sizer(1.5);
                height: 64px;
                border: solid 1px $chinese-white;
                background-color: $white;
                width: 20vw;
            }
            .card-body img {
                object-fit: contain;
                max-height: 100%;
                max-width: 100%;
                width: 20vw;
                border-top-left-radius: $border-radius-20;
                border-top-right-radius: $border-radius-20;
                overflow: hidden;
            }

            @media #{$xlarge_and_down} {
                width: 15vw;
                top: 15vh;

                .card-footer,
                .card-body img {
                    width: 15vw;
                }
            }
        }

        .content-desc1 {
            position: relative;
            z-index: 2;
            font-size: $font-size-48;
            line-height: $line-height-56;
            color: $black;
            padding: sizer(4) sizer(3) sizer(3) sizer(4);
            width: 40vw;

            span {
                font-family: $urbane-rounded;
                font-weight: $font-weight-urbane-medium;
                line-height: sizer(3.5);
                &.font-bold {
                    font-weight: $font-weight-urbane-demi-bold;
                }
                @media #{$xlarge_and_down} {
                    & {
                        line-height: $line-height-56;
                    }
                }
            }

            @media #{$xlarge_and_down} {
                font-size: $font-size-28;
                line-height: $line-height-36;
                padding-top: sizer(2.25);
            }
        }
        .content-desc2 {
            font-size: $font-size-20;
            font-weight: $font-weight-normal;
            line-height: $line-height-28;
            color: $black;
            padding-top: sizer(2);
            padding-right: sizer(4);
            text-align: right;

            @media #{$xlarge_and_down} {
                padding-top: sizer(1);
            }
        }

        @media #{$large_and_down} {
            display: none !important;
        }
    }

    &-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 96px;
        padding: 0 sizer(3); 

        @media #{$large_and_down} {
            padding: 0 sizer(1.5) !important; 
            background: $white;
        }
    
        @media #{$small_and_down} {
            padding: sizer(1.5); 
        }

        .dam-logo-img {
            max-width: 122px;
            height: 48px;
            align-self: center;
            width: auto;
        }
    }

    &-form {
        max-width: $large_screen;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .mfa-setup {
            padding: 0 sizer(3);
            h1 {
                margin: 0;
                height: 112px; 
                padding: 0 !important; 
                font-size: $font-size-48; 
                line-height: $line-height-56; 
                font-weight: $font-weight-urbane-medium; 

                @media #{$mobile} {
                    font-size: $mobile-font-size-48;
                    line-height: $mobile-line-height-56;  
                }

                @media ((min-width: 1025px) and (max-width:1280px)) {
                    font-size: $mobile-font-size-32; 
                }
            }

            p {
                margin-bottom: 0;
            }

            .label {
                .error {
                    color: $candy-red;
                }
            }

            .form-control {
                .error {
                    color: $candy-red;
                }
            }

            .error-label {
                color: $candy-red;
                font-weight: $font-weight-inter-bold;
                display: flex;
                position: absolute;

                &.hidden {
                    visibility: hidden !important;
                }
            }

            .icon-error {
                margin-right: 8px;
                path {
                    color: $candy-red;
                }
            }

            button {
                font-size: $font-size-20;
                line-height: $line-height-28;

                @media #{$mobile} {
                    line-height: $mobile-line-height-56;  
                }
            }

            .step-container {
                display: flex;
                flex-direction: row;
                margin: 0 0 8px 0;
                
                .icon {
                    margin-right: 8px;
                    cursor: pointer;
                    transition: opacity .25s ease-in-out;

                    &:hover {
                        opacity: 0.5;
                    }
                }

                .step-indicator-tag {
                    color: $spanish-gray;
                    font-size: sizer(1);
                    font-weight: $font-weight-inter-bold;
                    line-height: $line-height-24;
                }

                p {
                    margin: auto 0;
                }
            }

            .input {
                margin-bottom: 0; 
                .form-control {
                    &.error {
                        border: 1px $candy-red solid !important;
                        color: $black !important;
                    }
                }
            }

            .qr-wrapper {
                canvas {
                    width: 268px !important;
                    height: 268px !important;
                    margin-bottom: sizer(1.5);

                    @media #{"(max-width:#{$large_screen})"} {
                        width: 200px !important;
                        height: 200px !important;
                    }

                    @media ((min-width: 1024px) and (max-width:1480px)) {
                        width: clamp(12.5rem, 9.7741875rem + 6.26vw, 16.75rem) !important;
                        height: clamp(12.5rem, 9.7741875rem + 6.26vw, 16.75rem) !important;
                    }
                }

                .qr-info-wrapper {
                    gap: 8px;
                    display: flex;
                    flex-direction: column;
                }
            }

            .qr-code-info {
                span {
                    word-break: break-all;
                }
            }

            .qr-code-detail {
                gap: 24px;
                width: 414px;
                display: flex;
                border-radius: 8px;
                flex-direction: row;
                background-color: $light-gray;
                padding: sizer(0.75) sizer(1.5);
                
                p {
                    word-break: break-all;
                }

                i {
                    cursor: pointer;
                    &:hover {
                        opacity: 0.5;
                    }
                }

                @media #{$large_and_down} {
                    width: 100% !important;
                }
            }

            .general-wrapper {
                display: grid;
                gap: sizer(3);

                h1 {
                    height: fit-content;
                }

                @media #{"(max-width:#{$large_screen})"} {
                    gap: sizer(2.5);
                }
            }
            
            .mfa.form-group{
                margin-bottom: 0;

                .qr-code-info {
                    margin-bottom: 0;
                }
            }

            .back-up-code-wrapper {
                display: grid;
                gap: sizer(1.5);

                .mfa-desc-1 {
                    margin: 0 !important;
                }

                .mfa-desc-2 {
                    margin: 0;
                }

                .codes-tag {          
                    border: 1px solid $chinese-white;
                    border-radius: $border-radius-20;
                    display: grid;
                    padding: 24px;
                    width: 100%;
                    gap: sizer(0.5);

                    .code-line {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        > p {
                            width: 50%;
                            font-weight: $font-weight-inter-bold;
                            text-align: center;
                            margin: 0;
                        }
                    }
                }
            }

            .button-group {
                padding: 0 !important; 
                font-weight: $font-weight-inter-medium; 
                line-height: $line-height-26; 
                font-size: $font-size-20; 

                button {
                    height: 52px; 
                    width: 414px; 
                    justify-content: center;
                    
                    @media #{$mobile} {
                        width: 100%;
                    }

                    @media #{"(max-width:#{$large_screen})"} {
                        height: 50px; 
                        width: 100% !important;

                    }
                }
            }

            @media #{$small_to_large} {
                width: 464px;
                margin: 0 auto;
                padding: sizer(3);
                border-radius: 20px;
                background-color: $white;
            }

            @media #{$small_and_down} {
                padding: sizer(3) sizer(1.5);
            }
        }

        .mfa-verification {
            display: flex;
            gap: sizer(1.5);
            align-items: center;
            flex-direction: column;

            h1 {
                margin: 0 !important;
                padding-bottom: 0.5rem;
                
                @media #{$large_and_up} {
                    font-size: $font-size-48;
                }
            }

            p {
                margin: 0;
                max-width: 414px;

                @media (max-width: 462px) {
                    max-width: 100%;
                }
            }

            .label {
                .error {
                    color: $candy-red;
                }
            }

            .mfa-input-wrapper {
                flex-direction: column;
                display: flex;
                width: 414px;
                gap: 3rem;

                @media (max-width: 462px) {
                    width: 100%;
                }
            }

            .input {
                margin: 0;

                .form-control {
                    &.error {
                        border: 1px $candy-red solid !important;
                        color: $black !important;
                    }
                }

                .error-label,
                .error-label-backup {
                    font-weight: $font-weight-inter-bold;
                    position: absolute;
                    display: flex;
                    
                    span {
                        color: $candy-red !important;
                        cursor: default;
                    }

                    &.hidden {
                        visibility: hidden !important;
                    }
                }

                @media #{$large_and_up} {
                    max-width: 414px;
                }
            }

            .icon-error {
                margin-right: 8px;
                path {
                    color: $candy-red;
                }
            }

            .mfa-btn {
                padding-bottom: 0 !important;
                max-width: 414px;
                .btn {
                    width: 100%;
                    place-content: center;
                }
            }

            span {
                color: $sunset-orange;
                font-weight: $font-weight-inter-bold;
                cursor: pointer;

                &:hover {
                    opacity: 0.5;
                }
            }

            @media #{$small_to_large} {
                width: 560px;
                gap: sizer(2.5);
                align-self: center;
                padding: 48px 73px;
                border-radius: 20px;
                background-color: $white;
            }

            @media #{$small_and_down} {
                padding: sizer(3) 0 0 0;
                width: 100%;
                height: 100%;
                border-radius: 0;
            }

            @media (max-width: 462px) {
                padding: sizer(3) sizer(1.5);
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }

        &.login-page {
            .general-container {
                align-self: center;
        
                .message-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    min-height: sizer(3);
                    height: auto; 
        
                    .alert-notification {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }
        
                .message-group {
                    position: relative;
                    top: 0;
                    left: 0;
                    font-size: $font-size-16;
                    font-weight: $font-weight-normal;
                    line-height: $line-height-24;
                    color: $black;
        
                    background-color: $dawn-pink;
                    padding: sizer(1.25) sizer(1);
                    border-left: $candy-red sizer(.5) solid;
                    margin: 0 0 sizer(3) 0 !important;
        
                    width: 100%;
                    display: flex;
                    gap: sizer(.5);
        
        
                    @media (min-width: $large_screen_up) {
                        margin: sizer(3) 0 0 0;
                    }
                    @media (max-width: $large_screen) {
                        margin: sizer(3) 0 sizer(3) 0 !important;
                    }
                    .warning-icon {
                        color: $candy-red;
                    }
                }
        
                h1 {
                    padding-bottom: sizer(1.5);                
                    margin-bottom: unset;
                    text-align: center;
                    color: $black;
    
                    @media #{$large_and_up} {
                        font-size: $font-size-56;
                    }
                }
        
                h3 {
                    font-weight: $font-weight-inter-regular;
                    padding-bottom: sizer(2.5) !important;
                    text-align: center;
                    margin: 0;
                }
        
                .form-container {
                    padding-bottom: sizer(1.5);
                   
                    .eightchar, .lowercase, .uppercase {
                        color:$spanish-gray;
                    }
        
                    .uppercase {
                        padding-bottom: sizer(3);
                        margin-bottom: unset;
                    }
        
                    .newpassvalid {
                        color:$black;
                        svg {
                            color:$leafy-green
                        }
                    }
                }
                .forgot-password-cont {
                    padding-bottom: sizer(0.75);
        
                    .nav-link {
                        font-weight: $font-weight-bold;
                        line-height: $line-height-24;
                        font-size: $font-size-16;
                        padding: 0;
                    }
                }
        
                .form-group:focus-within label {
                    color:$black;
                }
                .form-group {
                    flex-direction: column;
                    position: relative;
                    display: flex;
        
                    label{
                        line-height: sizer(1);
                        top: sizer(-0.688);
                        left: sizer(1.25);
                        padding: 4px;
                        
                    }
                    .new-password-group {
                        padding-bottom: sizer(3);
                        margin-bottom: unset;
                    }
        
                    .reenterpassword-group {
                        margin-bottom: unset;
                    }
        
                    .form-control.warning-input {
                        border-color: $candy-red !important;
                    }
        
                    .label-container {
                        margin-bottom: 5px;
                    }
        
                    input:focus {
                        border-color: $black !important;
                    }
        
                    .warning-label {
                        color: $candy-red !important;
                    }
                }
        
                .button-group {
                    display: flex;
                    align-items: center;
                    padding: sizer(1.5) 0;
        
                    button {
                        width: 100%;
                        justify-content: center;
                    }
    
                    @media #{$small_and_down} {
                        padding-top: sizer(1) !important;
                    }
                }
                .button-group.verify-button-group {
                        margin-top: unset;
                        padding-top: sizer(3);
                }
                .register-now {
                    text-align: center;
        
                }
                .newpassvalid {
                    color:$black;
                    svg {
                        color:$leafy-green
                    }
                }
        
                .reenterpassword-warning {
                    color:$candy-red;
                    font-weight: $font-weight-bold;
                    margin-bottom: unset;
                    svg {
                        color:$candy-red
                    }
                }
    
                @media #{$mlarge_and_up} { width: 414px; }
                @media #{$large_to_mlarge} { width: 414px; }
                @media #{$small_to_large} {
                    width: 560px;
                    margin: 0 auto;
                    background: white;
                    border-radius: $border-radius-20;
                    padding: 0 sizer(3) sizer(3) sizer(3);
                    box-shadow: 0px 1px 10px 0px rgba(176, 176, 176, 0.12), 0px 4px 5px 0px rgba(150, 150, 150, 0.14), 0px 2px 4px 0px rgba(151, 151, 151, 0.15)
                }
                @media #{$small_and_down} {
                    width: 100%;
                    padding: 0 sizer(1.5);
                }
            }
        }
        @media #{$xxlarge_and_up} { max-width: 100%;}
    }

    @media #{$large_and_down} {
        &-cont {
            margin: auto sizer(5);
        }
    }

    @media #{$small_to_large} {
        &-form {
            padding: sizer(1.5) 0;

            &.login-page {
                background: $light-gray;
            }

            @media #{"(max-width:#{$large_screen})"} {
                background-color: $light-gray;
            }
        }
    }
    @media #{$small_and_down} {
        &-cont {
            padding: sizer(2) sizer(2.5);
            margin: 0;
            
        }

        &-form {
            padding: 96px 0 sizer(2);
            display: block;
        }
        
        &.login-page {
            min-height: 100%;
            height: auto;
        }
    }
}