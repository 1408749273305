.header {
    position: relative;
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $chinese-white;
    background-color: #ffffff;

    &-name-initials {
        cursor: pointer;
        font-size: $font-size-16;
        line-height: $line-height-24;
        font-weight: $font-weight-bold;
        color: $white;
        width: 28px;
        height: 28px;
        background-color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &-section {
            display: flex;
            align-items: center;
            gap: 8px;
            width: fit-content;
            cursor: pointer;

            &:hover {
                .header-fullname-container > p,
                .header-fullname-container > span {
                    opacity: .5;
                }
            }
            .header-initials-container {
                width: 28px;
                height: 28px;
                background-color: $black;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                span {
                    cursor: pointer;
                    font-size: $font-size-12;
                    line-height: $line-height-16;
                    font-weight: $font-weight-bold;
                    color: $white;
                }
            }

            .header-fullname-container {
                position: relative;
                display: flex;
                align-items: center;

                p {
                    font-size: $font-size-20;
                    line-height: $line-height-28;
                    font-weight: $font-weight-inter-regular;
                    color: $black;
                    cursor: pointer;
                    margin: unset;
                }

                .fullname-container-dropdown {
                    display: none;
                    flex-direction: column;
                    gap: sizer(.5);
                    padding: sizer(1);
                    border: 1px solid $black;
                    border-radius: $border-radius-20;
                    position: absolute;
                    bottom: -170px;
                    right: -30px;
                    width: 130px;
                    z-index: 2;
                    background-color: $white;

                    &.open { display: flex; }

                    p {
                        font-size: $font-size-16;
                        line-height: $line-height-28;
                        font-weight: $font-weight-inter-regular;
                        color: $black;
                        cursor: pointer;
                        padding: sizer(.25) sizer(.5);
                        border-radius: $border-radius-8;

                        &:hover {
                            background-color: $light-gray;
                        }

                        @media (min-width: 1481px) {
                            font-size: $font-size-20;
                        }
                    }

                    @media (min-width: 1481px) {
                        right: -40px !important;
                        width: 198px;
                    }
                }
            }
        }
    }
    &-dropdown {
        &-menu {
            margin-top: sizer(.5);

            .paragraph-2 {
                margin-bottom: sizer(1);
                font-size: $font-size-14;
                font-weight: $font-weight-medium;
                padding: 0 sizer(1);

                span {
                    font-weight: $font-weight-bold;
                }
            }

            .dropdown-item {
                padding: sizer(1);

                &:active {
                    background-color: $primary;
                }
            }
        }
    }
    
    .image-search-container {
        .image-search-upload-section {
            top: 36px;
            right: 8px;
    
            @media ((min-width:$large_screen_up) and (max-width:$xlarge_screen_up)) {
                right: 8px;
            }
            
            .image-search-upload-url-link {
                .image-search-input {        
                  svg {
                    margin-left: 20px;
                  }
                }
            }
    
            .btn-close { 
              svg {
                  display: none;
              }
            }
        }    
    }
    .container {
        margin: 0;

        @media #{$large_and_up} {
            margin: 0 sizer(6);
        }

        @media ((min-width:$large_screen_up) and (max-width:$xlarge_screen_up)) {
            margin: 0 sizer(3);
        }

        @media #{$mobile} {
            padding-left: sizer(1.5);
            padding-right: sizer(1.5);
        }
    }

    .row {
        @media (min-width:$large_screen_up) {
            margin-right: 0;
            margin-left: 0;
        }

        .col-6 {
            @media (min-width:$large_screen_up) {
                padding-right: 0;
            }

            &:nth-child(1) {
                width: 61%;
                gap: 13%;
                padding: unset;

                @media #{$large_to_xlarge} {
                    gap: min(13%,3%);
                }
            }
            &:nth-child(2) {
                width: 39%;
                margin-left: auto;

                @media #{$large_to_xlarge} {
                    width: min(39%,34%);
                }
            }
        }

        .col-6:nth-child(1):has(~ .col-6:nth-child(2) > .search-input) {
            .menu-tabs-wrapper .menu-tabs-nav {
                li button.active::after {
                    bottom: sizer(-1.25);
                }
            }
        }
    }

    hr {
        margin: sizer(1.5) 0;
    }

    .dam-logo {
        display: flex;
        align-items: center;
        border: 0px;
        background-color: unset;
        padding: unset;
        margin-left: sizer(1);
        
        @media (min-width:$large_screen_up) {
            margin-left: 0;
        }

        &-img {
            width: 122px;
            height: 48px;
	        object-fit: contain;
            border: 0;
            padding: 0;
        }

        &-text {
            margin-bottom: 0;
            margin-left: sizer(1);
            font-size: $font-size-32;
            line-height: $line-height-40;
            font-weight: $font-weight-bold;
            color: $black;

        }
    }

    .menu-button-container {
        display: none;
        height: 100%;
        width: 30px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-controls{
        display: flex;
        flex-direction: row;
        vertical-align: middle;
        justify-content: center;
    }

    .burger-menu {
        display: block;
        background-color: $black;
        height: 4px;
        width: 24px;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 2px;

        &::before,
        &::after {
            display: block;
            background-color: $black;
            position: absolute;
            height: 4px;
            width: 24px;
            transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
            border-radius: 2px;
        }

        &::before {
            content: '';
            margin-top: -8px;
        }

        &::after {
            content: '';
            margin-top: 8px;
        }

        &-container {
            display: flex;
            height: 32px;
            width: 32px;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // margin-right: sizer(1);
        }
    }

    #burger-menu-toggle {
        display: none;

        &:checked + .mobile-controls .burger-menu-container .burger-menu::before {
            margin-top: 0px;
            transform: rotate(405deg);
        }

        &:checked + .mobile-controls .burger-menu-container .burger-menu {
            background: rgba(255, 255, 255, 0);
        }

        &:checked + .mobile-controls .burger-menu-container .burger-menu::after {
            margin-top: 0px;
            transform: rotate(-405deg);
        }
    }

    button {
        .header-icon {
            width: 28px;
            height: 28px;
            border: 0;
            padding: 0;
            color: $spanish-gray;

            path {
                transition: all .25s ease-in-out;
            }

            &:hover {
                path {
                    fill: $translucent-black;
                }
            }
            &:not(:last-child) {
                margin-right: sizer(3);
            }
        }
    }

    .icon-button {
        background-color: transparent;
        color: $black;
        border: 0;
        padding: 0;
        margin: 0 0 0 sizer(3);
        width: 28px;
        height: 28px;

        &::before,
        &::after {
            display: none;
        }
        &:hover, &:focus, &:first-child:active {
            background-color: transparent;
            color: $black;
            border: 0;
        }
        &.show {
            background-color: transparent;
            color: $black;
            border: 0;

            svg {
                path {
                    fill: $primary;
                }
            }
        }
    }

    .search-input {
        * {
            border: none !important;
        }
        box-sizing: border-box;
        z-index: 1;

        & > div > div {
            padding: sizer(1);
            height: 56px;

            @media #{$large_and_down} {
                padding: sizer(1) sizer(1.5);
            }
        }

        button {
            background-color: $black;
            padding: 2px;
            width: 20px;
            height: 20px;

            svg {
                width: 16px;
                height: 16px;
                color: $white;

                path {
                    fill: $white;
                }
            }

            &:hover {
                opacity: 0.5;

                color: $spanish-gray;

                path {
                    fill: $spanish-gray;
                }
            }
        }

        .MuiInputBase-input {
            font-family: $inter;
            font-size: $font-size-16;
            line-height: $line-height-28;
            font-weight: $font-weight-inter-regular;

            padding-left: sizer(1);
            padding-top: 0;
            padding-bottom: 0;
        }
        .MuiInputBase-input::placeholder {
            color: #525151;
            font-family: $inter;
            font-size: $font-size-20;
            line-height: $line-height-28;
            font-weight: $font-weight-inter-regular;
        }
        svg {
            color: $white;
            height: 24px;
            width: 24px;
        }

        border: 1px solid $chinese-white !important;
        border-radius: $border-radius-40;
        height: 56px;

        &:active, &:focus-within {
            border: 1px solid $black !important;
        }

        .search-image-icon {
            margin-right: 32px;
        }

        .search-image-icon::after {
            border-left: 1px solid $chinese-white;
            content: "";
            display: inline-block;
            height: 100%;
            position: absolute;
            top: -1px;
            right: -16px;
        }
    }

    .mobile-navigation{
        &-container {
            width: 100%;
            height: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-top: sizer(3);
            padding-bottom: sizer(4);
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 15;
            width: 100%;
            padding: sizer(1.5) 0;

            .subpage-back {
                padding: 0;

                svg {
                    width: 32px;
                    height: 32px;
                    color: $black;

                    path {
                        fill: $black
                    }
                }
            }
        };

        &-menu {
            width: 100%;
            height: calc(100vh - 96px);
            position: fixed;
            top: 96px;
            left: 0;
            transform: translateY(-150%);
            transition: all .5s ease-in-out;
            background-color: $white;
            z-index: -1;
            pointer-events: none;

            &.mobile-sub-menu {
                .mobile-navigation{
                    &-container {
                        padding-top: sizer(2);
                    }
                }
            }

            &.open {
                pointer-events: all;
                transform: translateY(0);
            }

            .search-input {
                margin-bottom: sizer(2);

                &-container {
                    padding: 0 sizer(1.5);
                }
            }

            .menu-tabs {
                &-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    > div {
                        margin-top: sizer(3);
                        padding-left: sizer(1.5);
                        padding-right: sizer(1.5);
                    }
                }
                &-wrapper {
                    width: 100%;
                    height: 100%;
                    padding: 0;

                    > div {
                        width: 100%;

                        .btn-primary {
                            width: 100%;
                            justify-content: center;
                        }
                    }

                    .accordion {
                        &-header {
                            font-family: $inter;

                            button {
                                color: $black;
                                font-weight: $font-weight-medium;
                                font-size: 18px;
                                line-height: 26px;
                                padding: sizer(1) sizer(1.5);
                            }
                        }

                        &-body {
                            padding: 0;

                            .nav-item {
                                padding: 0;
                            }

                            button {
                                color: $black;
                                font-weight: $font-weight-normal;
                                font-size: 16px;
                                line-height: 24px;
                                padding: sizer(0.75) sizer(3);
                            }
                        }
                    }
                }
                &-nav {
                    height: unset;
                    display: flex;
                    flex-direction: column;

                    .nav-item {
                        padding: sizer(1) sizer(1.5);
                        width: 100%;
                        height: unset;
                        border-bottom: 1px solid $chinese-white;
                    }
                }
            }
        }
    }

    //mobile
    @media #{$large_and_down} {
        .dam-logo {
            margin-left: 0;
        }
    }

    //tablet
    @media #{$large_to_xlarge} {
        .col-12 {
            display: flex;
            justify-content: space-between;
            padding: 0
        }
    }

    //tablet to mobile
    @media #{$xlarge_and_down} {
        .row {
            .col-6 {
                .menu-tabs-nav {
                    .nav-item {
                        button {
                            padding: 0 sizer(1.5);
                        }
                    }
                }
            }
        }

    }

    //desktop
    @media #{$xlarge_and_up} {
        .col-12 {
            display: flex;
            justify-content: space-between;
            padding: 0
        }
    }
};

//Headroom
.headroom {
    &-wrapper {
        position: relative;
        z-index: 10;

        .headroom {
            background-color: $white;
        }
    }
}