.user-request {
    @media #{$tablet} {
        .user-request-empty img {
            position: relative;
            width: 100%;
        }
        .nav-tabs {
            border: 0;
            padding: 0;
            height: 0;
            display: none;
            button{
                padding: 0 !important;
                &.active {
                    color: unset;
                }
            }
        }
        .mobile-setting-bar{
            display: grid;
            grid-template-rows: auto auto;
            grid-template-columns: repeat(2, 1fr);
            button{
                padding: 0;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                &:active, &.icon-button.selected{
                    color: $black !important;
                    background-color: unset !important;
                    border-color: unset;
                    border: 0;
                }
                &.icon-button.selected svg path {
                    fill: $black !important;
                }
                h3{
                    margin-bottom: 0;
                }
                &.icon-button:hover {
                    opacity: unset;
                }
            }
            .mobile-setting-pending {
            //.mobile-setting-filter {
                &::after{
                    content: "";
                    position: absolute;
                    width: 140%; 
                    height: 1px;
                    top: 41px;
                    background-color: $chinese-white;
                    transform: translateX(-48px);
                }
                margin-bottom: sizer(1rem);
            }
            .mobile-setting-pending {
                grid-row: 1;
                grid-column: 1;
              }
              .mobile-setting-filter {
                grid-row: 1;
                grid-column: 2;
              }

              .form-search {
                grid-row: 2;
                grid-column: span 2;
                margin-top: sizer(1);
              }
        }
        thead{
          display: none;
        }
        table{
          //transform: translateX(-24px);
          //width: 113%;
          table-layout: fixed;
          .ant-table-expanded-row{
            display: none;
          }
          span{
            color: $black;
            &.ant-checkbox-inner{
              border-color: $black;
            }
          }

        }
        .ant-table-cell{
            width: 100% !important;

            &.mobile-col-name{
                padding-right: 0 !important;
                padding-left: 41px !important;
                //column-width: 280px !important;
                border-bottom: 0;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    width: 1400px; //63%;
                    height: 1px;
                    bottom: 0;
                    background-color: $chinese-white;
                    transform: translateX(-105px);
                    @media screen and (max-width: 500px) {
                        width: 449px;
                    }
                }
                &.approve-cell::after{
                    content: "";
                    position: absolute;
                    width: 860px; //63%;
                    height: 1px;
                    bottom: 0;
                    background-color: $chinese-white;
                    transform: translateX(-105px);
                    @media screen and (max-width: 500px) {
                        width: 490px;
                    }
                }
            }
            &.mobile-col-actions{
              border-bottom: 0;
              padding-left: 1rem !important;
              padding-right: 0 !important;
              width: auto !important;
              button.mobile-more-vertical{
                padding: 0;
                margin-right: -4px;
                svg{
                   transform: rotate(90deg);
                }

              }
            }
          }
        span.table-pagination{
          font-size: $mobile-font-size-18;
          line-height: $line-height-26;
        }

        .ant-table-custom-cell {
            .mobile-select-role{
                overflow: hidden;
                display: flex;
                flex-direction: column;
                width: 100%;
                //margin-left: sizer(1);
                button{
                    padding: 0;
                    justify-content: flex-start;
                    border: 0;
                    background: transparent;
                    max-width: 120px;
                    width: auto;
                    span{
                        //color: $spanish-gray;
                        font-weight: 400;
                        margin-bottom: 0;
                        margin-right: 0;
                    }
                }
            }
            span > div {
                position: absolute;
                left: 0 !important;
                top: 30px;
            }
        }
        .role-desc-container{
            display: flex;
            flex-direction: column;
            gap: 4px;
            span{
              &.paragraph-1{
                margin-bottom: 0;
              }
              &.role-desc{
                font-size: $font-size-12;
                font-weight: 400;
                line-height: $line-height-16;
                color: $spanish-gray
              }
            }
         }
         .mobile-check-delete{
            background-color: $light-gray;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: sizer(0.75) sizer(1.5);
            position: fixed;
            width: 100%;
            height: 74px;
            z-index: 1;
            left: 0;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5), 0 0 4px -4px rgba(0, 0, 0, 0.5);
            z-index: 99;
            .mobile-user-check{
              width: sizer(20.375);
              label{
                font-size: $mobile-font-size-18;
              }
              span{
                &.ant-checkbox-checked span{
                    background: $black !important;
                  }
                  span{
                    font-size: $mobile-font-size-18;
                    font-weight: 500;
                    line-height: 26px;
                    background: transparent;
                  }
              }
              .ant-checkbox-inner:before {
                background-color: $black;
                content: "";
                display: inline-block;
                position: absolute;
                top: 45%;
                left: 5px;
                height: 2.67px;
                width: 11.67px;
              }
              .selectall_checkbox.some-checkbox-filled:hover .ant-checkbox .ant-checkbox-inner::before {
                  background-color: transparent;
              }
            }
            .mobile-user-approve-reject{
                display: flex;
                gap: 24px;
                height: 24px;
              button{
                padding: 0;
              }
            }
        }

         .form-search{
            #search-full-name-rejected{
              padding: sizer(1) sizer(4) !important;
              padding-left: 4rem !important;
              border-radius: sizer(2.5);
            }
        }
        button.type-request{
            padding: 0.75rem 0.5rem !important;
            font-size: $font-size-18;
            line-height: 26px;
            display: flex;
            align-items: center;
            gap: 8px;
            height: 50px;
            color: $black;
            &.active{
                background-color: $black;
                color: $white;
                padding-top: sizer(0.75) !important;
                border-radius: sizer(0.375);
            }
            span.filters-h3> span{
                font-size: $font-size-16;
            }
        }
        .custom-ant-table.ant-table-wrapper .ant-table-tbody > tr > td {
            padding-left: 3.25rem;
            &.approve-cell{
                padding-left: 0 !important;
                .mobile-select-role{
                    margin-left: 0 !important;
                }
            }
        }
        .form-check-input[type=checkbox],
        .form-check-input:checked[type=checkbox] {
            width: sizer(1.5);
            height: sizer(1.5);
        }
        .form-check-input-mixed{
            &::before {
                width: sizer(1.5);
                height: sizer(1.5);
                background: transparent;
            }
            &::after {
                left: 7px;
                top: 11px;
            }
        }

        button.mobile-more-vertical{
            border: none;
            background: transparent;
        }
        .table-listing {
            position: relative;
            justify-content: flex-start !important;
            padding: sizer(0.75) 0 !important;
            &::before{
                content: "";
                height: sizer(2.5);
                width: 120%;
                position: absolute;
                bottom: -1px;
                left: -50px;
                border-bottom: 1px solid #F5F5F5;
                background-color: #efeded;
            }
            span{
                font-size: $font-size-12;
                font-weight: 500;
                line-height: $line-height-16;
                z-index: 1;
            }
        }
        .ant-pagination{
            &.ant-table-pagination{
                margin-top: sizer(2.5) !important;
            }
            &-item-active {
              height: 40px !important;
            }
            li{
              height: 40px;
              width: 24px;
              padding: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              &.ant-pagination-options{
                display: none !important;
              }
              .ant-pagination-item-link{
                display: flex;
                align-items: center;
              }
              a:hover {
                color: unset !important;
                opacity: unset !important;
              }
            }
          }
        .user-request-table {
            margin-top: sizer(2.5);
            .custom-ant-table {
                margin-top: sizer(0.5) !important;
                &.mobile-rejected-table{
                    table tr{
                        height: 56px !important;
                    }
                }
            }
        }
        .user-request-empty-filter{
            padding-top: sizer(2.5);
        }
        .user-request-offcanvas-box{
            margin-top: sizer(1.5);
        }
        .user-request-empty {
            padding-top: sizer(2.5);
        }
    }/*End mobile*/
    font-size: $font-size-16;
    line-height: $line-height-24;

    &-offcanvas {
        min-width: 33.33%;

        &-success {
            svg {
                fill: $leafy-green;
            }
        }
        &-error {
            svg {
                fill: $candy-red;
            }
        }
        &-success,
        &-error {
            display: flex;
                justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            svg {
                width: 160px;
                height: 160px;
                padding: sizer(1);
                margin-bottom: sizer(1.5);
            }

            h3 {
                font-weight: $font-weight-bold;
                margin-bottom: sizer(.5);
            }

            p {
                margin-bottom: sizer(1.5);
            }

            .btn {
                width: auto !important;
                @media #{$tablet}{
                    width: 100% !important;
                }
            }
        }

        &-invoices {
            &-plan {
                width: 100%;
                font-size: $font-size-12;
                line-height: $line-height-16;
                margin-top: sizer(3);
                padding-bottom: sizer(1.5);
                border-bottom: 1px solid $chinese-white;
                margin-bottom: sizer(1.5);
                gap: sizer(.5);
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    justify-content: space-between;

                    &:first-child {
                        font-size: $font-size-16;
                        line-height: $line-height-24;
                    }

                    &:not(:first-child) {
                        color: $spanish-gray;
                    }
                }
            }
            &-amount {
                width: 100%;
                font-size: $font-size-16;
                line-height: $line-height-24;
                padding-bottom: sizer(1.5);
                border-bottom: 1px solid $chinese-white;
                margin-bottom: sizer(1.5);
                gap: sizer(.5);
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    justify-content: space-between;
                    gap: sizer(.5);
                }
            }
            &-total {
                display: flex;
                justify-content: space-between;
                font-size: $font-size-28;
                line-height: $line-height-36;
                font-weight: $font-weight-bold;
                margin-bottom: sizer(3);
                h2{
                    font-weight: $font-weight-urbane-demi-bold;
                    margin-bottom: 0;
                }
            }
        }

        &-box {
            display: flex;
            gap: sizer(.5);
            flex-direction: column;
            margin-top: sizer(3);
            margin-bottom: sizer(3);
            border-radius: $border-radius-20;
            padding: sizer(1);
            border: 1px solid $chinese-white;
            max-height: 168px;

            > div {
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: sizer(.5);
                    border-radius: $border-radius-8;
                }
                &::-webkit-scrollbar-track {
                    background: $bright-gray;
                    border-radius: $border-radius-8;
                }
                &::-webkit-scrollbar-thumb {
                    background: $spanish-gray;
                    border-radius: $border-radius-8;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: $spanish-gray;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        .btn {
            width: 100%;
            justify-content: center;
        }

        .offcanvas-header {
            padding-top: sizer(6);
            padding-bottom: sizer(1.5);
            padding-left: sizer(3);
            padding-right: sizer(3);
            width: 100%;

            .btn-close {
                display: none;
            }
        }
        .offcanvas-body {
            padding-top: sizer(1.5);
            padding-bottom: sizer(3);
            padding-left: sizer(3);
            padding-right: sizer(3);

            &::-webkit-scrollbar {
                width: sizer(.5);
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-track {
                background: $bright-gray;
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-thumb {
                background: $spanish-gray;
                border-radius: $border-radius-8;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $spanish-gray;
            }

            .spinner-border {
                width: 16px;
                height: 16px;
            }
        }
        .offcanvas-title {
            font-weight: $font-weight-bold;
            font-size: $font-size-20;
            line-height: $line-height-28;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        .paragraph-2 {
            color: $spanish-gray;
            margin-top: sizer(1.5);
            margin-bottom: 0;
            text-align: center;
        }
    }
    .ant-table-pagination{
        font-family: $inter;
        margin-top: sizer(3) !important;
        a{
          font-family: $inter;
          &:hover{
            color: $spanish-gray;
            opacity: 0.5;
          }
        }
        .ant-pagination-options-quick-jumper input{
          border-color: $spanish-gray;
          &:focus {
            border-color: $black !important;
            box-shadow: none;
          }
          &:not(:focus):hover {
            opacity: 0.5;
          }
        }
      }
    .nav-item {
        button {
            position: relative;
            border: 0;
            padding: 0 46px;
            font-size: $font-size-20;
            line-height: $line-height-28;
            font-weight: $font-weight-medium;
            color: $spanish-gray;

            &.active {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: $black;
                    width: 100%;
                    height: 4px;
                    border-radius: $border-radius-40;
                    color: $black !important;
                }
            }
        }
    }

    &-filter {
        padding-top: sizer(3);

        &-button-group {
            display: flex;
            justify-content: flex-end;
            gap: sizer(1.5);
            margin-bottom: sizer(3);

            .clear-all-button,
            .apply-filter-button {
                height: 2.5rem;
                font-size: sizer(1) !important;
                line-height: $line-height-24;
                padding: sizer(0.5) sizer(1.5);
                font-weight: $font-weight-medium !important;
            }
        }

        &-options {

            .filter-options-rangepicker-container {
                position: relative;

                label {
                    position: absolute;
                    top: 32px;
                    left: 34px;
                    padding: 0 sizer(0.25);
                    background-color: $white;
                    z-index: 3;
                    font-size: $font-size-12;
                    line-height: $line-height-16;
                }

                .ant-picker-range.date-component {
                    .ant-picker-input {
                        text-align: unset;
                        width: fit-content;

                        &:first-child {
                            input { padding-right: 4px; width: 100px; }
                        }

                        input {
                            text-align: unset;
                        }
                    }
                }
            }

        }
    }
    &-settings-bar {
        width: 100%;
        border-bottom: 1px solid $chinese-white;
        padding: sizer(1) 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        gap: 40px;

        &-left {
            display: flex;
            align-items: center;
            input {
            //    &:disabled, &[disabled] {
            //         &~.form-check-label {
            //             color: $chinese-white;
            //             opacity: 1;
            //             margin-left: sizer(1);
            //             font-size: $font-size-20;
            //             line-height: $line-height-28;
            //             font-weight: $font-weight-medium;
            //             width: sizer(6.875);
            //         }
            //    }
                &~.form-check-label {
                    opacity: 1;
                    margin-left: sizer(1);
                    font-size: $font-size-20;
                    line-height: $line-height-28;
                    font-weight: $font-weight-medium;
                    text-wrap: nowrap;
                }

            }

            .form-check-input-mixed {
                margin-bottom: unset;
                &::before {
                    top: unset;
                    //transform: translateY(-50%);
                    //width: sizer(1.5);
                    //height: sizer(1.5);
                    vertical-align: unset;
                    background-repeat: unset;
                    background-position: unset;
                    background-size: unset;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 8px;
                    top: 50%;
                    height: 1px;
                    width: 8px;
                    //transform: translateY(-50%);
                    vertical-align: unset;
                    background-repeat: unset;
                    background-position: unset;
                    background-size: unset;
                    display: unset;
                    align-items: unset;
                    @media screen and (max-width: sizer(90)) {
                        left: 7px;
                    }
                }

               /*input {
                    transition: all .25s ease-in-out;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    margin: unset;
                    float: unset;
                    height: 24px;
                    width: 24px;
                }*/

                label {
                    font-size: $font-size-20;
                    line-height: $line-height-28;
                    transition: all .25s ease-in-out;
                    color: $black;
                    font-weight: $font-weight-medium;
                    margin-left: sizer(1);
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            .ant-checkbox-wrapper.some-checkbox-filled {
                &::after { content: unset; }

                .ant-checkbox {
                    .ant-checkbox-inner {
                        position: relative;
                        z-index: 5000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;

                        &::after { content: unset; }

                        &::before {
                            content: '';
                            background-color: $black;
                            height: 2px;
                            width: 10px;
                        }

                    }

                    // &.ant-checkbox-checked {
                    //     .ant-checkbox-inner {
                    //         &::before {
                    //             content: '';
                    //             background-color: $black;
                    //             height: 1px;
                    //             width: 8px;
                    //         }
                    //     }
                    // }
                }

            }

            .ant-checkbox-wrapper.some-checkbox-filled {
                &::after { content: unset; }

                .ant-checkbox {
                    .ant-checkbox-inner {
                        position: relative;
                        z-index: 5000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;

                        &::after { content: unset; }

                        &::before {
                            content: '';
                            background-color: $black;
                            height: 1px;
                            width: 8px;
                        }

                    }

                    // &.ant-checkbox-checked {
                    //     .ant-checkbox-inner {
                    //         &::before {
                    //             content: '';
                    //             background-color: $black;
                    //             height: 1px;
                    //             width: 8px;
                    //         }
                    //     }
                    // }
                }

            }
        }
        &-right {
            display: flex;
            flex-direction: row;
            gap: sizer(2.5);
            align-items: center;
            button.icon-button.selected {
                background-color: unset !important;
                color: $black !important;

                svg path { fill: $black !important; }
            }
            .form-search input {
                max-width: 414px;
                width: 100%;
            }
        }
        // @media screen and (max-width: 1600px) {
        //     gap: 0;
        // }
        button.icon-button.selected {
            pointer-events: all;
        }
        .btn:disabled {
            background-color: transparent;
        }
    }
    &-table {
        margin-top: sizer(3);

        .ant-table-cell {
            width: calc(100% / 4);
            &.col-role{
                padding-left: 0 !important;
                
                .ant-dropdown-trigger {
                    .ant-tag-container {
                        span {
                            margin: auto;
                        }
                    }

                    svg {
                        margin: auto 0;
                    }
                }
            }
        }
        .table-listing{
            margin-top: sizer(1.5);
            background-color: $light-gray;
            display: flex;
            justify-content: center;
            padding: 12px 48px 12px 48px;
            span{
                font-family: $inter;
            }
        }
        .custom-table-listing{
            margin-top: 0 !important;
        }
        .custom-ant-table  {
            margin-top: sizer(1.5);

            .ant-table {
                colgroup,
                .ant-table-tbody .ant-table-row-expand-icon-cell,
                .ant-table-thead .ant-table-row-expand-icon-cell {
                    display: none;
                }

                .ant-table-tbody {
                    .ant-table-row {
                        position: relative;
                        z-index: 3;
                        height: 76px;
                    }
                    .ant-table-expanded-row {
                        .ant-table-custom-expanded-cell {
                            > div {
                                //width: calc(100% / 4);
                                width: 100%;
                                &:nth-child(2) {
                                    padding-left: 1rem;
                                    @media screen and #{$mlarge_and_down}{
                                        padding-left: sizer(0.3) !important;
                                    }
                                }
                            }
                        }
                        .ant-table-custom-expanded-cell::before {
                           height: auto;
                        }
                        td.ant-table-column-sort {
                            background-color: $light-gray;
                        }
                        &.ant-table-row:hover >td{
                            background-color: $light-gray !important;
                        }
                    }

                }
                .ant-dropdown-select{
                    &:hover div, &:hover svg{
                      opacity: 0.5;
                    }
                }

            }
        }

        .actions_groups_btns {
            display: flex;
            flex-direction: row;
            gap: sizer(1);

            button {
                padding: 0;
                &.icon-button.expanded svg {
                    transform: rotate(0);
                }
            }
        }
    }
    &-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: sizer(3);
        text-align: center;

        &-filter {
            padding-top: sizer(3);

            p {
                margin-bottom: sizer(1.5);
            }

            ul {
                padding-left: sizer(1);
            }
        }

        img {
            margin-bottom: sizer(3);
        }

        h2 {
            margin-bottom: sizer(1);
        }

        p {
            color: $primary;
            font-weight: $font-weight-normal;
            margin-bottom: 0;
        }
    }

    .alert-notification  {
        margin-bottom: sizer(2);
        position: absolute;
        top: -140px;
    }
}
