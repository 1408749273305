.upload-edit {
    &-wrapper {
        width: 100%;
        height: 100%;

        .upload-offcanvas-form {
            > div {
                position: relative;
                label{
                    top: -0.688rem;
                    left: 1.25rem;
                    line-height: 1rem;
                    padding: 0.25rem;
                }
                #fileDescError {
                    position: absolute;
                    top: sizer(1.5);
                    left: sizer(1.5);
                    pointer-events: none;
                }
                #fileNameDesc {
                    position: absolute;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    color: $black;
                    top: 0;
                    left: 0;
                    padding-top: sizer(.5);
                   
                }
                #Filename{
                    color: $black;
                    padding: sizer(0.875) sizer(1.5);
                    border: 0;
                }
                #Description::placeholder{
                    color: $spanish-gray;
                }
                #fileNameError {
                    margin-top: sizer(.5);
                }



                textarea {
                    &:disabled {
                        background-color: $white;
                        border-color: $white;
                    }
                }
                #Description {
                    height: 168px;
                    color: black;
                    padding: sizer(0.875) sizer(1.5);
                    border: 0;
                }
            }

            .no-editing {
                textarea {
                    display: none;
                }
                label {
                    left: 0 !important;
                    padding: 0;
                    color: $spanish-gray !important;
                    margin-top: 0.75rem;
                }
                #fileNameDesc {
                    position: relative;
                    padding-top: 1.5rem;
                }
            }
        }
    }
}