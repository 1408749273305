.h1, h1 {
    font-family: $urbane-rounded;
    font-size: $font-size-48;
    line-height: $line-height-56;
    margin-bottom: sizer(3);

    @media #{$tablet} {
        font-size: $tablet-font-size-h1;
        line-height: $tablet-line-height-h1;
        margin-bottom: sizer(2.5);
    }
    @media #{$mobile} {
        font-size: $mobile-font-size-h1;
        line-height: $mobile-line-height-h1;
    }
}

.h2, h2 {
    font-family: $urbane-rounded;
    font-size: $font-size-28;
    line-height: $line-height-36;
    margin-bottom: sizer(1.75);
    &-mb-48{
        margin-bottom: sizer(3) !important;
    }
    @media #{$tablet} {
        font-size: $tablet-font-size-h2;
        line-height: $tablet-line-height-h2;
    }
    @media #{$mobile} {
        font-size: $mobile-font-size-h2;
        line-height: $mobile-line-height-h2;
    }
}
.display{
    font-size: $font-size-56;
    line-height: $line-height-64;
    @media #{$tablet} {
        font-size: $tablet-font-size-display;
        line-height: $tablet-line-height-display;
    }
    @media #{$mobile} {
        font-size: $mobile-font-size-display;
        line-height: $mobile-line-height-display;
    }
}

.h3, h3 {
    font-family: $inter;
    font-size: $font-size-20;
    line-height: $line-height-28;
    margin-bottom: sizer(1.25);
    @media #{$tablet} {
        font-size: $tablet-font-size-h3;
        line-height: $tablet-line-height-h3;
    }
    @media #{$mobile} {
        font-size: $mobile-font-size-h3;
        line-height: $mobile-line-height-h3;
    }
}
.h4, h4 {
    font-family: $inter;
    font-size: $font-size-48;
    line-height: $line-height-56;
    margin-bottom: sizer(1.25);
    @media #{$tablet} {
        font-size: $tablet-font-size-h4;
        line-height: $tablet-line-height-h4;
    }
    @media #{$mobile} {
        font-size: $mobile-font-size-h4;
        line-height: $mobile-line-height-h4;
    }
}

p, .paragraph {
    font-family: $inter;
    font-size: $font-size-16;
    line-height: $line-height-24;
    margin-bottom: sizer(1);

    &-header {
        font-weight: $font-weight-bold;
        margin-bottom: sizer(1.5) !important;
    }
    &-1 {
        font-size: $font-size-16;
        line-height: $line-height-24;
        margin-bottom: sizer(1);
    }
    &-2 {
        font-size: $font-size-12;
        line-height: $line-height-16;
        margin-bottom: sizer(0.75);
    }
}

label {
    font-size: $font-size-16;
    line-height: $line-height-24;
}

.micro {
    font-size: $font-size-10;
    line-height: $line-height-14;
    margin-bottom: sizer(0.625);
}

.error, [role="alert"] {
    margin-top: sizer(.25);
    color: $candy-red !important;
    font-size: $font-size-14;

    svg {
        padding-right: sizer(.5);
        path {
            fill: $candy-red;
        }
    }
}
.font-normal {
    font-weight: $font-weight-normal;
}
.font-medium {
    font-weight: $font-weight-medium;
}
.font-semi-bold {
    font-weight: $font-weight-semi-bold;
}
.font-bold {
    font-weight: $font-weight-bold;
}

.font-inter {
    font-family: $inter;
}
a {
    text-decoration: none;
    color: $sunset-orange;

    &:hover {
        color: $black;
        opacity: 0.7;
    }
    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
    &.icon-button {
        position: relative;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        margin: 0;
        color: $spanish-gray;
        cursor: pointer;

        &:disabled {
            pointer-events: none;
            border-color: transparent !important;

            svg {
                path {
                    fill: $bright-gray;
                }
            }
        }
        &-text {
            display: flex;
            align-items: center;
            gap: sizer(0.5);
            border-radius: $border-radius-16;
            padding: sizer(0.75) sizer(1.25);

            &:hover {
                opacity: 1;
                color: $white !important;
                background-color: $sunset-orange;

                svg {
                    path {
                        fill: $white !important;
                    }
                }
            }
        }

        &.icon-right {
            svg {
                order: 2;
            }
        }

        &.selected {
            background-color: $black !important;
            color: $white !important;
            pointer-events: none;

            svg {
                path {
                    fill: $white !important;
                }
            }
        }

        &:hover {
            opacity: .5;

            svg {
                path {
                    fill: $black;
                }
            }
        }
    }
}

.nav-link {
    text-decoration: none;
    color: $spanish-gray;
    font-weight: $font-weight-normal;

    &:hover {
        color: $purple-blue;
        opacity: 0.7;
    }
    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
}

.PaddingTop48 {
    padding-top: sizer(3);
}

.PaddingBottom48 {
    padding-bottom: sizer(3);
}

.PaddingBottom24 {
    padding-bottom: sizer(1.5) !important;
}