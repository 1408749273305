.container {
    padding-left: 0;
    padding-right: 0;

    @media #{$small_and_up} {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: sizer(1.5);
        padding-right: sizer(1.5);

        &-sm {
            max-width: $container-sm;
        }
    }

    @media #{$medium_and_up} {
        max-width: 100%;

        &-md {
            max-width: $container-md;
        }
    }

    @media #{$large_and_up} {
        max-width: calc(100vw - sizer(6));
        // margin-left: sizer(3);
        // margin-right: sizer(3);
        padding-left: 0;
        padding-right: 0;

        &-lg {
            max-width: $container-lg;
        }
    }

    @media #{$xlarge_and_up} {
        max-width: calc(100vw - sizer(12));
        margin-left: sizer(6);
        margin-right: sizer(6);

        &-xl {
            max-width: $container-xl;
        }
    }

    @media #{$xxlarge_and_up} {
        max-width: -webkit-fill-available;

        &-xxl {
            max-width: $container-xxl;
        }
    }
}