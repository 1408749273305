.error {
    @media #{$tablet} {
        &-wrapper {
            .row {
                display: unset;
                flex-wrap: unset;
                margin-top: unset;
                margin-right: unset;
                margin-left: unset;
                .col-12{
                    padding: 0;
                }
            }
            .col-header {
                padding: sizer(1.5) !important;
            }
        }
        &-container {
            width: 100% !important;
            max-width: unset !important;
            padding: 0 sizer(1.5);
            .button-group {
                flex-direction: column;
                width: 100%;
                margin-bottom: sizer(2) !important;
            }
            p{
                margin-bottom: sizer(2) !important;
            }
            img{
                width: 100% !important;
                max-width: 285.94px;
                margin-bottom: sizer(2);
            }
        }
    }
    &-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .col-header {
            padding: sizer(1.5) sizer(6);
            margin-bottom: sizer(3);

            img {
                max-width: 100%;
                height: 48px;
                padding-right: 0.5rem;
                width: auto;
            }
        }
    }
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        max-width: 560px;
        margin: auto;

        h2 {
            margin-bottom: sizer(1);
        }

        p {
            text-align: center;
            margin-bottom: sizer(3);

            a {
                font-weight: $font-weight-inter-bold;
                &:hover {
                    color: $sunset-orange;
                    opacity: 0.5;
                }
            }
        }

        img {
            width: 380px;
            margin-bottom: 50px;
        }

        .button-group {
            display: flex;
            align-items: center;
            margin-bottom: sizer(3);
            gap: sizer(1.5);
        }
    }
}