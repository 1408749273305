.story--components {
    &-color {
        &-primary {
            padding: sizer(1);
            color: $white;
        }
        &-title {
            font-size: $font-size-48;
            line-height: $line-height-56;
            font-weight: $font-weight-bold;
            padding: sizer(1.5) 0;
        }
        &-primary,
        &-secondary,
        &-neutral {
            color: $black;

            &-title {
                background-color: $black;
                color: $white;
                font-size: $font-size-32;
                line-height: $line-height-40;
                padding: sizer(1) sizer(1.5);
            }
            .story--components-color-bg {
                margin-top: sizer(2);
            }
            .story--components-color-class {
                margin-bottom: sizer(2);
            }

            ol {
                list-style-type: none;
                display: flex;
                gap: sizer(1.5);
            }
        }
        &-secondary,
        &-neutral {
            .story--components-color-bg {
                width: 100%;
            }

            ol {
                flex-wrap: wrap;

                li {
                    width: calc(100% / 3 - sizer(1.5));
                }
            }
        }
        &-bg {
            width: 414px;
            height: 202px;
            border: 1px solid $spanish-gray;
        }
        &-code {
            font-size: $font-size-16;
            line-height: $line-height-24;
            font-weight: $font-weight-bold;
        }
        &-class {
            p {
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
#storybook {
    &-docs {
        #story--components {
            &-toast-message--default--primary {
                .toast {
                    position: inherit;
                    bottom: 0;
                    left: 0;
                    width: auto;
                    display: flex;
                }
            }
            &-back-to-top--default--primary {
                .back-to-top  {
                    position: inherit;
                    top: 0;
                    left: 0;
                    right: inherit;
                }
            }
        }
    }
}