.upload-settings {
    &-wrapper {
        position: relative;
        padding: sizer(.25) 0;
        margin-bottom: 48px;
        max-width: unset !important;
        
        @media #{$mobile} {
            margin-bottom: sizer(2.5);

        }

        &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $chinese-white;
            transform: translateX(-48px);
            @media #{$tablet} {
                 @media screen and (max-width: 500px) {
                    width: 500px;
                    transform: translateX(-105px);
                }
            }
        }
        &::after {
            bottom: 0;
        }

        &.sticky {
            position: fixed;
            width: 100%;
            top: 0;
            left: sizer(6);
            z-index: 999;
            background-color: $white;
            padding: sizer(1) 0;
            background-color: $white;

            .upload-settings-cont{
                border-top: 1px solid $chinese-white;
                border-bottom: 1px solid $chinese-white;
                padding: 1rem 0;
            }


            &::before,
            &:after {
                display: none;
            }

            @media #{$small_and_up} {
                left: sizer(2);
            }

            @media #{$medium_and_up} {
                left: sizer(4);
            }

            @media #{$xlarge_and_up} {
                left: sizer(6);
            }
        }

        &.upload-setting-progress-state {
            // position: absolute;
            // right: 0;
            // top: sizer(5 * -1);
            position: relative;
            display: flex;
            align-items: center;
            padding: 0;
            margin-bottom: 0;
            width: auto !important;
            &::before,
            &::after {
                display: none;
            }
            @media #{$tablet} {
                width: 100% !important;
                top: unset !important;
                position: relative !important;
                margin-bottom: sizer(2.5) !important;
            }
        }
    }

    &-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: sizer(2.5);
        @media #{$xlarge_and_down}{
            gap: sizer(1.5);
        }
        @media #{$tablet} {
           gap: 16px;
           width: 100%;
           >div{
                justify-content: flex-end;
                width: 100%;
           }
       }
        > * {
            display: inline-flex;
            align-items: center;
            gap: sizer(2.5);
            font-size: $font-size-20;
            line-height: $line-height-28;
            color: $spanish-gray;
            @media #{$xlarge_and_down}{
                gap: sizer(1.5);
            }
            > * {
                display: flex;
            }
            
            .form-check {
                min-height: auto;
                margin-bottom: 0;
                position: relative;
                padding: unset;
                @media #{$tablet} {
                    width: 100%;
                }
                &-input-mixed {
                    &::before {
                        top: 50%;
                        transform: translateY(-50%);
                        width: sizer(1.5);
                        height: sizer(1.5);
                        vertical-align: unset;
                        background-repeat: unset;
                        background-position: unset;
                        background-size: unset;
                        @media #{$tablet} {
                            background-color: transparent;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 7px;
                        top: 50%;
                        transform: translateY(-50%);
                        vertical-align: unset;
                        background-repeat: unset;
                        background-position: unset;
                        background-size: unset;
                        display: unset;
                        align-items: unset;

                    }
                }
            }
        }

        @media #{$tablet} {
            .icon-button-text {
                gap: unset;
            }
        }

        @media #{$small_and_down} {
            .icon-button-text {
                padding: sizer(0.25) sizer(0.25);
                border-radius: sizer(0.5);
            }
        }

        button {
            display: inline-flex;
            color: $black;
            cursor: default;
            pointer-events: none;
            // transition: all .25s ease-in-out;

            &.enable {
                cursor: pointer;
                pointer-events: all;
                opacity: 1;
            }

            &.active {
                position: relative;
                color: $white;
                cursor: default;
                opacity: 1;
                pointer-events: all;

                &::before {
                    content: "";
                    position: absolute;
                    top: sizer(.75 * -1);
                    left: sizer(1 * -1);
                    background-color: $primary;
                    padding: sizer(1.5) sizer(3);
                    border-radius: $border-radius-16;
                    width: calc(100% + sizer(1.75));
                    height: 100%;
                    z-index: -1;
                }
                &:hover {
                    color: $white;

                    &::before {
                        background-color: $primary;
                    }
                }

                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }

        .upload-item {
            &.upload-settings {
                &-edit,
                &-tag,
                &-collection {
                    @media ((min-width: #{$large_screen}) and (max-width: 1183px)) {
                        padding: 0 sizer(0.5);
                    }

                    span {
                        @media ((min-width: #{$large_screen}) and (max-width: 1183px)) {
                            display: none;
                        }
                    }
                }

                &-delete {
                    @media ((min-width: #{$large_screen}) and (max-width: 1183px)) {
                        padding: 0 sizer(1.5) 0 sizer(0.5);
                    }

                    span {
                        @media ((min-width: #{$large_screen}) and (max-width: 1183px)) {
                            display: none;
                        }
                    }
                }
            }
        }

        .upload-settings-view {
            color: $spanish-gray;
            display: flex;
            align-items: center;

            svg {
                margin-left: sizer(0.5);
                margin-right: 0;
            }

            button {
                padding: 0;
                opacity: 1;
                pointer-events: all;
                cursor: pointer;

                &:hover {
                    &::before {
                        display: none;
                    }
                    svg {
                        path {
                            fill: $spanish-gray;
                            opacity: 0.5;
                        }
                    }
                }
                &.upload-progress-btn{
                    display: flex;
                    gap: sizer(0.5);
                    padding: sizer(0.75) sizer(1.25);
                    background: transparent;
                    border: 0;
                    margin-right: sizer(1.5);
                    &.selected:hover {
                        opacity: unset;
                        //cursor: default;
                        svg{
                            path {
                                fill: unset;
                                opacity: unset;
                            }
                        }
                    }
                    &:not(.selected):hover {
                        opacity: 0.5;
                        svg{
                            path {
                                fill: unset;
                                opacity: 0.5;
                            }
                        }
                    }
                    svg{
                        margin: 0;
                        transform: rotate(180deg);
                    }
                }
                &.active {
                    cursor: default;
                    pointer-events: none;

                    &::before {
                        display: none;
                    }

                    svg {
                        path {
                            fill: $black;
                        }
                    }
                }
                @media #{$tablet} {
                    &.grid-btn{
                        display: none;
                    }
                }
           }
            @media #{$tablet} {
                &.mobile-progress-upload{
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: sizer(1);
                    button.upload-progress-btn{
                        display: flex;
                        gap: sizer(0.5);
                        background: transparent;
                        border: 0;
                        padding: 0;
                        margin-right: 0;
                        span{
                            font-size: $font-size-16;
                            font-weight: $font-weight-medium;
                            line-height: $line-height-24;
                        }
                        svg{
                            margin: 0;
                            transform: rotate(180deg);
                        }
                    }
                }
               
                &::after{
                    content: "";
                    position: absolute;
                    width: 1100px; //63%;
                    height: 1px;
                    bottom: 0;
                    background-color: $chinese-white;
                    transform: translateX(-105px);
                    @media screen and (max-width: 500px) {
                        width: 500px;
                    }
                }
                
            }
        }
    }

    &-select-all {
        .form-check {
            input {
                transition: all .25s ease-in-out;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                margin: unset;
                float: unset;
                height: 24px;
                width: 24px;
                background: transparent;
                @media #{$tablet} {
                    background: transparent;
                }
            }
            label {
                font-size: $font-size-20;
                line-height: $line-height-28;
                transition: all .25s ease-in-out;
                color: $black;
                font-weight: $font-weight-medium;
                margin-left: sizer(1.5);
                padding-left: sizer(1);
                @media #{$mobile} {
                    font-size: $font-size-18;
                    line-height: $line-height-26;
                }
                &:hover {
                    opacity: 0.5;
                }
            }
        }
        .paragraph-1 {
            color: $black;
            margin-bottom: unset;
        }
        @media #{$tablet} {
            display: none;
        }
    }

    &-edit, &-tag, &-collection {
        @media #{$tablet} {
            display: none;
        }
    }

    &-delete {
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
        @media #{$tablet} {
            display: none;
        }
    }
}
#setting-tooltip {
    top: 15px !important;
    padding: 0;

    .tooltip-arrow {
        top: -8px;

        &::before {
            border-bottom-color: $chinese-white;
        }
    }
    .tooltip-inner {
        min-width: 60px;
        background-color: $chinese-white;
        color: $spanish-gray;
    }
}

.upload-item{
    .icon-button{
        font-weight: 500;
    }
}

.upload-process-wrapper {
    > * {
        @media #{$tablet} {
            width: unset;
            padding-bottom: sizer(1);
        }
    }
   
    @media #{$tablet} {
        > .upload-process-grid-view{
            padding-bottom: sizer(12);
        }
    }
    @media #{$mobile} {
        > .upload-process-grid-view{
            padding-bottom: sizer(6);
        }
    }
}