.alert {
    &-notification {
        position: relative;
        padding: sizer(1.25) sizer(1.5);
        background-color: $light-gray;
        font-size: $font-size-12;
        line-height: $line-height-16;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: sizer(.5);
            height: 100%;
            display: block;
            background-color: $sunset-orange;
        }

        &-infoIcon {
            margin-right: sizer(.5);

            path {
                fill: $sunset-orange;
            }
        }
        &-success {
            background-color: $light-green;

            &::before {
                background-color: $leafy-green;
            }
            &-group {
                display: flex;
                gap: sizer(.5);
            }
        }
        &-error {
            background-color: $dawn-pink;

            &::before {
                background-color: $candy-red;
            }
            &-group {
                display: flex;
                gap: sizer(.5);
            }
        }
        &-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $sunset-orange;
            font-weight: $font-weight-medium;
            margin-bottom: sizer(1);

            svg {
                margin-right: sizer(.5);
                width: sizer(1);
                height: sizer(1);

                path {
                    fill: $sunset-orange;
                }
            }
        }
        &-content {
            font-size: $font-size-16;
            line-height: $line-height-24;
            font-weight: $font-weight-normal;
            display: flex;
            gap: sizer(.5);
        }
    }
}