// Colors
$orange: #FF674D;
$sunset-orange: #FF6946;
$light-purple:#F4F0FF;
$purple-blue: #683BE2; // removed
$black: #000000;
$leafy-green: #30BA34;
$light-green: #ECF7F3;
$candy-red: #D80029;
// $light-purple: #F4F0FF; // removed
// $pale-purple: #F9F7FF; // removed
$dawn-pink: #F8E5E9;
$light-pink: #FFEBF2;
$hot-pink: #FF4E89;
$granite-gray: #6B6161;
$spanish-gray: #989595;
$font-gray: #CFCECE;
$chinese-white: #E1E1E1;
$bright-gray: #EDEDED;
$medium-gray: #CCCBCB;
$light-gray: #F5F5F5;
$pale-purple: #F9F7FF;
$blue: #0276E1;
$sky-blue: #D9F6FF;
$orange: #FB8B07;
$light-orange: #FFE9CE;
$pale-orange: #FFE8E2;
$white: #ffffff;
$black: #000000;
$translucent-black: #00000080;
$filter-gray: invert(51%) sepia(86%) saturate(0%) hue-rotate(162deg) brightness(97%) contrast(103%);
$filter-primary-1: invert(46%) sepia(83%) saturate(682%) hue-rotate(324deg) brightness(101%) contrast(101%);
$filter-white: invert(100%) sepia(0%) saturate(7500%) hue-rotate(126deg) brightness(112%) contrast(104%);
$filter-green: invert(48%) sepia(98%) saturate(352%) hue-rotate(72deg) brightness(100%) contrast(104%);
$filter-red: invert(10%) sepia(87%) saturate(7377%) hue-rotate(345deg) brightness(84%) contrast(104%);
$filter-purple: invert(49%) sepia(95%) saturate(6811%) hue-rotate(258deg) brightness(93%) contrast(89%);
$filter-primary: $filter-purple;
$filter-secondary: $filter-purple;
$primary: $black;
$secondary: $white;
$primary-shadow: 0 0 0 0.25rem rgba(104,59,226,.25);
$candy-red-shadow: 0 0 0 0.25rem rgba(216,0,41,.25);

// Responsive Font Size based on view ports min 1024px - max 1920px
$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: clamp(1rem, 0.9537rem + 0.2469vw, 1.25rem);
$font-size-24: clamp(1rem, 0.9074rem + 0.4938vw, 1.5rem);
$font-size-28: clamp(1rem, 0.8611rem + 0.7407vw, 1.75rem);
$font-size-32: clamp(1rem, 0.8148rem + 0.9877vw, 2rem);
$font-size-48: clamp(1rem, 0.6296rem + 1.9753vw, 3rem);
$font-size-56: clamp(1rem, 0.6296rem + 1.9753vw, 3.5rem);

// Responsive Font Size based on view ports min 768px - max 1024px
$tablet-font-size-18: clamp(1rem, 0.625rem + 0.781vw, 1.125rem);
$tablet-font-size-display: clamp(2rem, -2.5rem + 9.375vw, 3.5rem);
$tablet-font-size-h1: clamp(2rem, -1rem + 6.25vw, 3rem);
$tablet-font-size-h2: clamp(1.5rem, 0.75rem + 1.563vw, 1.75rem);
$tablet-font-size-32: clamp(2rem, -2.5rem + 9.375vw, 3.5rem);
$tablet-font-size-h3: clamp(1.125rem, 0.75rem + 0.781vw, 1.25rem);
$tablet-font-size-h4: clamp(2rem, -1rem + 6.25vw, 3rem);


// Responsive Font Size based on view ports min 414px - max 768px
$mobile-font-size-18: clamp(1.125rem, 0.979rem + 0.565vw, 1.25rem);
$mobile-font-size-display: clamp(2rem, 0.246rem + 6.78vw, 3.5rem);
$mobile-font-size-h1: clamp(2rem, 0.831rem + 4.52vw, 3rem);
$mobile-font-size-24: clamp(1rem, 0.415rem + 2.26vw, 1.5rem);
$mobile-font-size-h2: clamp(1.5rem, 1.208rem + 1.13vw, 1.75rem);
$mobile-font-size-32: clamp(1.75rem, 0.831rem + 4.52vw, 2rem);
$mobile-font-size-48: clamp(2rem, 0.831rem + 4.52vw, 3rem);
$mobile-font-size-h3: clamp(1.125rem, 0.979rem + 0.565vw, 1.25rem);
$mobile-font-size-h4: clamp(2rem, 0.831rem + 4.52vw, 3rem);


// Line Height based on view ports min 1024px - max 1920px
$line-height-14: 14px;
$line-height-16: 16px;
$line-height-24: 24px;
$line-height-26: 26px;
$line-height-28: clamp(1.5rem, 1.4537rem + 0.2469vw, 1.75rem);
$line-height-32: clamp(1.5rem, 1.4074rem + 0.4938vw, 2rem);
$line-height-36: clamp(1.5rem, 1.3611rem + 0.7407vw, 2.25rem);
$line-height-40: clamp(1.5rem, 1.3148rem + 0.9877vw, 2.5rem);
$line-height-56: clamp(1.5rem, 1.2222rem + 1.4815vw, 3.5rem);
$line-height-64: clamp(1.5rem, 1.2222rem + 1.4815vw, 4rem);

// Line Height based on view ports min 768px - max 1024px
$tablet-line-height-28: clamp(1.5rem, -1.25rem + 4.688vw, 1.75rem);
$tablet-line-height-32: clamp(2rem, 1.25rem + 1.563vw, 2.25rem);
$tablet-line-height-36: clamp(1.5rem, -6.5rem + 15.625vw, 3.5rem);
$tablet-line-height-40: clamp(2.5rem, -0.5rem + 6.25vw, 3.5rem);
$tablet-line-height-44: clamp(1.5rem, -8rem + 18.75vw, 4rem);
$tablet-line-height-56: clamp(2.5rem, -0.5rem + 6.25vw, 3.5rem);

$tablet-line-height-display: clamp(2.5rem, -2rem + 9.375vw, 4rem);
$tablet-line-height-h1: clamp(2.5rem, -0.5rem + 6.25vw, 3.5rem);
$tablet-line-height-h2: clamp(2rem, 1.25rem + 1.563vw, 2.25rem);
$tablet-line-height-h3: clamp(1.625rem, 1.25rem + 0.781vw, 1.75rem);
$tablet-line-height-h4: clamp(2.5rem, -0.5rem + 6.25vw, 3.5rem);


// Line Height based on view ports min 414 - max 768px
$mobile-line-height-28: clamp(1.5rem, 0.123rem + 3.39vw, 1.75rem);
$mobile-line-height-32: clamp(2rem, 1.708rem + 1.13vw, 2.25rem);
$mobile-line-height-36: clamp(1.5rem, -0.462rem + 5.65vw, 2.25rem);
$mobile-line-height-40: clamp(2.5rem, 1.331rem + 4.52vw, 3.5rem);
$mobile-line-height-44: clamp(1.5rem, -2.508rem + 13.559vw, 4rem);
$mobile-line-height-56: clamp(2.5rem, 1.331rem + 4.52vw, 3.5rem);

$mobile-line-height-display: clamp(2rem, -0.339rem + 9.04vw, 4rem);
$mobile-line-height-h1: clamp(2.5rem, 1.331rem + 4.52vw, 3.5rem);
$mobile-line-height-h2: clamp(2rem, 1.708rem + 1.13vw, 2.25rem);
$mobile-line-height-h3: clamp(1.625rem, 1.479rem + 0.565vw, 1.75rem);
$mobile-line-height-h4: clamp(2.5rem, 1.331rem + 4.52vw, 3.5rem);

// Font Weight
$font-weight-normal: 400; // Font weight normal
$font-weight-medium: 500; // Font weight medium
$font-weight-semi-bold: 600;
$font-weight-bold: 700; // Font weight bold

// Urbane Rounded - Font Weight
$font-weight-urbane-light: 300; // Font weight light
$font-weight-urbane-medium: 500; // Font weight medium
$font-weight-urbane-demi-bold: 600; // Font weight demi-bold

// Inter - Font Weight
$font-weight-inter-regular: 400; // Font weight regular
$font-weight-inter-medium: 500; // Font weight medium
$font-weight-inter-semi-bold: 600; // Font weight semi-bold
$font-weight-inter-bold: 700; // Font weight bold

// Border Radius
$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-12: 12px;
$border-radius-16: 16px;
$border-radius-20: 20px;
$border-radius-40: 40px;

// Aspect Ratio
$ratio-16x9: 56.25%;
$ratio-4x3: 75%;
$ratio-3x2: 66.66%;
$ratio-8x5: 62.5%;

// Gutter
$gutter: 30px;

// Media queries
$xsmall_screen: 320px;
$figma_small_screen: 414px;
$small_screen: 575px;
$small_screen_up: 576px;
$medium_screen: 768px;
$medium_screen_up: 769px;
$large_screen: 1024px;
$large_screen_up: 1025px;
$mlarge_screen_down: 1439px;
$mlarge_screen: 1440px;
$xlarge_screen: 1479px;
$xlarge_screen_up: 1480px;
$xxlarge_screen: 1920px;

// Container
$container-xxl: $xxlarge_screen;
$container-xl: $xlarge_screen_up;
$container-lg: $large_screen_up;
$container-md: $medium_screen_up;
$container-sm: $small_screen_up;

$xsmall_and_down: "(max-width: #{$xsmall_screen})";
$small_and_down: "(max-width: #{$small_screen})";
$small_and_up: "(min-width: #{$small_screen_up})";
$small_to_medium: "(min-width: #{$small_screen_up}) and (max-width: #{$medium_screen})";
$small_to_large: "(min-width: #{$small_screen_up}) and (max-width: #{$large_screen})";
$medium_and_down: "(max-width: #{$medium_screen})";
$medium_and_up: "(min-width: #{$medium_screen_up})";
$medium_to_large: "(min-width: #{$medium_screen_up}) and (max-width: #{$large_screen})";
$medium_to_xlarge: "(min-width: #{$medium_screen_up}) and (max-width: #{$xlarge_screen})";
$large_and_down: "(max-width: #{$large_screen})";
$large_and_up: "(min-width: #{$large_screen_up})";
$large_to_mlarge: "(min-width: #{$large_screen_up}) and (max-width: #{$mlarge_screen_down})";
$large_to_xlarge: "(min-width: #{$large_screen_up}) and (max-width: #{$xlarge_screen})";
$xlarge_and_down: "(max-width: #{$xlarge_screen})";
$mlarge_and_down: "(max-width: #{$mlarge_screen_down})";
$mlarge_and_up: "(min-width: #{$mlarge_screen})";
$xlarge_and_up: "(min-width: #{$xlarge_screen_up})";
$xxlarge_and_up: "(min-width: #{$xxlarge_screen})";

$tablet_to_mobile: "(min-width: #{$small_screen_up}) and (max-width: #{$medium_screen_up})";
$tablet: "(max-width: #{$large_screen_up})";
$mobile: "(max-width: #{$medium_screen_up})";

// Font Family
$font-family: "Archivo";
$urbane-rounded: "Urbane Rounded";
$inter: "inter";

// Modal Max Width
$modal-width: 850px;

// Sizes based on screen viewport
$responsive-size-20: clamp(1rem, 0.9383rem + 0.2597vw, 1.25rem);
$responsive-size-24: clamp(1rem, 0.8766rem + 0.5195vw, 1.5rem);
$responsive-size-32: clamp(1rem, 0.7532rem + 1.039vw, 2rem);
$responsive-size-48: clamp(1rem, 0.6098rem + 1.9512vw, 3rem);

$mobile-responsive-size-24: clamp(1rem, 0.415rem + 2.26vw, 1.5rem);

$icon-size: 24px;
$icon-size-collection-web: 160px; //makes the svg > path 133.33
$icon-size-collection-mobile: 192px;


$grey: #dddddd;