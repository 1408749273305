.forgotpassword {
    &-wrapper {
        display: flex;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;

        .nav-link{
            font-weight: $font-weight-bold;
            &:not(.disabled){
                color: $sunset-orange;
            }
            .spinner-border{
                width: sizer(1);
                height: sizer(1);
            }
            &.disabled:hover{
                color: #989595;
            }
        }

        .ring-icon {
            width: 80px;
            height: 80px;
            float: right;
            margin-bottom: 82px;
            margin-right: 412px;
            position: absolute;
            top: auto;
            bottom: 0%;
            right: auto;
            left: -40px;
            z-index: 1;
        }
    }
    &-left-cont {
        background-color: $white;
        position: relative;
        //overflow-y: auto;

        @media #{$mobile} {
            &.col-5 {
                width: 100vw;
                flex: unset;
                margin: sizer(1.5);
                box-sizing: border-box;

                .forgotpassword {
                    &-form {
                        padding: unset;

                        // success message
                        &:has(.message-container > .message-group) {
                            .forgotpassword-header {
                                margin-bottom: sizer(4);
                            }
                            .message-container {
                                margin-bottom: sizer(2.5);

                                .message-group {
                                    position: relative;
                                    margin-bottom: unset !important;
                                }
                            }
                        }

                        // error message
                        &:has(.message-container > .message-group-warning) {
                            .forgotpassword-header {
                                margin-bottom: sizer(4);
                            }
                            .message-container {
                                margin-bottom: sizer(2.5);

                                .message-group-warning {
                                    position: relative;
                                    margin-bottom: unset !important;
                                    padding-left: sizer(1);

                                    // div:last-child {
                                    //     transform: translateY(5px);
                                    // }
                                }
                            }
                        }

                        & > .content-text {
                            padding-bottom: sizer(2.5);
                        }

                        .form-container {
                            padding-bottom: sizer(1.5);

                            .form-group {
                                margin-bottom: sizer(1);
                            }

                            .new-password-criteria {
                                padding: unset;
                                margin-bottom: unset;

                                .eightchar,
                                .lowercase,
                                .uppercase {
                                    display: flex;
                                    align-items: center;

                                    svg {
                                        margin-right: sizer(.5);
                                    }
                                }

                                .uppercase { padding-bottom: sizer(2.5);}
                            }

                            .reenterpassword {
                                // display: none;
                                padding-bottom: sizer(1.5);
                                margin: unset;
                            }

                            &:has(.reenterpassword > .reenterpassword-warning) {
                                .reenterpassword {
                                    transform: translateY(-12px);
                                    padding-bottom: 0;

                                    p {
                                        margin: unset;
                                    }
                                }
                            }

                            input.warning-input { border-color: $candy-red !important; }

                            .successful-content-text { padding-bottom: sizer(2.5); }

                            .content-text + p.mb-0 { margin-bottom: sizer(1) !important; }
                            .button-group + p.PaddingTop48 { display: none; }
                        }

                        .button-group {
                            padding-bottom: sizer(1.5);

                            &.PaddingTop48 {
                                padding-top: sizer(1.5);
                            }
                        }
                    }

                    &-header {
                        padding-bottom: sizer(1.5);
                        margin-bottom: sizer(2.5);

                        img {
                            width: 122px;
                            padding-right: unset;
                        }
                    }

                    &-message {
                        padding-bottom: 52px;
                    }
                }

                .forgot-password-cont {
                    padding: unset;
                }
            }

        }

    }
    &-right-cont {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $white;
        border-left: 1px solid black;

        svg {
            width: 100%;
        }

        @media #{$mobile} {
            display: none;
        }
    }

    &-header {
        margin-bottom: sizer(3);
        padding: 0 sizer(1.5) 0;
        @media #{$small_and_up} {
            & {
                position: absolute;
                top: 0;
                left: 0;
                padding: sizer(1.5) sizer(8) sizer(1.5) sizer(6);
                background-color: $white;
                width: 100%;
            }
        }
      

        @media #{$large_and_down} {
            margin-bottom: sizer(1.5);
            padding: sizer(1.5);
        }

        .dam-logo-img {
            max-width: 100%;
            height: 48px;
            padding-right: sizer(.5);
            width: auto;
        }
    }

    &-form {
        // max-width: $large_screen;
        margin: auto;
        padding: 200px sizer(1.5) sizer(8.25) sizer(1.5);
        background: #F5F5F5;
        min-height: 100vh;
        .general-container {
            padding: sizer(1.5);
            @media #{$small_and_up} {
                & {
                    width: 560px;
                    margin: 0 auto;
                    background: $white;
                    border-radius: 20px;
                    padding: sizer(3);
                    box-shadow: 0px 1px 10px 0px rgba(176, 176, 176, 0.12), 0px 4px 5px 0px rgba(150, 150, 150, 0.14), 0px 2px 4px 0px rgba(151, 151, 151, 0.15);
                   
                
                }
            }
            h1.title-text{
                text-align: center;
            }
        }
        
        .forgotpassword-message {
            padding-bottom: sizer(1.5);
            margin-bottom: 0 !important;
        }

        .message-container {
            position: relative;
            // padding-top: sizer(3);
            // margin-bottom: sizer(3);
            display: flex;

            .alert-notification {
                position: relative;
                width: 100%;
                top: 0;
                left: 0;
                margin-top: 0;

                @media #{$large_and_down} {
                    margin: 0 0 48px 0;
                }
            }
        }

        .message-group {
            position: relative;
            top: 0;
            left: 0;
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;

            background-color: $light-green;
            padding: sizer(1.25) sizer(1);
            border-left: $leafy-green sizer(.5) solid;

            width: 100%;
            display: flex;
            gap: 10px;
            margin-bottom: sizer(3);
            .warning-icon {
                color: $leafy-green;
            }
        }

        .message-group-warning {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;

            background-color: $dawn-pink;
            padding: sizer(1.25) sizer(1);
            border-left: $candy-red sizer(.5) solid;

            width: 100%;
            display: flex;
            gap: 10px;

            margin-bottom: sizer(3);

            .warning-icon {
                color: $candy-red;
            }
        }

        .content-text {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            //padding-bottom: sizer(3);
            margin-bottom: sizer(1.5);
            &.verification {
                @media #{$large_and_down} {
                    margin-bottom: sizer(2.5);
                }
            }
        }

        .successful-content-text {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            padding-bottom: sizer(3);
            margin-bottom: unset;
        }

        .form-container {
            padding-bottom: sizer(3);

            .PaddingTop48 {   
                &.verification {
                    @media #{$large_and_down} {
                        padding-top: sizer(2.5);
                    }
                }
            }

            @media #{$large_and_down} {
                padding-bottom: sizer(2.5);
            }

            .link-bold {
                color: $sunset-orange   ;
                text-decoration-line: none;
                font-weight: bold;
            }
        }

        .forgot-password-cont {
            padding-top: sizer(1.5);
            padding-bottom: sizer(.5);
            text-align: center;
            justify-content: center;
            a {
                padding: unset;
            }
        }
        .form-group:focus-within label {
            color:$black;
          }
        .form-group.reenterpassword {
                padding-bottom: sizer(3);
                margin-bottom: unset;

                @media #{$large_and_down} {
                    padding-bottom: sizer(2.5);
                }
            }
        .form-group {
            margin-bottom: sizer(0.25);
            display: flex;
            flex-direction: column;
            position: relative;
            &.verification-code{
                margin-bottom: 0;
                .error-msg {
                    font-weight: 700;
                }
                &.form-error{
                    &:focus-within label {
                        color: $candy-red !important;
                    }
                    input:focus {
                        border-color: $candy-red !important;
                    }
                }
            }
            &.new-password-criteria{
                padding: sizer(1) 0;
            }

            input:focus {
                border-color: $black !important;
            }

            input.warning-input:focus {
                border-color: $candy-red !important;
            }

            .resend-code-cont {
                .resendcode-link {
                    padding: 0rem;
                    padding-bottom: 0;
                }
            }

            .eightchar, .lowercase, .uppercase {
                color:$spanish-gray;
                margin-bottom: 0.5rem;
            }

            .uppercase {
                padding-bottom: sizer(0.5);
                margin-bottom: unset;

                @media #{$large_and_down} {
                    padding-bottom: sizer(2.5);
                }
            }

            .newpassvalid {
                color:$black;
                svg {
                    color:$leafy-green
                }
            }

            .warning-label {
                color: $candy-red !important;
            }

            .reenterpassword-warning {
                color:$candy-red;
                font-weight: $font-weight-bold;
                svg {
                    color:$candy-red
                }
            }

            .label-container {
                margin-bottom: 5px;
              }

            label {
                position: absolute;
                top: sizer(-0.688);
                left: sizer(1.25);
                padding: 4px;
                line-height: sizer(1);
                background-color: $white;
                font-size: $font-size-12;
                // color: $primary;

            }

            .error {
                margin-top: sizer(.5);
            }

            input {
                font-size: $font-size-16;
                font-weight: $font-weight-normal;
                line-height: $line-height-24;
                color: $black;
                padding: sizer(1.5);
                // border: sizer(0.0625) $chinese-white solid !important;
            }
        }

        .button-group {
            display: flex;
            align-items: center;
            button{
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
            }
            &.PaddingTop48 {   
                &.verification {
                    @media #{$large_and_down} {
                        padding-top: sizer(2.5);
                    }
                }
            }
        

            .spinner-border {
                border-color: $primary;
                border-right-color: transparent;
                margin-left: sizer(1);
            }
        }
    }

    @media #{$large_and_down} {
        &-cont {
            margin: auto sizer(5);
        }
    }

    @media #{$large_and_down} {
        &-form {
            // margin: 0 sizer(1.5);
            padding: sizer(8) 0 sizer(2);
        }
    }
    @media #{$small_and_down} {
        &-cont {
            margin: 0;
            padding: sizer(2) sizer(2.5);
        }

        &-form {
            //margin: 0;
            padding: 0 0 sizer(2);
            background-color: $white;
        }
    }
}