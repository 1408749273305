.audit-trail {
    @media #{$tablet} {
        hr {
            display: none;
        }

        table {
            transform: translateX(-24px);
            width: 100%;
            margin-top: sizer(1.5);

            .ant-table-custom-cell {
                justify-content: space-between;

                button {
                    background: transparent;
                    border: 0;
                    padding: 0;
                }
            }

            span {
                color: $black;

                &.ant-checkbox-inner {
                    border-color: $black;
                }
            }

            thead {
                display: none;
            }

            tbody {
                tr {
                    border-bottom: 1px solid $chinese-white;
                    position: relative;
                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 0.5px;
                    width: calc(100% + 96px);
                    background-color: $chinese-white;
                    transform: translateX(-24px);
                  }
                }
            }

        }

        .custom-ant-table.ant-table-wrapper .ant-table-tbody>tr {
            >td {
                padding: sizer(1) sizer(1.5);
                transform: translateX(-42px);
                border-bottom: 1px solid $chinese-white;
                // &.mobile-col{
                //     border-bottom: 1px solid $chinese-white;
                // }
            }

            &.ant-table-expanded-row .ant-table-custom-expanded-cell {
                &::before {
                    height: 78px;
                    top: -79px;
                }

                .custom-box {
                    display: none;
                }

                .expand-data {
                    padding: 0 !important;
                }
            }
        }

        .mobile-cell {
            display: flex;
            flex-direction: column !important;
            .mobile-tag-date {
                display: flex;
                align-items: center;

                .ant-tag-container {
                    .ant-tag {
                        padding: sizer(0.25) sizer(0.5);
                        font-size: $font-size-10;
                        line-height: $line-height-14;
                    }
                }

                span {
                    margin-bottom: 0;
                    margin-right: sizer(0.5) !important;
                }

                .audit-date {
                    color: $spanish-gray;
                }
            }
        }

        .modal-period {
            &.mobile-modal-more-vertical button {
                padding: 0.75rem 0.5rem !important;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    >h2 {
        margin-bottom: sizer(3);
    }

    &-settings {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media #{$tablet} {
            height: 42px;
            gap: sizer(1);
            &::after {
                content: "";
                position: absolute;
                width: 120%; //63%;
                height: 1px;
                bottom: -2px;
                background-color: $chinese-white;
                transform: translateX(-48px);
                ;

                @media screen and (max-width: 500px) {
                    width: 500px;
                }
            }
        }

        .category-dropdown {
            position: relative;

            .dropdown-button-container {
                button {
                    border: 0;
                    background: transparent;
                    display: flex;
                    gap: sizer(0.5);
                    align-items: center;
                    padding: 0;

                    h3 {
                        margin-bottom: 0;
                    }
                    .rotate-arrow{
                        transform: rotate(180deg);
                    }
                }

                @media #{$tablet} {
                    button {
                        gap: 4px;
                    }
                }
            }

            &-menu {
                padding: 1rem;
                border: 1px solid $black;
                border-radius: 20px;
                font-family: "inter";
                position: absolute;
                background: $white;
                z-index: 10;
                top: 40px;

                @media #{$tablet} {
                    position: relative;
                    border: 0;
                    top: unset;
                    padding: 0;

                    ul {
                        margin-bottom: sizer(1.5) !important;

                        li {
                            padding: sizer(0.75) 0 !important;

                            .category-form-check-input {
                                input {
                                    width: 24px;
                                    height: 24px;
                                }
                                label{
                                    margin-left: sizer(0.5);
                                }
                            }

                            &:hover {
                                background: transparent !important;
                            }
                        }
                    }

                    .button-container {
                        height: 74px;
                        padding: sizer(0.75) 0;

                        &::before {
                            content: "";
                            height: 74px;
                            width: 115%;
                            position: absolute;
                            bottom: 0px;
                            background: $light-gray;
                            left: 0;
                            transform: translateX(-25px);
                        }

                        button {
                            z-index: 1;
                            width: 100%;
                            background: $black !important;
                            border-radius: sizer(2.5);
                            padding: sizer(0.75) sizer(3);

                            span {
                                color: $white !important;
                                text-align: center;
                                font-size: $font-size-18;
                                font-weight: $font-weight-medium !important;

                                &:hover {
                                    opacity: 1 !important;
                                }
                            }

                            &:active {
                                background: $black !important;
                            }
                        }
                    }
                }

                ul {
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style: none;
                        padding: sizer(0.5);
                        cursor: pointer;
                        overflow: hidden;

                        .category-form-check-input {
                            margin-bottom: 0;

                            label {
                                font-size: $font-size-16;
                                font-weight: $font-weight-normal;
                            }

                            input {
                                border-color: $black;
                            }
                        }

                        &:hover {
                            background: $light-gray;
                            border-radius: 8px;
                        }
                    }
                }

                hr {
                    margin: 8px;
                }

                .button-container {
                    display: flex;
                    justify-content: center;

                    button {
                        border: 0;
                        background: transparent;
                        padding: 0;

                        span {
                            color: $sunset-orange;
                            text-align: center;
                            font-weight: $font-weight-bold;

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        .days-export-container {
            display: flex;
            gap: sizer(2.5);

            @media #{$tablet} {
                gap: sizer(1);
                button {
                    border: 0;
                    background: transparent;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    h3 {
                        margin-bottom: 0;
                    }
                }
            }

            .select-days {
                // display: flex;
                // align-items: center;
                // gap: 8px;

                .ant-select-selector {
                    // padding: 0;

                    span {
                        font-weight: $font-weight-medium;
                        line-height: $line-height-28;
                        padding-inline-end: 24px;
                    }
                }

                .ant-select-arrow {
                    // position: relative;
                    // left: 0;
                    // top: 35%;
                    transform: translate(10px, -50%);

                    svg {
                        path {
                            fill: $black;
                            &:hover{
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            .export-container {
                display: flex;
                align-items: center;
                gap: sizer(0.5);
                cursor: pointer;

                h3 {
                    margin-bottom: 0;
                }
            }
        }
    }

    hr {
        margin-top: 8px;
        margin-bottom: sizer(3);
    }

    .audit-table-skeleton {
        .audit-header-skeleton {
            background-color: $black;
            color: $white;
        }

        >span {
            display: block;
            margin-bottom: sizer(1);

            +div {
                background-color: $black;
                color: $white;

                >div {
                    border-bottom: 0;

                    @media screen and (min-width: $large_screen_up) and (max-width: 1500px) {

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(5) {
                            flex-grow: unset;
                        }
                    }
                }
            }

        }

        >div {
            display: flex;
            flex-direction: row;

            >div {
                padding: sizer(1.25) sizer(1.5) sizer(1.25) sizer(3);
                //flex-grow: 1;
                border-bottom: 1px solid $chinese-white;

                &:nth-child(0) {
                    width: 10px;
                }

                &:nth-child(1) {
                    width: 292px;
                    @media screen and (min-width: 1025px) and (max-width: 1700px) {
                        min-width: 280px;
                        max-width: 280px;
                    }
                    @media screen and (min-width: 1025px) and (max-width: 1540px) {
                        min-width: 260px;
                        max-width: 260px;
                    }
                    @media #{$tablet}{
                        width: 100%;
                    }
                }
                &:nth-child(2){
                    width: 240px;
                    padding-left: 0;
                    @media screen and (max-width: 1700px) {
                        min-width: 180px;
                        max-width: 180px;
                    }
                    @media screen and (max-width: 1540px) {
                        min-width: 170px;
                        max-width: 170px;
                    }
                }
                &:nth-child(3) {
                    width: 260px;
                    padding-left: 0;
                    @media screen and (max-width: 1700px) {
                        min-width: 262.8px
                    }

                    @media screen and (max-width: 1540px) {
                        min-width: 222.64px;
                        max-width: 222.64px;
                    }

                  
                }

                &:nth-child(4) {
                    width: 560px;
                    padding-left: 0;
                }

                &:nth-child(5) {
                    width: 60px;
                    padding-left: 0 !important;
                    padding: sizer(1.25) sizer(1.5);
                    @media screen and (max-width: 1630px) {
                        max-width: 100px;
                    }
                }
            }
        }

        @media #{$tablet} {
            width: 100%;

            >span+div {
                background-color: transparent;
                color: #ffffff;
            }

            >div>div {
                position: relative;
                padding: 1rem 0;
                border: 0;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: -24px;
                    width: calc(100% + 48px);
                    height: 1px;
                    background-color: $chinese-white;
                }
            }
        }
    }

    &-table {
        table {
            
            tr {
                &.ant-table-expanded-row {
                    td.ant-table-cell{
                        padding: 0 !important;
                    }
                }
                th {
                    &:nth-child(2){
                        min-width: 292px;
                        max-width: 292px;
                        @media screen and (max-width: 1700px) {
                            min-width: 280px;
                            max-width: 280px;
                        }
                        @media screen and (max-width: 1540px) {
                            min-width: 260px;
                            max-width: 260px;
                        }
                    }
                    &:nth-child(3){
                        min-width: 240px;
                        max-width: 240px;
                        padding-left: 0 !important;
                        @media screen and (max-width: 1700px) {
                            min-width: 180px;
                            max-width: 180px;
                        }
                        @media screen and (max-width: 1540px) {
                            min-width: 170px;
                            max-width: 170px;
                        }
                    }
                    &.col-user {
                        padding-left: 0 !important;
                        min-width: 260px;
                        max-width: 260px;
                        @media screen and (max-width: 1700px) {
                            min-width: 260px;
                            max-width: 260px;
                        }
                        @media screen and (max-width: 1540px) {
                            min-width: 250px;
                            max-width: 250px;
                        }
                    }

                    &.col-activity {
                        width: 560px;
                        padding-left: 0 !important;
                    }

                    &.col-action {
                        width: 65px;
                        padding-left: 0 !important;
                        button {
                            background: transparent;
                            border: 0;
                        }
                    }

                    @media screen and (min-width: 1025px) and (max-width: 1540px) {
                        padding: sizer(1.25) sizer(1) !important;
                    }
                }

                td {
                    .ant-table-custom-cell{
                        overflow: auto;
                        width: max-content !important;
                        
                        @media #{$small_and_down} {
                            width: min-content !important;
                        }
                    }
                    @media screen and (min-width: 1025px) and (max-width: 1540px) {
                        padding: sizer(1.25) sizer(1) !important;
                    }
                    &:nth-child(3), 
                    &:nth-child(4),  
                    &:nth-child(5),  
                    &:nth-child(6){
                        padding-left: 0 !important;
                    }
                    &:nth-child(5){
                        .ant-table-custom-cell{
                            width: calc(100% + 19rem) !important;
                            @media screen and (max-width: 1850px) {
                                width: calc(100% + 5rem) !important;
                            }
                            @media screen and (max-width: 1500px) {
                                width: calc(100% + 2.5rem) !important;
                            }
                            @media screen and (max-width: 1300px) {
                                width: calc(100%) !important;
                            }
                        }
                    }
                   
                    .long-text-container {
                        display: flex;

                        p {
                            margin-bottom: 0;
                            font-weight: $font-weight-bold;

                            &:nth-child(1) {
                                width: auto;
                                max-width: 260px;

                                @media screen and #{$mlarge_and_down} {
                                    max-width: 220px;
                                }
                            }

                        }
                    }

                    .user-activity-container {
                        .activity-type {
                            width: 100%;
                            word-break: break-word;
                            white-space: pre-wrap;

                            span {
                                margin-right: sizer(0.3);

                                &.span-long-text:nth-child(2) {
                                    display: inline-block;
                                    width: 80%;

                                    span {
                                        display: inline-block;
                                        width: 100%;
                                    }
                                }

                                &::first-letter {
                                    text-transform: uppercase;
                                }

                                &.bold-activity {
                                    font-weight: $font-weight-bold;
                                }
                                &.user-activity{
                                    span.text-uppercase{
                                        margin: 0 !important;
                                    }
                                    @media #{$tablet} {
                                        text-transform: lowercase;
                                        span.text-uppercase{
                                            text-transform: uppercase !important;
                                        }
                                    }
                                }
                            }

                            ul {
                                margin-bottom: 0;
                                padding-left: 0;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;

                                li {
                                    display: flex;
                                    list-style: none;

                                    span {
                                        margin-bottom: 0 !important;

                                        &.span-long-text:nth-child(2) {
                                            display: inline-block;
                                            width: 100%;

                                            span {
                                                display: inline-block;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.ant-table-row-expand-icon-cell {
                        display: none;
                    }

                    .ant-tag-container {
                        .ant-tag {
                            border-radius: sizer(2.5);
                            padding: sizer(0.25) sizer(1);
                            margin: 0;
                        }
                    }

                    &.col-action {
                        padding-right: sizer(3) !important;

                        button {
                            background: transparent;
                            border: 0;
                        }
                    }

                    .ant-table-custom-expanded-cell {
                        display: flex !important;
                        padding: sizer(1) 0;
                        > div span{
                            display: unset !important;
                        }
                        &::before {
                            top: -65px !important;
                            @media screen and (max-width: 1540px) {
                                height: 78px !important;
                                top: -75px !important;
                            }
                            @media #{$tablet} {
                                top: -79px !important;
                            }
                        }

                        @media #{$tablet} {
                            width: 100%;
                            display: block !important;
                            padding: sizer(1) sizer(1.5);
                        }

                        .custom-box {
                            width: 100%;
                            &:nth-child(1){
                                min-width: 292px;
                                max-width: 292px;
                                @media screen and (max-width: 1700px) {
                                    min-width: 280px;
                                    max-width: 280px;
                                }
                                @media screen and (max-width: 1540px) {
                                    min-width: 260px;
                                    max-width: 260px;
                                }
                            }
                            &:nth-child(2){
                                padding-left: 0 !important;
                                min-width: 240px;
                                max-width: 240px;
                                @media screen and (max-width: 1700px) {
                                    min-width: 180px;
                                    max-width: 180px;
                                }
                                @media screen and (max-width: 1540px) {
                                    min-width: 170px;
                                    max-width: 170px;
                                }
                            }
                            &:nth-child(3){
                                min-width: 260px;
                                max-width: 260px;
                                @media screen and (max-width: 1700px) {
                                    min-width: 260px;
                                    max-width: 260px;
                                }
                                @media screen and (max-width: 1540px) {
                                    min-width: 250px;
                                    max-width: 250px;
                                }
                            }
                        }

                        .expand-data {
                            width: 100%;
                            padding-left: 0 !important;
                            padding-right: 3rem;
                            span {
                                word-break: break-word;
                                white-space: pre-wrap;
                            }
                        }

                    }
                }
            }
        }
    }

    .audit-empty-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .image-container {
            width: 100%;
            max-width: 307.34px;
            margin-bottom: sizer(3);

            img {
                width: 100%;
            }
        }

        h2 {
            margin-bottom: sizer(1);
        }

        @media #{$tablet} {
            margin-top: sizer(2.5);

            .image-container {
                max-width: 230.5px;
                margin-bottom: sizer(2.5);
            }

            >div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .ant-pagination {
        display: none;
    }
}