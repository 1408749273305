.react {
    &-pdf {
        &__Document,
        &__Page {
            width: 100%;
            height: 100%;
            overflow: auto;
            border-bottom: none !important;
            border: 1px solid $chinese-white;
        }
        &__Page__canvas {
            margin: 0 auto;
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
        }
    }
}