.fslightbox-open {
    .back-to-top {
        z-index: -1;
    }
}
.back-to-top {
    position: fixed;
    bottom: sizer(6);
    right: sizer(3);
    font-size: $font-size-12;
    line-height: $line-height-16;
    color: $spanish-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 9;
    opacity: 0;
    transition: all .25s ease-in-out;

    @media #{$mobile} {
        display: none;
    }

    &.active {
        opacity: 1;
    }
    &:hover {
        color: $primary;

        > div {
            background-color: $primary;
        }
        svg {
            animation: animateBackToTop 1s ease-in-out infinite;
        }
    }

    > div {
        border-radius: $border-radius-12;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $spanish-gray;
        margin-bottom: sizer(.5);
        transition: all .25s ease-in-out;
    }

    svg {
        width: sizer(1);
        height: sizer(1);

        path {
            fill: $white;
        }
    }
}

@keyframes animateBackToTop {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(3px);
    }
}