.bookmark-modal {
    .bookmark-names {
        min-height: auto;
        max-height: 200px;
        overflow-y: auto;

        .form-check {
            min-height: sizer(2.5);
            &-input {
                transition: none !important;
            }
        }
        input {
           padding: sizer(0.5) 0;   
           width: 24px !important;
           height: 24px !important;

           @media (max-width: 768px) {
            padding: 0 !important;
            
           }
        }
        label {
            font-weight: $font-weight-inter-regular;
            font-size: $font-size-16;
            line-height: $line-height-24;
            padding: 0;

            @media (max-width: 768px) {
                top: 0 !important;
                left: 0 !important;    
            }
        }

        &::-webkit-scrollbar {
            width: sizer(.5);
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-track {
            background: $bright-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb {
            background: $spanish-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $spanish-gray;
        }
    }
    .create-btn {
        width: auto;
        color: $sunset-orange;
        padding: 0;
        margin-top: sizer(1.5);
        border: none;
        background-color: transparent;
        
        display: flex;
        gap: sizer(0.5);
        cursor: pointer;
        flex-direction: row;
        justify-content: left;    
        
        &:hover,
        &:hover svg,
        &:hover p {
            opacity: 0.5;
            color: $sunset-orange;
        }
        &:hover svg path {
            fill: $sunset-orange !important;
        }

        svg {
            align-self: self-start;
            &:hover {
                opacity: 0.5;
            }
        }
        p {
            margin: 0;
            font-weight: $font-weight-inter-bold;
        }
    }
    
    .modal-content {
        width: 366px !important;
        padding-top: sizer(2);
        border-radius: 20px;
        .modal-header {
            margin: 0;
            padding: 0 0 sizer(0.5) sizer(1.5);
        }
        .modal-body {
            align-content: center;
            width: 100%;
            padding:  sizer(1.5) sizer(1.5) !important;

            .bookmark-input-wrapper {
                display: flex;
                flex-direction: column;

                .form-group {
                    width: 100%;

                    &.input {
                        margin-top: 24px;
                        .input-counter-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            color: $spanish-gray;
                            
                            p {
                                margin: 0;
                                font-size: $font-size-12;
                                font-weight: $font-weight-inter-regular;
                            }
    
                            .error-label {
                                color: $candy-red;
                                display: flex;
                                flex-direction: row;
                                gap: 8px;

                                p {
                                    font-size: $font-size-16;
                                    font-weight: $font-weight-inter-bold;
                                }
                                
                                svg {
                                    color: $candy-red !important;
                                }
                            }
                            
                            .limit-label {
                                &.hidden {
                                    visibility: hidden;
                                }
                            }

                            &.hidden {
                                visibility: hidden;
                            }
                        }

                        &.form-error {
                            border-color: $candy-red;
                            label {
                                color: $candy-red;
                            }
                            input {
                                color: $black;
                            }
                        }

                        &.single {
                            margin: 0 !important;
                        }
                    }
                }
            }
        }
        .modal-footer {
            padding: sizer(0.5) sizer(1.5);
            justify-content: center;
            display: flex;
            flex-direction: column;
            gap: sizer(0.75);
            
            button {
                width: 100%;
                justify-content: center;
            }

            .btn-secondary {
                background-color: transparent !important;
            }

            @media (max-width: $large_screen_up) {
                flex-direction: column !important;

            }
        }
    }
}