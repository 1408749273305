.toast {
    @media #{$mobile} {
        width: 366px;
        left: 50%;
        transform: translateX(-50%);
        .toast-header {
            padding: sizer(1) sizer(1.25);
        }
    }
    position: fixed;
    bottom: sizer(2.5);
    left: sizer(2.5);
    z-index: 99999;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;

    &-success,
    &-error {
        .toast-header {
            span {
                display: flex;
                align-items: center;
            }
            svg {
                width: 24px;
                height: 24px;
                padding: 0;
                margin-right: sizer(.5);
            }

            .icon-button {
                background-color: $black;

                &:active {
                    background-color: $black !important;
                }
                &:hover {
                    svg {
                        path {
                            fill: $white !important;
                        }
                    }
                }
                svg {
                    width: 18px;
                    height: 18px;
                    padding-right: 0;
                    margin: 0;

                    path {
                        fill: $white !important;
                    }
                }
            }
        }
    }

    &-success {
        .toast-header {
            background-color: $light-green;
            color: $black;

            span {
                svg {
                    path{
                        fill: $leafy-green;
                    }
                }
            }
        }
    }

    &-error {
        .toast-header {
            background-color: $dawn-pink;
            color: $black;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 2px 8px 4px 0 rgba(0, 0, 0, 0.04);
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: $border-radius-20;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.07);
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: $border-radius-20;
    }

    &-header{
        justify-content: space-between;
        background-color: $black;
        border-radius: $border-radius-20;
        padding: sizer(1.5) sizer(1.25);
        color: $white;
        font-size: $font-size-16;
        line-height: $line-height-24;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon-button {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin-left: sizer(1);
            display: flex;
            align-items: center;
            background-color: $white;
            padding: 0;
            justify-content: center;

            &:active {
                background-color: $white !important;
            }
            &:hover {
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
            svg {
                width: 18px;
                height: 18px;
                padding-right: 0;

                path {
                    fill: $black;
                }
            }
        }

        .btn-close {
            display: none;
        }
    }
}