.notification-btn {
    position: relative;

    @media (max-width: #{$large_screen}) {
        height: 32px;
        width: 32px;
        margin-right: 32px;

         .icon-button{
            height: 32px;
            width: 32px;
            margin: 0;
         }
    }
    
    .counter {
        position: absolute;
        top: 3px;
        left: 15px;

        display: flex;
        vertical-align: middle;
        justify-content: center;


        height: 20px;
        width: 28px;
        border-radius: 40px;
        border: 1px solid white;
        background-color: $sunset-orange;
        font-size: $font-size-12;
        line-height: 18px;
        font-weight: 700;
        color: white;
    }
}

.notificationBox {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 51px;
    right: -50px;

    @media #{$medium_to_large}{
        top: 51px;
        right: -46px;
    }

    width: 414px;
    height: calc(90vh - 51px);
    border-radius: 20px;
    border: 1px solid black;
    background-color: white;
    z-index: 10;

    @media #{$mobile} {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100vw;
        height: 100vh;
        border: none;
        border-radius: 0;
    }

    header {
        display: flex;
        justify-content: space-between;
        vertical-align: middle;
        height: 60px;
        width: 100%;
        border-bottom: 1px solid $chinese-white;

        @media #{$mobile}{
            justify-content: flex-start;
            height: 74px;
        }

        h1{
            font-family: $inter;
            padding: 24px 24px 8px 24px;
            font-size: $font-size-20;
            line-height: $line-height-28;
            margin: 0;

            @media #{$mobile}{
                padding: 24px 24px 24px 8px;
                font-size: $font-size-18;
                line-height: $line-height-26;
            }
        }

        .close-btn {
            padding: 26px 24px 10px 24px;

            @media #{$mobile}{
                display: none;
            }
        }

        .back-btn {
            display: none;
            align-items: center;
            padding: 24px 0px 24px 24px;

            @media #{$mobile}{
                display: flex;
            }
        }
    }

    main {
        height: auto;
        overflow: auto;

        /* Hide scrollbar for Chrome, Safari, and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge, and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        /* Style scrollbar track and thumb */
        scrollbar-width: none;

        .empty-notif {
            display: flex;
            flex-direction: column;
            vertical-align: middle;
            margin-top: 85px;
            img {
                height: 225px;
            }
            h1{
                text-align: center;
                margin-top: 17px;
                margin-bottom: 0;
                font-size: $font-size-28;
                line-height: $line-height-36;
            }
            p {
                text-align: center;
                margin-top: 16px;
                font-size: $font-size-16;
                line-height: $line-height-24;
            }
        }
    }


    .pointer {
        height: 20px;
        position: absolute;
        top: -17px;
        right:13px;

        @media #{$mobile}{
            display: none;
        }
    }

    button {
        background-color: transparent;
        color: #000000;
        border: 0;
    }

    .notification-period {
        header {
            height: 40px;
            border: none;
            
            h2{
                font-family: $inter;
                padding: 16px 24px 0px 24px;
                font-size: $font-size-16;
                line-height: $line-height-24;
                font-weight: 700;
                margin: 0;
            }
        }

        .notification-item {
            padding: 16px 24px;
            display: flex;
            border-bottom: 1px solid $chinese-white;

            &.unseen {
                background-color: rgba(245, 245, 245, 1);
            }

            &.join {
                .icon-bg {
                    background-color: rgba(217, 246, 255, 1);
                }
            }

            &.trial {
                .icon-bg {
                    background-color: rgba(244, 240, 255, 1);
                }
            }

            &.reset2fa {
                .icon-bg {
                    background-color: rgba(255, 235, 242, 1);
                }
            }

            .icon {
                width: 15%;
                min-width: 56px;

                .icon-bg {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    justify-content: center;
                    height: 48px;
                    width: 48px;
                    border-radius: 48px;

                    //for checking center
                    element::before,
                    element::after {
                    content: '';
                    position: absolute;
                    background: red;
                    }

                    element::before {
                    top: 50%;
                    left: 0;
                    right: 0;
                    height: 1px;
                    transform: translateY(-50%);
                    }

                    element::after {
                    left: 50%;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    transform: translateX(-50%);
                    }

                    & > span {
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        height: 48px !important;
                        width: 48px !important;
                        border-radius: 48px;

                        & > span {
                            height: 48px !important;
                            width: 48px !important;
                            border-radius: 48px;
                        }
                    }
                    
                }

                .icon-img {
                    height: 24px;
                    width: 24px;
                }
            }

            .details {
                display: flex;
                flex-direction: column;
                width: 85%;

                p {
                    font-family: $inter;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: 400;
                    margin: 0;
                }

                .notification-age {
                    font-size: $font-size-12;
                    line-height: $line-height-16;
                    font-weight: 500;
                    color: $spanish-gray;
                }

                .notif-btn {
                    height: 40px;
                    width: 112px;
                    border-radius: 40px;
                    background-color: black;
                    color: white;
                    margin-top: 8px;
                    font-family: $inter;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: 500;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background-color: rgba(0,0,0,0.5);
                    }
                }
            }
        }
    }
}
