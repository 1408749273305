textarea {
    resize: none;
}
small {
    &.form-text {
        display: block;
        font-size: $font-size-12;
        line-height: $line-height-16;
        color: $spanish-gray;
    }
}

.form {
    &-privacy-policy {
        margin-bottom: sizer(3);

        p {
            margin: 0;

            a {
                text-decoration: underline;
                font-weight: $font-weight-medium;
                color: $black;
                &:hover{
                    color: $black !important;
                    opacity: 0.5 !important;
                }
            }
        }
    }
    &-error {
        .error-msg {
            color: $candy-red;
            display: flex;
            gap: sizer(.5);
            align-items: center;
            margin-top: sizer(.25);
            svg{
                @media #{$mobile}{
                    width: sizer(1.191);
                    height: sizer(1.063);
                }
            }
        }
        label, input {
            border-color: $candy-red;
            box-shadow: none;
            color: $candy-red;
        }
    }
    &-group {
        position: relative;

        input + .show-password-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: sizer(1.5);
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;

            & > svg { color: black; }
        }

        &.form-error {
            label, input {
                border-color: $candy-red;
                box-shadow: none;
                color: $candy-red;

                &:focus {
                    color: $black;
                }
            }

            .ant-select-customize-checkbox-tag {
                color: $candy-red;
            }
            .ant-select-single,
            .ant-select-multiple {
                &.ant-select-customize {
                    .ant-select-selector {
                        border-color: $candy-red;
                        color: $candy-red;

                        .ant-select-selection-item  {
                            color: $candy-red !important;
                        }
                    }
                }
            }
        }
        .form-check {
            label {
                position: relative;
                left: 0;
                top: 0;
                font-size: 16px;
                line-height: $line-height-24;
                color: $black;
            }

            input {
                &:checked {
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
        input {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;
            padding: sizer(1.5);
            border: 1px $chinese-white solid;
            background-color: transparent !important;

            &::placeholder {
                color: $spanish-gray;
            }
            &:-ms-input-placeholder {
                color: $spanish-gray;
            }
            &::-ms-input-placeholder {
                color: $spanish-gray;
            }

            &.warning-input {
                border-color: $candy-red;
            }

            @media #{$mobile} {
                padding-top: sizer(1) !important;
                padding-bottom: sizer(1) !important;
            }
            &:not([type="checkbox"]){
                @media #{$mobile}{
                    padding: sizer(1) sizer(1.5) !important;
                    //height: sizer(3.5);
                }
            }

        }

        label {
            position: absolute;
            top: -10px;
            left: sizer(1.25);
            background-color: $white;
            padding: 0 5px;
            font-size: $font-size-12;
            color: $black;
            z-index: 2;

            &.warning-label {
                color: $candy-red;
            }

            @media #{$mobile} {
                top: -0.78rem !important;
                left: 1.3rem !important;
            }
        }
        button.form-control {
            @media #{$mobile}{
                padding: sizer(1) sizer(1.5);
                height: sizer(3.5) !important;
            }
        }
    }
    &-search {
        position: relative;
        border-radius: $border-radius-12;

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: sizer(1.5);
            color: $primary !important;
        }
        input {
            padding-top: sizer(.5) ;
            padding-bottom: sizer(.5) ;
            padding-left: sizer(4) ;
        }
    }
    &-select {
        padding: sizer(1.5);
        border-radius: $border-radius-20;
        border-color: $chinese-white;
        font-size: $font-size-16;
        line-height: $line-height-24;
    }
    &-label {
        color: $spanish-gray;
    }
    &-control {
        border-color: $chinese-white;
        padding: sizer(1.5);
        border-radius: $border-radius-20;

        &:disabled {
            background-color: transparent !important;
        }
        &:focus {
            border-color: $primary;
            box-shadow: none;
        }
        .error, &[aria-invalid="true"] {
            border-color: $candy-red;
            box-shadow: none;
            color: $candy-red;
        }
        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: $spanish-gray;
            border-radius: 40px;
        }
        &::-webkit-scrollbar-track {
            background: $bright-gray;
            border-radius: 40px;
        }
    }
    &-check {
        display: flex;
        align-items: center;

        &-input {
            cursor: pointer;
            border: 2px solid $black;
            width: $icon-size;
            height: $icon-size;
            margin: 0;
            transition: .25s all ease-in-out;
            @media #{$mobile}{
                width: $mobile-responsive-size-24;
                height: $mobile-responsive-size-24;
            }
           &:disabled, &[disabled] {
                &~.form-check-label {
                    color: $chinese-white;
                    opacity: 1;
                }
            }
            + label {
                margin-left: sizer(1);
                font-size: $font-size-20;
                line-height: $line-height-28;
                font-family: $inter;
                font-weight: $font-weight-medium;
            }

            &-mixed {
                position: relative;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: $icon-size;
                    height: $icon-size;
                    vertical-align: top;
                    background-color: #fff;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    border: 1px solid $black;
                    border-radius: 0.25em;
                    pointer-events: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 6px;
                    top: 9px;
                    width: 10px;
                    height: 2px;
                    vertical-align: top;
                    background-color: $black;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    pointer-events: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                input {
                    opacity: 0 !important;
                };
            }

            &[type=checkbox] {
                position: relative;
                border: 1px solid $black;
            }
            &:checked {
                background-color: $primary !important;
                border-color: $primary !important;

                &[type=checkbox] {
                    border: none;
                    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cg clip-path="url(%23clip0_86_2218)"%3E%3Cpath d="M7.49987 13.4751L4.6082 10.5834C4.2832 10.2584 3.7582 10.2584 3.4332 10.5834C3.1082 10.9084 3.1082 11.4334 3.4332 11.7584L6.91654 15.2418C7.24154 15.5668 7.76654 15.5668 8.09154 15.2418L16.9082 6.4251C17.2332 6.1001 17.2332 5.5751 16.9082 5.2501C16.5832 4.9251 16.0582 4.9251 15.7332 5.2501L7.49987 13.4751Z" fill="white"%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_86_2218"%3E%3Crect width="20" height="20" fill="white"%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');
                    background-size: cover;
                    width: $icon-size;
                    height: $icon-size;
                }
                &[type=radio] {
                    border: 2px solid $black;
                    background-color: $white !important;
                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23000%27/%3e%3c/svg%3e");
                    width: $icon-size;
                    height: $icon-size;
                }
            }

            &:hover {
                opacity: .5;
                @media #{$mobile} {
                    opacity: unset;
                }
            }
            &:focus {
                box-shadow: none;
                border-color: grey;
            }
        }
        &-label {
            cursor: pointer;
        }
    }
    &-select {
        &:focus {
            box-shadow: none;
        }
    }
}

input[type="checbox"] {
    cursor: pointer;
    border: 1px solid $spanish-gray;
    width: $icon-size;
    height: $icon-size;
    margin: 0;
    transition: .25s all ease-in-out;

    &:checked {
        background-color: $primary !important;
        border-color: $primary !important;

        &[type=checkbox] {
            border: none;
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cg clip-path="url(%23clip0_86_2218)"%3E%3Cpath d="M7.49987 13.4751L4.6082 10.5834C4.2832 10.2584 3.7582 10.2584 3.4332 10.5834C3.1082 10.9084 3.1082 11.4334 3.4332 11.7584L6.91654 15.2418C7.24154 15.5668 7.76654 15.5668 8.09154 15.2418L16.9082 6.4251C17.2332 6.1001 17.2332 5.5751 16.9082 5.2501C16.5832 4.9251 16.0582 4.9251 15.7332 5.2501L7.49987 13.4751Z" fill="white"%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_86_2218"%3E%3Crect width="20" height="20" fill="white"%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');
            background-size: sizer(0.938);
        }
    }
}

input[type="text"] + label,
textarea + label,
.dropdown + label {
    position: absolute;
    background-color: $white;
    font-size: $font-size-12;
    line-height: $line-height-16;
    top: -7px;
    left: sizer(1);
    color: $spanish-gray;
    padding: 0 sizer(.25);
    margin: 0;
}

.MuiAutocompleted-popper {
    li {
        pointer-events: none;
        cursor: default;

        .form-check-input {
            margin-right: sizer(.5);
        }
    }
}


// Ant Select
.ant-select {
    &.ant-select-arrow {
        border: 0 !important;
        margin: 0 !important;
        width: 24px;
        height: 24px;
        padding-left: 0;
        top: 3px;
        right: 0;
    }
    &-customize-checkbox-tag {
        position: absolute;
        left: sizer(1.5);
        top: 0;
        height: 73px;
        display: flex;
        align-items: center;
        z-index: 2;
    }
    &-disabled {
        pointer-events: none;
    }
    &-dropdown {
        padding: sizer(1);
        font-family: $inter;
        //border: 1px solid $chinese-white;

        border-radius: 20px;
        border: 1px solid var(--neutral-black, #000);
        background: var(--neutral-white, #FFF);
        // box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 1px 2px 2px 0px rgba(152, 149, 149, 0.12);
        box-shadow: unset;
        .ant-select-customize {
            &-checkbox {
                .form-check {
                    padding: sizer(.5) 0;

                    &-input {
                        margin: 0;
                    }
                }
            }
            &-dropdown {
                &-clear-all {
                    border-top: 1px solid $spanish-gray;
                    margin-top: sizer(1);
                    padding: sizer(1) 0;
                    text-align: center;
                    font-size: $font-size-16;
                    line-height: $line-height-24;

                    a {
                        text-align: center;
                        font-weight: $font-weight-bold;
                        color: $sunset-orange;
                    }
                }
            }
        }

        .ant-select {
            &-item{
                &-option {
                    padding: sizer(.25) sizer(.5);
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-normal;
                    color: $black !important;

                    &:not(:last-child) {
                        margin-bottom: sizer(.5);
                    }
                    &:hover {
                        background-color: $light-gray !important;
                    }
                    &-active {
                        background-color: transparent !important;
                    }
                    &-selected {
                        pointer-events: none;
                        background-color: $black !important;
                        color: $white !important;
                    }
                }
            }
        }
    }
    &-open {
        .ant-select-arrow {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &-selection {
        &-search,
        &-item {
            border: 0 !important;
            margin: 0 !important;
            color: $black !important;
            font-family: $inter;
            font-weight: $font-weight-normal;
            font-size: $font-size-20;
            line-height: $line-height-28;
            align-items: center;
            display: flex;
        }
    }
    &-arrow {
        border: 0 !important;
        margin: 0 !important;
        width: 24px;
        height: 24px;
        top: 3px;
        right: 0;

        svg {
            path {
                fill: $spanish-gray;
            }
        }
    }
    &-single {
        &.ant-select-customize {
            cursor: pointer;
            min-width: 240px;

            &-dropdown {
                border-top: 1px solid $spanish-gray;

                a {
                    text-align: center;
                    font-weight: $font-weight-bold;
                    color: $sunset-orange;
                }
            }

            .ant-select-selector {
                padding: sizer(1.5);
                border-radius: $border-radius-20;
                border: 1px solid $chinese-white;
                font-size: $font-size-16;
                line-height: $line-height-24;
                height: calc(25px + sizer(3));

                .ant-select-selection-search {
                    position: absolute;
                    left: sizer(1.5);
                    width: calc(100% - sizer(3));

                    input {
                        border: 0;
                        outline: none;
                    }
                }
                .ant-select-selection-item {
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-normal;
                }
                .ant-select-selection-placeholder {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0 1.5rem;
                }
                input {
                    padding: 0;
                }
            }
            .ant-select-selection-placeholder {
                color: $spanish-gray;
            }
        }
    }

    &-multiple {
        &.ant-select-disabled {
            &.ant-select:not(.ant-select-customize-input) {
                .ant-select-selector {
                    background-color: transparent;
                }
            }
        }
        &.ant-select-customize {
            cursor: pointer;
            min-width: 240px;

            .ant-select-selector {
                padding: sizer(1.5);
                border-radius: $border-radius-20;
                border: 1px solid $chinese-white;
                font-size: $font-size-16;
                line-height: $line-height-24;
                height: calc(25px + sizer(3));

                .ant-select-selection-search {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: calc(100% - sizer(3)) !important;

                    input {
                        border: 0;
                        outline: none;
                    }
                }
                .ant-select-selection-item {
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-normal;
                }
                .ant-select-selection-placeholder {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0 1.5rem;
                }
                input {
                    padding: 0;
                }
            }
            .ant-select-selection-placeholder {
                color: $spanish-gray;
            }
        }
    }
    .ant-select-arrow {
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
    }
}

// RangePicker
.rangepicker-container {
    position: relative;

    label {
        position: absolute;
        left: 20px;
        transform: translateY(-8px);
        z-index: 2;
        font-family: inherit;
        font-size: $font-size-12;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-16;
        color: $black;
        padding: 0 sizer(.25);
        background-color: $white;
    }
}

// Ant Date
.ant-picker {
    &.ant-picker {
        padding: sizer(1.5);
        border-radius: $border-radius-20;
        font-family: $inter;
        box-shadow: none !important;

        //&:hover { border-color: #d9d9d9 !important; }

        &-range {
            .ant-picker-active-bar {
                display: none;
            }
            .ant-picker-range-separator {
                display: none;
            }
            .ant-picker-suffix {
                display: none;
            }
            .ant-picker-clear {
                margin-right: 13px;
                opacity: 1;
                svg:hover{
                    opacity: 0.5;
                }
            }
            .ant-picker-input {
                &:nth-child(1){
                    //width: 140px;

                    &::after {
                        content: '-';
                        font-family: inherit;
                    }

                    &:has(input:placeholder-shown) {
                        &::after { color: $spanish-gray; }
                    }
                    &:has(input:not(:placeholder-shown)) {
                        &::after { color: $black; }
                    }
                }
                &:nth-child(3) {
                    margin-left: 10px;
                    &::after {
                        content: url('../../../public/images/date-range.svg');
                    }
                }
                input {
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    //text-align: center;
                    &::placeholder {
                        color: $spanish-gray;
                        opacity: 1; /* Firefox */
                    }
                }
            }
    //         .ant-picker-clear {
    //             display: none;
    //         }
    //         label {
    //             font-size: $font-size-14;
    //             position: absolute;
    //             top: -12px;
    //             left: 18px;
    //             background-color: $white;
    //             color: $spanish-gray;
    //         }
    //         input {
    //             padding: 5px;
    //             width: 80%;
    //             display: inline-grid;
    //         }
    //         .icon {
    //             display: inline-grid;
    //             color: $spanish-gray;
    //             height: 20px;
    //         }
        }
    }
    &-header-view {
        button {
            color: $sunset-orange !important;
        }
    }
    &-cell-in-view:hover {
        .ant-picker-cell-inner {
            background-color: $translucent-black !important;
            color: $white;
        }
    }
    &-super-prev-icon {
        color: $black;
    }
    &-prev-icon {
        color: $black;
    }
    &-next-icon {
        color: $black;
    }
    &-super-next-icon {
        color: $black;
    }
}

#button-search {
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border: none;
    height: 33.33px;
    width: 33.33px;
    padding-left: 5px;

    &:hover svg path { fill: $spanish-gray; }
}

// Ant Checkbox
.ant-checkbox {
    top: -3px;

    &:after {
        border: 0;
        animation: none;
        visibility: unset;
        transition: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-wrapper {
        font-family: $inter;
        transition: none;

        &:hover {
            opacity: .5;
        }
    }

    &-checked {
        &::after {
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cg clip-path="url(%23clip0_86_2218)"%3E%3Cpath d="M7.49987 13.4751L4.6082 10.5834C4.2832 10.2584 3.7582 10.2584 3.4332 10.5834C3.1082 10.9084 3.1082 11.4334 3.4332 11.7584L6.91654 15.2418C7.24154 15.5668 7.76654 15.5668 8.09154 15.2418L16.9082 6.4251C17.2332 6.1001 17.2332 5.5751 16.9082 5.2501C16.5832 4.9251 16.0582 4.9251 15.7332 5.2501L7.49987 13.4751Z" fill="white"%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_86_2218"%3E%3Crect width="20" height="20" fill="white"%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');
            background-size: cover;
            width: $icon-size;
            height: $icon-size;
        }
    }
    .ant-checkbox {
        &-input {
            width: $icon-size;
            height: $icon-size;
            border-color: $spanish-gray;
        }

        &-inner {
            width: $icon-size;
            height: $icon-size;
            border-color: $spanish-gray;

            &:hover {
                border-color: $black;
                opacity: .5;
            }

            &::after {
                transform: none;
                border: 0;
                visibility: unset;
            }
        }
    }

    &-disabled {
        .ant-checkbox-inner {
            background-color: unset;
        }
    }
}


.InputContainer, .InputElement { font-family: $inter !important; }

.truncated-span {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
