.mobile-responsive {
    .modal {
        &-content {
            padding-top: sizer(1);
        }

        &-header {
            padding-bottom: sizer(1);
            border: 0;
            justify-content: flex-end;

            svg {
                cursor: pointer;
                width: 48px;
                height: 48px;

                &:hover {
                    opacity: .5;
                }
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                margin-bottom: sizer(2);
            }
        }
    }
}