/* Archivo - Regular */
@font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-display: swap;
    src: local(''),
         url('../../../public/fonts/archivo.woff2') format('woff2'),
         url('../../../public/fonts/archivo.woff') format('woff');
}

/* Urbane Rounded - Light */
@font-face {
    font-family: $urbane-rounded;
    font-style: normal;
    font-weight: $font-weight-urbane-light;
    font-display: swap;
    src: local(''),
         url('../../../public/fonts/UrbaneRounded-Light.woff2') format('woff2'),
         url('../../../public/fonts/UrbaneRounded-Light.woff') format('woff'),
         url('../../../public/fonts/UrbaneRounded-Light.eot') format('eot'),
         url('../../../public/fonts/UrbaneRounded-Light.ttf') format('ttf');
}

/* Urbane Rounded - Medium */
@font-face {
    font-family: $urbane-rounded;
    font-style: normal;
    font-weight: $font-weight-urbane-medium;
    font-display: swap;
    src: local(''),
         url('../../../public/fonts/UrbaneRounded-Medium.woff2') format('woff2'),
         url('../../../public/fonts/UrbaneRounded-Medium.woff') format('woff'),
         url('../../../public/fonts/UrbaneRounded-Medium.eot') format('eot'),
         url('../../../public/fonts/UrbaneRounded-Medium.ttf') format('ttf');
}

/* Urbane Rounded - Demi-bold */
@font-face {
    font-family: $urbane-rounded;
    font-style: normal;
    font-weight: $font-weight-urbane-demi-bold;
    font-display: swap;
    src: local(''),
         url('../../../public/fonts/UrbaneRounded-DemiBold.woff2') format('woff2'),
         url('../../../public/fonts/UrbaneRounded-DemiBold.woff') format('woff'),
         url('../../../public/fonts/UrbaneRounded-DemiBold.eot') format('eot'),
         url('../../../public/fonts/UrbaneRounded-DemiBold.ttf') format('ttf');
}

@font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: url("../../../public/fonts/Inter-Regular.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 400; font-display: swap; src: url("../../../public/fonts/Inter-Italic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 500; font-display: swap; src: url("../../../public/fonts/Inter-Medium.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 500; font-display: swap; src: url("../../../public/fonts/Inter-MediumItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 600; font-display: swap; src: url("../../../public/fonts/Inter-SemiBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 600; font-display: swap; src: url("../../../public/fonts/Inter-SemiBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: url("../../../public/fonts/Inter-Bold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 700; font-display: swap; src: url("../../../public/fonts/Inter-BoldItalic.woff2") format("woff2"); }


























// /* Inter - Regular */
// @font-face {
//     font-family: $inter;
//     font-style: normal;
//     font-weight: $font-weight-inter-regular;
//     font-display: swap;
//     src: local(''),
//          url('../../../public/fonts/Inter-Regular.ttf') format('ttf');
// }

// /* Inter - Medium */
// @font-face {
//     font-family: $inter;
//     font-style: normal;
//     font-weight: $font-weight-inter-medium;
//     font-display: swap;
//     src: local(''),
//          url('../../../public/fonts/Inter-Medium.ttf') format('ttf');
// }

// /* Inter - Semi Bold */
// @font-face {
//     font-family: $inter;
//     font-style: normal;
//     font-weight: $font-weight-inter-semi-bold;
//     font-display: swap;
//     src: local(''),
//          url('../../../public/fonts/Inter-SemiBold.ttf') format('ttf');
// }

// /* Inter - Bold */
// @font-face {
//     font-family: $inter;
//     font-style: normal;
//     font-weight: $font-weight-inter-bold;
//     font-display: swap;
//     src: local(''),
//          url('../../../public/fonts/Inter-Bold.ttf') format('ttf');
// }