.MuiAutocomplete {
    &-root,
    &-paper {
        font-family: $inter !important;

        * {
            font-family: $inter !important;
        }
    }
}

.MuiPaper-root {
    font-family: $inter !important;

    * {
        font-family: $inter !important;
    }
}