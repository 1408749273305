.invoices-list {
    @media #{$tablet}{
        thead{
            display: none;
        }
          table{
            transform: translateX(-24px);
            width: 100%;
            margin-top: sizer(0.5);
            tbody {
              td {
                border-bottom: 1px solid $chinese-white;
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: -1px;
                  height: 1px;
                  width: calc(100% + 96px);
                  background-color: $chinese-white;
                  transform: translateX(-24px); 
                }
              }
            }
            .ant-table-expanded-row{
              display: none;
            }
            span{
              color: $black;
              &.ant-checkbox-inner{
                border-color: $black;
              }
            }

          }
          span.table-pagination{
            font-size: $mobile-font-size-18;
            line-height: $line-height-26;
          }
          .custom-ant-table.ant-table-wrapper .ant-table-tbody > tr > td {
            padding-left: sizer(1.5);
          }
          .invoices-list-table{
            margin-top: 40px;
            .invoices-list-pagination{
                margin-bottom: 0;
            }
          }
          .mobile-cell{
            flex-direction: column !important;
          }
          .mobile-invoices-btn{
            height: 79px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .invoices-addition-info{
            display: flex;
            align-items: center;
            gap: sizer(1);
            .invoices-pending{
                padding: sizer(0.25) sizer(0.5);
                width: 55px;
                height: 22px;
                span{
                    color: $candy-red;
                    font-weight: 500;
                }
            }
            .invoices-paid{
                padding: sizer(0.25) sizer(0.5);
                width: 37px;
                height: 22px;
                span{
                    color: $leafy-green;
                    font-weight: 500;
                }
            }
            span{
                margin-bottom: 0;
                color: $spanish-gray;
            }
          }
          .mobile-setting-bar{
            height: 42px;
            .mobile-setting-filter{
                position: relative;
                button{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0;
                    h3{
                        margin-bottom: 0;
                    }
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 140%; 
                    height: 1px;
                    top: 41px;
                    background-color: $chinese-white;
                    transform: translateX(-48px);
                }
            }
        }
          .mobile-modal-more-vertical{
            button {
                padding: sizer(0.75) sizer(0.5) !important;
            }
          }
          .ant-pagination{
            margin-top: sizer(2.5) !important;
            &-item-active {
              height: 40px !important;
            }
            li{
              height: 40px;
              width: 24px;
              padding: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              &.ant-pagination-options{
                display: none !important;
              }
              .ant-pagination-item-link{
                display: flex;
                align-items: center;
              }
              a{
                font-family: $inter;
                &:hover{
                  opacity: unset;
                }
              }
            }
          }
          .invoices-list-table-skeleton{
            width: 100%;
            > span + div {
              background-color: transparent;
              color: #ffffff;
            }
            > div > div {
              position: relative;
              padding: 1rem 0;
              border: 0;

              &::before {
                content: "";
                position: absolute;
                bottom: -1px;
                left: -48px;
                width: calc(100% + 120px);
                height: 1px;
                background-color: $chinese-white;
              }
            }
          }
    }
    &-table {
        &-skeleton {
            > span {
                display: block;
                margin-bottom: sizer(1);

                + div {
                    background-color: $black;
                    color: $white;

                    > div {
                        border-bottom: 0;
                        @media screen and (min-width: $large_screen_up) and (max-width: 1500px){
                          &:nth-child(1),
                          &:nth-child(2),
                          &:nth-child(5){
                            flex-grow: unset;
                          }
                        }
                    }
                }

            }
            > div {
                display: flex;
                flex-direction: row;

                > div {
                    padding: sizer(1.25) sizer(3);
                    flex-grow: 1;
                    border-bottom: 1px solid $chinese-white;

                    &:nth-child(0) {
                        width: 10px;
                    }
                    @media screen and (min-width: $large_screen_up) and (max-width: 1500px){
                      padding: sizer(1.25) sizer(2) sizer(1) sizer(1.25);
                    }
                }
            }
        }
        .custom-ant-table.ant-table-wrapper {
          .ant-table-thead > tr > th {
            @media screen and (min-width: $large_screen_up) and (max-width: 1500px){
              padding: sizer(1.25) sizer(2) sizer(1) sizer(1.25);
              .ant-table-column-sorters{
                width: max-content;
              }
            }
          }
          .ant-table-tbody > tr > td {
            @media screen and (min-width: $large_screen_up) and (max-width: 1500px){
              padding: sizer(1.25) sizer(2) sizer(1) sizer(1.25);
            }
          }
        }
    }
    .invoices-pending{
      background-color: $dawn-pink;
      padding: sizer(0.25) sizer(1);
      border-radius: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 94px;
      height: 32px;
      span{
          color: $candy-red;
          font-weight: 400;
          margin-bottom: 0;
      }
  }
  .invoices-paid{
      background-color: $light-green;
      padding: sizer(0.25) sizer(1);
      border-radius: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      height: 32px;
      span{
          color: $leafy-green;
          font-weight: 400;
          margin-bottom: 0;
      }
  }
    &-pagination {
        margin-bottom: sizer(1.5);
        font-size: $font-size-16;
        line-height: $line-height-24;
    }
    &-action-btns {
        display: flex;
        flex-direction: row;
        gap: sizer(1);
    }
}