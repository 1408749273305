:root {
    --chinese-white: #E1E1E1;
    --black: #000;
    --bs-body-color: var(--black);
    --bs-border-color: var(--chinese-white);
    --bs-gutter-x: sizer(1.5);
    --bs-tooltip-bg: var(--chinese-white);
    --bs-btn-active-bg: var(--black);
    --bs-btn-active-border-color: var(--black);
}
