.custom-ant-table {
    &.ant-table-wrapper {
        .ant {
            &-table {
                &-thead {
                    > tr {

                        &:first-child {
                            > * {
                                &:first-child {
                                    border-radius: 0;
                                }
                            }
                        }

                        > th {
                            background-color: $black;
                            color: $white;
                            padding: sizer(1.25) sizer(3);
                            font-weight: 500;
                            font-family: 'Inter';

                            &::before {
                                display: none;
                            }

                            &.ant-table-column-has-sorters:hover {
                                background-color: $black;
                            }
                        }
                    }
                }
                &-tbody {
                    > tr {
                        &.ant-table-expanded-row {
                            > td {
                                background-color: $light-gray;
                                z-index: 2;
                                position: relative;
                            }

                            .ant-table-custom-expanded-cell {
                                //display: flex;
                                //flex-direction: row;
                                display: grid;
                                grid-template-columns: 30.5% 1fr;


                                &::before {
                                    content: '';
                                    background-color: whitesmoke;
                                    width: 100%;
                                    height: 73px;
                                    position: absolute;
                                    top: -73px;
                                    left: 0;
                                    z-index: 1;
                                }

                                > div {

                                    &:nth-child(2) {
                                        padding-left: sizer(1.3);
                                        @media screen and #{$mlarge_and_down}{
                                            padding-left: sizer(0.75) !important;
                                        }
                                    }
                                    span {
                                        display: block;
                                        overflow: hidden;
                                        text-overflow: ellipsis;

                                        &.font-bold > strong { font-weight: $font-weight-bold; }

                                        &:not(:last-child) {
                                            margin-bottom: sizer(.5);
                                        }
                                    }
                                }

                            }
                        }
                        &.ant-table-row {
                            &:hover {
                                > td {
                                    background-color: unset !important;
                                }
                            }
                        }
                        > td {
                            padding: sizer(1) sizer(3);
                            padding-right: sizer(1.5);
                            white-space: nowrap;
                            z-index: 3;

                            &.ant-table-column-sort {
                                background-color: transparent;
                            }
                        }
                    }
                }
                &-container {
                    table > thead > tr:first-child >*:last-child {
                        border-radius: 0;
                    }
                }
                .ant-table-row-level-0{
                    .ant-table-cell {
                        column-width: 200px;
                        @media screen and (max-width: sizer(90)) {
                            column-width: 180px;
                        }
                    }
                }
                &-cell {
                    .ant-table-custom-cell {
                        display: flex;
                        flex-direction: row;
                        gap: sizer(1.5);
                        width: 100%;
                    }
                    .ant-table-column-sorters {
                        .ant-table-column-title {
                            position: relative;
                            flex: none;

                            &:before {
                                content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="white" d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>');
                                position: absolute;
                                width: sizer(1.5);
                                height: sizer(1.5);
                                right: calc((sizer(.5) + 24px) * -1);
                                top: 3px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .ant-table-column-sorter {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.ant-tooltip{
    display: none;
}