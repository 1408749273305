.image-search-container {
  position: relative;

  @media #{$large_and_down} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 85px;
    width: 100%;
  }

  .image-search-upload-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    width: 480px;
    // min-height: 464px;
    background-color: $white;
    border: 1px solid $black;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-radius: 20px;
    padding: sizer(1.5);
    top: 54px;
    right: -55px;
    z-index: 7;

    @media #{$large_and_down} {
      width: 100%;
      max-width: 480px;
      position: unset;
      top: unset;
      right: unset;
    }

    .pointer {
      height: 20px;
      position: absolute;
      right: 20px;
      top: -16.6px;

      @media #{$large_and_down} {
        display: none;
      }
    }

    .image-search-upload-title-container {
      display: flex;
      width: 100%;
      height: 36px;
      justify-content: space-between;
      position: relative;
    }

    .image-search-upload-title-container::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 111.7%;
      bottom: 0;
      background-color: $chinese-white;
      transform: translateX(-25px);
    }

    .image-search-upload-loader-container {
      height: 270px;
      display: flex;
      gap: 8px;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .loader {
        border: 10px solid $chinese-white;
        border-radius: 50%;
        border-top: 10px solid $leafy-green;
        width: 80px;
        height: 80px;
        animation: spin 2s linear infinite;
        margin-bottom: 10px;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    .image-search-upload-message-container {
      .alert-notification {
        margin: unset;
      }
    }

    .image-search-upload-box-container {
      max-width: 432px;
      // padding-left: 24px;
      // padding-right: 24px;

      .search-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 256px;
      }
      
      .search-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      .upload-dropzone-wrapper {
        height: 256px;

        .upload-dropzone-content p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: .5rem;
        }
      }
      .image-cropper-section {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 256px;
        position: relative;

        .image-cropper-container {
          height: 256px;
          display: flex;
          align-items: center;
          max-width: 432px;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);

          .ReactCrop__child-wrapper {
            max-height: 256px;
          }
        }
        img {
          // height: 256px;
        }
      }
    }

    .upload-dropzone-info {
      color: $spanish-gray;
    }

    .image-search-upload-url-link {
      display: flex;
      gap: 8px;
      flex-direction: column;

      .image-search-input {
        border: 1px solid $chinese-white !important;
        border-radius: 40px;
        box-sizing: border-box;
        height: 40px;
        margin-right: 8px;

        .MuiFormControl-root {
          @media #{$small_and_down} {
            width: 90%;
          }
        }

        .MuiInputBase-root {
          padding-left: unset;
          width: 115%;

          @media #{$large_and_down} {
            width: 300%;
          }
        }

        input {
          padding: sizer(0.5) sizer(1);
        }

        fieldset {
          border-style: unset;
        }

        svg {
          margin-left: -30px;

          @media #{$large_and_down} {
            margin-left: -140px;
          }
          
          @media #{$small_and_down} {
            margin-left: unset;
          }
        }
      }

      .black-border {
        border: 1px solid black !important;
      }
    }

    .image-search-upload-url-top {
      display: flex;
      justify-content: space-between;
      
      @media #{$large_and_down} {
        flex-direction: column;
        gap: 8px;
      }

      .action-group {
        display: flex;
        gap: 24px;
        align-items: center;

        .cancel {
          justify-content: flex-start;
        }

        .save {
          justify-content: flex-end;
        }
      }

      .action-group.cancel {
        @media #{$large_and_down} {
          display: table-column;
        }
      }

      .action-group.save {
        @media #{$large_and_down} {
          display: table-column;
        }
      }
    }

    .image-search-upload-url-bottom {
      display: flex;
      justify-content: center;
      color: $spanish-gray;
      margin-bottom: unset;
    }

    #button-close {
      opacity: unset;
      margin-right: unset;

      svg {
        display: none;
      }
    }

    #button-close:hover {
      opacity: 0.5;
    }

    .btn-close::after {
      content: unset !important;
    }
  }

}