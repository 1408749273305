.related-images-wrapper {
  padding: 48px;
  border-top: 1px solid #E1E1E1;

  @media #{$tablet} {
    padding: 48px 24px;
  }

  .title-heading {
    margin-bottom: 24px;
  }

  .image-card-container {
    display: grid;
    gap: 24px;
  }

  .image-card {
    position: relative;
    width: 100%;
    padding-top: 75%; /* Aspect ratio (4:3) */
    overflow: hidden;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
    }

    .image-card-filename {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(217, 217, 217, 0.00) 100%);
      color: #fff;
      display: flex;
      align-items: flex-end;
      padding: 15px;
      opacity: 0;
      transition: opacity 0.3s ease;

      .title-container {
        display: contents;
        width: 80%;

        .first-text {
          flex: 0 1 content;
          max-width: -webkit-fit-content;
          max-width: -moz-fit-content;
          max-width: fit-content;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: auto;
        }

        .end-text {
          flex: 1 0 content;
          white-space: nowrap;
        }
      }
    }

    &:hover .image-card-filename {
      opacity: 1;
    }
  }

  @media #{$mlarge_and_up} {
    .image-card-container {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  @media #{$large_to_xlarge} {
    .image-card-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media #{$large_and_down} {
    .image-card-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media #{$medium_and_down} {
    .image-card-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
