.ant-tag {
    &-container {
        display: flex;
        flex-wrap: wrap;

        .ant-tag {
            padding: sizer(.25) sizer(.5);
            margin-bottom: sizer(.5);
            margin-right: sizer(.5);
            border-radius: $border-radius-8;
            color: $black;
            background-color: $chinese-white;
            white-space: nowrap;
            display: inline-block;
            font-family: $inter;
            font-size: $font-size-16;
            line-height: $line-height-24;
            border: 0;
            @media #{$mobile}{
                margin-bottom: 0;
            }

            &.green {
                background-color: $light-green;
                color: $leafy-green;
            }
            &.purple {
                background-color: $light-purple;
                color: $purple-blue;
            }
            &.pink {
                background-color: $light-pink;
                color: $hot-pink;
            }
            &.orange {
                background-color: $light-orange;
                color: $orange;
            }
            &.blue {
                background-color: $sky-blue;
                color: $blue;
            }
        }
    }
}