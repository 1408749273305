.mobile-modal {
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: unset !important;
    justify-content: flex-end !important;
    z-index: 999;

    &-content {
        display: flex;
        max-height: 90% !important;
        min-height: unset !important;
        background-color: $white;
        align-items: unset !important;
        justify-content: unset !important;
        flex-direction: column;
        gap: sizer(.75);
        border-top-left-radius: $border-radius-20;
        border-top-right-radius: $border-radius-20;
        position: relative;

        &#with-btn-bottom {
            padding-bottom: unset;
        }

        &::before {
            content: '';
            height: sizer(.25);
            width: sizer(3);
            background-color: $medium-gray;
            border-radius: $border-radius-40;
            position: absolute;
            top: sizer(.75);
            left: 50%;
            transform: translateX(-50%);
        }

        &-wrapper {
            padding: sizer(2.5) sizer(1.5) 0 sizer(1.5);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &-header {
        display: flex;
        justify-content: unset !important;
        //gap: sizer(.75);
        padding-bottom: sizer(.5);
        position: relative;

        &::before {
            content: "";
            height: 0.5px;
            width: 115%;
            position: absolute;
            bottom: -1px;
            left: 0;
            transform: translateX(-25px);
            border-bottom: 1px solid #E1E1E1;
        }

        .list-view-title-container {
            width: 90%;
            display: inline-flex !important;
            flex-wrap: nowrap;
            align-items: unset !important;
            justify-content: unset !important;
            font-family: "inter";
            font-size: $mobile-font-size-18;
            line-height: 26px;
            font-weight: 500;
            color: #000000;

            h3 {
                font-weight: $font-weight-medium;
                color: $black;
                margin-bottom: unset;
            }

            .first-text {
                flex: 0 1 content;
                text-overflow: ellipsis;
                overflow:hidden;
                white-space:nowrap;
                max-width: fit-content;
                width: auto;

                @media #{$tablet} {
                    width: fit-content;
                    max-width: 300px;
                }

                @media #{$mobile} {
                    width: fit-content;
                    max-width: 200px;
                }
            }

            .end-text {
                flex: 1 0 content;
                white-space:nowrap;
                display: unset !important;
            }
        }
    }

    &-close {
        cursor: pointer;
        margin-left: auto;

        svg {
            width: sizer(1.5);
            height: sizer(1.5);
        }

        &:hover {
            opacity: 0.5;
        }
    }
    .btn.btn-primary:hover {
        opacity: unset !important;
    }
    &-body {
        display: flex;
        align-items: unset !important;
        justify-content: unset !important;
        flex-direction: column;
        padding-top: 24px;

        // list view more icon
        .mobile-modal-more-vertical {
            flex-direction: column;
            align-items: unset !important;
            justify-content: unset !important;

            button {
                justify-content: unset;
                padding: unset;
                text-decoration: none;
                padding: 12px 0 !important;
                &:first-child{
                    &:active {
                        color: $black;
                        background: transparent !important;
                        border-color: transparent;
                        svg path {
                            fill: $black;
                        }
                    }
                }
                svg {
                    width: sizer(1.5);
                    height: sizer(1.5);
                    fill: $black;
                }

                .spinner-border {
                    height: 24px !important;
                }

                h3 {
                    color: $black;
                    margin-bottom: unset;
                    font-weight: $font-weight-normal;
                }
                &.selected{
                    background-color: $black;
                    padding: sizer(0.75) sizer(0.5) !important;
                    border-radius: 8px;
                    h3{
                        color: $white;
                    }
                }
            }
        }

        // file type
        .mobile-modal-file-type {
            width: 100%;
            position: relative;

            nav {
                width: 100%;

                div.g-0 {
                    width: 100%;

                    .navbar-nav {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .nav-item {
                            width: 100%;
                            border-radius: $border-radius-8;

                            a {
                                width: 100%;
                                font-size: $mobile-font-size-18;
                                line-height: 26px;
                                color: $black;
                                padding: sizer(.75) sizer(.5);
                                display: flex;
                                align-items: center;

                                img { margin-right: sizer(.5); }

                                h3 {
                                    margin-bottom: unset;
                                    font-weight: $font-weight-normal;
                                }

                                span {
                                    margin-left: sizer(.5);
                                    background-color: unset !important;
                                    padding: unset;
                                    border-radius: unset;
                                    color: $black;
                                    font-size: $font-size-18;
                                    line-height: $line-height-26;

                                    p {
                                        margin-bottom: unset;
                                        display: inline-block;
                                    }
                                }
                            }

                            &.selected {
                                background-color: $black;

                                a {
                                    color: $white;

                                    img {
                                        filter: invert(100%);
                                    }

                                    h3 {
                                        margin-bottom: unset;
                                        display: inline-block;
                                    }

                                    span {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // search icon
        .mobile-modal-search-type-icon {
            width: 100%;
            position: relative;

            ul {
                margin: unset;
                padding: unset;

                li {
                    width: 100%;
                    font-size: $mobile-font-size-18;
                    line-height: 26px;
                    color: $black;
                    padding: sizer(.75) sizer(.5);
                    list-style: none;
                    display: flex;
                    gap: sizer(.5);
                    align-items: center;
                    border-radius: $border-radius-8;
                    cursor: pointer;

                    h3 {
                        margin-bottom: unset;
                        display: inline-block;
                        font-weight: $font-weight-normal;
                    }

                    &.active {
                        background-color: $black;
                        color: $white;

                        img {
                            filter: invert(100%);
                        }
                    }
                }
            }
        }

        // sort only
        .mobile-modal-sort-only {
            .sort-data {
                input {
                    display: none;
                }

                label {
                    padding: sizer(.75) sizer(.5);
                    border-radius: $border-radius-8;
                    width: 100%;
                    cursor: pointer;

                    h3 {
                        margin-bottom: unset;
                    }
                }

                &:has(input:checked) {
                    label {
                        background-color: $black;
                        color: $white;
                    }
                }
            }
        }

        // filter
        .mobile-modal-sort {
            display: flex;
            flex-direction: column;
            margin-top: sizer(0.75);
            margin-bottom: sizer(1.5);

            .sort-title {
                margin-bottom: sizer(.25);
                p {
                    font-weight: $font-weight-medium;
                    margin-bottom: unset;
                }
            }

            .sort-data {
                padding: sizer(.75) 0;
                display: flex;
                align-items: center;
                gap: sizer(.5);
                position: relative;
                /*&:first-child{
                    padding-top: 0;
                }*/
                input { display: none; }
                .form-check {
                    width: 24px;
                }
                label {
                    position: relative;
                    margin-left: 28px;
                    margin-bottom: unset;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -26px;
                        transform: translateY(-50%);
                        height: 20px;
                        width: 20px;
                        border: 2px solid $black;
                        border-radius: 50%;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -20px;
                        transform: translateY(-50%);
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                    }
                }

                &:has(input:checked) {
                    label {
                        &::after {
                            background-color: $black;
                        }
                    }
                }
            }
        }

        .mobile-modal-filter {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            .asset-filter-container{
                gap: 0 !important;
            }

            .asset-filter-top {
                order: 2;
                height: 74px;
                display: flex;
                flex-direction: row !important;
                align-items: center;
                justify-content: center;
                gap: 20px;
                position: relative;
                background-color: $light-gray;
                > div{
                    width: 100%;
                    .apply-filter-button{
                        margin-left: 0 !important;
                    }
                }

                .btn{
                    font-size: $font-size-18 !important;
                    &.btn-secondary {
                        background-color: transparent;
                    }
                }
                &::before,
                &::after {
                    content: "";
                    height: 74px;
                    width: 100%;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    clip-path: inset(0 -100vmax);
                    box-shadow: 0 0 0 100vmax $light-gray;
                    border-bottom: 1px solid $light-gray;
                }

                & > div {
                    &:nth-child(1) {
                        order: 2;
                    }

                    &:nth-child(2) {
                        order: 1;
                    }
                }
            }

            .asset-filter-container {
                order: 1;
                margin-top: unset;
                display: flex;
                flex-direction: column;
                gap: sizer(1.5);
                width: 100%;
                position: relative;
                margin: unset;

                .col-3 {
                    width: 100%;
                    flex: unset;
                    padding: unset;
                    margin-bottom: 1.5rem;

                    .rangepicker-container {
                        p {
                            font-weight: $font-weight-medium !important;
                            //margin-bottom: sizer(1.5) !important;
                        }

                        .ant-picker {
                            width: 100%;
                            padding: sizer(1) sizer(1.5);
                        }
                    }

                    &.orientation {
                        .filter-title p {
                            margin-bottom: 0;
                        }
                    }
                }

                .col-4 {
                    width: 100%;
                    flex: unset;
                    padding: unset;
                    padding-bottom: 0;
                    margin-bottom: 1.5rem;

                    .filter-title {
                        font-weight: $font-weight-medium !important;
                        //margin-bottom: sizer(1.5) !important;
                    }

                    .file-size-input-fields {
                        height: 56px;

                        .size-component {
                            padding: sizer(1) sizer(1.5);
                            width: 159px;

                            label { top: -13px; }
                        }
                    }
                }
            }
        }
    }
    &-tooltip {
        padding-bottom: 110.6px;

        &-button {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: $light-gray;
            padding: sizer(.75) sizer(1.5);
        }
    }
}