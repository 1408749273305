.accordion {
    padding: 0;
    color: $spanish-gray;
    background-color: transparent;
    border: 0;
    outline: 0;

    &-item {
        border: 0;
        border-radius: 0;
    }
    &-button {
        display: flex;
        justify-content: space-between;
        transform: rotate(0);
        transition: transform .25s ease-in-out;
        border-radius: 0 !important;

        &[aria-expanded="true"] {
            svg {
                transform: rotate(-180deg);
            }
        }
        &::after {
            display: none;
        }
    }
    &-header{
        .accordion-button {
            color: $spanish-gray;
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            border-bottom: 1px solid $chinese-white
        }
    }

    &-collapse  {
        .accordion-body {
            padding: sizer(1) 0;
        }
    }
}