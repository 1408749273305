// 576px
.show_on_small_and_down {
    @media #{$small_and_down} {
        display: block !important;
    };
    @media #{$small_and_up} {
        display: none !important;
    };
}

.show_on_small_and_up {
    @media #{$small_and_down} {
        display: none !important;
    };
    @media #{$small_and_up} {
        display: block !important;
    };
}

// 768px
.show_on_medium_and_down {
    @media #{$medium_and_down} {
        display: block !important;
    };
    @media #{$medium_and_up} {
        display: none !important;
    };
}

.show_on_medium_and_up {
    @media #{$medium_and_down} {
        display: none !important;
    };
    @media #{$medium_and_up} {
        display: block !important;
    };
}

// 1024px
.show_on_large_and_down {
    @media #{$large_and_down} {
        display: block !important;
    };
    @media #{$large_and_up} {
        display: none !important;
    };
}

.show_on_large_and_up {
    @media #{$large_and_down} {
        display: none !important;
    };
    @media #{$large_and_up} {
        display: block !important;
    };
}