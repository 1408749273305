.bookmark-edit{
    &-wrapper {
        width: 100%;
        height: 100%;

        .form-container {
            .bookmark.form-group {
                
                .input-title-container {

                    & > label {
                        top: -12px !important;
                        left: 20px !important;
                    }

                    textarea {
                        height: auto;
                        overflow-y: hidden;
                        min-height: 3.5rem;
                        padding: 1rem 1.5rem;
                        align-content: center;
                        word-break: break-word;
                        line-height: $line-height-16;
                        font-weight: $font-weight-inter-regular;
                    }

                    textarea::placeholder {
                        color: $spanish-gray;
                    }

                    .input-counter-container {
                        padding-top: 4px;
                        display: flex;
                        height: 24px;
                        
                        .limit-label {
                            margin-right: auto;
                            
                            &.hidden {
                                visibility: hidden;
                            }
                        }

                        .counter-label {
                            &.hidden {
                                visibility: hidden;
                            }
                        }

                        label {
                            position: relative;
                            left: 0 !important;
                            top: 0 !important;
                            color: $spanish-gray;
                            font-size: $font-size-12;
                            line-height: $line-height-16;
                        }

                        .error-label {
                            padding: 0px;
                            font-size: $font-size-16;
                            line-height: $line-height-24;
                            align-items: center;
                            font-weight: $font-weight-inter-bold;
                            color: $candy-red;
                            margin-right: auto;
                            display: flex;
                            flex-wrap: wrap;
                            align-content: center;
                            gap: 8px;

                            svg {
                                font-size: $font-size-16;
                                color: $candy-red !important;
                                height: 24px;
                                width: 24px;
                            }
                        }
                    }
                }

                .red-font {
                    color: $candy-red;
                }

                .red-border {
                    border: 1px $candy-red solid;
                }

                & .save-btn {
                    margin-top: 28px;
                }

                & .delete-btn {
                    .btn {
                        color: $black;
                        background-color: $white;
                    }
                }

                & .btn {
                    justify-content: center; 
                    margin-bottom: 24px;  
                    width: 100%;  
                }
            }
        }
                
    }

}

@media #{$mobile} {
    .bookmark-edit {
        &-wrapper {
            position: relative;
            overflow-y: hidden;
            
            .form-container{
                height: 90%;
               
                .bookmark.form-group {
                    .input-title-container {
                        margin-top: 2.25rem;
                        padding: 0 1rem;
                        
                        label{
                            left: 2.3rem !important;
                            top: -0.7rem !important;
                        }
                    }

                    & .save-btn {
                        margin-top: 0;
                        padding-bottom: 0.75rem;
                    }

                    & .btn-wrapper {
                        bottom: 0;
                        width: 100%;
                        padding: 0.75rem 1.5rem;
                        position: fixed;
                        background-color: $light-gray;
                    }

                    & .btn {
                        margin-bottom: 0;  
                        &.delete-bookmark-btn{
                            background-color: transparent !important;
                        }
                    }
                }
            }
        }
    }     
}

