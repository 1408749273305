.welcome {
    &-elko {
        display: flex;
        width: 100%;
        height: calc(100vh - 72px);
        justify-content: center;
        align-items: center;
        @media screen and #{$medium_and_down} {
            display: block;
            padding-left: 24px;
            padding-right: 24px;
            text-align: center;
            align-items: unset;
        }

        .col-12 {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            @media screen and #{$medium_and_down} {
                display: block;
            }
        }
        img {
            margin-bottom: sizer(3);
            @media screen and #{$medium_and_down} {
                padding-top: 96px;
            }
            @media screen and #{$small_and_down} {
                width: 100%;
            }
        }

        h2 {
            margin-bottom: sizer(1);
        }

        p {
            margin-bottom: sizer(3);
            @media screen and #{$medium_and_down} {
                margin-bottom: sizer(2.5);
            }
            a{
                font-weight: $font-weight-bold;
                &:hover{
                    color: $sunset-orange;
                    opacity: 0.5;
                }
            }
        }

        button {
            margin-bottom: sizer(1);
        }
    }
}