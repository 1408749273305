.custom-vjs {
    .vjs {
        &-tech {
            object-fit: scale-down;
        }
        &-control-bar {
            .vjs-progress-control {
                position: absolute;
                left: 0;
                top: -3px;
                width: 100%;
                height: auto;

                .vjs-progress-holder  {
                    margin: 0;
                }
            }
            .vjs-play-progress {
                &::before {
                    color: $sunset-orange;
                }
            }
            // .vjs-picture-in-picture-control,
            // .vjs-fullscreen-control {
            //     display: none;
            // }
        }
    }
}