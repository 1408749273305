.asset-card {
  &-wrapper {
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        margin-top: 0;

        @media (max-width: #{$small_screen_up + 1px}) {
            margin-top: sizer(3);
        }
    }
    
    @media #{$large_and_down} {
      &:nth-child(4) {
       // margin-top: sizer(3);
      }
    }
    &:hover .movie-footer p {
      display: inline;
    }
  }
  &-container {
    position: relative;
    border-radius: $border-radius-20;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      border-radius: $border-radius-12;
    }

    &:hover .asset-card-header {
      display: flex;
      // width: 91%;
    }
    // &:hover .asset-card-header.header-video {
    //   width: 75%;
    // }
    &:hover .asset-card-body .movie-footer::before {
      display: unset;
    }
    .svg-container {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-header {
      display: none;
      justify-content: space-between;
      padding: sizer(1) $icon-size sizer(1.1);
      padding-right: 0;
      align-items: center;
      gap: sizer(1);
      position: absolute;
      // bottom: Min(61px, 100vw/24);
      bottom: 65px;
      z-index: 3;
      width: calc(100% - 1.5rem);
      color: $white;

      &.header-video,
      &.header-image {
        width: calc(65% - 16px);
        bottom: unset;
        top: calc(100% - calc(70px + (32px + 22.5px)));
      }

      .grid-view-title-container {
        width:114%;
        display:inline-flex;
        flex-wrap:nowrap;
        line-height: 24px;
        @media screen and (max-width: sizer(90)) {
          width:100%;
        }

        .first-text {
            flex: 0 1 content;
            text-overflow: ellipsis;
            overflow:hidden;
            white-space:nowrap;
            max-width: fit-content;
            width: auto;

            @media #{$tablet} {
              width: fit-content;
              max-width: 300px;
            }

            @media #{$mobile} {
                width: fit-content;
                max-width: 200px;
            }
        }

        .end-text {
            flex: 1 0 content;
            white-space:nowrap;
        }
      }

      p {
        width: 100%;
      }

      button {
          svg {
              width: 20px;
              height: 20px;
              path {
                  fill: $spanish-gray;
              }
          }
      }
  }
  &-body {
      position: relative;
      width: 100%;
      cursor: pointer;

      &-loading {
        pointer-events: none;
        cursor: default;
      }
      .spinner-grow {
        position: absolute;
        width: 50px;
        height: 50px;
      }
      .svg-icon {
        position: absolute;
        width: 50px;
        height: 66px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $black !important;
      }
      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
      svg {
        color: $black;
      }
      .movie-footer {
        display: flex;
        justify-content: flex-end;
        position: absolute;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: sizer(1);
          right: sizer(1.5);
          height: fit-content;
          width: fit-content;
          padding: sizer(.25) sizer(.75);
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: $border-radius-20;
        }
        svg {
            position: relative;
            width: sizer(1.5);
            height: sizer(1.5);
            display: inline;
            transform: none;
           /* right: 2px;
            top: 8px;*/

            path {
              fill: $white;
            }
        }
        p {
            display: none;
            color: $white;
            margin-bottom: 0;
            font-size: $font-size-16;
            line-height: $line-height-24;
            z-index: 2;
            align-self: flex-end;
            transform: translate(sizer(-1.5), -15.5px);
        }
      }
      &:hover .movie-footer p{
        display: inline;
      }
      video {
        object-fit: cover;
        pointer-events: none;
      }
      .volumn-icon-section {
        position: absolute;
        top: calc(100% - sizer(2.35));
        left: unset;
        right: 73px;
        z-index: 5000;
        width: sizer(1.5);
        height: sizer(1.5);

        svg {
          position: absolute;
          bottom: 2px;
          fill: $white;

          path {
            fill: $white;
          }
        }
      }
  }
  &-footer {
      border-top: 1px solid $chinese-white;
      padding: sizer(1.25) sizer(1.5);
      height: 70px;
      display: flex;
      gap: sizer(1);
      align-items: center;

      .btn-group {
        gap: sizer(1);

        button {
          display: flex;
          align-items: center;
          padding: 0;
          width: $icon-size;
          height: $icon-size;
          border: 0;
          flex: initial;

          svg {
              color: $black;
              width: $icon-size;
              height: $icon-size;
          }
        }
      }

      @media #{$mobile} {
        padding: 20px sizer(1.5);

        .btn-group {
          button {
            width: sizer(1.5);
            height: sizer(1.5);

            svg {
              font-size: sizer(1.5);
              width: sizer(1.5);
              height: sizer(1.5);
            }
          }
        }
      }

      @media #{$xxlarge_and_up} {
        gap: sizer(2.5);
      }
  }
  &-skeleton {
    display: flex;
    flex-direction: column;
    gap: sizer(.5);
    border-radius: $border-radius-20;

    &-list-view {
      > div {
        position: relative;

        > .skeleton-list-view {
          display: flex;
          flex-direction: row;
          margin-bottom: sizer(1);
          gap: sizer(.5);

          div {
            &:nth-child(1) {
              width: 80px;
            }
            &:nth-child(2) {
              width: 100%;
            }
          }
        }

        hr {
          position: relative;
          width: calc(100% + 48px);
          left: -24px;
          margin: 0;
          padding: 0;
          margin-bottom: sizer(1);
        }
      }
    }

    > div {
      .react-loading-skeleton {
        width: 100%;
        height: 100%;
        border-top-left-radius: $border-radius-20;
        border-top-right-radius: $border-radius-20;
      }
    }

    > span {
      display: flex;
      width: 100%;

      .react-loading-skeleton {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: $border-radius-20;
        border-bottom-right-radius: $border-radius-20;
      }
    }

    .react-loading-skeleton {
      border-radius: 0;
    }
  }

}

.movie-footer{
  &::before{
    display: none;
    position: absolute;
    content: "";
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(217, 217, 217, 0.00) 100%);
  }

  .my-svg{
    z-index: 6;
    width: 16px !important;
    height: 16px !important;
  }
}

.row-sound-icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 60px;
  .svg-icon{
    color: $black !important;
    height: 24px;
    width: 24px;
  }

}
