.ant-picker {
    &-dropdown {
        .ant-picker-range-wrapper {
            @media #{$mobile} {
                .ant-picker-panel-container {
                    .ant-picker-panel-layout {
                        .ant-picker-panels {
                            width: calc(100vw - sizer(3));
                            position: relative;

                            .ant-picker-panel {
                                width: 100%;

                                &:nth-child(1) { display: block; }
                                &:nth-child(2) { display: none; }

                                .ant-picker-date-panel {
                                    width: 100%;

                                    .ant-picker-header {
                                        .ant-picker-header-super-prev-btn { visibility: unset !important; }
                                        .ant-picker-header-prev-btn { visibility: unset !important; }
                                        .ant-picker-header-next-btn { visibility: unset !important; }
                                        .ant-picker-header-super-next-btn { visibility: unset !important; }
                                    }

                                    .ant-picker-body {
                                        padding: 8px clamp(12px, 1.5vw, 18px);
                                        .ant-picker-content {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:has(.ant-picker-range-arrow[style="left: 105px;"]) {
                    .ant-picker-panel-container {
                        .ant-picker-panel-layout {
                            .ant-picker-panels {
                                width: calc(414px - sizer(3));
                                position: relative;
        
                                .ant-picker-panel {
                                    &:nth-child(1) { display: none; }
                                    &:nth-child(2) { display: block; }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}