.modal {
    @media #{$mobile}{
        .modal-filename {
            width: 100% !important;
        }
    }
    &-content {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: 2px 8px 4px 0 rgba(0, 0, 0, 0.04);
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.07);
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        &::-webkit-scrollbar {
            width: sizer(.5);
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-track {
            background: $bright-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb {
            background: $spanish-gray;
            border-radius: $border-radius-8;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $spanish-gray;
        }

        .modal {
            &-header {
                position: relative;
                .modal-title {
                    margin-bottom: 0;
                    line-height: sizer(1.75);
                    font-size: sizer(1.25);
                    font-family: $inter;
                    @media #{$mobile}{
                        font-size: $font-size-18;
                    }
                }
            }
            &-body {
                position: relative;
                padding: sizer(3) 0;
            }
            &-footer {
                position: relative;
                border-top: 0;
                box-shadow: 0 -4px 2px 0 rgba(0, 0, 0, 0.06);
            }
        }
    }
    &-footer {
        background-color: transparent;
    }
}