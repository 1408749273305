.upload-process {
    margin-top: sizer(3);
    @media #{$medium_and_down} {
        margin-bottom: sizer(6);
        margin-top: sizer(2.5);
    }
    @media #{$tablet} {
        &.upload-progress-process{
            margin-top: 0;
        }
     }
    &-wrapper {
        position: relative;

        .paragraph-2 {
            color: $spanish-gray;
            padding: sizer(2) 0;
            font-weight: $font-weight-medium;
        }
    }
    &-grid-view {
        display: flex;
        gap: sizer(1.5);
        flex-wrap: wrap;
        width: 100%;
        @media #{$mobile} {
            column-gap: sizer(1.25);
            row-gap: sizer(2.5);
        }
        .upload-card-wrapper {
            flex: 0 0 calc(100% / 6 - sizer(1.5));
           // max-width: calc(100% / 6 - sizer(1.5));
            -webkit-box-flex: 0;
            width: 100%;
            margin-bottom: sizer(1.5);
            
            hr {
                display: none;
            }
            .upload-card {
                &-header {
                    border-top: 1px solid $chinese-white;
                    .upload-card-process {
                        color: $spanish-gray;
                    }

                    .upload-card-completed {
                        color: $black;
                    }

                    .upload-card-delete,
                    .upload-card-process,
                    .upload-card-error,
                    .upload-card-completed {
                        display: flex;
                        align-items: center;
                    }
                }
                &-type,
                &-termsofuse,
                &-delete,
                &-process,
                &-error,
                &-completed {
                    display: none;
                }

                &-pdf,
                &-audio {
                    border-top-left-radius: $border-radius-20;
                    border-top-right-radius: $border-radius-20;
                }


                &-cont:has(.upload-card-header > .upload-card-process) {
                    .upload-card-body {
                        .upload-card-bottom-container { display: none; }
                    }
                }

                &-cont:has(.upload-card-header > .upload-card-error) {
                    .upload-card-body {
                        .upload-card-bottom-container { display: none; }
                    }
                }

                &-cont:has(.upload-card-header > .upload-card-completed) {
                    .upload-card-body {
                        .upload-card-bottom-container { display: none; }
                    }
                }
            }

            @media #{$tablet} {
                flex: 0 0 calc(100% / 4 - sizer(1.25));
                max-width: calc(100% / 4 - sizer(1.25));
            }
            @media #{$small_and_down} {
                flex: 0 0 calc(100% / 1.98 - sizer(1.25));
                max-width: calc(100% / 1.98 - sizer(1.25));
                margin-bottom: 0;
            }
            @media screen and (max-width: $figma_small_screen) {
                flex: 0 0 calc(100% / 1.9 - sizer(1.25));
                max-width: calc(100% / 1.9 - sizer(1.25));
                margin-bottom: 0;
            }
            .upload-card-image::before { display: none; }
            .upload-card-footer { display: none;
                @media #{$mobile} {
                    padding: 1.2rem !important;
                }
            }

            // .upload-card-footer > p:first-child { display: block; }
            .upload-card-footer > div.grid-view-title-container {
                display: inline-flex;

                @media only screen and (max-width: 1600px) {
                    width: 80% !important;
                }
                @media #{$mobile} {
                    width: 100% !important;
                }
            }
            .upload-card-footer > div.list-view-title-container {
                display: none;

            }

            .upload-card-cont:hover .upload-card-image::before { display: block; }
            .upload-card-cont:hover .upload-card-footer { display: block; }

            .upload-card-image-icon {
                display: flex;
                align-items: center;
                justify-content: center;position: absolute;
                top: 16px;
                right: 24px;
                height: fit-content;
                width: fit-content;
                padding: 4px 12px;
                background-color: rgba(0, 0, 0, 0.20);
                border-radius: 20px;

                svg {
                    height: 16px;
                    width: 16px;

                    path {
                        fill: $white;
                    }
                }
                @media #{$mobile} {
                    top: 8px;
                    right: 17px;
                    padding: sizer(0.25) sizer(0.75);
                    svg {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
    }
    &-list-view {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        @media #{$mobile} {
            padding-bottom: sizer(6) !important;
        }
        &.disableDelete {
            .upload-process {
                &-list-sorting {
                    > div {
                        &:nth-child(1) {
                            width: 104px;
                            min-width: 0;
                        }
                        &:nth-child(3) {
                            padding: unset;
                        }
                        &:nth-child(5) {
                            width: 15%;
                        }
                    }
                }
            }
            .upload-card-wrapper {
                .upload-card {
                    &-header {
                        display: none !important;
                    }
                    // &-footer {
                    //     max-width: 300px;
                    // }
                    &-process,
                    &-error,
                    &-completed {
                        width: 15%; //15%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        @media #{$tablet} {
                            width: auto;
                            justify-content: flex-end;
                            .spinner-border{
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        
        .upload-card-wrapper {
            /*&:hover {
                background-color: $pale-orange;
            }*/

            &.highlight {
                background-color: $pale-orange;

                .upload-card-cont {
                    border: 1px solid transparent;
                    border-bottom: 1px solid $chinese-white;

                    &::before {
                        display: none;
                    }
                }
            }

            .upload-card-cont {
                border: 0;
                box-shadow: none;
                border-bottom: 1px solid transparent;
                @media #{$large_and_up} {
                    border-bottom: 1px solid $chinese-white;
                }
                @media #{$mobile} {
                    border-color: transparent;
                    // &::after{
                    //     content: "";
                    //     position: absolute;
                    //     width: 800px; //63%;
                    //     height: 1px;
                    //     bottom: 0;
                    //     background-color: $chinese-white;
                    //     transform: translateX(-105px);
                    //     @media screen and (max-width: 500px) {
                    //         width: 500px;
                    //     }
                    // }
                }
            }
        }

        .upload-process {
            &-list-sorting {
                display: flex;
                background-color: $primary;
                color: $white;
                padding: sizer(.5) 0;
                color: $white;
                width: 100%;
                @media #{$tablet} {
                    display: none;
                }

                > div {
                    display: flex;
                    align-items: center;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-medium !important;
                    cursor: pointer;

                    &:nth-child(1) {
                        // min-width: 48px;
                        // width: 11%;
                        width: 153px;
                        pointer-events: none;
                    }
                    &:nth-child(2) {
                        width: 54%;
                        // min-width: 80px;
                    }
                    &:nth-child(3) {
                        // min-width: 300px;
                        padding: 0 sizer(1.5);
                    }
                    &:nth-child(4) {
                        width: 25%;
                        padding-right: sizer(1);
                    }
                    &:nth-child(5) {
                        width: 15%;
                        pointer-events: none;
                    }

                    svg {
                        width: sizer(1);
                        height: sizer(1);
                        margin-left: sizer(.25);
                    }
                }
            }
        }

        .upload-card-wrapper {
            position: relative;
            width: 100%;
            border-radius: 0;

            button.mobile-more-vertical{
                padding: 0;
                margin-right: -8px;
                border: 0;
                background-color: $white;
                svg{
                   transform: rotate(90deg);
                   @media #{$tablet} {
                        width: 24px;
                        height: 24px;
                    }
                }

            }

            hr {
                display: none;

                @media #{$tablet} {
                    display: block;
                    margin: 0;
                    width: 140%;
                    transform: translateX(-48px);
                }
            }
            
            * {
                font-size: $font-size-16;
                line-height: $line-height-24;
                @media #{$tablet} {
                    font-size: $font-size-18;
                    line-height: $line-height-24;
                    button{
                        h3{
                            line-height: $line-height-24;
                            font-weight: 400;
                        }
                    }

                     hr {
                    //position: absolute;
                    width: 140%; //63%;
                    height: 1px;
                    bottom: 0;
                    background-color: $chinese-white;
                }
                }
            }
            @media #{$mobile} {
                &:nth-child(2) .upload-card-cont{
                    padding-top: 0;
                }
            }

            &:nth-child(2) .upload-card-cont{
                &:nth-child(1) {
                    @media #{$large_and_down} {
                        padding: 0 0 16px 0;
                    }
                }    
            }

            .upload-card-zoom {
                svg {
                    font-size: 1.5rem
                }
            }
            .upload-card {
                &-cont {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    border-radius: 0;
                    border-left: 0;
                    border-right: 0;
                    padding: 8px 0;
                  
                /* &:hover {
                        background-color: $pale-orange;
                    }*/
                }
                &-header {
                    min-width: 48px;
                    width: 48px;
                    border-bottom: 0;
                    order: unset;
                    border-top: unset;
                  
                    .upload-card-delete {
                        display: none;
                    }

                    &-list + div {
                        width: 50%;

                        @media #{$mobile} {
                            width: 100%;
                        }

                    }
                    @media #{$mobile} {
                        min-width: auto;
                        width: 32px;
                    }
                }
                &-body {
                    display: inline-flex;
                    width: 80px;
                    min-width: 80px;
                    order: 1;

                    .upload-card-audio {
                        &::before {
                            display: none;
                        }
                    }
                    .upload-card-icon,
                    .upload-card-pdf,
                    .upload-card-audio {
                        svg {
                            width: sizer(1.5);
                            height: sizer(1.5);
                        }
                    }
                    .upload-card-image,
                    .upload-card-pdf,
                    .upload-card-audio,
                    .upload-card-video {
                        .upload-card-audio-mute,
                        .upload-card-audio-duration,
                        .upload-card-video-mute,
                        .upload-card-video-duration,
                        .upload-card-zoom {
                            display: none;
                        }
                    }
                    .ratio {
                        &::before {
                            padding-bottom: 0;
                        }

                        .upload-card-image {

                            &::before {
                                content: unset;
                            }

                            img {
                                border-radius: unset;
                                height: 53px;
                                object-fit: cover;
                                transform: translateY(4px);
                            }
                        }
                    }

                    .upload-card-image-icon { display: none; }
                    .upload-card-bottom-container { display: none; }
                    .upload-card-video video { border-radius: unset; }
                }
                &-footer {
                    display: inline-flex;
                    align-items: center;
                    border-top: 0;
                    max-width: 85%;
                    padding: 0 sizer(1.5);
                    // height: 100px;
                    position: unset;
                    order: 2;
                    @media #{$mobile} {
                        padding: 0 sizer(0.5);
                        max-width: 72%;
                    }
                    p {
                        color: $black;

                        &:first-child { display: none; }
                    }

                    .list-view-title-container {
                        width:100%;
                        display:inline-flex;
                        flex-wrap:nowrap;
                        @media screen and (max-width: $xlarge_screen_up){
                            width: 80%;
                        }
                        .filename-container {
                            display: flex;
                        }

                        @media #{$tablet} {
                            display: flex;
                            flex-direction: column;
                        }

                        .card-type-text {
                            color: $spanish-gray;
                            padding-top: sizer(0.5);
                            @media #{$mobile} {
                                font-size: $font-size-12;
                                line-height: $line-height-16;
                            }
                        }

                        .first-text {
                            flex: 0 1 content;
                            text-overflow: ellipsis;
                            overflow:hidden;
                            white-space:nowrap;
                            max-width: fit-content;
                            width: auto;

                            @media #{$tablet} {
                                width: fit-content;
                                max-width: 300px;
                            }

                            @media #{$mobile} {
                                width: fit-content;
                                max-width: 200px;
                            }
                        }

                        .end-text {
                            flex: 1 0 content;
                            white-space:nowrap;
                        }
                    }
                    .grid-view-title-container {
                        display: none;
                    }
                }
                &-type,
                &-termsofuse {
                    width: 25%;
                    padding-right: sizer(1);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media #{$medium_to_large} {
                        text-align: center;
                        margin-right: 15%;
                    }
                    @media #{$mobile} {
                        display: none;
                    }
                }
                &-delete {
                    width: 15%;
                    display: flex;
                    justify-content: flex-start;
                    align-self: center;
                    gap: sizer(1.5);

                    @media #{$medium_to_large} {
                        display: none;
                    }
                    @media #{$mobile} {
                        display: none;
                    }

                    .upload-card-zoom {
                        width: 24px;
                        height: 24px;
                        padding-top: .25rem;
                    }
                    button {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.upload-process-list-sorting{
    height: 60px;
}

.flex-1{
    flex: 1;
}
