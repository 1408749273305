.profile-view {
    &-wrapper {
        // border-top: 1px solid $chinese-white;

        @media #{$tablet} {
            .alert-notification {
                margin: 40px 0 40px 0;
            }

            .mobile-open {
                display: block !important;
            }
            .mobile-close {
                display: none !important;
            }
        }

        .border-right {
            border-right: 1px solid $chinese-white;
            padding-right: sizer(1.5);

            @media #{$tablet}{
                border-right: none;
            }
        }

        .profile-btn {
            @media #{$tablet} {
                font-size: $mobile-font-size-18 !important;
                line-height: 26px !important;
            }
        }

        .hidden-border {
            border-right: unset;
        }

        .hidden { display: none; }

        .profile-mobile-tab {
            position: relative;
            display: none;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -7.5vw;
                max-width: 105vw;
                width: 105vw;
                height: 1px;
                background: $chinese-white;
            }

            @media #{$tablet} {
                display: flex;
                flex-direction: row;
                gap: 28px;
            }

            .tab {
                font-size: 18px;
                line-height: 26px;
                padding: 16px 4px;
                color: $spanish-gray;

                &.active{
                    position: relative;
                    color: black;

                    // active black line
                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 2;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background: black;
                        border-radius: 2px;
                    }
                }
            }
        }

        .header-icon, .header-name-initials {

            font-size: $font-size-48;
            font-weight: $font-weight-medium;
            line-height: $line-height-56;
            width: 104px;
            height: 104px;

            .profile-initals {
                font-family: $urbane-rounded;

                @media #{$tablet} {
                    width: 78px;
                    text-align: center;
                    left: -28px;
                    font-size: $mobile-font-size-32;
                    font-weight: 600;
                    line-height: 40px;
                }

                @media #{$medium_to_large} {
                    width: 80px;
                    text-align: center;
                    left: -30px;
                    font-size: $font-size-32;
                    font-weight: 600;
                    line-height: 40px;
                }
            }


            @media #{$tablet} {
                width: 78px;
                height: 78px;

            }

            @media #{$medium_to_large} {
                width: 80px;
                height: 80px;
            }
        }

        .title-caption {
            font-size: $font-size-12;
            font-weight: $font-weight-normal;
            line-height: $line-height-16;
            color: $spanish-gray;
            padding-bottom: sizer(.25);
        }
        .profile-data-caption {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;

            word-break: break-word;
            overflow-wrap: break-word;
        }
        .data-caption {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;
            padding-bottom: sizer(2.5);
        }

        .profile-group {
            padding: sizer(0) sizer(1.5) sizer(3) sizer(0);
            overflow-y: auto;

            @media #{$tablet} {
                padding: unset;
                overflow-y: unset;
            }

            p {
                margin-bottom: 2.5rem;
            }

            .button-group {
                display: flex;
                flex-wrap: wrap;
                gap: sizer(1.5);

                @media #{$tablet} {
                    margin-bottom: sizer(2);
                }
            }

            .profile-icon {
                padding-bottom: sizer(2.5);
                margin-left: 0px;

                .hover-image-bg {
                    //opacity: 0.8;
                    filter: brightness(0.7);
                    background-color: #341e71;
                }
                .hover-text-bg {
                    opacity: 1;
                }
                .no-blur-bg {
                    opacity: 1;
                }

                .initials-container {
                    font-size: $font-size-48;
                    font-weight: $font-weight-bold;
                    line-height: $line-height-56;

                    position: relative;
                    display: inline-block;

                    .image-upload-label {
                        position: absolute;
                        inset: 0;
                    }
                  }

                .processing-circle {
                    width: 72.5px;
                    height: 72.5px;
                    border-radius: 50%;
                    border: 10px solid $leafy-green;
                    border-top-color: $leafy-green;
                    animation: spin 1s linear infinite;

                    @media #{$tablet} {
                        width: 54.37px;
                        height: 54.37px;
                    }
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }

                .check-icon-overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    z-index: 1;background: $leafy-green;
                    color:$leafy-green;

                    .header-icon {
                        font-size: $font-size-48;
                        font-weight: $font-weight-medium;
                        line-height: $line-height-56;
                        width: 53.33px;
                        height: 53.33px;

                        @media #{$tablet}{
                            width: 40px;
                            height: 40px;
                        }
                    }

                    .bg-icon {
                        font-size: $font-size-48;
                        font-weight: $font-weight-medium;
                        line-height: $line-height-56;
                        width: 50.33px;
                        height: 50.33px;
                        color: white;
                        position: absolute;
                        top: -26px;
                        left: -25px;
                        z-index: -1;

                        @media #{$tablet}{
                            width: 38px;
                            height: 38px;
                            top: -19px;
                            left: -20px;
                        }
                    }
                }

                .camera-icon-overlay {
                    position: absolute;
                    bottom: -60px;
                    right: -60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white;
                    border-radius: 50%;
                    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08)) drop-shadow(1px 2px 2px rgba(152, 149, 149, 0.12));

                    @media #{$tablet} {
                        width: 40px;
                        height: 40px;
                        bottom: -50px;
                    }

                    .header-icon {
                        font-size: $font-size-48;
                        font-weight: $font-weight-medium;
                        line-height: $line-height-56;
                        width: 24px;
                        height: 24px;
                        color: $black;
                        //z-index: 1;background: #000000;
                        //opacity: 0.5;
                        &:hover{
                            opacity: 0.5;
                        }
                    }

                    .header-name-initials {
                        font-size: $font-size-48;
                        line-height: $line-height-56;
                        width: 34px;
                        height: 34px;
                    }
                }
            }

            .title-text {
                font-size: $font-size-28;
                font-weight: $font-weight-medium;
                line-height: $line-height-56;
                color: $black;

                @media #{$tablet} {
                    font-size: $mobile-font-size-24;
                    line-height: 32px;
                    padding-bottom: 40px;
                    margin-bottom: 0px;
                }
            }


            .form-container {
                .form-group:focus-within label {
                    color:$black;
                  }

                .form-group {
                    margin-bottom: sizer(1);
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    padding-bottom: sizer(2.5);

                    @media #{$tablet} {
                        margin-bottom: 0;
                    }

                    .department-select {
                        /* :hover {
                            border: 1px solid var(--neutral-chinese-white, #E1E1E1);
                        } */

                        .ant-select-selection-item {
                            font-size: $font-size-16;
                            font-weight: $font-weight-normal !important;
                            line-height: $line-height-24;
                            padding-left: 3px;
                        }

                        .ant-select-selector {
                            height: 60px;
                            border-radius: 16px;
                            //padding: 24px;
                        }

                        input {
                            font-size: $font-size-16;
                            font-weight: $font-weight-normal !important;
                            line-height: $line-height-24;
                            padding-left: 3px;
                            border: 0px !important;
                        }
                    }

                    label {
                        position: absolute;
                        top: -10px;
                        left: 20px;
                        background-color: $white;
                        padding: 0 5px;
                        font-size: 12px;
                        z-index: 1;
                    }

                    input {
                        font-size: $font-size-16;
                        font-weight: $font-weight-normal;
                        line-height: $line-height-24;
                        color: $black;
                        padding: sizer(1.5);
                        border: sizer(0.0625) $chinese-white solid !important;
                    }

                    input:focus {
                        border-color: $black !important;
                    }
                }
            }
        }

        .profile-message-container {
            //margin-top: sizer(3);
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 1.5rem 0 0rem;

            @media #{$tablet} {
                padding: 0;
            }
        }

        .profile-view-backup-code-2fa {
            border: 1px solid $chinese-white;
            border-radius: $border-radius-20;
            padding: sizer(1.5);
            margin-bottom: sizer(6);

            h3 {
                font-weight: $font-weight-bold;
                margin-bottom: sizer(.5);
            }
            p {
                margin-bottom: sizer(1.5);
            }

            button {
                font-size: $font-size-16;
                line-height: $line-height-24;
                font-weight: $font-weight-bold;
                color: $sunset-orange;
                padding: 0;
            }

            @media #{$tablet} {
                margin-bottom: sizer(3);
            }
        }

        .message-container {
            //margin-top: sizer(3);
            position: relative;
            //display: flex;
            //align-items: center;
            //justify-content: center;
            padding: 0 6rem 0 10.6rem;
            width: 46rem;

            @media #{$tablet} {
                padding: sizer(0) sizer(3) sizer(0) sizer(3);
                width: 100%;
            }

            @media #{$tablet} {
                padding: unset;
                width: unset;
            }
        }

        .message-group {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;

            background-color: $light-green;
            padding: 20px sizer(1);
            border-left: $leafy-green sizer(.5) solid;

            width: 100%;
            display: flex;
            gap: sizer(.5);

            .warning-icon {
                color: $leafy-green;
            }
        }

        .message-group-warning {
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;

            background-color: $dawn-pink !important;
            padding: 20px sizer(1);
            border-left: $candy-red sizer(.5) solid !important;

            width: 100%;
            display: flex;
            gap: sizer(.5);

            .warning-icon {
                color: $candy-red !important;
            }
        }
        .top-adjust {
            margin-top: sizer(6) !important;

            @media #{$tablet} {
                margin-top: sizer(3) !important;
            }

            .button-group button {
                font-size: $font-size-16;
                line-height: $line-height-24;
            }
        }

        .change-password-group {
            padding: sizer(0) sizer(6) sizer(3) sizer(10.6);
            width: sizer(46);

            @media #{$tablet} {
                padding: sizer(0) sizer(3) sizer(3) sizer(3);
                width: 100%;
            }

            @media #{$tablet} {
                padding: unset;
                width: unset;
            }

            .title-text {
                font-size: $font-size-28;
                font-weight: $font-weight-medium;
                line-height: 36px;
                color: $black;
                padding-bottom: sizer(3);
                margin-bottom: unset;

                @media #{$tablet} {
                    font-size: 24px;
                    line-height: 32px;
                    padding-bottom: 40px;
                }
            }

            .form-container {
                padding-bottom: sizer(3.25);

                .password-validation {
                    margin-bottom: sizer(1) !important;
                }
                .form-group:focus-within label {
                    color:$black;
                }

                .form-group {
                    // margin-bottom: sizer(1);
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: sizer(2.5);

                    .form-control.warning-input {
                        border-color: $candy-red !important;
                    }

                    p {
                        margin-bottom: sizer(.5);
                        display: flex;
                        align-items: center;
                        gap: sizer(.5);
                        line-height: $line-height-24;
                        &:last-child { margin: unset; }
                    }

                    input:focus {
                        border-color: $black !important;
                    }

                    .eightchar, .lowercase, .uppercase {
                        color:$spanish-gray;
                    }

                    .newpassvalid {
                        color:$black;
                        svg {
                            color:$leafy-green
                        }
                    }

                    .warning-label {
                        color: $candy-red !important;
                    }

                    .reenterpassword-warning {
                        color:$candy-red;
                        font-weight: $font-weight-bold;
                        svg {
                            color:$candy-red
                        }
                    }
                }
            }

            .reenterpassword-group {
                margin-top: sizer(-2) !important;
                padding-bottom: 1rem !important;
            }
        }

    }
}

.profile-view-backup-code-2fa {
    &.modal {
        .modal-body {
            .form-container {
                position: relative;
                max-width: 560px;
                margin-top: sizer(2);

                .form-group + span {
                    position: absolute;
                    display: flex;
                    left: 0;
                    font-weight: $font-weight-bold;
                    //bottom: calc((25px + 8px) * -1);

                    svg {
                        margin-right: 8px;
                    }

                    p {
                        margin-bottom: 0 !important;
                    }
                }

                @media #{$tablet} {
                    max-width: 100%;
                }
            }
            .view-backup-code {
                border-radius: $border-radius-20;
                border: 1px solid $chinese-white;
                font-weight: $font-weight-inter-bold;
                padding: sizer(1.5);
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: sizer(.5) sizer(1.5);
                justify-content: center;
                align-items: center;
                text-align: center;
                max-width: 560px;
                margin: auto;

                @media #{$tablet} {
                    max-width: 100%;
                }
            }
            .paragraph-1 {
                margin-bottom: sizer(1.5);
            }
        }
    }
}