.image-cropper-modal {
    .image-cropper-section {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 561px;
      position: relative;
      // padding: 48px 48px 0 48px;
    
      .image-cropper-container {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
  
        img {
          // height: 561px;
        }
    
        .reactEasyCrop_Container {
            width: 461px;
            height: 461px;
            top: -210px;
            left: -234px;
        }
      }
    }
    
    .image-cropper-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      // padding: 0 48px 0 48px;
  
      @media #{$large_and_down} {
        flex-direction: column;
        gap: 12px;
      }
    }
    
    .modal-content {
      padding-top: unset;
      gap: 24px;
    }
    
    .modal-body {
        padding: 48px !important;
    }
    
    .modal-dialog {
      max-width: 744px;
    }
  }