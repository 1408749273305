.dropdown {
    .dropdown-toggle {
        span {
            margin-left: sizer(3);
            color: $spanish-gray;
        }
    }
    .dropdown-menu {
        width: 100%;

        &-loading {
            padding: sizer(1);

            .spinner-grow {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
        }

        .dropdown-item {
            line-height: $line-height-24;

            &:focus {
                background-color: $primary;
                color: $white;
            }
        }

        a {

        }
        a {
            padding: sizer(.75) sizer(1);
            display: flex;
            justify-content: space-between;

            &:not(:last-child) {
                border-bottom: 1px solid $chinese-white;
            }

            span {
                &:nth-child(2) {
                    color: $spanish-gray;
                }
            }
        }
    }

    button {
        border: 1px solid $chinese-white;
        width: 100%;
        text-align: left;
        padding: sizer(1.5);
        font-size: $font-size-16;
        line-height: $line-height-24;
        color: $black;
        height: auto;

        &::after {
            display: none;
        }
        &:hover {
            border: 1px solid $chinese-white;
        }
    }
}

.ant-dropdown {
    min-width: 250px !important;
    max-width: 250px !important;

    &-open {
        .ant-dropdown-arrow {
            transform: rotate(180deg);
        }
    }
    &-select {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .ant-tag-container {
            color: $spanish-gray;
            margin-right: sizer(.25);
        }
    }
    .ant-dropdown {
        &-menu {
            padding: sizer(1);
            border: 1px solid $chinese-white;
            border-radius: $border-radius-20;
            font-family: $inter;
            &-note {
                color: $spanish-gray;
                font-weight: $font-weight-normal;
                font-size: $font-size-12;
                line-height: $line-height-16;
                width: 100%;

                span {
                    display: block;
                }
            }

            .ant-dropdown-menu {
                &-item {
                    padding: 0;
                    color: $black;
                    font-size: $font-size-16;
                    line-height: $line-height-24;

                    &:not(:last-child) {
                        margin-bottom: sizer(1);
                    }

                    .form-check {
                        margin: 0;

                        &-label {
                            font-size: $font-size-16;
                            font-weight: $font-weight-normal;
                        }
                    }
                }

                &-divider {
                    pointer-events: none;

                    hr {
                        margin: sizer(.5) 0;
                    }
                }

                &-clear-all {
                    text-align: center;
                    font-weight: $font-weight-bold;
                    color: $sunset-orange;
                    a{
                        color: $sunset-orange;
                        &:hover{
                            color:rgb(255, 105, 70, 0.5);
                        }
                    }
                }
            }
        }
    }
}