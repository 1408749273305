.btn-dropdown{

    position: relative;
    overflow: visible;

}
.dropdown-box {
    position: absolute;
    z-index: 200;

    padding: 1rem;
    font-family: "inter";
    border-radius: 20px;
    border: 1px solid var(--neutral-black, #000);
    background: var(--neutral-white, #FFF);
    box-shadow: unset;
    cursor: pointer;

    ul{
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 4px 8px;
            border-radius: 8px;
            white-space: nowrap;
            text-align: left;
            &:hover {
                background-color: #F5F5F5 !important;
            }
        }
    }
}