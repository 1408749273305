.search-view-section {
  background-color: $white;
  padding: sizer(6) 0;

  .search-group {
    padding: 0 10%;

    .search-title {
      font-size: $font-size-56;
      font-weight: $font-weight-medium;
      line-height: sizer(4);
      text-align: center;
      margin-bottom: sizer(3);
    }

    .search-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 248px;
      border-radius: unset;
      flex: none;
      order: 0;
      flex-grow: 0;
      position: relative;
      //box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
      // filter: drop-shadow(2px 8px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07));

      @media #{$medium_and_down} {
        width: 100%;
        height: 80px;

        + .input-group {
          height: 80px;
        }
      }
    }

    .search-dropdown .btn:hover,
    .search-dropdown .btn {
      font-size: $font-size-20;
      font-weight: $font-weight-normal;
      line-height: $line-height-28;
      color: $black;

      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      background: var(--neutral-white, $white);
      border-radius: $border-radius-20;

      border-style: none;
      padding: unset;

      .MuiSvgIcon-root path {
        fill: $primary !important;
      }
    }

    .search-dropdown.show.dropdown {
      border-radius: $border-radius-20 $border-radius-20 0 0;
    }

    .search-dropdown .dropdown-item {
      font-size: $font-size-20;
      font-weight: $font-weight-normal;
      line-height: $line-height-32;
      padding: sizer(.25) sizer(.5);
      border-bottom: 0px;
      opacity: unset;
      width: sizer(16.75);
    }

    .search-dropdown .dropdown-item.active {
      background: $black !important;
      border-radius: $border-radius-8;
      color: $white;
      pointer-events: none;
    }

    .search-dropdown .dropdown-item:hover {
      background: $light-gray;
      border-radius: $border-radius-8;
    }

    .search-dropdown .btn .MuiSvgIcon-root {
      position: absolute;
      right: 0;
      transition: transform 0.3s ease;
      color: $primary !important;
      height: sizer(1.75);
      width: sizer(1.75);
    }

    .search-dropdown .btn .MuiSvgIcon-root:hover path {
      fill: $spanish-gray !important;
    }

    .search-dropdown .dropdown-menu.show {
      transform: translateY(80px) !important;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: sizer(1);
      gap: sizer(0.5);
      width: 100%;
      min-width: sizer(18.75);
      height: fit-content;
      background: $white;
      //border-style: none;
      border: 1px solid $primary;
      border-radius: 20px;
      //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
      //filter: drop-shadow(2px 8px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07));
    }

    @media #{$xxlarge_and_up} {
      padding: 0 20%;
    }

    @media #{$large_and_down} {
      padding: 0;
    }

    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .explore-input-container {
      display: flex;
      gap: 24px;
      align-items: center;
      padding-left: sizer(3);
      margin-left: sizer(3);
      border-left: 1px solid $chinese-white;
      flex-wrap: nowrap;
      flex-direction: row;

      #button-search:hover, #button-image-search:hover {
        background-color: $black;
        opacity: .5;
        svg path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }

      #button-image-search {
          border-radius: 50px;
          -moz-border-radius: 50px;
          -webkit-border-radius: 50px;
          border: none;
          height: 33.33px;
          width: 33.33px;
          padding-left: 5px;
      
          &:hover svg path { fill: $spanish-gray; }
      }
      
      #button-close {
          opacity: unset;
      }

      #button-close:hover {
        opacity: 0.5;
      }
    }
  }

  .search-main-section {
    padding-top: 207px;
  }

  .blackborder {
    border: 1px solid $black !important;
  }

  .input-btn-group {
    display: flex;
    border-radius: $border-radius-40;
    border: 1px solid $chinese-white;
    padding: sizer(1.5) sizer(3);

    .search-input::placeholder {
      color: $spanish-gray;
    }

    .search-input {
      font-size: $font-size-20;
      font-weight: $font-weight-normal;
      line-height: $line-height-28;
      border: none;
      padding: unset;
      // filter: drop-shadow(2px 8px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07));
      filter: unset;
      //box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
      /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
      box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.04); */


      @media #{$large_and_down} {
        width: 70%;
      }
    }
    /* .search-input:hover, .search-input:focus {
      background: $white;
      border-radius: $border-radius-20 0px 0px $border-radius-20;
      border-color: $light-gray;
    } */

    .input-group .btn-search {
      border-radius: 50%;
      background-color: $black;

      @media #{$large_and_down} {
        padding: sizer(0.75);
      }
      // filter: drop-shadow(2px 8px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
    }

    .input-group .btn-close {
      border-radius: 50%;
      margin-right: 48px;
      
      svg {
        display: none;
      }

      @media #{$large_and_down} {
        padding: sizer(0.75);
        margin-right: -10px;
      }
      // filter: drop-shadow(2px 8px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
    }

    .input-group .btn-close::after {
      border-left: 1px solid $chinese-white;
      content: "";
      display: inline-block;
      height: 100%;
      position: absolute;
      top: -1px;
      right: 77px;
      
      @media #{$large_and_down} {    
        content: unset;
      }
    }

    @media #{$medium_and_down} {
      flex-wrap: wrap;
    }
  }

  .mobile-search-dropdown { display: none; }

  & + .container {
    .asset-view-container {
      .mobile-type-active-container { display: none; }
      .mobile-filter-container { display: none; }
      .mobile-view-container { display: none; }
    }
  }

  @media #{$large_and_down} {
    padding: unset;
    margin-bottom: sizer(3);

    .search-group {
      h2.search-title.display {
        font-size: $mobile-font-size-32;
        line-height: $mobile-line-height-40;
        margin-top: sizer(3);
        margin-bottom: sizer(2.5);
        word-wrap: break-word;
      }

      .input-btn-group {

        @media #{$mobile} {
            width: 100%;
        }

        padding: unset;
        flex-direction: row;
        gap: sizer(1);
        flex-wrap: nowrap;
        height: 60px;
        position: relative;
        padding-left: sizer(1.5);
        padding-right: sizer(1.5);
        box-sizing: border-box;
        width: 75%;
        margin: 0 auto;

        &:has(.explore-input-container > input:focus) {
          border: 1px solid $black;
        }

        .mobile-search-dropdown {
          width: fit-content;
          padding-right: sizer(1);
          height: calc(100% - sizer(2));
          position: relative;
          display: flex;
          align-items: center;
          // gap: clamp(2px, 0.5vw, 4px);
          gap: sizer(0.25);
          border-right: 1px solid #DADADA;
          margin: sizer(1) 0;
        }

        .search-dropdown.dropdown {
          display: none;
        }

        .explore-input-container {
          padding: unset;
          margin: unset;
          width: 80%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: space-between;
          gap: unset;
          border: unset;
          flex-direction: row;

          input {
            width: 80%;
            height: 24px;
            min-width: unset;
            flex: unset;
            padding: unset !important;
            border-radius: unset;
          }

          button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: sizer(1.5) !important;
            height: sizer(1.5) !important;
            border-radius: 50%;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              font-size: 20px;
              height: 20px;
              width: 20px;
              margin-left: 7.3px;
            }
          }
        }
      }
    }

    & + .container {
      section.pb-5:first-child {
        padding-bottom: sizer(2.5) !important;

        h2.h2-mb-48 {
          margin-bottom: sizer(2.5) !important;
        }

        .asset-view-container {
          flex-direction: row;
          justify-content: space-between;
          // gap: 51.5px;
          width: unset;
          flex-wrap: nowrap;
          margin: unset;
          padding-bottom: sizer(1);
          position: relative;

          &::before {
            content: "";
            height: 0.5px;
            width: 100%;
            position: absolute;
            bottom: -1px;
            left: 0;
            clip-path: inset(0 -100vmax);
            box-shadow: 0 0 0 100vmax $chinese-white;
            border-bottom: 1px solid $chinese-white;
          }

          .mobile-type-active-container {
            display: flex;
            align-items: center;
            width: 127px;
            padding: unset;
            cursor: pointer;

            h3 {
              margin-bottom: unset;
              margin-right: sizer(.25);
              display: inline-block;
              font-weight: $font-weight-normal;
            }
          }

          .mobile-filter-container {
            display: flex;
            align-items: center;
            width: 112px;
            padding: unset;
            cursor: pointer;

            h3 {
              margin-bottom: unset;
              margin-right: sizer(.25);
              display: inline-block;
              font-weight: $font-weight-normal;
            }
          }

          .mobile-view-container {
            display: block;
            width: 24px;
            padding: unset;
            cursor: pointer;

            .mobile-view-icon {
              &.show {
                display: block;
              }

              &.hide {
                display: none;
              }
            }
          }

          .navbar {
            display: none;
          }
        }
      }

      section.pb-5:last-child {
        padding-bottom: sizer(3) !important;

        .assets-container {
          margin: unset !important;

          .asset-card-wrapper {
            &:last-child {
              margin-bottom: unset !important;
            }
            &:nth-child(4) {
              margin-top: sizer(3);
            }
          }

          .asset-view-no-asset {
            padding: unset !important;
            img {
              width: 221.89px;
              height: 225px;
            }

            p {
              text-align: center !important;
              margin-bottom: sizer(2.5) !important;
            }
          }

          .no-cards-found {
            padding: unset;
            margin-top: unset;

            ul {
              padding-left: sizer(1.6);

              li {
                font-size: $font-size-16;
                line-height: $line-height-24;
              }
            }
          }
        }
      }
    }
  }
}