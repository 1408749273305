.registration {
    &-wrapper {
        display: flex;
        width: 100vw;
    }

    &-left-cont {
        position: relative;
        z-index: 1;
        background-color: $white;

        .registration {
            &-form {
                &:has(.message-container > .alert-notification-success) {
                    .message-container {
                        margin-bottom: sizer(3);
                    }

                }
            }
        }

        @media #{$mobile} {
            &.col-5 {
                width: 100vw;
                flex: unset;
                margin: sizer(1.5);
                box-sizing: border-box;

                .registration {
                    &-form {
                        padding: unset;

                        &:has(.message-container > .message-group) {
                            .registration-header {
                                margin-bottom: sizer(4);
                            }
                            .message-container {
                                margin-bottom: sizer(2.5);

                                .message-group {
                                    position: relative;
                                    margin-bottom: unset !important;
                                    // div:last-child {
                                    //     transform: translateY(5px);
                                    // }
                                }
                            }
                        }

                        &:has(.message-container > .alert-notification-success) {
                            .registration-header {
                                margin-bottom: sizer(4);
                            }
                            .message-container {
                                margin-bottom: sizer(2.5);

                                .alert-notification-success {
                                    .alert-notification-success-group {
                                        align-items: flex-start;
                                    }
                                }
                            }

                        }

                        & > h1 {
                            font-size: $mobile-font-size-32;
                            line-height: $mobile-line-height-40;
                            padding-bottom: sizer(1.5);
                        }

                        & > h3 {
                            padding-bottom: sizer(3.25);
                            font-weight: 400;

                            &.font-inter {
                                strong {
                                    font-weight: $font-weight-bold;
                                }
                            }
                        }

                        .paragraph {
                            &-1 {
                                line-height: $line-height-24;
                                margin-top: sizer(1);

                                &.mb-5 {
                                    margin-bottom: sizer(2.5) !important;
                                }
                            }
                        }

                        .form-container {
                            padding-bottom: sizer(3.25);

                            .form-group {
                                button { height: 52.9px; }
                            }

                            .password-container {
                                .valid-password {
                                    display: flex;
                                    align-items: center;

                                    svg { margin-right: sizer(.5); }
                                }
                            }

                            &:nth-child(9) {
                                padding-bottom: sizer(2.5);
                            }

                            .form-error {
                                position: relative;

                                .username-char-limit {
                                    bottom: -20px;
                                }

                                .error-msg {
                                    //position: absolute;
                                    //bottom: -28px;
                                    display: flex;
                                    align-items: center;
                                }
                            }

                            &:has(.form-group > #verify-code){
                                padding-bottom: sizer(2.5);

                                .form-group {
                                    .verify-code-spinner {
                                        top: 0.9rem;
                                    }
                                }
                            }
                        }

                        .form-privacy-policy {
                            margin-bottom: sizer(2.5);

                            p {
                                line-height: $line-height-24;
                            }
                        }

                        .registration-reset-email {
                            margin-top: unset;
                        }
                    }

                    &-header {
                        margin-bottom: sizer(1.5);

                        img {
                            width: 122px;
                            padding-right: unset;
                        }
                    }
                }
            }
        }
    }

    &-right-cont {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;
        height: 100%;
        // border-left: 1px solid black;
        background-color: $light-gray;

        @media #{$mobile} {
            display: none;
        }
        .content-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .content-wrapper{
                padding: 0 sizer(2) 0 sizer(4);
                img{
                    width: 100%;
                }
                h2{
                    margin-bottom: sizer(1.25);
                }
            }
        }

        svg {
            width: 150vh;
            height: 80vh;
        }

        .ring-icon {
            width: 80px;
            height: 80px;
            float: right;
            margin-bottom: 82px;
            margin-right: -40px;
            position: fixed;
            top: auto;
            bottom: 0%;
            left: auto;
            right: 50%;
            z-index: 2;
        }

        .ring-top-icon {
            width: 80px;
            height: 80px;
            float: right;
            margin-top: 82px;
            margin-right: -40px;
            position: fixed;
            top: 0%;
            bottom: auto;
            left: auto;
            right: 58.4%;
            z-index: 2;
        }

        .content-desc1 {
            font-size: $font-size-48;
            font-weight: $font-weight-normal;
            line-height: $line-height-56;
            color: $black;
            padding: sizer(7) sizer(3) sizer(3) sizer(4.5);
        }
        .content-desc2 {
            font-size: $font-size-20;
            font-weight: $font-weight-normal;
            line-height: $line-height-28;
            color: $black;
            padding-top: sizer(3.5);
            padding-right: sizer(5);
            text-align: right;
        }
        .calendar-display {
            padding-top: sizer(3.5);
            padding-left: sizer(7.5);

            .calendar {
                font-size: $font-size-16;
                font-weight: $font-weight-normal;
                line-height: $line-height-24;

                height: 352px;
                width: 852px;
                background-color: $white;
                border: 1px solid #E1E1E1;
                border-radius: 20px;

                display: flex;
                gap: sizer(3);
            }

            .calendar-box {
              flex-grow: 1;
              padding: 10px;
            }

            .calendar h2 {
                font-weight: $font-weight-bold;
                color: $sunset-orange;
                text-align: center;
                //margin-bottom: 10px;
            }

            .calendar .week {
                display: flex;
                justify-content: space-between;
                //margin-bottom: 5px;
            }

            .calendar .day {
                width: calc(100% / 7);
                padding: sizer(.5);
                text-align: center;
                //border: 1px solid #ccc;
            }

            .calendar .highlight {
                background-color: #ffcc00;
            }

            .calendar .gray-color {
                color: $spanish-gray
            }
        }
    }

    &-header {
        margin-bottom: sizer(3);
        padding: sizer(0) sizer(8) sizer(0) sizer(6);
        @media #{$small_to_large} {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: #ffffff;
            padding: sizer(1.5) 0 sizer(1.5) sizer(3);
        }
       
        @media #{$small_and_down} {
            padding: 0 sizer(1.5) sizer(1.5); 
        }
        .dam-logo-img {
            max-width: 100%;
            height: 48px;
            padding-right: sizer(.5);
            width: auto;

            // @media #{$large_and_down} {
            //     margin-bottom: sizer(1.5);
            // }
        }

        /* //border-bottom: 1px solid $spanish-gray;
        padding: sizer(2) 0;

        h3 {
            margin-bottom: 0;
        } */
    }

    &-reset-email {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: sizer(1.5);

        button {
            color: $sunset-orange;
            align-items: center;

            &:disabled {
                pointer-events: none;
                color: $spanish-gray;
            }

            .spinner-border {
                margin-left: sizer(.5);
            }
        }
    }
    &-form {
        max-width: $large_screen;
        margin: auto;
        padding: sizer(1.5) sizer(0) sizer(8.25) sizer(0);

        @media #{$small_to_large} {
            padding: 173px sizer(1.5) sizer(8.25) sizer(1.5);
            background: #F5F5F5;
            min-height: 100vh;
        }
        // @media (min-width: 1440px) {
            .general-container {
                max-width: 560px;
                margin: 0 auto;
                &>div.content-wrapper{
                    max-width: 414px;
                    margin: 0 auto;
                    // @media #{$tablet}{
                    //     max-width: unset;
                    //     margin: unset;
                    // }
                }
                @media #{$small_to_large} {
                    width: 560px;
                    margin: 0 auto;
                    background: white;
                    border-radius: $border-radius-20;
                    padding: sizer(3);
                    box-shadow: 0px 1px 10px 0px rgba(176, 176, 176, 0.12), 0px 4px 5px 0px rgba(150, 150, 150, 0.14), 0px 2px 4px 0px rgba(151, 151, 151, 0.15)
                }
                @media #{$small_and_down} {
                    width: 100%;
                    padding: 0 sizer(1.5);
                }
            }
        // }

        .verify-code-spinner {
            position: absolute;
            right: sizer(1.5);
            top: sizer(1.5);
        }

        .message-container {
            position: relative;
            //margin-bottom: sizer(3);
            display: flex;
            align-items: center;
            min-height: sizer(3);
            height: auto; //64px;

            .alert-notification {
                position: relative;
                top: 0;
                left: 0;
                margin: 0;
                margin-bottom: sizer(3);
                width: 100%;
                @media #{$mobile}{
                    margin-bottom: sizer(2.5);
                }
                @media (min-width: $large_screen) {
                    margin-bottom: 0;
                }
            }
        }
        p{
            a{
                &:hover{
                    color: $sunset-orange;
                    opacity: 0.5;
                }
            }
        }
        .message-group {
            position: relative;
            top: 0;
            left: 0;
            font-size: $font-size-16;
            font-weight: $font-weight-normal;
            line-height: $line-height-24;
            color: $black;

            background-color: $dawn-pink;
            padding: sizer(1.25) sizer(1);
            border-left: $candy-red sizer(.5) solid;

            width: 100%;
            display: flex;
            gap: sizer(.5);
            margin-bottom: sizer(3);

            .warning-icon {
                color: $candy-red;
            }
        }

        .username-char-limit {
            //position: absolute;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            right: 0;
            //width: 100%;
            bottom: -16px;
            font-size: $font-size-12;
            line-height: $line-height-16;
            font-weight: $font-weight-inter-regular;
            color: $spanish-gray;
            pointer-events: none;

            &.max-limit {
                justify-content: space-between;
                width: 100%;
            }
            &.special-char-limit{
                width: unset !important;
            }
            &.username-limit{
                position: absolute;
                width: 100%;
            }
        }

        h1 {
            color: $black;
            padding: sizer(3) 0 sizer(1.5) 0;
            margin-bottom: unset;
            text-align: center;
            &.verify-email{
                text-align: center;
                @media #{$large_and_up} {
                    text-align: start;
                }
            }
            @media #{$large_and_down} {
                padding: 0 0 sizer(1.5) 0;
            }
        }

        h3 {
            margin: 0;
            text-align: center;
            font-weight: 400;
        }

        .form-container {
            padding-bottom: sizer(1.5);
            padding-top: 4px;

            .eightchar, .lowercase, .uppercase {
                color:$spanish-gray;
            }

            .uppercase {
                padding-bottom: sizer(3);
                margin-bottom: unset;
            }

            .newpassvalid {
                color:$black;
                svg {
                    color:$leafy-green
                }
            }
        }
        .forgot-password-cont {
            padding-bottom: sizer(.5);

            .nav-link {
                font-size: $font-size-16;
                font-weight: $font-weight-bold;
                line-height: $line-height-24;

                padding: 0rem 0rem 0.25rem 0rem;
            }
        }

        .form-group:focus-within label {
            color:$black;
        }

        .form-group {
            margin-bottom: sizer(1);
            display: flex;
            flex-direction: column;
            position: relative;
            label{
                top: sizer(-0.688);
                left: sizer(1.25);
                padding: 4px;
                line-height: sizer(1);
            }
            &:last-child {
                margin: 0;
                .error-msg {
                    font-weight: $font-weight-bold;
                }
            }
            &.form-error{
                &:focus-within label {
                    color: $candy-red !important;
                }
                input {
                    border-color: $candy-red;
                    box-shadow: none;
                    color: $primary;

                    &:focus {
                        color: $black;
                    }
                }
                .show-password-icon {
                    &.reenter-btn {
                        top: 36% !important;

                        @media (max-width: $medium_screen_up) {
                            top: 33.5% !important;
                        }
                    }
                }
            }
            &.password-container {
                color: $spanish-gray;

                p {
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    font-weight: $font-weight-normal;
                    margin-bottom: sizer(0.5);

                    &:last-child {
                        margin-bottom: 0;
                    }
                    // @media #{$xlarge_and_up} {
                    //     &:nth-child(2),
                    //     &:nth-child(3) {
                    //         width: 50%;
                    //         margin-bottom: 0;
                    //     }
                    // }
                }

                .valid-password {
                    color: $black;
                }
            }

            .new-password-group {
                margin-bottom: unset;
                padding-bottom: sizer(3);
            }

            .reenterpassword-group {
                margin-bottom: unset;
            }

            .form-control.warning-input {
                border-color: $candy-red !important;
            }

            .label-container {
                margin-bottom: 5px;
            }

            .warning-label {
                color: $candy-red !important;
            }
        }
        .form-privacy-policy {
            display: flex;
            justify-content: center;
            p {
                line-height: $line-height-24;
                max-width: 366px;
            }
            @media #{$small_and_down} {
                margin-bottom: sizer(2.5); 
            }
        }

        .button-group {
            display: flex;
            align-items: center;
            padding-bottom: sizer(1.5);
            button, a {
                width: 100%;
                justify-content: center;
            }
        }
        .existing-user-login{
            text-align: center;
            a{
                font-weight: 700;
                color: $sunset-orange;
            }
        
        }
        .button-group.verify-button-group {
                margin-top: unset;
                padding-top: sizer(3);
            }
        .newpassvalid {
            color:$black;

            svg {
                color:$leafy-green
            }
        }

        .reenterpassword-warning {
            color:$candy-red;
            font-weight: $font-weight-bold;
            margin-bottom: unset;
            svg {
                color:$candy-red;
            }
        }
    }
}