.upload-card {
    &-click-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    &-pdf {
        &.upload-card-pdf-loaded {
            img {
                display: block;
            }
            .upload-card-icon {
                display: none;
            }
        }
        .upload-card-icon {
            width: 100%;
            height: 100%;
        }
        img {
            display: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    &-image {
        img {
            width: 100%;
            height: 100%;
        }
    }

    &-zoom-2{
        display: flex;
        flex: 1;
        height: 100%;
        width: 100%;
        background-size: cover;
    }
    &-zoom {
        border-radius: $border-radius-4;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 3;
        opacity: 0;
        pointer-events: none;
        width: clamp(1rem, 0.8766rem + 0.5195vw, 1.5rem);
        height: clamp(1rem, 0.8766rem + 0.5195vw, 1.5rem);
        cursor: pointer;

        .audio-zoom-svg{
            width: clamp(1rem, 0.8766rem + 0.5195vw, 1.5rem);
            height: clamp(1rem, 0.8766rem + 0.5195vw, 1.5rem);
        }

        &:hover {
            //transform: scale(.9);
            svg:not(.my-svg){
                path{
                    fill: $spanish-gray;
                }
            }
        }

        &-visible {
            opacity: 1;
            pointer-events: all;
            height: Min(24px, 100vw/80);
            width: Min(24px, 100vw/80);
            @media #{$mobile} {
                width: 24px;
                height: 24px;
            }
            /* svg {
                height: Min(24px, 100vw/80);
                width: Min(24px, 100vw/80);
            } */
        }

        svg:not(.my-svg) {
            path {
                fill: $black;
            }
        }
    }
    &-audio,
    &-video,
    &-image,
    &-icon,
    &-pdf {
        width: 100%;
        height: 100%;
        border-top-right-radius: $border-radius-20;
        border-top-left-radius: $border-radius-20;
        cursor: pointer;
    }
    &-delete {
        padding: 0;
    }
    &-process {
        display: flex;
        align-items: center;
        font-size: $font-size-16;

        .spinner-border{
            width: 20px;
            height: 20px;
            margin-right: sizer(.5);
        }
    }
    &-error {
        svg {
            margin-right: sizer(.5);

            path {
                fill: $candy-red;
            }
        }
    }
    &-completed {
        line-height: $line-height-24;

        svg {
            margin-right: sizer(.5);
            font-size: sizer(1.5) !important;

            path {
                fill: $leafy-green;
            }
        }
        @media #{$tablet} {
            &-list{
                justify-content: flex-end !important;
                svg{
                    margin-right: 0;
                }
            }
        }
    }
    &-checkbox {
        position: relative;
        display: flex;
        gap: sizer(1.5);
        align-items: center;
        width: 100%;
        @media screen and (max-width: 360px) {
            gap: unset;
            justify-content: space-between;
        }
        input {
            border: 1px solid $spanish-gray;
            width: $icon-size;
            height: $icon-size;
            margin: 0;
            transition: .25s all ease-in-out;
            position: absolute;
            inset: 0;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }

        label {
            &.custom-checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: $icon-size;
                height: $icon-size;
                border: 1px solid $black;
                border-radius: 4px;
                cursor: pointer;
                background-color: $white;
                input {
                    border: 1px solid $black;
                }
                svg {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 4px;

                    path  {
                        fill: $white;
                    }
                    @media #{$tablet} {
                        height: 110%;
                        width: 108%;
                    }
                }
            }
            .ant-checkbox-checked{
                input.ant-checkbox-input, .ant-checkbox-inner{
                    border: 1px solid $black;
                }
            }
            @media #{$tablet} {
                &.ant-checkbox-wrapper:hover {
                    opacity: 1;
                    .ant-checkbox-inner:hover{
                        opacity: 1;
                    }
                }
            }
        }

        input[type="checkbox"]:checked + .custom-checkbox svg {
            background-color: $black;
            border-radius: inherit;
        }


        input[type="checkbox"]:checked + .custom-checkbox svg path {
            fill: $white;
        }


        &.without-zoom {
            gap: sizer(1.5);
        }
    }
    &-wrapper {
        border-radius: $border-radius-20;

        &.highlight {
            .upload-card-cont {
                position: relative;
                border: 1px solid transparent;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border: 4px $sunset-orange solid;
                    border-radius: $border-radius-20;
                    z-index: 1;
                }
            }
        }
    }
    &-cont {
        border: 1px solid $chinese-white;
        border-radius: $border-radius-20;
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 4px solid transparent;
            border-radius: $border-radius-20;
            z-index: 5;
            pointer-events: none;
        }

        &-checked {
            border-color: $black;
        }

        &:hover {
            .upload-card-body .upload-card-audio::before {
                opacity: 1;
            }

            .upload-card-body .upload-card-pdf::before {
                opacity: 1;
            }
            .upload-footer-hover::before{
                //display: none;
                position: absolute;
                content: "";
                width: 100%;
                height: 80px;
                top: -19px;
                bottom: 0;
                left: 0;
                z-index: 2;
                opacity: 1;
                transition: opacity 0.25s ease-in-out;
                pointer-events: none;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(217, 217, 217, 0) 100%);
                @media #{$mobile} {
                    display: none;
                }
            }
            @media #{$mobile} {
                .grid-view-title-container{
                    display: none !important;
                }
            }
        }
    }
    &-header {
        padding: $responsive-size-20 $icon-size;
        width: 100%;
        order: 2;
        position: relative;
        z-index: 2;

        @media #{$tablet} {
            padding: sizer(0.75) sizer(1);
        }

        @media #{$tablet} {
            padding: sizer(0.75) sizer(1);
        }

        &.uploading {
            padding: 0;
        }

        &-list{
            padding: 0;
        }

        button {
            svg {
                width: $icon-size;
                height: $icon-size;

                path {
                    fill: $black;
                }
            }
        }
    }
    &-body {
        width: 100%;
        height: auto;
        position: relative;
        order: 1;
        @media #{$mobile} {
            &:hover{
                .upload-card-video-mute{
                    pointer-events: all !important;
                    opacity: 1 !important;
                    right: 1rem !important;
                    svg{
                        height: sizer(1.5) !important;
                        width: sizer(1.5) !important;
                    }
                }
                .upload-card-video-mute.upload-card-video-mute-visible{
                    pointer-events: unset !important;
                    opacity: unset !important;
                }
                & + .upload-footer-hover{
                    &::before{
                        display: block;
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 80px;
                        top: -15px;
                        bottom: 0;
                        left: 0;
                        z-index: 2;
                        opacity: 1;
                        transition: opacity 0.25s ease-in-out;
                        pointer-events: none;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(217, 217, 217, 0) 100%);
                    }
                    .grid-view-title-container{
                        display: flex !important;
                    }
                }
            }
        }
        img {
            cursor: pointer;
            border-top-left-radius: $border-radius-20;
            border-top-right-radius: $border-radius-20;
            object-fit: cover;
        }

        .react-pdf__Page__textContent {
            width: 100% !important;
            height: 100% !important;
        }
        .react-pdf__Document,
        .react-pdf__Page {
            margin: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            font-size: $font-size-16;
            line-height: $line-height-24;
            color: $white;

            canvas {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
                object-position: top;
            }
        }

        .upload-card {
            &-icon {
                background-color: $white;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: sizer(4);
                    height: sizer(4);

                    path {
                        fill: $spanish-gray;
                    }
                }
            }
            &-image,
            &-pdf {
                &-visible {
                    &:hover {
                        &::before {
                            opacity: 1;
                        }
                    }

                    &::before {
                        opacity: 0;
                    }
                }

                /*&:before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    bottom: 0px;
                    left: 0;
                    z-index: 2;
                    opacity: 0;
                    transition: opacity .25s ease-in-out;
                    pointer-events: none;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(217, 217, 217, 0.00) 50.17%);
                    transition: all .25s ease-in-out;
                }*/
            }
            &-audio {
                background-color: $white;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    &::before {
                        opacity: 1;
                    }
                }
               /* &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    bottom: 0px;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    pointer-events: none;
                    transition: all .25s ease-in-out;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(217, 217, 217, 0.00) 50.17%);
                }*/

                .upload-card-audio-mute {
                    cursor: pointer;
                    // transform: scale(1);
                    z-index: 3;
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    bottom: sizer(1);
                    right: 64px;
                    transform: translateY(-2px);

                    &-visible {
                        opacity: 1;
                        pointer-events: all;
                    }

                    @media only screen and (max-width: 1600px) {
                        right: sizer(1.5);
                        transform: translateY(-4px);
                    }

                    svg {
                        position: relative;
                        z-index: 4;
                        width: $responsive-size-20;
                        height: $responsive-size-20;

                        path {
                            fill: $white;
                        }
                    }
                }

                .upload-card-audio-mute-visible ~ .upload-card-audio-duration { display: flex; }

                .upload-card-audio-duration {
                    color: $white;
                    width: auto;
                    height: sizer(1);
                    display: none;
                    align-items: center;
                    z-index: 3;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    flex-direction: row;
                    position: absolute;
                    bottom: sizer(1.25);
                    right: sizer(1.5);

                    @media only screen and (max-width: 1600px) {
                        opacity: 0;
                    }

                    svg {
                        width: $icon-size;
                        height: $icon-size;
                        margin-right: sizer(.25);

                        path {
                            fill: $white;
                        }
                    }
                }
                audio {
                    width: 100%;
                    height: 100%;
                }
                svg {
                    position: relative;
                    z-index: 1;

                    path {
                        fill: $black;
                    }
                }
            }
            &-video {
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                };

                /*&::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 50px;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    background-color: $black;
                    opacity: 0;
                    pointer-events: none;
                    transition: all .25s ease-in-out;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%, rgb(255, 255, 255) 100%);
                }*/

                .upload-card-video-mute {
                    position: absolute;
                    bottom: sizer(1);
                    right: 62px;
                    transform: translateY(-2px);
                    cursor: pointer;
                    z-index: 3;
                    opacity: 0;
                    pointer-events: none;

                    &-visible {
                        pointer-events: all;
                        opacity: 1;
                    }

                    @media only screen and (max-width: 1600px) {
                        right: sizer(1.5);
                        transform: translateY(-4px);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 3;
                        opacity: .35;
                    }
                    svg {
                        position: relative;
                        z-index: 4;
                        width: $responsive-size-20;
                        height: $responsive-size-20;

                        path {
                            fill: $white;
                        }
                    }
                }

                .upload-card-video-mute-visible ~ .upload-card-video-duration { display: flex; }

                .upload-card-video-duration {
                    position: absolute;
                    bottom: sizer(1.25);
                    right: sizer(1.5);
                    color: $white;
                    width: auto;
                    height: sizer(1);
                    display: none;
                    align-items: center;
                    z-index: 3;
                    font-size: $font-size-16;
                    line-height: $line-height-24;
                    flex-direction: row;

                    svg {
                        width: $icon-size;
                        height: $icon-size;
                        margin-right: sizer(.25);

                    }

                    @media only screen and (max-width: 1600px) {
                        opacity: 0;
                    }
                }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-top-right-radius: $border-radius-20;
                    border-top-left-radius: $border-radius-20;
                }
            }
        }
    }
    &-footer {
        position: relative;
        padding: $responsive-size-20 $icon-size;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 62px;
        z-index: 3;
        pointer-events: none;
        @media #{$mobile} {
            bottom: 50px;
        }
        p {
            height: auto;
            margin-bottom: 0;
            font-size: $font-size-16;
            line-height: $line-height-24;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $white;
        }

        .list-view-title-container,
        .grid-view-title-container {
            width:100%;
            display:inline-flex;
            flex-wrap:nowrap;
            font-family: $inter;
            font-size: $font-size-16;
            line-height: $line-height-24;

            .first-text {
                flex: 0 1 content;
                text-overflow: ellipsis;
                overflow:hidden;
                white-space:nowrap;
                max-width: fit-content;
                width: auto;
                z-index: 3;

                @media #{$tablet} {
                    width: fit-content;
                    max-width: 300px;
                }

                @media #{$mobile} {
                    width: fit-content;
                    max-width: 200px;
                }
            }

            .end-text {
                flex: 1 0 content;
                white-space:nowrap;
                z-index: 3;
            }
        }

        .grid-view-title-container {
            color: $white;
        }
    }
}

.spinner-border{
    color: $spanish-gray;
}

.gif-svg-container{
    //position: absolute;
    //transform: translateX(120px);
}

.add-all-text,
.clear-all-text {
    margin-left: 8px;
    font-weight: 500;
}