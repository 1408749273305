.upload-tag {
    &-AISystem {
        font-size: $font-size-16;
        line-height: $line-height-24;
        color: $spanish-gray;
        margin-top: sizer(1.5);
        margin-bottom: sizer(2.5);
        text-align: center;
    }
    &-wrapper {
        width: 100%;
        height: 100%;

        > div {
            &:last-child {
                padding-bottom: sizer(10);
                @media #{$mobile} {
                    padding-bottom: sizer(16);
                }
            }
        }
        /* hr {
            margin: sizer(2) 0;
        } */
        .upload-offcanvas-header {
            h3 {
                margin-bottom: sizer(0.5);
            }
        }
    }
    &-tagging {
        color: $spanish-gray;

        &-same {
            margin-top: sizer(1.5);
        }
        &-desc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: sizer(1);
            color: $spanish-gray;

            button {
                border: 0;
                outline: none;
                background-color: transparent;

                svg {
                    margin-right: sizer(.25);
                }
            }
            @media #{$tablet} {
                margin-bottom: sizer(0.5);
            }
        }
        &-cont {
            display: flex;
            gap: sizer(1);
            flex-wrap: wrap;
            button{
                width: auto;
                max-width: 100%;
                word-break: break-word;
                height: auto;
            }
        }
        &-clear-all {
            display: flex;
            justify-content: flex-end;

            .icon-button{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                border-radius: 10px;

                &:hover{
                    color: $translucent-black;
                    background-color: $white;
                    svg{
                       path{
                        fill: $translucent-black;
                       }
                    }
                }
            }
        }
    }
    &-no-tagging {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

}

.upload-tag-tagging-desc .paragraph-1{
    margin-bottom: 0;
}

#tag{
    &::placeholder{
        color: $spanish-gray;
    }
    @media #{$mobile} {
        border-radius: 1.25rem;
        padding: 1rem 1.5rem !important;
    }
}