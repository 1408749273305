.email-verification{
    &-success {
        display: flex;
        width: 100%;
        height: calc(100vh - 72px);
        justify-content: center;
        align-items: center;

        .col-12 {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        img {
            margin-bottom: sizer(3);
        }

        h2 {
            margin-bottom: sizer(1);
        }

        p {
            margin-bottom: sizer(3);
        }

        button {
            margin-bottom: sizer(1);
        }
    }
}